import { NgClass, NgStyle } from "@angular/common";
import { Component, computed, input, signal } from "@angular/core";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { ExtensiveImageComponent } from "../../../../media/extensive-image/extensive-image.component";
import { MediaWidgetItemViewerComponent } from "../../../../media/widget/item/viewer/media-widget-item-viewer.component";

@Component({
  selector: "simple-image",
  templateUrl: "./simple-image.component.html",
  styleUrls: ["./simple-image.component.less"],
  standalone: true,
  imports: [
    ExtensiveImageComponent,
    NgStyle,
    MediaWidgetItemViewerComponent,
    NgClass,
  ],
})
export class SimpleImageComponent {
  visibleMediaViewer = signal<boolean>(false);
  currentItem = computed(() => {
    return new MediaWidgetItem({
      url: this.url(),
    });
  });

  url = input<string>();
  rounded = input<boolean>();
  placeholderImageType = input<string>();
  size = input<number>();

  handleClick(event: MouseEvent) {
    event.stopPropagation();
    this.visibleMediaViewer.set(true);
  }
}
