<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.facility?.name"
  [closeText]="'Close' | translate"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [(value)]="tabValue" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi-pin-map-fill'"
            [title]="'Locations' | translate"
            [helpText]="'AssignmentHandleLocationsHelpText' | translate"
            [displayCount]="modifiedModel().facilities.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'ii-entities'"
            [title]="'Entities' | translate"
            [helpText]="'AssignmentHandleEntitiesHelpText' | translate"
            [displayCount]="modifiedModel().entities.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="2">
          <simple-tab-heading
            [icon]="'bi bi-people'"
            [title]="'Groups' | translate"
            [helpText]="'AssignmentHandleGroupsHelpText' | translate"
            [displayCount]="modifiedModel().assigneeGroups.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="3">
          <simple-tab-heading
            [icon]="'bi bi-person'"
            [title]="'Assignees' | translate"
            [helpText]="'AssignmentHandleUsersHelpText' | translate"
            [displayCount]="modifiedModel().assignees.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <handle-facilities-tab
            *tabVisible="tabValue()"
            [items]="modifiedModel().facilities"
            [headerSubtitleText]="
              'AddedLocationsSubtitleAssignment' | translate
            "
            [displayOnlyFacilitiesWithPositionDataInList]="
              modifiedModel().geoControlled
            "
            s
            (onItemsChange)="
              handleItemsChange(propertyStrings.facilities, $event);
              handleItemsChangeExtraFacility()
            "
          >
          </handle-facilities-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-entities-tab
            *tabVisible="tabValue()"
            [items]="modifiedModel().entities"
            [headerSubtitleText]="'AddedEntitiesSubtitleAssignment' | translate"
            [facilityIds]="facilityIds()"
            [hideFacilityColumn]="isEdit"
            [hideAddButton]="!modifiedModel().facilities.length"
            (onItemsChange)="
              handleItemsChange(propertyStrings.entities, $event)
            "
          >
          </handle-entities-tab>
        </p-tabpanel>
        <p-tabpanel [value]="2">
          <handle-assignee-groups-tab
            *tabVisible="tabValue()"
            [items]="modifiedModel().assigneeGroups"
            [headerSubtitleText]="'AddedGroupsSubtitleAssignment' | translate"
            [isMeasure]="true"
            (onItemsChange)="
              handleItemsChange(propertyStrings.assigneeGroups, $event)
            "
          >
          </handle-assignee-groups-tab>
        </p-tabpanel>
        <p-tabpanel [value]="3">
          <handle-assignees-tab
            *tabVisible="tabValue()"
            [items]="modifiedModel().assignees"
            [headerSubtitleText]="'AddedUsersSubtitleAssignment' | translate"
            [selectedAssigneeGroups]="modifiedModel().assigneeGroups"
            [isMeasure]="true"
            (onItemsChange)="
              handleItemsChange(propertyStrings.assignees, $event)
            "
          >
          </handle-assignees-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
