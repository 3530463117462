import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { SimpleFormColorComponent } from "../../../../simple-components/crud/modal/tabs/details/form/form-color/simple-form-color.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "../../../../simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { Choice } from "../../../choice";

@Component({
  selector: "details-choice-tab",
  templateUrl: "./details-choice-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    SimpleFormColorComponent,
    TranslateModule,
    SimpleFormSelectComponent,
  ],
})
export class DetailsChoiceTabComponent extends DetailsBaseDirective<Choice> {
  selectableRuleItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableRuleItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.value]: [
          this.modifiedModel().value,
          [Validators.required, Validators.maxLength(3)],
        ],
        [this.propertyStrings.color]: [
          this.modifiedModel().color,
          Validators.required,
        ],
        [this.propertyStrings.rulesInternal]: [
          this.modifiedModel().rulesInternal,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableRuleItems() {
    this.selectableRuleItems.set([
      new FormSelectItem("isError", this.translationService.instant("IsError")),
      new FormSelectItem(
        "isDefault",
        this.translationService.instant("IsDefault"),
      ),
      new FormSelectItem(
        "isUnspecified",
        this.translationService.instant("IsUnspecified"),
      ),
      new FormSelectItem(
        "allowPriority",
        this.translationService.instant("AllowsPriority"),
      ),
    ]);
  }
}
