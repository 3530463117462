import { Facility } from "app/components/facility/facility";
import { TemplateType } from "app/components/template-type/template-type";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableAssignmentTemplateColumn<
  T,
> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => AssignmentTemplateContent;

  constructor(
    labels: string[],
    getValue: (row: T) => AssignmentTemplateContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.ASSIGNMENT_TEMPLATE;
  }
}

export class AssignmentTemplateContent {
  templateType: TemplateType;
  facility: Facility;

  constructor(templateType: TemplateType, facility: Facility) {
    this.templateType = templateType;
    this.facility = facility;
  }
}
