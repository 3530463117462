import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "../../../../simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { Task } from "../../../task";

@Component({
  selector: "details-task-tab",
  templateUrl: "./details-task-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
    SimpleFormSelectComponent,
  ],
})
export class DetailsTaskTabComponent extends DetailsBaseDirective<Task> {
  selectableRuleItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableRuleItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.text]: [
          this.modifiedModel().text,
          [Validators.required, Validators.maxLength(150)],
        ],
        [this.propertyStrings.code]: [
          this.modifiedModel().code,
          Validators.maxLength(150),
        ],
        [this.propertyStrings.description]: [
          this.modifiedModel().description,
          Validators.maxLength(2000),
        ],
        [this.propertyStrings.rulesInternal]: [
          this.modifiedModel().rulesInternal,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableRuleItems() {
    this.selectableRuleItems.set([
      new FormSelectItem(
        "isRequired",
        this.translationService.instant("IsRequired"),
      ),
      new FormSelectItem(
        "isDefault",
        this.translationService.instant("IsDefault"),
      ),
      new FormSelectItem(
        "allowComment",
        this.translationService.instant("AllowsComment"),
      ),
      new FormSelectItem(
        "allowMedia",
        this.translationService.instant("AllowsMedia"),
      ),
    ]);
  }
}
