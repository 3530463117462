import {
  Directive,
  effect,
  inject,
  input,
  output,
  signal,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalStateService } from "app/global-state/global-state.service";
import { ToastrService } from "app/services/toastr.service";
import { TranslationService } from "app/services/translation.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { PropertyNameGetter } from "app/tools/property-name-getter";
import { Utils } from "app/tools/utils";
import Swal from "sweetalert2";

@Directive()
export abstract class CreateEditBaseDirective<T> {
  initialModel = signal<T | null>(null);
  modifiedModel = signal<T | null>(null);
  pending = signal<boolean>(false);
  changeDetailsIsValid = signal<boolean>(false);
  tabValue = signal<number>(0);

  visible = input<boolean>();
  itemIds = input<string[]>([]);
  hideCloseButton = input<boolean>();
  hideSubmitButton = input<boolean>();
  useUnsavedChangesLogic = input<boolean>();
  displayDeleteButton = input<boolean>();

  onClose = output();
  onDelete = output();
  onRequestDone = output<T[]>();

  get isEdit() {
    return !!this.itemIds().length;
  }

  get onlyOneSelected() {
    return this.itemIds().length === 1;
  }

  protected globalState = inject(GlobalStateService);
  protected translationService = inject(TranslationService);
  protected router = inject(Router);
  protected toastrService = inject(ToastrService);

  constructor() {
    effect(() => {
      if (this.visible()) {
        this.initiate();
      } else {
        this.initialModel.set(null);
        this.modifiedModel.set(null);
        this.pending.set(false);
      }
    });
  }

  handleClose() {
    if (this.useUnsavedChangesLogic()) {
      this.closeWithUnsavedChangesLogic();
    } else {
      this.onClose.emit();
      this.tabValue.set(0);
    }
  }

  handleDelete() {
    this.onDelete.emit();
  }

  handleDetailsChange(form: FormGroup) {
    Object.entries(form.controls).forEach(([name, control]) => {
      if (!control.disabled) {
        this.modifiedModel()[name] = control.value;
        this.refreshModel();
      }
    });
    this.changeDetailsIsValid.set(form.valid);
  }

  handleItemsChange(property: string, items: unknown[]) {
    this.modifiedModel()[property] = items;
    this.refreshModel();
  }

  private refreshModel() {
    this.modifiedModel.set(this.modifiedModel());
  }

  private closeWithUnsavedChangesLogic() {
    if (this.hasUnsavedChanges()) {
      Swal.fire(
        new SwalConfig(this.translationService).getUnsavedChanges({}),
      ).then(async (result) => {
        if (result.value) {
          this.onClose.emit();
          this.tabValue.set(0);
        }
      });
    } else {
      this.onClose.emit();
      this.tabValue.set(0);
    }
  }

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf({} as new (args?: unknown) => T);
  }

  protected hasUnsavedChanges() {
    return !Utils.isEqual(this.modifiedModel(), this.initialModel());
  }

  protected getSubmitText() {
    return this.translationService.instant(this.isEdit ? "Update" : "Create");
  }

  protected getSuccessMessage(model: string, modelPlural?: string) {
    return this.translationService.instant(
      `The${this.onlyOneSelected || !this.isEdit ? model : modelPlural}${this.onlyOneSelected || !this.isEdit ? "Was" : "Were"}${this.isEdit ? "Updated" : "Created"}`,
    );
  }

  protected abstract initiate(): void;
}
