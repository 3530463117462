<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.name"
          [form]="form()"
          [maxlength]="100"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!isEdit"
        >
        </simple-form-input>
      </div>
    </div>
  </div>
</simple-details-tab>
