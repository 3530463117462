<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Header' | translate"
          [name]="propertyStrings.header"
          [form]="form()"
          [maxlength]="150"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-select
          [label]="'Type' | translate"
          [name]="propertyStrings.reportFileFormat"
          [form]="form()"
          [items]="selectableFileFormatItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>

        <simple-form-checkbox
          [label]="'SendReportByEmail' | translate"
          [name]="sendReportByEmailName()"
          [form]="form()"
          [serverErrors]="serverErrors()"
          [disabled]="tooLargeForDownload()"
        >
        </simple-form-checkbox>
      </div>

      @if (sendReportByEmail() || tooLargeForDownload()) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "Recipients" | translate }}
          </div>
          @if (subscribers().length) {
            <div class="recipient-section">
              <div class="recipient-section-header">
                {{ "FromNotifications" | translate }}
              </div>
              @for (subscriber of subscribers(); track $index) {
                <div
                  class="recipient"
                  title="{{
                    (subscriber.selected ? 'Unselect' : 'Select') | translate
                  }}"
                  (mouseover)="subscriber.hovered = true"
                  (mouseleave)="subscriber.hovered = false"
                  (click)="subscriber.selected = !subscriber.selected"
                >
                  <span
                    class="email subscriber"
                    [ngClass]="{
                      selected: subscriber.selected,
                    }"
                  >
                    {{ subscriber.email }}
                  </span>
                  <div
                    class="action bi"
                    [ngClass]="{
                      'bi-square': !subscriber.selected && !subscriber.hovered,
                      'bi-check-square':
                        subscriber.hovered && !subscriber.selected,
                      'bi-check-square-fill': subscriber.selected,
                    }"
                  ></div>
                </div>
              }
            </div>
          }
          <div class="recipient-section">
            <div class="recipient-section-header">
              {{ "Users" | translate }}
            </div>
            @for (user of modifiedModel().users; track $index) {
              <div
                class="recipient"
                title="{{ 'Remove' | translate }}"
                (click)="removeUser(user)"
              >
                <span class="email">
                  {{ user.email }}
                </span>
                <div class="action bi bi-trash"></div>
              </div>
            }
            <button
              class="btn btn-transparent"
              title="{{ 'Add' | translate }}"
              (click)="handleAddUsersClick()"
            >
              {{ "Add" | translate }}
            </button>
          </div>
          <div class="recipient-section">
            <simple-form-select
              [label]="'ExternalEmailParenthesis' | translate"
              [name]="propertyStrings.emails"
              [form]="form()"
              [items]="selectableEmailItems()"
              [multiple]="true"
              [searchable]="true"
              [addable]="true"
              [serverErrors]="serverErrors()"
              (onAddNewItem)="handleAddNewItemInSelect($event)"
            >
            </simple-form-select>
          </div>
        </div>
      }

      @if (tooLargeForDownload()) {
        <div class="details-section">
          <div class="report-info">
            {{ "TheReportIsTooLargeToDownload" | translate }}
          </div>
        </div>
      }
    </div>
  </div>
</simple-details-tab>
