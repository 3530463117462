import { Component, computed, input, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { ScheduleGroup, ScheduleUser } from "app/components/schedule/schedule";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableUserColumn,
  UserContent,
} from "app/components/simple-components/list/table/columns/simple-table-user-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { ListUserComponent } from "app/components/user/list-user/list-user.component";
import { User } from "app/components/user/user";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-schedule-users-tab",
  templateUrl: "./handle-schedule-users-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListUserComponent,
    TranslateModule,
  ],
})
export class HandleScheduleUsersTabComponent extends HandleItemsBaseDirective<ScheduleUser> {
  globalDummyUser = new ScheduleUser({});

  visibleListUser = signal<boolean>(false);

  selectedScheduleGroups = input<ScheduleGroup[]>([]);

  itemAsIds = computed(() => this.items().map((item) => item.user.id));

  table = viewChild<SimpleTableComponent<ScheduleUser>>(SimpleTableComponent);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "",
        "CanPerformScheduled",
        StringUtils.icons.scheduledAssignment,
        () => this.bulkChange(this.propertyStrings.canPerformScheduled),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "",
        "CanPerformMeasure",
        StringUtils.icons.measureAssignment,
        () => this.bulkChange(this.propertyStrings.canPerformMeasure),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "",
        "CanAssign",
        StringUtils.icons.group,
        () => this.bulkChange(this.propertyStrings.canAssign),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "AddAssignees",
        "AddAssignees",
        StringUtils.icons.new,
        () => this.visibleListUser.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableUserColumn(
        ["Name", "EmailAddress"],
        (row) => new UserContent(row),
      ),
      new SimpleTableRuleColumn(
        ["Rules"],
        (row) => new RuleContent(row.rulesInSchedule, true),
        (row, ruleName) => {
          row[ruleName] = !row[ruleName];
          this.configureTableColumns();
        },
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              this.userExistsInAnySelectedScheduleGroup(row)
                ? StringUtils.icons.warning
                : "",
              "Note",
              "UserExistsInGroup",
              true,
            ),
          ]),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.user.getCurrentStatus(
              this.globalState.selectedOrganization().id,
            ),
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Assignees", StringUtils.icons.user),
    );
  }

  handleCloseListUser() {
    this.visibleListUser.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListUser(items: User[]) {
    this.handleCloseListUser();
    const scheduleUsers = items.map((user) => {
      const scheduleUser = new ScheduleUser(user);
      scheduleUser.user = new User(user);
      return scheduleUser;
    });
    scheduleUsers.forEach((item) => {
      const existingItem = this.items().find((i) => i.user.id === item.id);
      if (existingItem) {
        item.canPerformScheduled = existingItem.canPerformScheduled;
        item.canPerformMeasure = existingItem.canPerformMeasure;
        item.canAssign = existingItem.canAssign;
      }
    });
    this.items.set(scheduleUsers);
    this.items().sortByProperty("fullName");
    this.onItemsChange.emit(this.items());
    this.selectedIds.set([]);
  }

  private bulkChange(property: string) {
    const selectedItems = this.items().filter((item) =>
      this.selectedIds().includes(item.id),
    );
    const allTrue = selectedItems.every((item) => item[property]);
    selectedItems.forEach((item) => (item[property] = !allTrue));
    this.configureTableColumns();
  }

  private userExistsInAnySelectedScheduleGroup(scheduleUser: ScheduleUser) {
    return this.selectedScheduleGroups().some((scheduleGroup) =>
      scheduleGroup.userGroup.users.some(
        (user) => user.id === scheduleUser.user.id,
      ),
    );
  }
}
