import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { TemplateType } from "app/components/template-type/template-type";
import { SimpleDetailsTabComponent } from "../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";

@Component({
  selector: "details-template-type-tab",
  templateUrl: "./details-template-type-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
    SimpleFormSelectComponent,
  ],
})
export class DetailsTemplateTypeTabComponent extends DetailsBaseDirective<TemplateType> {
  selectableTemplateBaseTypeItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableTemplateBaseTypeItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.name]: [
          this.modifiedModel().name,
          [Validators.required, Validators.maxLength(100)],
        ],
        [this.propertyStrings.abbreviation]: [
          this.modifiedModel().abbreviation,
          [Validators.required, Validators.maxLength(10)],
        ],
        [this.propertyStrings.templateBaseTypeId]: [
          this.modifiedModel().templateBaseTypeId,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableTemplateBaseTypeItems() {
    this.selectableTemplateBaseTypeItems.set(
      this.modifiedModel().selectableTemplateBaseTypes.map(
        (baseType) => new FormSelectItem(baseType.id, baseType.name),
      ),
    );
  }
}
