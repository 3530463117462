import { Component, signal } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { PublishingType, Schedule } from "app/components/schedule/schedule";
import { SimpleFormCalendarComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-calendar/simple-form-calendar.component";
import { SimpleFormInfoComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleFormTimeComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-time/simple-form-time.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { StringUtils } from "app/tools/string-utils";
import { DateTime } from "luxon";
import { TimelineComponent } from "../../../timeline/timeline.component";

@Component({
  selector: "details-schedule-tab",
  templateUrl: "./details-schedule-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    SimpleFormSelectComponent,
    SimpleFormCalendarComponent,
    SimpleFormTimeComponent,
    TimelineComponent,
    TranslateModule,
    SimpleFormInfoComponent,
  ],
})
export class DetailsScheduleTabComponent extends DetailsBaseDirective<Schedule> {
  publishingType = PublishingType;

  selectableStatusItems = signal<FormSelectItem[]>([]);
  selectablePublishingTypeItems = signal<FormSelectItem[]>([]);
  selectableIntervalYearItems = signal<FormSelectItem[]>([]);
  selectableIntervalMonthItems = signal<FormSelectItem[]>([]);
  selectableIntervalWeekItems = signal<FormSelectItem[]>([]);
  selectableIntervalItems = signal<FormSelectItem[]>([]);
  selectableWeekdayItems = signal<FormSelectItem[]>([]);

  get minStartDate() {
    return DateTime.now().toSQLDate();
  }

  get maxStartDate() {
    return DateTime.fromSQL(this.modifiedModel().endDate)
      .minus({ days: 1 })
      .toSQLDate();
  }

  get minEndDate() {
    return DateTime.fromSQL(this.modifiedModel().startDate)
      .plus({ days: 1 })
      .toSQLDate();
  }

  ngOnInit() {
    this.setSelectableStatusItems();
    this.setSelectablePublishingTypeItems();
    this.setSelectableIntervalYearItems();
    this.setSelectableIntervalMonthItems();
    this.setSelectableIntervalWeekItems();
    this.setSelectableIntervalItems();
    this.setSelectableWeekdayItems();

    this.form.set(
      this.formBuilder.group(
        {
          [this.propertyStrings.name]: [
            this.modifiedModel().name,
            [Validators.required, Validators.maxLength(100)],
          ],
          [this.propertyStrings.active]: [
            this.modifiedModel().active,
            Validators.required,
          ],
          [this.propertyStrings.publishingType]: [
            this.modifiedModel().publishingType,
            Validators.required,
          ],
          [this.propertyStrings.interval]: [
            this.modifiedModel().interval,
            Validators.required,
          ],
          [this.propertyStrings.weekdaysInternal]: [
            this.modifiedModel().weekdaysInternal,
            Validators.required,
          ],
          [this.propertyStrings.daysDelay]: [
            this.modifiedModel().daysDelay,
            [Validators.required, this.daysDelayValidator],
          ],
          [this.propertyStrings.startDate]: [
            this.modifiedModel().startDate,
            [Validators.required, this.dateValidator],
          ],
          [this.propertyStrings.endDate]: [
            this.modifiedModel().endDate,
            this.dateValidator,
          ],
          [this.propertyStrings.publishingTime]: [
            this.modifiedModel().publishingTime,
            [Validators.required, this.timeValidator],
          ],
        },
        { validators: this.weekdaysValidator },
      ),
    );
    super.ngOnInit();
  }

  private daysDelayValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.NUMBER).test(control.value)) {
      return { number: true };
    } else {
      return null;
    }
  }

  private dateValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.DATE).test(control.value)) {
      return { date: true };
    } else {
      return null;
    }
  }

  private timeValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.TIME).test(control.value)) {
      return { time: true };
    } else {
      return null;
    }
  }

  private weekdaysValidator(form: FormGroup): ValidationErrors {
    const publishingType = form.get("publishingType");
    const weekdaysInternal = form.get("weekdaysInternal");

    if (
      publishingType.value === PublishingType.Weekly &&
      !weekdaysInternal.value.length
    ) {
      weekdaysInternal.setErrors({ required: true });
    } else {
      weekdaysInternal.setErrors(null);
    }
    return null;
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }

  private setSelectablePublishingTypeItems() {
    this.selectablePublishingTypeItems.set([
      new FormSelectItem(
        PublishingType.Once,
        this.translationService.instant("Once"),
      ),
      new FormSelectItem(
        PublishingType.Weekly,
        this.translationService.instant("Weekly"),
      ),
      new FormSelectItem(
        PublishingType.Monthly,
        this.translationService.instant("Monthly"),
      ),
      new FormSelectItem(
        PublishingType.Annually,
        this.translationService.instant("Annually"),
      ),
    ]);
  }

  private setSelectableIntervalYearItems() {
    const items = Array.from(
      { length: 3 },
      (_, index) =>
        new FormSelectItem(
          index,
          this.translationService.instant(
            StringUtils.getIntervalText(index, PublishingType.Annually),
          ),
        ),
    );
    this.selectableIntervalYearItems.set(items);
  }

  private setSelectableIntervalMonthItems() {
    const items = Array.from(
      { length: 11 },
      (_, index) =>
        new FormSelectItem(
          index,
          this.translationService.instant(
            StringUtils.getIntervalText(index, PublishingType.Monthly),
          ),
        ),
    );
    this.selectableIntervalMonthItems.set(items);
  }

  private setSelectableIntervalWeekItems() {
    const items = Array.from(
      { length: 53 },
      (_, index) =>
        new FormSelectItem(
          index,
          this.translationService.instant(
            StringUtils.getIntervalText(index, PublishingType.Weekly),
          ),
        ),
    );
    this.selectableIntervalWeekItems.set(items);
  }

  setSelectableIntervalItems() {
    if (
      this.modifiedModel().publishingType === PublishingType.Once ||
      this.modifiedModel().publishingType === PublishingType.Annually
    ) {
      this.selectableIntervalItems.set(this.selectableIntervalYearItems());
    } else if (this.modifiedModel().publishingType === PublishingType.Monthly) {
      this.selectableIntervalItems.set(this.selectableIntervalMonthItems());
    } else if (this.modifiedModel().publishingType === PublishingType.Weekly) {
      this.selectableIntervalItems.set(this.selectableIntervalWeekItems());
    }
  }

  private setSelectableWeekdayItems() {
    const items = Array.from(
      { length: 7 },
      (_, index) =>
        new FormSelectItem(
          index,
          this.translationService.instant(
            StringUtils.getWeekdayText([index], "long"),
          ),
        ),
    );
    this.selectableWeekdayItems.set(items);
  }
}
