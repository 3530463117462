<simple-landing-page [isLoading]="pending()">
  <div class="header-wrapper" ngProjectAs="landing-page-header-slot">
    <div
      class="image"
      [ngClass]="{
        clickable: globalState
          .loggedInUser()
          .canEdit(globalState.selectedOrganization().friendlyUrl),
      }"
      (click)="visibleChangeLogotypeImage.set(true)"
    >
      <extensive-image
        [imageUrl]="selectedOrganization().logotypeUrl"
        [imageStorageSize]="65"
        [placeholderImageType]="'organization'"
        [displayLoader]="true"
      >
      </extensive-image>
      <div class="image-overlay bi bi-camera-fill"></div>
    </div>
    <div class="details">
      <div class="title" [title]="selectedOrganization().name">
        {{ selectedOrganization().name }}
      </div>
      @if (selectedOrganization().publicName) {
        <div class="subtitle" [title]="selectedOrganization().publicName">
          {{ selectedOrganization().publicName }}
        </div>
      }
    </div>
    <div class="actions">
      <div
        class="actions-menu bi-three-dots"
        [ngClass]="{
          opened: actionsOpened(),
        }"
        [title]="'OpenMenu' | translate"
        (click)="actionTemplate.toggle($event)"
      ></div>
      <p-popover
        #actionTemplate
        (onShow)="actionsOpened.set(true)"
        (onHide)="actionsOpened.set(false)"
      >
        <div class="popover-panel-header">
          {{ selectedOrganization().name }}
        </div>
        @if (globalState.loggedInUser().isSuperAdmin()) {
          <div
            class="standard-menu-item"
            [title]="'NewOrganization' | translate"
            (click)="visibleCreateEdit.set(true); actionTemplate.toggle($event)"
          >
            <i [class]="icons.new"></i>{{ "NewOrganization" | translate }}
          </div>
        }
        <div
          class="standard-menu-item"
          [title]="'EditOrganization' | translate"
          (click)="visibleCreateEdit.set(true); actionTemplate.toggle($event)"
        >
          <i [class]="icons.edit"></i>{{ "EditOrganization" | translate }}
        </div>
        @if (globalState.loggedInUser().isSuperAdmin()) {
          <div
            class="standard-menu-item"
            [title]="'DeleteOrganization' | translate"
            (click)="actionTemplate.toggle($event)"
          >
            <i [class]="icons.delete"></i>{{ "DeleteOrganization" | translate }}
          </div>
        }
      </p-popover>
    </div>
    @if (selectedOrganization().isTest) {
      <div class="test-marker">
        {{ stringUtils.TEST }}
      </div>
    }
  </div>
  <div class="content" ngProjectAs="landing-page-content-slot">
    <view-details-tab
      [modifiedModel]="selectedOrganization()"
    ></view-details-tab>
  </div>
</simple-landing-page>

<create-edit-organization
  [visible]="visibleCreateEdit()"
  [useUnsavedChangesLogic]="true"
  [displayDeleteButton]="false"
  (onClose)="handleCloseCreateEdit()"
  (onRequestDone)="handleRequestDoneCreateEdit($event)"
>
</create-edit-organization>

<change-logotype-image
  [visible]="visibleChangeLogotypeImage()"
  (onClose)="handleCloseChangeLogotypeImage()"
  (onRequestDone)="handleRequestDoneChangeLogotypeImage($event)"
></change-logotype-image>
