import {
  Component,
  ElementRef,
  inject,
  input,
  signal,
  viewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Entity } from "app/components/entity/entity";
import { ListEntityComponent } from "app/components/entity/list-entity/list-entity.component";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowAction,
  SimpleTableRowActionEdit,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  EntityContent,
  SimpleTableEntityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-entity-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { ExportService } from "app/services/export.service";
import {
  ImportActionKey,
  ImportExportModelKey,
  ImportHelper,
} from "app/services/import-helper";
import { ImportService } from "app/services/import.service";
import { ParseXLSXService } from "app/services/parse-xlsx.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { FileHelper } from "app/tools/file-helper";
import { FileUtils } from "app/tools/file-utils";
import { StringUtils } from "app/tools/string-utils";
import { coordinateSystems } from "app/tools/utils";
import Swal from "sweetalert2";
import { CreateEditEntityComponent } from "../../../../entity/create-edit-entity/create-edit-entity.component";

@Component({
  selector: "handle-entities-tab",
  templateUrl: "./handle-entities-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListEntityComponent,
    TranslateModule,
    CreateEditEntityComponent,
  ],
})
export class HandleEntitiesTabComponent extends HandleItemsBaseDirective<Entity> {
  visibleListEntity = signal<boolean>(false);
  pending = signal<boolean>(false);

  hideFacilityColumn = input<boolean>();
  facilityIds = input<string[]>([]);
  hideAddButton = input<boolean>();
  facilityName = input<string>();

  importInput = viewChild<ElementRef>("importInput");

  private importService = inject(ImportService);
  private exportService = inject(ExportService);
  private importHelper = inject(ImportHelper);
  private parseXLSXService = inject(ParseXLSXService);
  private fileHelper = inject(FileHelper);

  protected async configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        this.facilityName() ? "Create" : "AddEntities",
        this.facilityName() ? "Create" : "AddEntities",
        StringUtils.icons.new,
        () =>
          this.facilityName()
            ? this.visibleCreateEditItem.set(true)
            : this.visibleListEntity.set(true),
        () => this.hideAddButton(),
      ),
      new SimpleListAction(
        "Import",
        "Import",
        StringUtils.icons.import,
        () => this.importInput().nativeElement.click(),
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() ||
          !this.facilityName(),
      ),
      new SimpleListAction(
        "Export",
        "Export",
        StringUtils.icons.export,
        () => this.export(),
        () =>
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) ||
          !this.facilityName(),
      ),
    ]);

    if (this.globalState.loggedInUser().isSuperAdmin()) {
      // Check if has any imports to start monitoring.
      try {
        const inProgress = await this.importService.hasImportInProgress(
          ImportExportModelKey.Entity,
          ImportActionKey.ImportEntities,
        );
        if (inProgress) {
          this.importService.monitorProgress(
            ImportExportModelKey.Entity,
            ImportActionKey.ImportEntities,
          );
        }
      } catch (error) {
        this.toastrService.error(error.message);
      }
    }
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableEntityColumn(
        ["Name", "Description"],
        (row) => new EntityContent(row),
      ),
      new SimpleTableTextColumn(
        ["TypeDesignation", "Manufacturer"],
        (row) => new TextContent(row.typeDesignation, row.manufacturer),
      ),
      new SimpleTableFacilityColumn(
        ["Location"],
        (row) => new FacilityContent(row.facility),
        () => this.hideFacilityColumn(),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status ? StringUtils.ACTIVE : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.currentItem.set(row);
          this.visibleCreateEditItem.set(true);
        },
        () => !this.facilityName(),
      ),
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Entities", StringUtils.icons.entity),
    );
  }

  handleCloseListEntity() {
    this.visibleListEntity.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListEntity(items: Entity[]) {
    this.handleCloseListEntity();
    this.items.set(items.map((item) => new Entity(item)));
    this.items().sortByProperty("name");
    this.onItemsChange.emit(this.items());
    this.selectedIds.set([]);
  }

  handleImportInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    if (file) {
      this.import(file);
    }
    input.value = "";
  }

  protected async import(file: File) {
    if (!FileUtils.isExcel(file)) {
      this.toastrService.error(
        this.translationService.instant(StringUtils.BAD_FILE_FORMAT),
      );
    }
    try {
      const inProgress = await this.importService.hasImportInProgress(
        ImportExportModelKey.Entity,
        ImportActionKey.ImportEntities,
      );
      if (inProgress) {
        this.toastrService.info(
          `${this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS)}!`,
        );
      } else {
        let { value: coordinatSystem } = await Swal.fire(
          new SwalConfig(this.translationService).getInfo({
            title: this.translationService.instant("ChooseCoordinateSystem"),
            text: this.translationService.instant(
              "ChooseCoordinateSystemStatement",
            ),
            confirmButtonText: this.translationService.instant("Import"),
            cancelButtonText: this.translationService.instant("Cancel"),
            input: "select",
            inputOptions: {
              doNotConvert: "WGS84",
              ...coordinateSystems,
            },
          }),
        );
        if (coordinatSystem) {
          if (coordinatSystem === "doNotConvert") {
            coordinatSystem = "";
          }
          const parsedData = await this.parseXLSXService.parseFile(file);
          const data = this.importHelper.generateData(
            parsedData,
            ImportExportModelKey.Entity,
            coordinatSystem as string,
          );
          if (!data.length) {
            this.toastrService.error(
              this.translationService.instant(StringUtils.EMPTY_FILE_ERROR),
            );
          }
          const items = await this.importService.import(
            data,
            ImportExportModelKey.Entity,
            ImportActionKey.ImportEntities,
            this.globalState.selectedOrganization().id,
          );
          if (items.length) {
            this.toastrService.success(
              this.translationService.instant(StringUtils.IMPORT_SUCCEEDED),
            );
            this.handleConfirmListEntity(items);
          }
        }
      }
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  private async export() {
    this.pending.set(true);
    try {
      const fileData = await this.exportService.export(
        this.selectedIds(),
        ImportExportModelKey.Entity,
        this.globalState.selectedOrganization().id,
      );
      this.pending.set(false);
      this.fileHelper.handleFile(fileData);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
}
