<handle-header
  [headerTitleText]="'AddedTasks' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
></handle-header>

<simple-table
  [columns]="columns()"
  [headerActions]="headerActions()"
  [rowActions]="rowActions()"
  [emptyState]="emptyState()"
  [rows]="items()"
  [selectedIds]="selectedIds()"
  [addedIds]="addedIds()"
  [modifiedIds]="modifiedIds()"
  [unselectableIds]="unselectableIds()"
  [inWizard]="true"
  [totalCount]="totalCount"
  [selectedCount]="selectedCount"
  [hasSelectableData]="hasSelectableData"
  [someAreSelected]="someAreSelected"
  [allAreSelected]="allAreSelected"
  (onCheckboxClick)="handleCheckboxClick($event)"
  (onRowSingleClick)="handleRowSingleClick($event)"
  (onRowDoubleClick)="handleRowDoubleClick($event)"
  (onRowCheckboxClick)="handleRowCheckboxClick($event)"
>
</simple-table>

<list-task
  [visible]="visibleListTask()"
  [isInModal]="true"
  [dataToSetAsSelected]="itemAsIds()"
  [includeChoices]="includeChoicesInList()"
  [templateTypeId]="templateTypeIdForList()"
  (onClose)="handleCloseListTask()"
  (onConfirm)="handleConfirmListTask($event)"
></list-task>

<list-choice
  [visible]="visibleListChoice()"
  [isInModal]="true"
  [dataToSetAsSelected]="choiceIds()"
  (onClose)="handleCloseListChoice()"
  (onConfirm)="handleConfirmListChoice($event)"
></list-choice>
