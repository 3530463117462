import { Component, input } from "@angular/core";
import { Facility } from "app/components/facility/facility";
import { TemplateType } from "app/components/template-type/template-type";
import { SimpleImageComponent } from "../image/simple-image.component";

@Component({
  selector: "simple-assignment-template",
  templateUrl: "./simple-assignment-template.component.html",
  styleUrls: ["./simple-assignment-template.component.less"],
  standalone: true,
  imports: [SimpleImageComponent],
})
export class SimpleAssignmentTemplateComponent {
  templateType = input<TemplateType>();
  facility = input<Facility>();
}
