<div class="publish-date-wrapper">
  @if (value().date) {
    <div
      class="publish-date"
      [title]="value().date | datex: 'with-time'"
      [ngClass]="{
        today:
          utils.isTodayDate(value().date) && utils.isFutureDate(value().date),
        'red-color': !value().active,
      }"
    >
      @if (value().active) {
        {{ format(utils.convertToHumanDate(value().date)) }}
      } @else {
        {{ value().date | datex }}
      }
    </div>
  }
</div>
