<handle-header
  [headerTitleText]="'AddedEntities' | translate"
  [headerSubtitleText]="headerSubtitleText()"
  [listActions]="listActions()"
></handle-header>

<simple-table
  [columns]="columns()"
  [headerActions]="headerActions()"
  [rowActions]="rowActions()"
  [emptyState]="emptyState()"
  [rows]="items()"
  [selectedIds]="selectedIds()"
  [addedIds]="addedIds()"
  [modifiedIds]="modifiedIds()"
  [unselectableIds]="unselectableIds()"
  [inWizard]="true"
  [totalCount]="totalCount"
  [selectedCount]="selectedCount"
  [hasSelectableData]="hasSelectableData"
  [someAreSelected]="someAreSelected"
  [allAreSelected]="allAreSelected"
  [pending]="pending()"
  (onCheckboxClick)="handleCheckboxClick($event)"
  (onRowSingleClick)="handleRowSingleClick($event)"
  (onRowDoubleClick)="handleRowDoubleClick($event)"
  (onRowCheckboxClick)="handleRowCheckboxClick($event)"
>
</simple-table>

<list-entity
  [visible]="visibleListEntity()"
  [isInModal]="true"
  [dataToSetAsSelected]="itemAsIds()"
  [facilityIds]="facilityIds()"
  (onClose)="handleCloseListEntity()"
  (onConfirm)="handleConfirmListEntity($event)"
></list-entity>

<create-edit-entity
  [visible]="visibleCreateEditItem()"
  [facilityName]="facilityName()"
  [currentEntity]="currentItem()"
  (onClose)="handleCloseCreateEditItem()"
  (onRequestDone)="handleRequestDoneCreateEditItem($event)"
></create-edit-entity>

<input
  #importInput
  type="file"
  class="u-display-none"
  [accept]="acceptedMediaTypesForImport"
  (change)="handleImportInputChange($event)"
/>
