import { Entity } from "app/components/entity/entity";
import { Facility } from "app/components/facility/facility";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableMeasureTargetColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => MeasureTargetContent;

  constructor(
    labels: string[],
    getValue: (row: T) => MeasureTargetContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.MEASURE_TARGET;
  }
}

export class MeasureTargetContent {
  facility: Facility;
  entity: Entity;

  constructor(facility: Facility, entity: Entity) {
    this.facility = facility;
    this.entity = entity;
  }
}
