@if (!isInModal()) {
  <ng-container *ngTemplateOutlet="tableContent"></ng-container>
}
@if (isInModal()) {
  <simple-crud-modal
    [visible]="visible()"
    [headerText]="'AddMeasures' | translate"
    [closeText]="'Close' | translate"
    [submitText]="'ConfirmSelected' | translate"
    [large]="true"
    (onClose)="handleClose()"
    (onSubmit)="handleConfirm()"
  >
    <ng-container *ngTemplateOutlet="tableContent"></ng-container>
  </simple-crud-modal>
}

<ng-template #tableContent>
  <list-header
    [emptyState]="emptyState()"
    [listActions]="listActions()"
    [columns]="columns()"
    [inModal]="isInModal()"
    (onCustomColumnConfigChange)="handleCustomColumnConfigChange($event)"
  ></list-header>
  <simple-table
    [initialFacets]="initialFacets()"
    [searchValue]="searchValue()"
    [filterInputs]="filterInputs()"
    [sortObjects]="sortObjects()"
    [currentSortObject]="currentSortObject()"
    [columns]="columns()"
    [headerActions]="headerActions()"
    [rowActions]="rowActions()"
    [emptyState]="emptyState()"
    [filteredIds]="filteredIds()"
    [rows]="items()"
    [selectedIds]="selectedIds()"
    [addedIds]="addedIds()"
    [modifiedIds]="modifiedIds()"
    [unselectableIds]="unselectableIds()"
    [pending]="pending()"
    [inModal]="isInModal()"
    [totalCount]="totalCount"
    [selectedCount]="selectedCount"
    [hasSelectableData]="hasSelectableData"
    [someAreSelected]="someAreSelected"
    [allAreSelected]="allAreSelected"
    [hasActiveSearchOrFilter]="hasActiveSearchOrFilter"
    (onSearch)="handleSearch($event)"
    (onSearchClear)="handleSearchClear()"
    (onSortDirectionChange)="handleSortDirectionChange($event)"
    (onSortLabelChange)="handleSortLabelChange($event)"
    (onFilter)="handleFilter($event)"
    (onFilterClear)="handleFilterClear()"
    (onSelectedClear)="handleSelectedClear()"
    (onCheckboxClick)="handleCheckboxClick($event)"
    (onRowSingleClick)="handleRowSingleClick($event)"
    (onRowDoubleClick)="handleRowDoubleClick($event)"
    (onRowCheckboxClick)="handleRowCheckboxClick($event)"
    (onLazyLoad)="handleLazyLoad($event)"
  >
  </simple-table>

  <detailed-assignment-measure-ongoing
    [visible]="visibleCreateEdit()"
    [itemIds]="itemIds()"
    [useUnsavedChangesLogic]="!isInModal()"
    [displayDeleteButton]="
      !!itemIds().length &&
      globalState
        .loggedInUser()
        .canEdit(globalState.selectedOrganization().friendlyUrl)
    "
    (onClose)="handleCloseCreateEdit()"
    (onDelete)="handleDelete()"
    (onRequestDone)="handleRequestDoneCreateEdit($event)"
  ></detailed-assignment-measure-ongoing>

  <create-manual-measure
    [visible]="visibleCreateManualMeasure()"
    [itemIds]="itemIds()"
    [useUnsavedChangesLogic]="!isInModal()"
    (onClose)="handleCloseCreateManualMeasure()"
    (onRequestDone)="handleRequestDoneCreateManualMeasure($event)"
  >
  </create-manual-measure>

  <generate-report
    [visible]="visibleGenerateReport()"
    [itemIds]="itemIds()"
    [isMeasure]="true"
    [sort]="filterObject.sort"
    (onClose)="handleCloseGenerateReport()"
    (onRequestDone)="handleRequestDoneGenerateReport($event)"
  >
  </generate-report>

  <static-report
    [visible]="visibleGetReport()"
    [itemIds]="itemIds()"
    [hideText]="onlyOneSelected"
    [isMeasure]="true"
    (onClose)="handleCloseGetReport()"
    (onRequestDone)="handleRequestDoneGetReport($event)"
  >
  </static-report>

  <handle-assignee-groups-and-assignees
    [visible]="visibleHandleAssigneeGroupsAndAssignees()"
    [itemIds]="itemIds()"
    [isMeasure]="true"
    (onClose)="handleCloseHandleAssigneeGroupsAndAssignees()"
    (onRequestDone)="handleRequestDoneHandleAssigneeGroupsAndAssignees($event)"
  >
  </handle-assignee-groups-and-assignees>

  <change-priority
    [visible]="visibleChangePriority()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseChangePriority()"
    (onRequestDone)="handleRequestDoneChangePriority($event)"
  >
  </change-priority>

  <change-admin-comment
    [visible]="visibleChangeAdminComment()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseChangeAdminComment()"
    (onRequestDone)="handleRequestDoneChangeAdminComment($event)"
  ></change-admin-comment>

  <change-status-assignment
    [visible]="visibleChangeStatus()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseChangeStatus()"
    (onRequestDone)="handleRequestDoneChangeStatus($event)"
  >
  </change-status-assignment>

  <simple-view-client
    [(visible)]="visibleViewClient"
    [headerText]="'Client' | translate"
    [client]="client()"
    (onClose)="visibleViewClient.set(false)"
  >
  </simple-view-client>

  <simple-view-content
    [(visible)]="visibleViewContent"
    [headerText]="'AdminComment' | translate"
    [content]="adminComment()"
    (onClose)="visibleViewContent.set(false)"
  >
  </simple-view-content>

  <simple-view-items
    [(visible)]="visibleViewItems"
    (onClose)="visibleViewItems.set(false)"
  >
    @if (assigneeGroups().length) {
      <div>
        <div class="sub-label">
          {{ "Groups" | translate }}
        </div>
        <simple-view-connections [userGroups]="assigneeGroups()">
        </simple-view-connections>
      </div>
    }
    @if (assignees().length) {
      <div>
        <div class="sub-label">
          {{ "Assignees" | translate }}
        </div>
        <simple-view-connections [users]="assignees()">
        </simple-view-connections>
      </div>
    }
  </simple-view-items>
</ng-template>
