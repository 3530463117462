import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { NgClass, NgStyle } from "@angular/common";
import {
  Component,
  computed,
  inject,
  input,
  output,
  signal,
  viewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import { ToastrService } from "app/services/toastr.service";
import { NumberUtils } from "app/tools/number-utils";
import { Utils } from "app/tools/utils";
import { environment } from "environments/environment";
import { Popover } from "primeng/popover";
import { Scroller, ScrollerScrollIndexChangeEvent } from "primeng/scroller";
import { debounceTime, fromEvent, Subscription } from "rxjs";
import { GetVisibleRowActions } from "../../../../../pipes/get-visible-row-actions.pipe";
import { ListSkeletonComponent } from "../../../various/skeleton/list/list-skeleton.component";
import { SimpleTableColumn } from "../columns/simple-table-column";
import { SimpleTableEmptyState } from "../empty-state/simple-table-empty-state";
import { ColumnTypeSelectorComponent } from "./column-types/column-type-selector.component";
import { SimpleTableRowAction } from "./simple-table-row-action";

@Component({
  selector: "simple-table-body",
  templateUrl: "./simple-table-body.component.html",
  styleUrls: ["./simple-table-body.component.less"],
  standalone: true,
  imports: [
    NgClass,
    CdkCopyToClipboard,
    TranslateModule,
    Scroller,
    NgStyle,
    Popover,
    ColumnTypeSelectorComponent,
    ListSkeletonComponent,
    GetVisibleRowActions,
  ],
})
export class SimpleTableBodyComponent<
  T extends {
    id?: string;
    url?: string;
  },
> {
  private viewModeSubscription = new Subscription();

  itemSize = signal<number>(0);

  columns = input<SimpleTableColumn<T>[]>();
  emptyState = input<SimpleTableEmptyState>();
  rowActions = input<SimpleTableRowAction<T>[]>();
  isSingleSelect = input<boolean>();
  rows = input<T[]>();
  selectedIds = input<string[]>();
  addedIds = input<string[]>();
  modifiedIds = input<string[]>();
  unselectableIds = input<string[]>();
  pending = input<boolean>();
  inModal = input<boolean>();
  inWizard = input<boolean>();
  hasActiveSearchOrFilter = input<boolean>();

  visibleColumns = computed(() =>
    this.columns().filter((column) => !column.hidden()),
  );

  // customVisibleColumns = computed(() =>
  //   this.columns()
  //     .filter((column) => !column.customHidden)
  //     .sort((a, b) => a.customOrder - b.customOrder),
  // );

  onRowSingleClick = output<T>();
  onRowDoubleClick = output<T>();
  onRowCheckboxClick = output<T>();
  onLazyLoad = output<ScrollerScrollIndexChangeEvent>();

  scrollerBody = viewChild<Scroller>("scroller");

  globalState = inject(GlobalStateService);
  protected toastrService = inject(ToastrService);

  get itemHeight() {
    return Utils.isMediumScreenSize()
      ? this.visibleColumns().length * 50 + 50
      : 50;
  }

  ngOnInit() {
    this.itemSize.set(this.itemHeight);
    this.viewModeSubscription.add(
      fromEvent(window, "resize")
        .pipe(debounceTime(NumberUtils.WINDOW_RESIZE_WAIT_TIME))
        .subscribe(() => {
          this.itemSize.set(this.itemHeight);
        }),
    );
  }

  handleRowSingleClick(row: T) {
    this.onRowSingleClick.emit(row);
  }

  handleRowDoubleClick(row: T, event: MouseEvent) {
    // TODO kolla om var på menyknapp, bild eller checkbox och isåfall inte emitta!
    this.onRowDoubleClick.emit(row);
  }

  handleRowCheckboxClick(row: T) {
    // We do nothing as the single click on the row does the same thing for us.
    //this.onRowCheckboxClick.emit(row);
  }

  getReferenceUrl(row: T) {
    return `${environment.coreUrl}/${
      this.globalState.selectedOrganization().friendlyUrl
    }/${row.url}`;
  }

  handleCopyReferenceClick() {
    this.toastrService.secondary("Referens kopierad");
  }

  handleLazyLoad(event: ScrollerScrollIndexChangeEvent) {
    this.onLazyLoad.emit(event);
  }

  trackByIndex(idx: number): number {
    return idx;
  }

  ngOnDestroy() {
    this.viewModeSubscription.unsubscribe();
  }
}
