import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { Result } from "app/components/assignment/assignment";
import { ScheduleForViewing } from "app/components/schedule/schedule";
import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { RoutesUtils } from "app/tools/routes-utils";
import { Entity } from "../entity/entity";
import { TaskChoice } from "../template-type/template-type";

export class Facility extends CrudItem<Facility> {
  name: string = "";
  description: string = "";
  streetName: string = "";
  streetNumber: string = "";
  zipCode: string = "";
  city: string = "";
  latitude: string = "";
  longitude: string = "";
  radius: string = "";
  externalId: string = "";
  infoLink: string = "";
  status: boolean = true;
  entities: Entity[] = [];
  media: MediaWidgetItem[] = [];
  thumbnailUrl: string = null;
  hasPosition: boolean = false;
  hasGeoControlledTemplates: boolean = false;
  results: Result[] = [];
  accessible: boolean = false;
  assignmentTemplates: AssignmentTemplate[] = [];
  schedules: ScheduleForViewing[] = [];
  extraInfo: string = "";

  issueDetails: string = "";
  issueMedia: MediaWidgetItem[] = [];
  priority: boolean = false;
  taskChoices: TaskChoice[] = [];
  numberOfErrors: number;
  shouldDeleteEntities: boolean = true;

  get displayName() {
    return this.name;
  }

  constructor(facility: Partial<Facility>) {
    super();
    Object.assign(this, facility);
    this.mapData();
    this.fixOptionalTextFieldInconsistencies();
    this.setUrl(RoutesUtils.locations);
  }

  private mapData() {
    this.mapEntities();
    this.mapMedia();
    this.mapResults();
    this.mapAssignmentTemplates();
    this.mapSchedules();
  }

  private mapEntities() {
    this.entities = this.entities.map((entity) => new Entity(entity));
    this.entities.sortByProperty(new Entity({}).propertyStrings.name);
  }

  private mapMedia() {
    this.media = this.media.map((media) => new MediaWidgetItem(media));
  }

  private mapResults() {
    const results = this.results.map((result) => new Result(result));
    if (results.length && results.every((result) => result.task)) {
      results.sortByProperty("task.text");
    }
    this.results = results;
  }

  private mapAssignmentTemplates() {
    this.assignmentTemplates = this.assignmentTemplates.map(
      (assignmentTemplate) => new AssignmentTemplate(assignmentTemplate),
    );
  }

  private mapSchedules() {
    this.schedules = this.schedules.map(
      (schedule) => new ScheduleForViewing(schedule),
    );
  }

  private fixOptionalTextFieldInconsistencies() {
    if (this.description === null) {
      this.description = "";
    }
    if (this.streetName === null) {
      this.streetName = "";
    }
    if (this.streetNumber === null) {
      this.streetNumber = "";
    }
    if (this.zipCode === null) {
      this.zipCode = "";
    }
    if (this.city === null) {
      this.city = "";
    }
    if (this.latitude === null) {
      this.latitude = "";
    }
    if (this.longitude === null) {
      this.longitude = "";
    }
    if (this.radius === null) {
      this.radius = "";
    } else {
      this.radius = this.radius.toString();
    }
    if (this.infoLink === null) {
      this.infoLink = "";
    }
    if (this.externalId === null) {
      this.externalId = "";
    }
    if (this.extraInfo === null) {
      this.extraInfo = "";
    }
    this.latitude = this.latitude.toString();
    this.longitude = this.longitude.toString();
    this.radius = this.radius.toString();
  }

  toPayloadObject(orgId: number) {
    return new FacilityPayload({
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      latitude: this.latitude,
      longitude: this.longitude,
      radius: this.radius,
      organizationId: orgId,
      streetName: this.streetName,
      streetNumber: this.streetNumber,
      zipCode: this.zipCode,
      city: this.city,
      accessible: this.accessible,
      infoLink: this.infoLink,
      externalId: this.externalId,
      extraInfo: this.extraInfo,
    });
  }
}

export class FacilityPayload extends Facility {
  organizationId: number;
  entityIds: string[] = [];

  constructor(facilityPayload: Partial<FacilityPayload>) {
    super(facilityPayload);
    Object.assign(this, facilityPayload);
  }
}
