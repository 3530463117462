import { NgTemplateOutlet } from "@angular/common";
import { Component, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import { SimpleTableHeaderActionDelete } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import { SwalConfig } from "app/swal/swal-config.component";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import {
  TemplateTypeFilter,
  TemplateTypeService,
} from "../../../services/template-type.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { CreateEditTemplateTypeComponent } from "../create-edit-template-type/create-edit-template-type.component";
import { TemplateType } from "../template-type";

@Component({
  selector: "list-template-type",
  templateUrl: "./list-template-type.component.html",
  standalone: true,
  imports: [
    CreateEditTemplateTypeComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    NgTemplateOutlet,
    ListHeaderComponent,
  ],
})
export class ListTemplateTypeComponent extends ListBaseDirective<TemplateType> {
  readonly filterObject = new TemplateTypeFilter();

  hasTemplateBaseTypes = signal<boolean>(false);
  isDoneFetchingTemplateTypes = signal<boolean>(false);

  manualMeasureTT: TemplateType; // TODO
  manualMeasureTTFromParent: TemplateType; // TODO

  parentOrganizationId = input<number>();

  constructor(private templateTypeService: TemplateTypeService) {
    super(templateTypeService);
  }

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewTemplateType",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),

        () => !this.hasTemplateBaseTypes() || this.isInModal(),
      ),
      new SimpleListAction(
        "ImportFromParentOrganization",
        "ImportFromParentOrganization",
        StringUtils.icons.import,
        () => this.openListModalTemplateType(), // TODO visible osv
        () =>
          !this.globalState.selectedOrganization().parentId ||
          !this.isDoneFetchingTemplateTypes() ||
          this.isInModal(),
      ),
    ]);

    // TODO fixa
    if (
      this.globalState.loggedInUser().isSuperAdmin() &&
      this.globalState.selectedOrganization().parentId
    ) {
      this.setImportManualMeasureFlags();
    }
  }

  protected async configureTableFilter() {
    if (this.parentOrganizationId()) {
      this.filterObject.parentOrganizationId = this.parentOrganizationId();
    }

    const baseTypesKey = StringUtils.BASE_TYPES_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [baseTypesKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
      });
    }

    try {
      const templateBaseTypes =
        await this.templateTypeService.getTemplateBaseTypes();
      const convertedTemplateBaseTypes = templateBaseTypes.map(
        (baseType) => new SimpleFilterInputItem(baseType.id, baseType.name),
      );

      this.hasTemplateBaseTypes.set(!!templateBaseTypes.length);

      this.filterInputs.set([
        new SimpleFilterInput(
          baseTypesKey,
          baseTypesKey,
          StringUtils.icons.type,
          SimpleFilterInputType.MultiSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(baseTypesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(baseTypesKey, ""));
            }
            return keyValuePairs;
          },
          convertedTemplateBaseTypes,
        ),
        new SimpleFilterInput(
          createdKey,
          createdKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(createdKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(createdKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          updatedKey,
          updatedKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(updatedKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(updatedKey, ""));
            }
            return keyValuePairs;
          },
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.name, "Name", true),
      new SortObject(this.propertyStrings.abbreviation, "Abbreviation"),
      new SortObject("TemplateBaseType.Name" as keyof TemplateType, "BaseType"),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(["Name"], (row) => new TextContent(row.name)),
      new SimpleTableTextColumn(
        ["Abbreviation"],
        (row) => new TextContent(row.abbreviation),
      ),
      new SimpleTableTextColumn(
        ["BaseType"],
        (row) => new TextContent(row.templateBaseType.name),
      ),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "TemplateTypes",
        StringUtils.icons.templateType,
      ),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteTemplateType")
            : this.translationService.instant("DeleteTemplateTypes"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.templateTypeService.deleteRange(
            this.itemIds(),
          );
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete("TemplateType", "TemplateTypes"),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  private openListModalTemplateType() {
    //TODO
    // this.bsModalRef = this.modalService.show(ListTemplateTypeComponent, {
    //   initialState: {
    //     isInModal: true,
    //     hideListActions: true,
    //     hideTableHeaderActions: true,
    //     hideTableRowActions: true,
    //     parentOrganizationId: this.selectedOrganization().parentId,
    //     idsToSetAsUnselectable:
    //       this.manualMeasureTT && this.manualMeasureTTFromParent
    //         ? [this.manualMeasureTTFromParent.id]
    //         : [],
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_XX_LARGE,
    // });
    // const listComponent = this.bsModalRef.content;
    // this.subscriptions.add(
    //   listComponent.confirmed$
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe(async (confirmedItems) => {
    //       if (confirmedItems) {
    //         if (confirmedItems.length) {
    //           this.pending = true;
    //           try {
    //             const response =
    //               await this.templateTypeService.importFromParent(
    //                 confirmedItems,
    //                 this.selectedOrganization().id
    //               );
    //             this.pending = false;
    //             this.displaySuccessMessage(response.successMessage);
    //             this.getTableData();
    //             this.setImportManualMeasureFlags();
    //           } catch (errorResponse) {
    //             this.pending = false;
    //             this.handleErrorResponse(errorResponse);
    //           }
    //         }
    //         this.closeModal();
    //       }
    //     })
    // );
    // this.subscriptions.add(
    //   listComponent.closed$
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe((wasClosed) => {
    //       if (wasClosed) {
    //         this.closeModal();
    //       }
    //     })
    // );
  }

  private async setImportManualMeasureFlags() {
    //TODO
    // Make sure we can't import manual measure from parent if we already have one in this organization.
    // try {
    //   this.isDoneFetchingTemplateTypes = false;
    //   const templateTypes = await this.assignmentService.getTemplateTypes(
    //     this.globalState.selectedOrganization().id
    //   );
    //   const templateTypesFromParent =
    //     await this.assignmentService.getTemplateTypes(
    //       this.globalState.selectedOrganization().parentId
    //     );
    //   this.isDoneFetchingTemplateTypes = true;
    //   this.manualMeasureTT = templateTypes.find((item) => item.isManualMeasure);
    //   this.manualMeasureTTFromParent = templateTypesFromParent.find(
    //     (item) => item.isManualMeasure
    //   );
    // } catch (errorResponse) {
    //   this.listService.handleErrorResponse(errorResponse);
    // }
  }
}
