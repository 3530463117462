import { Component, input, signal } from "@angular/core";
import { FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { Choice } from "app/components/choice/choice";
import { SimpleFormInfoComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { StringUtils } from "app/tools/string-utils";
import { SimpleAssessmentComponent } from "../../../../../../simple-components/various/assessments/simple-assessment.component";
import { SimpleEntityComponent } from "../../../../../../simple-components/various/entity/simple-entity.component";
import { SimpleFacilityComponent } from "../../../../../../simple-components/various/facility/simple-facility.component";
import { SimpleStatusLabelComponent } from "../../../../../../simple-components/various/status-label/simple-status-label.component";
import { SimpleUserComponent } from "../../../../../../simple-components/various/user/simple-user.component";

@Component({
  selector: "details-assignment-tab",
  templateUrl: "./details-assignment-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInfoComponent,
    SimpleFormInputComponent,
    TranslateModule,
    SimpleFormSelectComponent,
    SimpleAssessmentComponent,
    SimpleUserComponent,
    SimpleFacilityComponent,
    SimpleEntityComponent,
    SimpleStatusLabelComponent,
  ],
})
export class DetailsAssignmentTabComponent extends DetailsBaseDirective<MeasureAssignment> {
  selectableStatusItems = signal<FormSelectItem[]>([]);
  selectablePriorityItems = signal<FormSelectItem[]>([]);

  disabledPriorityLink = signal<string>("");
  priorityIsDisabled = signal<boolean>(false);

  choice = input<Choice>();

  ngOnInit() {
    this.setSelectableStatusItems();
    this.setSelectablePriorityItems();

    this.priorityIsDisabled.set(!this.choice() || !this.choice().allowPriority);
    this.disabledPriorityLink.set(
      this.globalState.loggedInUser().isSuperAdmin() && this.choice()
        ? this.choice().url
        : "",
    );

    this.form.set(
      this.formBuilder.group(
        {
          [this.propertyStrings.priority]: [
            this.modifiedModel().priority,
            Validators.required,
          ],
          [this.propertyStrings.status]: [
            this.modifiedModel().status === StringUtils.ACTIVE
              ? StringUtils.NOT_STARTED
              : this.modifiedModel().status,
            Validators.required,
          ],
          [this.propertyStrings.notes]: [
            this.modifiedModel().notes,
            Validators.required,
          ],
          [this.propertyStrings.adminComment]: [
            this.modifiedModel().adminComment,
            Validators.maxLength(500),
          ],
        },
        { validators: this.measureValidator },
      ),
    );
    super.ngOnInit();
  }

  private measureValidator(form: FormGroup): ValidationErrors {
    const status = form.get("status");
    const notes = form.get("notes");

    if (
      status.value !== StringUtils.NOT_STARTED &&
      status.value !== StringUtils.STARTED &&
      !notes.value
    ) {
      notes.setErrors({ required: true });
    } else {
      notes.setErrors(null);
    }
    return null;
  }

  private setSelectableStatusItems() {
    const status =
      this.modifiedModel().status === StringUtils.STARTED
        ? StringUtils.STARTED
        : StringUtils.NOT_STARTED;
    this.selectableStatusItems.set([
      new FormSelectItem(status, this.translationService.instant(status)),
      new FormSelectItem(
        StringUtils.COMPLETED,
        this.translationService.instant(
          !this.modifiedModel().isManualMeasureAssignment
            ? StringUtils.RESOLVED
            : StringUtils.COMPLETED,
        ),
      ),
      new FormSelectItem(
        StringUtils.CLOSED,
        this.translationService.instant(StringUtils.CLOSED),
      ),
    ]);
  }

  private setSelectablePriorityItems() {
    this.selectablePriorityItems.set([
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
    ]);
  }

  getReport() {
    //TODO
    // this.bsModalRef = this.modalService.show(StaticReportComponent, {
    //   initialState: {
    //     editModelIds: [this.viewModel.id],
    //     disableTabIndexUrl: true,
    //     useAffectableIds: true,
    //     hideText: true,
    //     isMeasure: this.viewModel.isMeasureAssignment(),
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_LARGE,
    // });
    // this.subscribeToCrudModalContent();
  }
}
