<pdf-viewer
  [ngClass]="{
    'thumbnail-version': thumbnailVersion(),
  }"
  [src]="fullUrl()"
  [render-text]="false"
  [original-size]="false"
  [show-all]="!thumbnailVersion()"
  [autoresize]="!thumbnailVersion()"
  (page-rendered)="setPdf()"
  (error)="setErrorImage()"
>
</pdf-viewer>

<div class="error-div" #errorDivEl></div>

<dots-loader *ngIf="displayLoader() && isLoading()"></dots-loader>
