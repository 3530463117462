import { inject, Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class ToastrService {
  private life = 3000;
  private closeIcon = "bi-x-lg";

  protected messageService = inject(MessageService);

  success(message: string) {
    this.messageService.add({
      severity: "success",
      summary: "",
      detail: message,
      life: this.life,
      icon: "bi-check-circle",
      closeIcon: this.closeIcon,
    });
  }

  info(message: string) {
    this.messageService.add({
      severity: "info",
      summary: "",
      detail: message,
      life: this.life,
      icon: "bi-check-circle",
      closeIcon: this.closeIcon,
    });
  }

  secondary(message: string) {
    this.messageService.add({
      severity: "secondary",
      summary: "",
      detail: message,
      life: this.life,
      icon: "bi-check-circle",
      closeIcon: this.closeIcon,
    });
  }

  error(message: string) {
    const errors = message.split("/");
    errors.forEach((error) => {
      this.messageService.add({
        severity: "error",
        summary: "",
        detail: error,
        life: this.life,
        icon: "bi-x-circle",
        closeIcon: this.closeIcon,
        sticky: errors.length > 1,
      });
    });
  }

  warn(message: string) {
    this.messageService.add({
      severity: "warn",
      summary: "",
      detail: message,
      life: this.life,
      icon: "bi-exclamation-circle",
      closeIcon: this.closeIcon,
    });
  }

  contrast(message: string) {
    this.messageService.add({
      severity: "contrast",
      summary: "",
      detail: message,
      life: this.life,
      icon: "bi-info-circle",
      closeIcon: this.closeIcon,
    });
  }
}
