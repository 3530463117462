import { Choice } from "app/components/choice/choice";
import { Client } from "app/components/organization/organization";
import { Schedule } from "app/components/schedule/schedule";
import { CrudItem } from "app/components/simple-components/crud/crud-item";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import { RoutesUtils } from "app/tools/routes-utils";
import { Entity } from "../entity/entity";
import { Facility } from "../facility/facility";
import { RuleObject } from "../simple-components/various/rules/simple-rules.component";
import { Task } from "../task/task";
import { TaskChoice, TemplateType } from "../template-type/template-type";

export class AssignmentTemplate extends CrudItem<AssignmentTemplate> {
  name: string = "";
  templateTypeId: string = "";
  templateType: TemplateType = null;
  clientId: string = null; // Needs to be instantiated with null instead of empty as it is used for the ng-select lib, and is optional.
  client: Client = null;
  clientName: string = "";
  categoryIds: string[] = [];
  categories: Category[] = [];
  geoControlled: boolean = false;
  hasActiveTemplateObjects: boolean = false;
  facility: Facility = null;
  facilityTasks: Task[] = [];
  entities: Entity[] = [];
  entityTasks: Task[] = [];
  schedules: Schedule[] = [];
  quantity: number = 1;
  quantityCached: number = 1;
  publishDirectly: boolean = false;
  facilityName: string = "";

  selectableTemplateTypes: TemplateType[] = [];
  selectableClients: Client[] = [];
  selectableCategories: Category[] = [];
  facilities: Facility[] = [];

  quantityOne: boolean = true;
  quantityTwo: boolean = false;
  quantityThree: boolean = false;
  quantityFour: boolean = false;
  quantityFive: boolean = false;

  get displayName() {
    return this.name;
  }

  get publishDirectlyRule() {
    const rules: RuleObject[] = [];
    if (this.publishDirectly) {
      rules.push(
        new RuleObject({
          icon: "bi-calendar-plus green",
          title: "PublishDirectly",
        }),
      );
    }
    if (!this.publishDirectly) {
      rules.push(
        new RuleObject({
          icon: "bi-calendar-plus",
          title: "PublishDirectly",
        }),
      );
    }
    return rules;
  }

  get quantityRules() {
    const rules: RuleObject[] = [];
    if (this.quantityOne) {
      rules.push(
        new RuleObject({
          icon: "bi-1-circle-fill green",
          title: "QuantityOne",
        }),
      );
    }
    if (!this.quantityOne) {
      rules.push(
        new RuleObject({
          icon: "bi-1-circle",
          title: "QuantityOne",
        }),
      );
    }
    if (this.quantityTwo) {
      rules.push(
        new RuleObject({
          icon: "bi-2-circle-fill green",
          title: "QuantityTwo",
        }),
      );
    }
    if (!this.quantityTwo) {
      rules.push(
        new RuleObject({
          icon: "bi-2-circle",
          title: "QuantityTwo",
        }),
      );
    }
    if (this.quantityThree) {
      rules.push(
        new RuleObject({
          icon: "bi-3-circle-fill green",
          title: "QuantityThree",
        }),
      );
    }
    if (!this.quantityThree) {
      rules.push(
        new RuleObject({
          icon: "bi-3-circle",
          title: "QuantityThree",
        }),
      );
    }
    if (this.quantityFour) {
      rules.push(
        new RuleObject({
          icon: "bi-4-circle-fill green",
          title: "QuantityFour",
        }),
      );
    }
    if (!this.quantityFour) {
      rules.push(
        new RuleObject({
          icon: "bi-4-circle",
          title: "QuantityFour",
        }),
      );
    }
    if (this.quantityFive) {
      rules.push(
        new RuleObject({
          icon: "bi-5-circle-fill green",
          title: "QuantityFive",
        }),
      );
    }
    if (!this.quantityFive) {
      rules.push(
        new RuleObject({
          icon: "bi-5-circle",
          title: "QuantityFive",
        }),
      );
    }
    return rules;
  }

  constructor(assignmentTemplate: Partial<AssignmentTemplate>) {
    super();
    Object.assign(this, assignmentTemplate);
    this.mapData();
    this.setUrl(RoutesUtils.assignmentTemplates);
  }

  private mapData() {
    this.mapTemplateType();
    this.mapClient();
    this.mapCategories();
    this.mapTasks();
    this.mapSchedules();
  }

  private mapTemplateType() {
    if (this.templateType) {
      this.templateType = new TemplateType(this.templateType);
      this.templateTypeId = this.templateType.id;
    }
  }

  private mapClient() {
    if (this.client) {
      this.client = new Client(this.client);
      this.clientId = this.client.id;
    }
  }

  private mapCategories() {
    this.categories = this.categories.map((category) => new Category(category));
    this.categoryIds = this.categories.map((category) => category.id);
  }

  private mapTasks() {
    // TODO all mappning ske i respektive modell, inte nestlat!!
    if (this.facility) {
      this.facility = new Facility(this.facility);
      this.facilityTasks = this.facility.taskChoices.map((taskChoice) => {
        const task = new Task(taskChoice.task);
        task.choices = task.choices.map((choice) => new Choice(choice));
        task.choices.sortByProperty(new Choice({}).propertyStrings.value);
        return task;
      });
      this.facilityTasks.sortByProperty(new Task({}).propertyStrings.text);
    }

    if (this.entities.length) {
      this.entities = this.entities.map((entity) => new Entity(entity));
      this.entities.sortByProperty(this.propertyStrings.name);
      this.entityTasks = this.entities[0].taskChoices.map((taskChoice) => {
        const task = new Task(taskChoice.task);
        task.choices = task.choices.map((choice) => new Choice(choice));
        task.choices.sortByProperty(new Choice({}).propertyStrings.value);
        return task;
      });
      this.entityTasks.sortByProperty(new Task({}).propertyStrings.text);
    }
  }

  private mapSchedules() {
    this.schedules = this.schedules.map((schedule) => new Schedule(schedule));
  }

  toPayloadObject(orgId: number) {
    return new AssignmentTemplatePayload({
      id: this.id,
      organizationId: orgId,
      templateTypeId: this.templateTypeId,
      facilityIds: this.facilities.map((o) => o.id),
      entityIds: this.entities.map((o) => o.id),
      categoryNames: this.getCategoryNames(),
      facilityTaskChoices: this.getFacilityTaskChoices(),
      entityTaskChoices: this.getEntityTaskChoices(),
      geoControlled: this.geoControlled,
      clientId: this.clientId,
    });
  }

  getCategoryNames() {
    const categoryNames = [];
    this.categoryIds.forEach((id) => {
      const category = this.selectableCategories.find((sc) => sc.id === id);
      if (category) {
        categoryNames.push(category.name);
      }
    });
    return categoryNames;
  }

  private getFacilityTaskChoices() {
    return this.facilityTasks.map((task) => {
      return new TaskChoice({
        task: task,
        choices: task.choices,
      });
    });
  }

  private getEntityTaskChoices() {
    return this.entityTasks.map((task) => {
      return new TaskChoice({
        task: task,
        choices: task.choices,
      });
    });
  }
}

export class AssignmentTemplatePayload extends AssignmentTemplate {
  organizationId: number;
  facilityIds: string[] = [];
  entityIds: string[] = [];
  facilityTaskChoices: TaskChoice[] = [];
  entityTaskChoices: TaskChoice[] = [];
  categoryNames: string[] = [];

  constructor(assignmentTemplatePayload: Partial<AssignmentTemplatePayload>) {
    super(assignmentTemplatePayload);
    Object.assign(this, assignmentTemplatePayload);
  }
}
