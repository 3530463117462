import { NgClass } from "@angular/common";
import { Component, input, output, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { Utils } from "app/tools/utils";
import { SelectButton } from "primeng/selectbutton";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "../simple-filter-input";
import { SimpleFilterInputItem } from "../simple-filter-input-item";

@Component({
  selector: "simple-filter-sub",
  templateUrl: "./simple-filter-sub.component.html",
  styleUrls: ["./simple-filter-sub.component.less"],
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    SelectButton,
    NgSelectModule,
    FormsModule,
  ],
})
export class SimpleFilterSubComponent {
  inputTypes = SimpleFilterInputType;
  yearItems = this.getYearItems();
  monthItems = this.getMonthItems();
  dayItems = this.getDayItems();
  randomNumberString = signal<string>(Utils.getRandomNumberString());

  filterInputs = input<SimpleFilterInput[]>();

  onFilter = output<SimpleFilterInput>();

  handleMultiChange(filterInput: SimpleFilterInput) {
    this.emit(filterInput);
  }

  handleSingleChange(filterInput: SimpleFilterInput) {
    if (filterInput.selectedItem) {
      filterInput.selectedItems = [filterInput.selectedItem];
    } else {
      filterInput.selectedItems = [];
    }
    this.emit(filterInput);
  }

  handleDateChange(filterInput: SimpleFilterInput) {
    if (
      filterInput.selectedYear &&
      filterInput.selectedMonth &&
      filterInput.selectedDay
    ) {
      const date = `${filterInput.selectedYear} ${filterInput.selectedMonth} ${filterInput.selectedDay}`;
      filterInput.selectedItems = [new SimpleFilterInputItem(date, date)];
      this.emit(filterInput);
    } else if (
      !filterInput.selectedYear &&
      !filterInput.selectedMonth &&
      !filterInput.selectedDay &&
      filterInput.selectedItems.length
    ) {
      filterInput.selectedItems = [];
      this.emit(filterInput);
    }
  }

  handleDateRangeChange(filterInput: SimpleFilterInput) {
    if (
      filterInput.selectedYear &&
      filterInput.selectedMonth &&
      filterInput.selectedDay &&
      filterInput.selectedYear2 &&
      filterInput.selectedMonth2 &&
      filterInput.selectedDay2
    ) {
      const date = `${filterInput.selectedYear} ${filterInput.selectedMonth} ${filterInput.selectedDay}_${filterInput.selectedYear2} ${filterInput.selectedMonth2} ${filterInput.selectedDay2}`;
      filterInput.selectedItems = [new SimpleFilterInputItem(date, date)];
      this.emit(filterInput);
    } else if (
      !filterInput.selectedYear &&
      !filterInput.selectedMonth &&
      !filterInput.selectedDay &&
      !filterInput.selectedYear2 &&
      !filterInput.selectedMonth2 &&
      !filterInput.selectedDay2 &&
      filterInput.selectedItems.length
    ) {
      filterInput.selectedItems = [];
      this.emit(filterInput);
    }
  }

  private emit(filterInput: SimpleFilterInput) {
    this.onFilter.emit(filterInput);
  }

  private getYearItems() {
    const yearItems: SimpleFilterInputItem[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      yearItems.push(
        new SimpleFilterInputItem(
          (currentYear - i).toString(),
          (currentYear - i).toString(),
        ),
      );
    }
    return yearItems;
  }

  private getMonthItems() {
    return [
      new SimpleFilterInputItem("01", "January"),
      new SimpleFilterInputItem("02", "February"),
      new SimpleFilterInputItem("03", "March"),
      new SimpleFilterInputItem("04", "April"),
      new SimpleFilterInputItem("05", "May"),
      new SimpleFilterInputItem("06", "June"),
      new SimpleFilterInputItem("07", "July"),
      new SimpleFilterInputItem("08", "August"),
      new SimpleFilterInputItem("09", "September"),
      new SimpleFilterInputItem("10", "October"),
      new SimpleFilterInputItem("11", "November"),
      new SimpleFilterInputItem("12", "December"),
    ];
  }

  private getDayItems() {
    const dayItems: SimpleFilterInputItem[] = [];
    for (let i = 1; i <= 31; i++) {
      dayItems.push(
        new SimpleFilterInputItem(
          (i < 10 ? "0" : "") + i.toString(),
          i.toString(),
        ),
      );
    }
    return dayItems;
  }
}
