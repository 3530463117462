<p-drawer
  [(visible)]="visible"
  [position]="'left'"
  [showCloseIcon]="false"
  [appendTo]="'body'"
>
  <ng-template #header>
    <div class="label">
      {{ headerText() }}
    </div>
    <div class="actions">
      <div
        class="action"
        [title]="'Close' | translate"
        (click)="visible.set(false)"
      >
        <i class="icon bi-x-lg"></i>
      </div>
    </div>
  </ng-template>
  <div class="view-location-data">
    <div class="view-location-data-section">
      <div class="view-location-data-section-header">
        {{ "Latitude" | translate }}
      </div>
      <div class="view-location-data-section-value">
        {{ locationData()[0] }}
      </div>
    </div>
    <div class="view-location-data-section">
      <div class="view-location-data-section-header">
        {{ "Longitude" | translate }}
      </div>
      <div class="view-location-data-section-value">
        {{ locationData()[1] }}
      </div>
    </div>
    @if (locationData().length > 2) {
      <div class="view-location-data-section">
        <div class="view-location-data-section-header">
          {{ "Radius" | translate }}
        </div>
        <div class="view-location-data-section-value">
          {{ locationData()[2] }}
        </div>
      </div>
    }
    @if (locationData().length) {
      <google-maps-component
        [latitude]="locationData()[0]"
        [longitude]="locationData()[1]"
        [radius]="locationData()[2]"
        [isStatic]="true"
        [height]="200"
      >
      </google-maps-component>
    }
  </div>
</p-drawer>
