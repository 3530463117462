<div class="choices-wrapper">
  @if (choices().length) {
    <div class="choices apx-scrollbar">
      @for (choice of choices(); track $index) {
        <div
          class="choice"
          [ngClass]="{
            editable: editable(),
          }"
          [title]="editable() ? ('Remove' | translate) : choice.value"
          (click)="editable() ? handleChoiceClick(choice, $event) : null"
        >
          @if (!hideRules()) {
            <div class="choice-rules-wrapper">
              @for (rule of choice.rules; track $index) {
                <div
                  class="choice-rule {{ rule.icon }}"
                  [title]="rule.title | translate"
                ></div>
              }
            </div>
          }
          <div class="choice-meta-wrapper">
            <span class="choice-value">
              {{ choice.value }}
            </span>
            <span class="choice-color" [style.backgroundColor]="choice.color">
            </span>
          </div>
        </div>
      }
    </div>
  }
  @if (editable()) {
    <div
      class="action bi-plus"
      title="{{ 'Add' | translate }}"
      (click)="handleAddClick($event)"
    ></div>
  }
</div>
