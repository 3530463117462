import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { ListAssignmentTemplateComponent } from "app/components/assignment-template/list-assignment-template/list-assignment-template.component";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  AssignmentTemplateContent,
  SimpleTableAssignmentTemplateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-assignment-template-column";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-assignment-templates-tab",
  templateUrl: "./handle-assignment-templates-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListAssignmentTemplateComponent,
    TranslateModule,
  ],
})
export class HandleAssignmentTemplatesTabComponent extends HandleItemsBaseDirective<AssignmentTemplate> {
  visibleListAssignmentTemplate = signal<boolean>(false);

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "",
        "PublishDirectly",
        StringUtils.icons.publishDirectly,
        () => this.bulkChange(this.propertyStrings.publishDirectly),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "AddAssignmentTemplates",
        "AddAssignmentTemplates",
        StringUtils.icons.new,
        () => this.visibleListAssignmentTemplate.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableAssignmentTemplateColumn(
        ["TemplateType", "Location"],
        (row) => new AssignmentTemplateContent(row.templateType, row.facility),
      ),
      new SimpleTableRuleColumn(
        ["Quantity"],
        (row) => new RuleContent(row.quantityRules, true),
        (row, ruleName) => {
          row.quantityOne = false;
          row.quantityTwo = false;
          row.quantityThree = false;
          row.quantityFour = false;
          row.quantityFive = false;
          row[ruleName] = !row[ruleName];
          this.configureTableColumns();
        },
      ),
      new SimpleTableRuleColumn(
        [],
        (row) => new RuleContent(row.publishDirectlyRule, true),
        (row, ruleName) => {
          row[ruleName] = !row[ruleName];
          this.configureTableColumns();
        },
        () => !this.globalState.loggedInUser().isSuperAdmin(),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.hasActiveTemplateObjects
              ? StringUtils.ACTIVE
              : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "AssignmentTemplates",
        StringUtils.icons.assignmentTemplate,
      ),
    );
  }

  handleCloseListAssignmentTemplate() {
    this.visibleListAssignmentTemplate.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListAssignmentTemplate(items: AssignmentTemplate[]) {
    this.handleCloseListAssignmentTemplate();
    items.forEach((item) => {
      const existingItem = this.items().find((i) => i.id === item.id);
      if (existingItem) {
        item.quantityOne = existingItem.quantityOne;
        item.quantityTwo = existingItem.quantityTwo;
        item.quantityThree = existingItem.quantityThree;
        item.quantityFour = existingItem.quantityFour;
        item.quantityFive = existingItem.quantityFive;
        item.publishDirectly = existingItem.publishDirectly;
      }
    });
    this.items.set(items.map((item) => new AssignmentTemplate(item)));
    this.items().sortByProperty("name");
    this.onItemsChange.emit(this.items());
    this.selectedIds.set([]);
  }

  private bulkChange(property: string) {
    const selectedItems = this.items().filter((item) =>
      this.selectedIds().includes(item.id),
    );
    const allTrue = selectedItems.every((item) => item[property]);
    selectedItems.forEach((item) => (item[property] = !allTrue));
    this.configureTableColumns();
  }
}
