import { NgClass } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { DotsLoaderComponent } from "app/components/dots-loader/dots-loader.component";
import { GlobalStateService } from "app/global-state/global-state.service";
import { FeedbackService } from "app/services/feedback.service";
import { ToastrService } from "app/services/toastr.service";
import { TranslationService } from "app/services/translation.service";
import * as domToimage from "dom-to-image";
import { Popover } from "primeng/popover";

@Component({
  selector: "feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.less"],
  providers: [FeedbackService],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    DotsLoaderComponent,
    TranslateModule,
    Popover,
  ],
})
export class FeedbackComponent {
  maxLength = signal<number>(2000);
  popoverOpened = signal<boolean>(false);
  loading = signal<boolean>(false);
  screenshot = signal<boolean>(false);
  sending = signal<boolean>(false);
  wasSent = signal<boolean>(false);
  wasNotSent = signal<boolean>(false);
  feedback = signal<FeedbackObject>(new FeedbackObject({}));

  isInModal = input<boolean>();

  protected toastrService = inject(ToastrService);

  constructor(
    private globalState: GlobalStateService,
    protected translationService: TranslationService,
    protected feedbackService: FeedbackService,
  ) {}

  async getScreenShot() {
    return await domToimage.toPng(document.body);
  }

  async submit(helpPop: Popover) {
    if (
      this.feedback().description.length &&
      this.feedback().description.length <= this.maxLength()
    ) {
      this.loading.set(true);
      setTimeout(async () => {
        try {
          helpPop.hide();
          this.loading.set(false);
          this.popoverOpened.set(false);
          setTimeout(async () => {
            this.screenshot.set(true);
            const screenshot = await this.getScreenShot();
            if (screenshot) {
              this.feedback().screenshot = screenshot;
            }
            this.screenshot.set(false);
            this.sending.set(true);
            setTimeout(async () => {
              const response = await this.feedbackService.createClickUpSpec(
                this.feedback(),
                this.globalState.loggedInUser(),
                this.globalState.selectedOrganization(),
              );
              this.sending.set(false);
              if (response.ok) {
                this.wasSent.set(true);
                this.feedback.set(new FeedbackObject({}));
                this.toastrService.secondary(
                  this.translationService.instant("YourMessageHasBeenSent"),
                );
                setTimeout(() => {
                  this.wasSent.set(false);
                }, 2000);
              } else {
                this.wasNotSent.set(true);
                this.toastrService.error(
                  this.translationService.instant("SendMessageError"),
                );
                setTimeout(() => {
                  this.wasNotSent.set(false);
                }, 2000);
              }
            }, 500);
          }, 0);
        } catch (e) {
          this.loading.set(false);
          this.sending.set(false);
          this.wasNotSent.set(true);
          this.toastrService.error(
            this.translationService.instant("ErrorRequestFailed"),
          );
          console.error(
            "Error while creating screenshot or sending feedback: ",
            e,
          );
          setTimeout(() => {
            this.wasNotSent.set(false);
          }, 2000);
        }
      }, 500);
    }
  }

  focusTextarea() {
    const textarea = document.querySelector(
      ".feedback-textarea",
    ) as HTMLElement;
    if (textarea) {
      textarea.focus();
    }
  }
}

export class FeedbackObject {
  description: string = "";
  screenshot: string = "";

  constructor(feedbackObject: Partial<FeedbackObject>) {
    Object.assign(this, feedbackObject);
  }
}
