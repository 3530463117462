import { Component } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";

@Component({
  selector: "details-admin-comment-tab",
  templateUrl: "./details-admin-comment-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
  ],
})
export class DetailsAdminCommentTabComponent extends DetailsBaseDirective<MeasureAssignment> {
  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.adminComment]: [
          this.modifiedModel().adminComment,
          Validators.maxLength(500),
        ],
      }),
    );
    super.ngOnInit();
  }
}
