<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="'ScheduleChangeDetailsHelpText' | translate"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'bi bi-map'"
            [title]="'AssignmentTemplates' | translate"
            [helpText]="'ScheduleHandleAssignmentTemplatesHelpText' | translate"
            [displayCount]="modifiedModel().assignmentTemplates.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="2">
          <simple-tab-heading
            [icon]="'bi bi-people'"
            [title]="'Groups' | translate"
            [helpText]="'ScheduleHandleGroupsHelpText' | translate"
            [displayCount]="modifiedModel().scheduleGroups.length"
          ></simple-tab-heading>
        </p-tab>
        <p-tab [value]="3">
          <simple-tab-heading
            [icon]="'bi bi-person'"
            [title]="'Assignees' | translate"
            [helpText]="'ScheduleHandleUsersHelpText' | translate"
            [displayCount]="modifiedModel().scheduleUsers.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="4">
          <simple-tab-heading
            [icon]="'bi bi-bell'"
            [title]="'Subscribers' | translate"
            [helpText]="'ScheduleHandleSubscribersHelpText' | translate"
            [displayCount]="modifiedModel().subscribers.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-schedule-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-schedule-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-assignment-templates-tab
            [items]="modifiedModel().assignmentTemplates"
            [headerSubtitleText]="
              'AddedAssignmentTemplatesSubtitleSchedule' | translate
            "
            (onItemsChange)="
              handleItemsChange(propertyStrings.assignmentTemplates, $event)
            "
          >
          </handle-assignment-templates-tab>
        </p-tabpanel>
        <p-tabpanel [value]="2">
          <handle-schedule-groups-tab
            [items]="modifiedModel().scheduleGroups"
            [headerSubtitleText]="'AddedGroupsSubtitleSchedule' | translate"
            (onItemsChange)="
              handleItemsChange(propertyStrings.scheduleGroups, $event)
            "
          >
          </handle-schedule-groups-tab>
        </p-tabpanel>
        <p-tabpanel [value]="3">
          <handle-schedule-users-tab
            [items]="modifiedModel().scheduleUsers"
            [headerSubtitleText]="'AddedUsersSubtitleSchedule' | translate"
            [selectedScheduleGroups]="modifiedModel().scheduleGroups"
            (onItemsChange)="
              handleItemsChange(propertyStrings.scheduleUsers, $event)
            "
          >
          </handle-schedule-users-tab>
        </p-tabpanel>
        <p-tabpanel [value]="4">
          <!-- <handle-subscribers-tab
          [items]="modifiedModel().subscribers"
          [headerSubtitleText]="'AddedSubscribersSubtitleSchedule' | translate"
          [displayUrgentIcon]="hasAtLeastOneNotificationChoice"
          (onItemsChange)="
            handleItemsChange(propertyStrings.subscribers, $event)
          "
        >
        </handle-subscribers-tab> -->
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
