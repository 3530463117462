import { NgClass, NgIf } from "@angular/common";
import {
  Component,
  ElementRef,
  inject,
  input,
  OnChanges,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { MediaUtils } from "app/tools/media-utils";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DotsLoaderComponent } from "../../components/dots-loader/dots-loader.component";
import { MediaResolver } from "../resolver/media-resolver";

/**
 * This component works as a wrapper for the pdf viewer. It will resolve a pdf based on passed in url and options.
 * It also has added extensive functionality like error handling if a pdf fails to load.
 */
@Component({
  selector: "extensive-pdf",
  templateUrl: "./extensive-pdf.component.html",
  styleUrls: ["./extensive-pdf.component.less"],
  standalone: true,
  imports: [PdfViewerModule, NgClass, NgIf, DotsLoaderComponent],
})
export class ExtensivePdfComponent implements OnInit, OnChanges {
  fullUrl = signal<string>("");
  isLoading = signal<boolean>(false);

  pdfUrl = input<string>();
  blob = input<Blob>();
  thumbnailVersion = input<boolean>();
  displayLoader = input<boolean>();

  @ViewChild("errorDivEl", { read: ElementRef, static: true })
  errorDivEl: ElementRef;

  mediaResolver = inject(MediaResolver);

  get errorDiv(): HTMLDivElement {
    return this.errorDivEl.nativeElement;
  }

  ngOnInit() {
    if (this.displayLoader()) {
      this.isLoading.set(true);
    }
  }

  ngOnChanges() {
    this.refresh();
  }

  refresh() {
    if (this.blob()) {
      const blobUrl = URL.createObjectURL(this.blob());
      this.fullUrl.set(blobUrl);
    } else {
      const resolvedUrl = this.mediaResolver.resolve(this.pdfUrl());
      this.fullUrl.set(resolvedUrl);
    }
  }

  setErrorImage() {
    this.errorDiv.style.backgroundImage = `url(${MediaUtils.GRAPHICS_ERROR_IMAGE_BROKEN_LINK})`;
    this.errorDiv.classList.add("visible");
    if (this.displayLoader()) {
      this.isLoading.set(false);
    }
  }

  setPdf() {
    this.errorDiv.classList.remove("visible");
    if (this.displayLoader()) {
      this.isLoading.set(false);
    }
  }
}
