import { Component, inject, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { MediaResolver } from "app/media/resolver/media-resolver";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { MediaUtils } from "app/tools/media-utils";
import { SimpleCrudModalComponent } from "../../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { ExtensiveImageComponent } from "../../../extensive-image/extensive-image.component";
import { ExtensivePdfComponent } from "../../../extensive-pdf/extensive-pdf.component";

@Component({
  selector: "media-widget-item-viewer",
  templateUrl: "media-widget-item-viewer.component.html",
  styleUrls: ["media-widget-item-viewer.component.less"],
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    ExtensiveImageComponent,
    ExtensivePdfComponent,
    TranslateModule,
  ],
})
export class MediaWidgetItemViewerComponent extends CreateEditBaseDirective<MediaWidgetItem> {
  media = MediaUtils;
  index: number;
  pdfThumbnail = MediaUtils.MISC_PDF;

  hasNext = signal<boolean>(false);
  hasPrev = signal<boolean>(false);
  currentItem = signal<MediaWidgetItem>(null);

  item = input<MediaWidgetItem>();
  collection = input<MediaWidgetItem[]>();

  private mediaResolver = inject(MediaResolver);

  initiate() {
    this.setCurrentItem(this.item());
  }

  setCurrentItem(item: MediaWidgetItem) {
    this.currentItem.set(item);

    this.index = this.collection().indexOf(item);

    this.hasNext.set(
      this.collection().length && this.index < this.collection().length - 1,
    );
    this.hasPrev.set(this.collection().length && this.index > 0);
  }

  next() {
    if (this.collection().length && this.index < this.collection().length - 1) {
      this.setCurrentItem(this.collection()[this.index + 1]);
    }
  }

  prev() {
    if (this.collection().length && this.index > 0) {
      this.setCurrentItem(this.collection()[this.index - 1]);
    }
  }

  download() {
    const mediaItem = this.currentItem();
    const url = this.mediaResolver.resolve(mediaItem.url);
    window.open(url, "_blank");
  }
}
