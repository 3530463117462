<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.value"
          [form]="form()"
          [maxlength]="3"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!modifiedModel().id"
        >
        </simple-form-input>

        <simple-form-color
          [label]="'Color' | translate"
          [name]="propertyStrings.color"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-color>

        <simple-form-select
          [label]="'Rules' | translate"
          [name]="propertyStrings.rulesInternal"
          [form]="form()"
          [items]="selectableRuleItems()"
          [multiple]="true"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>
      </div>
    </div>
  </div>
</simple-details-tab>
