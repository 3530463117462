<div
  class="rules apx-scrollbar"
  [ngClass]="{
    editable: editable(),
  }"
>
  @for (rule of rules(); track $index) {
    <div
      class="rule {{ rule.icon }}"
      [title]="rule.title | translate"
      (click)="editable() ? handleRuleClick(rule, $event) : null"
    ></div>
  }
</div>
