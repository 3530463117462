<simple-details-tab>
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "TemplateType" | translate }}
          </div>
          <div class="content-value">
            {{ modifiedModel().assignmentTemplate.templateType.name }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Location" | translate }}
          </div>
          <div class="content-value">
            <simple-facility [facility]="modifiedModel().facility">
            </simple-facility>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Categories" | translate }}
          </div>
          @if (modifiedModel().assignmentTemplate.categories.length) {
            <div class="content-value">
              <simple-categories
                [categories]="modifiedModel().assignmentTemplate.categories"
              >
              </simple-categories>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        @if (modifiedModel().scheduleName) {
          <div class="content">
            <div class="content-header">
              {{ "Schedule" | translate }}
            </div>

            <div class="content-value">
              {{ modifiedModel().scheduleName }}
            </div>
          </div>
        }
        <div class="content">
          <div class="content-header">
            {{ "Geofencing" | translate }}
          </div>
          {{
            modifiedModel().assignmentTemplate.geoControlled
              ? ("Yes" | translate)
              : ("No" | translate)
          }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Client" | translate }}
          </div>
          @if (modifiedModel().assignmentTemplate.client) {
            <div class="content-value">
              {{ modifiedModel().assignmentTemplate.client.name }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Archived" | translate }}
          </div>
          <div
            class="content-value"
            [title]="modifiedModel().dueDate | datex: 'with-time'"
          >
            {{
              (modifiedModel().completed
                ? modifiedModel().completed
                : modifiedModel().dueDate
              ) | datex
            }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Status" | translate }}
          </div>
          <div class="content-value">
            <simple-status-label
              [text]="
                modifiedModel().status === stringUtils.ACTIVE
                  ? stringUtils.NOT_STARTED
                  : modifiedModel().status
              "
              [title]="
                modifiedModel().status === stringUtils.ACTIVE
                  ? stringUtils.NOT_STARTED
                  : modifiedModel().status
              "
            >
            </simple-status-label>
          </div>
        </div>
      </div>

      @if (modifiedModel().isSSEN()) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "Implementation" | translate }}
          </div>
          @if (modifiedModel().assignmentTemplate.templateType.scope) {
            <div class="content">
              <div class="content-header">
                {{ "Scope" | translate }}
              </div>
              <div
                class="content-value with-frame"
                [innerHTML]="
                  modifiedModel().assignmentTemplate.templateType.scope
                    | parseMD
                "
              ></div>
            </div>
          }
          <div class="content">
            <div class="content-header">
              {{
                (modifiedModel().assignmentTemplate.templateType.scope
                  ? "Deviations"
                  : "ScopeFreeText"
                ) | translate
              }}
            </div>

            @if (modifiedModel().notes) {
              <div class="content-value">
                {{ modifiedModel().notes }}
              </div>
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
        </div>
      }
      @if (modifiedModel().reportUrl) {
        <div class="details-section">
          <button
            class="btn-transparent"
            [title]="'GetAssignmentReport' | translate"
            (click)="getReport()"
          >
            {{ "GetAssignmentReport" | translate }}
          </button>
        </div>
      }
    </div>
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "InspectionResults" | translate }}
        </div>
        @if (modifiedModel().facility.results.length) {
          <div class="content">
            <div class="content-header">
              {{ "Assessments" | translate }}
            </div>
            <div class="content-value flex-column">
              @for (result of modifiedModel().facility.results; track $index) {
                <simple-assessment
                  [task]="result.task"
                  [choice]="result.task.choice"
                  [comment]="result.comment"
                  [media]="result.media"
                  [displayInfoOnClick]="true"
                  [infoText]="
                    result.task.choice.isError && result.measureAssignmentId
                      ? ('MeasureAssignment' | translate) +
                        ' ' +
                        ((result.solved ? 'Handled' : 'NotHandled')
                          | translate
                          | lowercase)
                      : ''
                  "
                  [url]="
                    result.measureAssignmentId
                      ? getUrl(result.measureAssignmentId, !!result.solved)
                      : ''
                  "
                  [linkText]="'MeasureAssignment' | translate"
                ></simple-assessment>
              }
            </div>
          </div>
        }
        @if (modifiedModel().entities.length) {
          <div class="content">
            <div class="content-value flex-column">
              @for (entity of modifiedModel().entities; track $index) {
                <div
                  class="entity"
                  [ngClass]="{
                    opened: entity.actionsOpened,
                  }"
                  (click)="entity.actionsOpened = !entity.actionsOpened"
                >
                  <simple-entity [entity]="entity"></simple-entity>
                  <div
                    class="value"
                    [ngClass]="{
                      'has-errors': entity.numberOfErrors > 0,
                    }"
                    [title]="'AmountOfErrors' | translate"
                  >
                    {{
                      entity.numberOfErrors > 0
                        ? entity.numberOfErrors +
                          " " +
                          ("Issues" | translate | lowercase)
                        : "OK"
                    }}
                  </div>
                  <i
                    class="icon"
                    [ngClass]="{
                      'bi-chevron-up': entity.actionsOpened,
                      'bi-chevron-down': !entity.actionsOpened,
                    }"
                  >
                  </i>
                </div>
                @if (entity.actionsOpened) {
                  <div
                    class="entity-content"
                    [ngClass]="{
                      last: $index === modifiedModel().entities.length - 1,
                    }"
                  >
                    <div class="content">
                      <div class="content-header">
                        {{ "Assessments" | translate }}
                      </div>
                      <div class="content-value flex-column">
                        @for (result of entity.results; track $index) {
                          <simple-assessment
                            [task]="result.task"
                            [choice]="result.task.choice"
                            [comment]="result.comment"
                            [media]="result.media"
                            [displayInfoOnClick]="true"
                            [infoText]="
                              result.task.choice.isError &&
                              result.measureAssignmentId
                                ? ('MeasureAssignment' | translate) +
                                  ' ' +
                                  ((result.solved ? 'Handled' : 'NotHandled')
                                    | translate
                                    | lowercase)
                                : ''
                            "
                            [url]="
                              result.measureAssignmentId
                                ? getUrl(
                                    result.measureAssignmentId,
                                    !!result.solved
                                  )
                                : ''
                            "
                            [linkText]="'MeasureAssignment' | translate"
                          ></simple-assessment>
                        }
                      </div>
                    </div>
                  </div>
                }
              }
            </div>
          </div>
        }
        @if (
          !modifiedModel().facility.results.length &&
          !modifiedModel().entities.length
        ) {
          <div class="content">
            <div class="content-header">
              {{ "Assessments" | translate }}
            </div>
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          </div>
        }
        @if (
          modifiedModel().facility.results.length ||
          modifiedModel().entities.length
        ) {
          <div class="content">
            <div class="content-header">
              {{ "CompletedBy" | translate }}
            </div>
            @if (modifiedModel().completedByUser) {
              <div class="content-value">
                <simple-user
                  [user]="modifiedModel().completedByUser"
                ></simple-user>
              </div>
              @if (modifiedModel().isSSEN() && modifiedModel().performed) {
                <div class="performed-date">
                  {{ "PerformedDate" | translate }}:
                  {{ modifiedModel().performed | datex }}
                </div>
              }
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</simple-details-tab>
