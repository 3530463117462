import { NgClass } from "@angular/common";
import { Component, input, output, signal } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { Subscriber, User } from "app/components/user/user";
import { RegexUtils } from "app/tools/regex-utils";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormCheckboxComponent } from "../../../../../simple-components/crud/modal/tabs/details/form/form-checkbox/simple-form-checkbox.component";
import { GeneratedReport } from "../../generated-report";

@Component({
  selector: "details-generate-report-tab",
  templateUrl: "./details-generate-report-tab.component.html",
  styleUrls: ["../../../report-shared-style.less"],
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    SimpleFormSelectComponent,
    SimpleFormCheckboxComponent,
    TranslateModule,
    NgClass,
  ],
})
export class DetailsGenerateReportTabComponent extends DetailsBaseDirective<GeneratedReport> {
  selectableFileFormatItems = signal<FormSelectItem[]>([]);
  selectableEmailItems = signal<FormSelectItem[]>([]);

  sendReportByEmailName = signal<string>("sendReportByEmail");

  subscribers = input<Subscriber[]>();
  tooLargeForDownload = input<boolean>();
  sendReportByEmail = input<boolean>();

  onSendReportByEmailToggle = output();

  ngOnInit() {
    this.setSelectableFileFormatItems();
    this.setSelectableEmailItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.header]: [
          this.modifiedModel().header,
          Validators.required,
        ],
        [this.propertyStrings.reportFileFormat]: [
          this.modifiedModel().reportFileFormat,
          Validators.required,
        ],
        [this.sendReportByEmailName()]: [
          this.sendReportByEmail,
          Validators.required,
        ],
        [this.propertyStrings.emails]: [
          this.modifiedModel().emails,
          this.emailsValidator,
        ],
      }),
    );
    super.ngOnInit();
  }

  private emailsValidator(control: AbstractControl) {
    if (
      control.value &&
      control.value.some(
        (value: any) => !new RegExp(RegexUtils.EMAIL).test(value),
      )
    ) {
      return { emails: true };
    } else {
      return null;
    }
  }

  private setSelectableFileFormatItems() {
    this.selectableFileFormatItems.set([
      new FormSelectItem(
        StringUtils.PDF,
        this.translationService.instant(StringUtils.PDF),
      ),
      new FormSelectItem(
        StringUtils.EXCEL,
        this.translationService.instant(StringUtils.EXCEL),
      ),
    ]);
  }

  setSelectableEmailItems() {
    this.selectableEmailItems.set(
      this.modifiedModel().selectableEmails.map(
        (email) => new FormSelectItem(email, email),
      ),
    );
  }

  handleSendReportByEmailClick() {
    this.onSendReportByEmailToggle.emit();
  }

  handleAddUsersClick() {
    // TODO
    // this.bsModalRef = this.modalService.show(ListUserComponent, {
    //   initialState: {
    //     isInModal: true,
    //     hideListActions: true,
    //     hideTableHeaderActions: true,
    //     hideTableRowActions: true,
    //     idsToSetAsSelected: this.changeModel.users.map((item) => item.id),
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_XX_LARGE,
    // });
    // const listComponent = this.bsModalRef.content;
    // this.subscriptions.add(
    //   listComponent.confirmed$
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe((confirmedItems: User[]) => {
    //       if (confirmedItems) {
    //         this.changeModel.users = confirmedItems;
    //         this.closeModal();
    //       }
    //     })
    // );
    // this.subscriptions.add(
    //   listComponent.closed$
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe((wasClosed) => {
    //       if (wasClosed) {
    //         this.closeModal();
    //       }
    //     })
    // );
  }

  closeModal() {
    // this.bsModalRef.hide();
    // this.bsModalRef = null;
  }

  removeUser(userToRemove: User) {
    this.modifiedModel().users = this.modifiedModel().users.filter(
      (user) => user.id !== userToRemove.id,
    );
  }

  handleAddNewItemInSelect(item: FormSelectItem) {
    this.modifiedModel().selectableEmails.push(item.name);
  }
}
