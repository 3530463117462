<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select
          [label]="'Categories' | translate"
          [name]="propertyStrings.categoryIds"
          [form]="form()"
          [items]="selectableCategoryItems()"
          [multiple]="true"
          [searchable]="true"
          [addable]="true"
          [useStrictSearch]="true"
          [serverErrors]="serverErrors()"
          (onAddNewItem)="handleAddNewItemInSelect($event)"
        >
        </simple-form-select>
      </div>
    </div>
  </div>
</simple-details-tab>
