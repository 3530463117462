import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Facility } from "app/components/facility/facility";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "details-status-facility-tab",
  templateUrl: "./details-status-facility-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    TranslateModule,
    SimpleFormSelectComponent,
  ],
})
export class DetailsStatusFacilityTabComponent extends DetailsBaseDirective<Facility> {
  selectableStatusItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableStatusItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.status]: [
          this.modifiedModel().status,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }
}
