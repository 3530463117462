<div
  class="form-editor"
  [formGroup]="form()"
  [ngClass]="{
    disabled: disabled(),
  }"
>
  <div
    class="label-wrapper"
    [ngClass]="{
      focused: focused(),
      'has-errors': hasErrors(),
    }"
  >
    <label [for]="name()">
      {{ label() }}
    </label>
    <label [for]="name()" class="secondary-label">
      {{
        !isRequired() ? "" : " (" + ("Required" | translate | lowercase) + ")"
      }}
    </label>
  </div>
  <p-editor
    [formControlName]="name()"
    [placeholder]="label()"
    [style]="{ height: '150px' }"
  />
  <simple-form-error
    [name]="name()"
    [form]="form()"
    [serverErrors]="serverErrors()"
  >
  </simple-form-error>
</div>
