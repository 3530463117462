import { Injectable } from "@angular/core";
import { Client } from "app/components/organization/organization";
import { Schedule } from "app/components/schedule/schedule";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import { TemplateType } from "app/components/template-type/template-type";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import {
  AssignmentTemplate,
  AssignmentTemplatePayload,
} from "../components/assignment-template/assignment-template";
import { Facility } from "../components/facility/facility";
import { Filter, KeyValuePair, SortDirection } from "../filter";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class AssignmentTemplateService extends BaseService<AssignmentTemplate> {
  prefix = RoutesUtils.assignmentTemplate;

  constructor() {
    super(AssignmentTemplate);
  }

  async get(id: string): Promise<AssignmentTemplate> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new AssignmentTemplate(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: AssignmentTemplatePayload): Promise<AssignmentTemplate> {
    try {
      const response = await this.hostedHttpClient.post(`${this.prefix}`, item);
      return this.extractData(response)["created"].map(
        (data: AssignmentTemplate) => new AssignmentTemplate(data),
      )[0];
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: AssignmentTemplatePayload): Promise<AssignmentTemplate> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
      );
      return new AssignmentTemplate(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getTemplateTypes(
    organizationId: number,
    includeTaskChoices?: boolean,
  ): Promise<TemplateType[]> {
    try {
      if (includeTaskChoices) {
        const response = await this.hostedHttpClient.get(
          `templateType/organization/${organizationId}?includeManualMeasure=true&includeTaskChoices=true`,
        );
        return this.extractData(response).map(
          (item: TemplateType) => new TemplateType(item),
        );
      } else {
        const response = await this.hostedHttpClient.get(
          `templateType/organization/${organizationId}?includeManualMeasure=true`,
        );
        return this.extractData(response).map(
          (item: TemplateType) => new TemplateType(item),
        );
      }
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getFacilities(): Promise<Facility[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<Facility>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `facility/filteredac`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: Facility) => new Facility(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getSchedules(organizationId: number): Promise<Schedule[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<Schedule>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `schedule?organization=${organizationId}`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: Schedule) => new Schedule(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getCategories(organizationId: number): Promise<Category[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `organization/${organizationId}/category`,
      );
      return this.extractData(response).map(
        (item: Category) => new Category(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getCategoriesFromItems(
    itemIds: string[],
    organizationId: number,
  ): Promise<Category[]> {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `category/byassignmenttemplates?organization=${organizationId}`,
        model,
      );
      return this.extractData(response).map(
        (item: Category) => new Category(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getClients(organizationId: number): Promise<Client[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `client?organization=${organizationId}`,
        true,
      );
      return this.extractData(response).map((item: Client) => new Client(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getClientsFromItems(
    itemIds: string[],
    organizationId: number,
  ): Promise<Client[]> {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `client/byassignmenttemplates?organization=${organizationId}`,
        model,
      );
      return this.extractData(response).map((item: Client) => new Client(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateCategories(
    itemIds: string[],
    categoryNames: string[],
    organizationId: number,
  ): Promise<AssignmentTemplate[]> {
    try {
      const model = {
        itemIds: itemIds,
        categoryNames: categoryNames,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/categories?organization=${organizationId}`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateClient(
    itemIds: string[],
    clientId: string,
    organizationId: number,
  ): Promise<AssignmentTemplate[]> {
    try {
      const model = {
        itemIds: itemIds,
        clientId: clientId,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/client?organization=${organizationId}`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateGeoFencing(
    itemIds: string[],
    value: boolean,
    organizationId: number,
  ): Promise<AssignmentTemplate[]> {
    try {
      const model = {
        itemIds: itemIds,
        geoControlled: value,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/geofencing?organization=${organizationId}`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getGeoFencingData(
    itemIds: string[],
    value: boolean,
    organizationId: number,
  ): Promise<string[]> {
    try {
      const model = {
        itemIds: itemIds,
        geoControlled: value,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/geofencingids?organization=${organizationId}`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class AssignmentTemplateFilter extends Filter {
  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.TEMPLATE_TYPES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.TEMPLATE_TYPE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.TEMPLATE_TYPE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.CATEGORIES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.CATEGORY_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.CATEGORY_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.CLIENTS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.CLIENT_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.CLIENT_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.LOCATIONS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.FACILITY_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.FACILITY_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_SCHEDULES_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_SCHEDULES_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_SCHEDULES_KEY] = "false";
        }
      } else if (facet.key === StringUtils.SCHEDULES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.SCHEDULE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.SCHEDULE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.GEO_FENCING_KEY) {
        const geoFencing = facet.value;
        if (geoFencing === StringUtils.YES) {
          tempFacets[StringUtils.GEO_CONTROLLED_KEY] = "true";
        } else if (geoFencing === StringUtils.NO) {
          tempFacets[StringUtils.GEO_CONTROLLED_KEY] = "false";
        }
      } else if (facet.key === StringUtils.GEO_CONTROLLED_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.STATUS_KEY) {
        const status = facet.value;
        if (status === StringUtils.ACTIVE) {
          tempFacets[StringUtils.HAS_ACTIVE_TEMPLATE_OBJECTS_KEY] = "true";
        } else if (status === StringUtils.INACTIVE) {
          tempFacets[StringUtils.HAS_ACTIVE_TEMPLATE_OBJECTS_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_ACTIVE_TEMPLATE_OBJECTS_KEY) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    return {
      filter: JSON.stringify(this),
    };
  }
}
