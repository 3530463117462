import { NgClass } from "@angular/common";
import { Component, computed, Signal } from "@angular/core";
import { DatexPipe } from "app/pipes/datex.pipe";
import { Utils } from "app/tools/utils";
import { DueDateContent } from "../../../columns/simple-table-due-date-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-due-date",
  templateUrl: "./column-type-due-date.component.html",
  styleUrl: "./column-type-due-date.component.less",
  standalone: true,
  imports: [DatexPipe, NgClass],
})
export class ColumnTypeDueDateComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<DueDateContent>;
  utils = Utils;

  format(humanDate: string) {
    const [first, second] = humanDate.split(" ");
    return `${Utils.uppercaseEachFirst(first)}${second}`;
  }
}
