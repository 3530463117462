import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Entity } from "app/components/entity/entity";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { EntityService } from "app/services/entity.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsStatusEntityTabComponent } from "./tabs/details/details-status-entity-tab.component";

@Component({
  selector: "change-status-entity",
  templateUrl: "./change-status-entity.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsStatusEntityTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangeStatusEntityComponent extends CreateEditBaseDirective<Entity> {
  private detailsStatusEntityTabComponent = viewChild(
    DetailsStatusEntityTabComponent,
  );

  private entityService = inject(EntityService);

  async initiate() {
    this.initialModel.set(new Entity({}));
    this.modifiedModel.set(
      new Entity(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.entityService.updateStatus(
        this.itemIds(),
        this.modifiedModel().status,
      );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("Entity", "Entities"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsStatusEntityTabComponent().setServerErrors(error.errors);
    }
  }
}
