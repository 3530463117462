import { NgTemplateOutlet } from "@angular/common";
import { Component, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import {
  SimpleTableTranslatedTextColumn,
  TranslatedTextContent,
} from "app/components/simple-components/list/table/columns/simple-table-translated-text-column";
import {
  SimpleTableUserColumn,
  UserContent,
} from "app/components/simple-components/list/table/columns/simple-table-user-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import { SwalConfig } from "app/swal/swal-config.component";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import { UserFilter, UserService } from "../../../services/user.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { CreateEditUserComponent } from "../create-edit-user/create-edit-user.component";
import { ChangePasswordComponent } from "../profile/security/password/change-password.component";
import { ResendEmailComponent } from "../resend-email/resend-email.component";
import { User } from "../user";
import { ChangeStatusUserComponent } from "./change-status/change-status-user.component";

@Component({
  selector: "list-user",
  templateUrl: "./list-user.component.html",
  standalone: true,
  imports: [
    CreateEditUserComponent,
    ResendEmailComponent,
    ChangePasswordComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    ChangeStatusUserComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
  ],
})
export class ListUserComponent extends ListBaseDirective<User> {
  readonly filterObject = new UserFilter();

  visibleResendEmail = signal<boolean>(false);
  visibleChangePassword = signal<boolean>(false);
  visibleChangeStatus = signal<boolean>(false);

  isInvite = signal<boolean>(false);

  private appInitializer = inject(AppInitializer);

  constructor(private userService: UserService) {
    super(userService);
  }

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewUser",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleListAction(
        "Invite",
        "Invite",
        StringUtils.icons.email,
        () => {
          this.isInvite.set(true);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
    ]);
  }

  protected async configureTableFilter() {
    this.initialFacets.set([
      new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
    ]);

    const roleKey = StringUtils.ROLE_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [roleKey]: null,
        [statusKey]: null,
      });
    }

    try {
      const roles = this.appInitializer.roles;
      const convertedRoles = roles.map(
        (role) => new SimpleFilterInputItem(role.id, role.id),
      );

      this.filterInputs.set([
        new SimpleFilterInput(
          roleKey,
          roleKey,
          StringUtils.icons.role,
          SimpleFilterInputType.MultiSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(roleKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(roleKey, ""));
            }
            return keyValuePairs;
          },
          convertedRoles,
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.ACTIVE, StringUtils.ACTIVE),
            new SimpleFilterInputItem(
              StringUtils.INACTIVE,
              StringUtils.INACTIVE,
            ),
            new SimpleFilterInputItem(StringUtils.PENDING, StringUtils.PENDING),
          ],
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.firstname, "Name", true),
      new SortObject(this.propertyStrings.email, "EmailAddress"),
      new SortObject(this.propertyStrings.certificates, "CertificateNumber"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableUserColumn(
        ["Name", "EmailAddress"],
        (row) => new UserContent(row),
      ),
      new SimpleTableTextColumn(
        ["CertificateNumber"],
        (row) => new TextContent(row.certificates),
      ),
      new SimpleTableTranslatedTextColumn(
        ["Role"],
        (row) =>
          new TranslatedTextContent(
            row
              .getCurrentRoles(this.globalState.selectedOrganization().id)
              .join(", "),
          ),
      ),
      new SimpleTableDateColumn(
        ["Invited"],
        (row) =>
          new DateContent(
            row.getCurrentInviteDate(
              this.globalState.selectedOrganization().id,
            ),
          ),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.getCurrentStatus(this.globalState.selectedOrganization().id),
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "ChangeStatus",
        StringUtils.icons.setStatus,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeStatus.set(true);
        },
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        (row) =>
          row.getCurrentStatus(this.globalState.selectedOrganization().id) ===
            "Pending" ||
          row.id === this.globalState.loggedInUser().id ||
          this.isInModal(),
      ),
      new SimpleTableRowActionEdit(
        "SendVerificationLink",
        StringUtils.icons.email,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleResendEmail.set(true);
        },
        (row) =>
          row.getCurrentStatus(this.globalState.selectedOrganization().id) !==
            "Pending" || this.isInModal(),
      ),
      new SimpleTableRowActionEdit(
        "ChangePassword",
        StringUtils.icons.password,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleChangePassword.set(true);
        },
        (row) =>
          !this.globalState.loggedInUser().isSuperAdmin() ||
          row.isSuperAdmin() ||
          row.getCurrentStatus(this.globalState.selectedOrganization().id) ===
            "Pending" ||
          row.id === this.globalState.loggedInUser().id ||
          this.isInModal(),
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        (row) => {
          if (this.isInModal()) {
            return true;
          } else {
            if (row.id === this.globalState.loggedInUser().id) {
              return true;
            } else if (this.globalState.loggedInUser().isSuperAdmin()) {
              if (row.isSuperAdmin()) {
                return true;
              } else {
                return false;
              }
            } else {
              return (
                row.isSuperAdmin() ||
                row.id === this.globalState.loggedInUser().id
              );
            }
          }
        },
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Users", StringUtils.icons.user),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteUser")
            : this.translationService.instant("DeleteUsers"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.userService.deleteRange(
            this.itemIds(),
            this.globalState.selectedOrganization().id,
          );
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete("User", "Users"),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseCreateEdit() {
    this.visibleCreateEdit.set(false);
    this.itemIds.set([]);
    this.isInvite.set(false); // TODO se över om bättre lösning så kan ta bort handle från child...
  }

  handleCloseResendEmail() {
    this.visibleResendEmail.set(false);
    this.itemIds.set([]);
  }

  handleCloseChangePassword() {
    this.visibleChangePassword.set(false);
    this.itemIds.set([]);
  }

  handleCloseChangeStatus() {
    this.visibleChangeStatus.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneCreateEdit(data: User[]) {
    this.handleCloseCreateEdit();
    this.isInvite.set(false); // TODO se över om bättre lösning så kan ta bort handle från child...
    this.getTableDataAndScrollToItem(data[0].id);
  }

  handleRequestDoneResendEmail(data: User[]) {
    this.handleCloseResendEmail();
    this.getTableDataAndScrollToItem(data[0].id);
  }

  handleRequestDoneChangePassword(data: User[]) {
    this.handleCloseChangePassword();
    this.getTableDataAndScrollToItem(data[0].id);
  }

  async handleRequestDoneChangeStatus(data: User[]) {
    this.handleCloseChangeStatus();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }
}
