import { Component, computed, Signal } from "@angular/core";
import { SimpleMeasureTargetComponent } from "app/components/simple-components/various/measure-target/simple-measure-target.component";
import { MeasureTargetContent } from "../../../columns/simple-table-measure-target-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-measure-target",
  templateUrl: "./column-type-measure-target.component.html",
  standalone: true,
  imports: [SimpleMeasureTargetComponent],
})
export class ColumnTypeMeasureTargetComponent<
  T,
> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<MeasureTargetContent>;
}
