import { Component, input, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { SimpleFormInfoComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "details-priority-assignment-tab",
  templateUrl: "./details-priority-assignment-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    TranslateModule,
    SimpleFormInfoComponent,
    SimpleFormSelectComponent,
  ],
})
export class DetailsPriorityAssignmentTabComponent extends DetailsBaseDirective<MeasureAssignment> {
  selectablePriorityItems = signal<FormSelectItem[]>([]);

  affectableIds = input<string[]>();

  ngOnInit() {
    this.setSelectablePriorityItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.priority]: [
          this.modifiedModel().priority,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectablePriorityItems() {
    this.selectablePriorityItems.set([
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
    ]);
  }
}
