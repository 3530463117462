<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="'TemplateTypeChangeDetailsHelpText' | translate"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'ii-task'"
            [title]="'Tasks' | translate"
            [helpText]="'TemplateTypeHandleTasksHelpText' | translate"
            [displayCount]="modifiedModel().tasks.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-template-type-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="handleDetailsChange($event)"
          >
          </details-template-type-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-tasks-tab
            [items]="modifiedModel().tasks"
            [headerSubtitleText]="'AddedTasksSubtitleTemplateType' | translate"
            (onItemsChange)="handleItemsChange(propertyStrings.tasks, $event)"
          >
          </handle-tasks-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
