<div class="measure-target">
  <simple-image [url]="facility().thumbnailUrl"></simple-image>
  <div class="text-wrapper">
    <div class="facility" [title]="facility().name">
      {{ facility().name }}
    </div>
    @if (entity()) {
      <div class="entity" [title]="entity().name">
        {{ entity().name }}
      </div>
    }
  </div>
</div>
