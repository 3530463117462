import { Component } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { UserGroup } from "app/components/user-group/user-group";
import { SimpleDetailsTabComponent } from "../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";

@Component({
  selector: "details-user-group-tab",
  templateUrl: "./details-user-group-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
  ],
})
export class DetailsUserGroupTabComponent extends DetailsBaseDirective<UserGroup> {
  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.name]: [
          this.modifiedModel().name,
          [Validators.required, Validators.maxLength(100)],
        ],
      }),
    );
    super.ngOnInit();
  }
}
