import { Component } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { DatexPipe } from "../../../../../pipes/datex.pipe";
import { SimpleFormInfoComponent } from "../../../../simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";
import { SimpleDetailsTabComponent } from "../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { User } from "../../../user";

@Component({
  selector: "details-resend-email-tab",
  templateUrl: "./details-resend-email-tab.component.html",
  styleUrls: ["./details-resend-email-tab.component.less"],
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    SimpleFormInfoComponent,
    TranslateModule,
    DatexPipe,
  ],
})
export class DetailsResendEmailTabComponent extends DetailsBaseDirective<User> {
  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.email]: [
          this.modifiedModel().email,
          [Validators.required, this.emailValidator],
        ],
      }),
    );
    super.ngOnInit();
  }

  private emailValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
      return { email: true };
    } else {
      return null;
    }
  }
}
