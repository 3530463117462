import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleEntitiesTabComponent } from "app/components/facility/create-edit-facility/tabs/handle-entities/handle-entities-tab.component";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { HandleTasksTabComponent } from "app/components/template-type/create-edit-template-type/tabs/handle-tasks/handle-tasks-tab.component";
import { TabVisibleDirective } from "app/directives/tab-visible.directive";
import { ClientService } from "app/services/client.service";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { AssignmentTemplateService } from "../../../services/assignment-template.service";
import { AssignmentTemplate } from "../assignment-template";
import { DetailsAssignmentTemplateTabComponent } from "./tabs/details/details-assignment-template-tab.component";
import { HandleFacilitiesTabComponent } from "./tabs/handle-facilities/handle-facilities-tab.component";

@Component({
  selector: "create-edit-assignment-template",
  templateUrl: "./create-edit-assignment-template.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsAssignmentTemplateTabComponent,
    HandleFacilitiesTabComponent,
    HandleTasksTabComponent,
    HandleEntitiesTabComponent,
    TranslateModule,
    TabsModule,
    TabVisibleDirective,
  ],
})
export class CreateEditAssignmentTemplateComponent extends CreateEditBaseDirective<AssignmentTemplate> {
  currentTemplateTypeId: string;

  facilityIds = signal<string[]>([]);

  private detailsAssignmentTemplateTabComponent = viewChild(
    DetailsAssignmentTemplateTabComponent,
  );

  private assignmentTemplateService = inject(AssignmentTemplateService);
  private clientService = inject(ClientService);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        const [clients, categories] = await Promise.all([
          this.clientService.getAll(),
          this.assignmentTemplateService.getCategories(
            this.globalState.selectedOrganization().id,
          ),
        ]);
        const data = await this.assignmentTemplateService.get(
          this.itemIds()[0],
        );
        this.initialModel.set(data);
        this.pending.set(false);

        this.modifiedModel.set(
          new AssignmentTemplate(Utils.getUniqueVariant(this.initialModel())),
        );

        this.initialModel().selectableTemplateTypes.push(
          this.initialModel().templateType,
        );
        this.initialModel().selectableClients = clients;
        this.initialModel().selectableCategories = categories;
        this.initialModel().facilities.push(this.initialModel().facility);

        this.modifiedModel().selectableTemplateTypes.push(
          this.modifiedModel().templateType,
        );
        this.modifiedModel().selectableClients = clients;
        this.modifiedModel().selectableCategories = categories;
        this.modifiedModel().facilities.push(this.modifiedModel().facility);

        this.facilityIds.set([this.initialModel().facility.id]);
      } else {
        // Abort if not superadmin.
        if (!this.globalState.loggedInUser().isSuperAdmin()) {
          this.toastrService.error(
            `${this.translationService.instant(StringUtils.NO_PERMISSION)}!`,
          );
          this.handleClose();
          return;
        }

        let templateTypes =
          await this.assignmentTemplateService.getTemplateTypes(
            this.globalState.selectedOrganization().id,
          );
        templateTypes = templateTypes.filter(
          (templateType) => !templateType.isManualMeasure,
        );
        const facilities = await this.assignmentTemplateService.getFacilities();

        // Abort if no template types or facilities.
        if (!templateTypes.length || !facilities.length) {
          this.toastrService.error(
            `${this.translationService.instant(
              StringUtils.NO_TEMPLATE_TYPE_ERROR,
            )}!`,
          );
          this.handleClose();
          return;
        }

        const [clients, categories] = await Promise.all([
          this.clientService.getAll(),
          this.assignmentTemplateService.getCategories(
            this.globalState.selectedOrganization().id,
          ),
        ]);
        this.pending.set(false);

        this.initialModel.set(new AssignmentTemplate({}));

        this.modifiedModel.set(
          new AssignmentTemplate(Utils.getUniqueVariant(this.initialModel())),
        );

        this.initialModel().templateTypeId = templateTypes[0].id;
        this.initialModel().selectableTemplateTypes = templateTypes;
        this.initialModel().selectableClients = clients;
        this.initialModel().selectableCategories = categories;
        this.initialModel().name = templateTypes[0].name;

        this.modifiedModel().templateTypeId = templateTypes[0].id;
        this.modifiedModel().selectableTemplateTypes = templateTypes;
        this.modifiedModel().selectableClients = clients;
        this.modifiedModel().selectableCategories = categories;
        this.modifiedModel().name = templateTypes[0].name;

        this.currentTemplateTypeId = this.initialModel().templateTypeId;
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.assignmentTemplateService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.assignmentTemplateService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );
      this.toastrService.success(
        this.translationService.instant(
          this.getSuccessMessage("AssignmentTemplate"),
        ),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsAssignmentTemplateTabComponent().setServerErrors(
        error.errors,
      );
    }
  }

  handleDetailsChangeExtra() {
    if (this.detailsAssignmentTemplateTabComponent()) {
      const templateTypeIdCtrl = this.detailsAssignmentTemplateTabComponent()
        .form()
        .get(this.propertyStrings.templateTypeId);
      const geoControlledCtrl = this.detailsAssignmentTemplateTabComponent()
        .form()
        .get(this.propertyStrings.geoControlled);
      if (
        !this.isEdit &&
        templateTypeIdCtrl.value !== this.currentTemplateTypeId
      ) {
        this.modifiedModel().facilityTasks = [];
        this.modifiedModel().entityTasks = [];
        this.currentTemplateTypeId = templateTypeIdCtrl.value;
        this.modifiedModel().name =
          this.modifiedModel().selectableTemplateTypes.find(
            (templateType) => templateType.id === templateTypeIdCtrl.value,
          ).name;
      }
      if (!this.isEdit && geoControlledCtrl.value) {
        this.modifiedModel().facilities =
          this.modifiedModel().facilities.filter(
            (facility) => facility.hasPosition,
          );
        this.handleItemsChangeExtraFacility();
      }
    }
  }

  handleItemsChangeExtraFacility() {
    this.facilityIds.set(
      this.modifiedModel().facilities.map((facility) => facility.id),
    );
    this.modifiedModel().entities = this.modifiedModel().entities.filter(
      (entity) => this.facilityIds().includes(entity.facility.id),
    );
    if (!this.modifiedModel().facilities.length) {
      this.modifiedModel().facilityTasks = [];
    }
    if (!this.modifiedModel().entities.length) {
      this.modifiedModel().entityTasks = [];
    }
  }

  handleItemsChangeExtraEntity() {
    if (!this.modifiedModel().entities.length) {
      this.modifiedModel().entityTasks = [];
    }
  }
}
