import { NgTemplateOutlet } from "@angular/common";
import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { DetailedAssignmentMeasureArchivedComponent } from "app/components/assignment/detailed-assignment/measure/archived/detailed-assignment-measure-archived.component";
import { GenerateReportComponent } from "app/components/assignment/report/generated/generate-report.component";
import { GeneratedReport } from "app/components/assignment/report/generated/generated-report";
import { StaticReport } from "app/components/assignment/report/static/static-report";
import { StaticReportComponent } from "app/components/assignment/report/static/static-report.component";
import { IntegrationTypes } from "app/components/organization/my-organization/integrations/integration";
import { Client } from "app/components/organization/organization";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import {
  SimpleTableRowAction,
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  AssessmentContent,
  SimpleTableAssessmentColumn,
} from "app/components/simple-components/list/table/columns/simple-table-assessment-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  IssueNumberContent,
  SimpleTableIssueNumberColumn,
} from "app/components/simple-components/list/table/columns/simple-table-issue-number-column";
import {
  MeasureTargetContent,
  SimpleTableMeasureTargetColumn,
} from "app/components/simple-components/list/table/columns/simple-table-measure-target-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import {
  SimpleTableUserColumn,
  UserContent,
} from "app/components/simple-components/list/table/columns/simple-table-user-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import {
  AssignmentFilter,
  AssignmentService,
} from "app/services/assignment.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import { ListHeaderComponent } from "../../../../header/list-header/list-header.component";
import { SimpleViewClientComponent } from "../../../../simple-components/various/view-client/simple-view-client.component";
import { SimpleViewContentComponent } from "../../../../simple-components/various/view-content/simple-view-content.component";

@Component({
  selector: "list-assignment-measure-archived",
  templateUrl: "./list-assignment-measure-archived.component.html",
  standalone: true,
  imports: [
    DetailedAssignmentMeasureArchivedComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    GenerateReportComponent,
    StaticReportComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewContentComponent,
    SimpleViewClientComponent,
  ],
})
export class ListAssignmentMeasureArchivedComponent extends ListBaseDirective<Assignment> {
  readonly filterObject = new AssignmentFilter();

  visibleGenerateReport = signal<boolean>(false);
  visibleGetReport = signal<boolean>(false);
  client = signal<Client>(new Client({}));
  adminComment = signal<string>("");
  visibleViewClient = signal<boolean>(false);
  visibleViewContent = signal<boolean>(false);

  constructor(private assignmentService: AssignmentService) {
    super(assignmentService);
  }

  protected configureListActions() {}

  protected async configureTableFilter() {
    this.filterObject.context = RoutesUtils.measureArchived;

    this.initialFacets.set([
      new KeyValuePair(StringUtils.IS_COMPLETED_KEY, "true"),
      new KeyValuePair(StringUtils.IS_CLOSED_KEY, "true"),
      new KeyValuePair(StringUtils.IS_MEASURE_KEY, "true"),
      new KeyValuePair(StringUtils.IS_MANUAL_MEASURE_KEY, "true"),
      new KeyValuePair(StringUtils.STATUS_CONDITION_KEY, "Or"),
    ]);

    const typeKey = StringUtils.TYPE_KEY;
    const locationsKey = StringUtils.LOCATIONS_KEY;
    const entitiesKey = StringUtils.ENTITIES_KEY;
    const tasksKey = StringUtils.TASKS_KEY;
    const choicesKey = StringUtils.CHOICES_KEY;
    const prioritizedKey = StringUtils.PRIORITIZED_KEY;
    const handledByKey = StringUtils.HANDLED_BY_KEY;
    const archivedBetweenKey = StringUtils.ARCHIVED_BETWEEN_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    const integrationsKey = StringUtils.INTEGRATIONS_KEY;
    const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
    const categoriesKey = StringUtils.CATEGORIES_KEY;
    const issueNumbersKey = StringUtils.ISSUE_NUMBERS_KEY;
    const schedulesKey = StringUtils.SCHEDULES_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [typeKey]: null,
        [locationsKey]: null,
        [entitiesKey]: null,
        [tasksKey]: null,
        [choicesKey]: null,
        [prioritizedKey]: null,
        [handledByKey]: null,
        [archivedBetweenKey]: null,
        [statusKey]: null,
        [integrationsKey]: null,
        [templateTypesKey]: null,
        [categoriesKey]: null,
        [issueNumbersKey]: null,
        [schedulesKey]: null,
      });
    }

    try {
      const [
        templateTypes,
        facilities,
        entities,
        tasks,
        choices,
        users,
        integrations,
        schedules,
        categories,
        issueNumbers,
      ] = await Promise.all([
        this.assignmentService.getTemplateTypes(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getFacilities(),
        this.assignmentService.getEntities(),
        this.assignmentService.getTasks(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getChoices(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getUsers(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getIntegrations(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getSchedules(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getCategories(
          this.globalState.selectedOrganization().id,
        ),
        this.assignmentService.getIssueNumbers(
          this.globalState.selectedOrganization().id,
          false,
        ),
      ]);

      const manualMeasure = templateTypes.find(
        (templateType) => templateType.isManualMeasure,
      );
      const convertedManualMeasure = manualMeasure
        ? new SimpleFilterInputItem(manualMeasure.id, manualMeasure.name)
        : null;
      const convertedFacilities = facilities.map(
        (facility) => new SimpleFilterInputItem(facility.id, facility.name),
      );
      const convertedEntities = entities.map(
        (entity) => new SimpleFilterInputItem(entity.id, entity.name),
      );
      const convertedTasks = tasks.map(
        (task) => new SimpleFilterInputItem(task.id, task.text),
      );
      const convertedChoices = choices.map(
        (choice) => new SimpleFilterInputItem(choice.id, choice.value),
      );
      const convertedUsers = users.map(
        (user) =>
          new SimpleFilterInputItem(
            user.id,
            user.fullName ? user.fullName : user.email,
          ),
      );
      const convertedIntegrations = integrations
        .filter(
          (i) =>
            i.type === IntegrationTypes.DfRespons ||
            i.type === IntegrationTypes.InfraControl,
        )
        .map(
          (integration) =>
            new SimpleFilterInputItem(integration.id, integration.name),
        );
      const convertedTemplateTypes = templateTypes
        .filter((templateType) => !templateType.isManualMeasure)
        .map(
          (templateType) =>
            new SimpleFilterInputItem(templateType.id, templateType.name),
        );
      const convertedCategories = categories.map(
        (category) => new SimpleFilterInputItem(category.id, category.name),
      );
      const convertedIssueNumbers = issueNumbers.map(
        (issueNumber) =>
          new SimpleFilterInputItem(
            issueNumber.toString(),
            issueNumber.toString(),
          ),
      );
      const convertedSchedules = schedules.map(
        (schedule) => new SimpleFilterInputItem(schedule.id, schedule.name),
      );
      if (convertedIntegrations.length) {
        convertedIntegrations.push(
          new SimpleFilterInputItem(
            IntegrationTypes.None,
            IntegrationTypes.None,
          ),
        );
      }

      this.filterInputs.set([
        new SimpleFilterInput(
          locationsKey,
          locationsKey,
          StringUtils.icons.location,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(locationsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(locationsKey, ""));
            }
            return keyValuePairs;
          },
          convertedFacilities,
        ),
        new SimpleFilterInput(
          entitiesKey,
          entitiesKey,
          StringUtils.icons.entity,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(entitiesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(entitiesKey, ""));
            }
            return keyValuePairs;
          },
          convertedEntities,
        ),
        new SimpleFilterInput(
          choicesKey,
          choicesKey,
          StringUtils.icons.choice,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(choicesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(choicesKey, ""));
            }
            return keyValuePairs;
          },
          convertedChoices,
        ),
        new SimpleFilterInput(
          tasksKey,
          tasksKey,
          StringUtils.icons.task,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(tasksKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(tasksKey, ""));
            }
            return keyValuePairs;
          },
          convertedTasks,
        ),
        new SimpleFilterInput(
          handledByKey,
          handledByKey,
          StringUtils.icons.user,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(handledByKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(handledByKey, ""));
            }
            return keyValuePairs;
          },
          convertedUsers,
        ),
        new SimpleFilterInput(
          issueNumbersKey,
          issueNumbersKey,
          StringUtils.icons.issueNumber,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(issueNumbersKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(issueNumbersKey, ""));
            }
            return keyValuePairs;
          },
          convertedIssueNumbers,
        ),
        new SimpleFilterInput(
          prioritizedKey,
          prioritizedKey,
          StringUtils.icons.priority,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(prioritizedKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(prioritizedKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          archivedBetweenKey,
          archivedBetweenKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateRangeSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(archivedBetweenKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(archivedBetweenKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(
              StringUtils.COMPLETED,
              StringUtils.COMPLETED,
            ),
            new SimpleFilterInputItem(StringUtils.CLOSED, StringUtils.CLOSED),
          ],
        ),
        new SimpleFilterInput(
          templateTypesKey,
          templateTypesKey,
          StringUtils.icons.templateType,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, ""));
            }
            return keyValuePairs;
          },
          convertedTemplateTypes,
        ),
        new SimpleFilterInput(
          categoriesKey,
          categoriesKey,
          StringUtils.icons.category,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(categoriesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(categoriesKey, ""));
            }
            return keyValuePairs;
          },
          convertedCategories,
        ),
        new SimpleFilterInput(
          schedulesKey,
          schedulesKey,
          StringUtils.icons.schedule,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(schedulesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(schedulesKey, ""));
            }
            return keyValuePairs;
          },
          convertedSchedules,
        ),
        new SimpleFilterInput(
          typeKey,
          typeKey,
          StringUtils.icons.type,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(typeKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(typeKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(
              StringUtils.MEASURE_ASSIGNMENT,
              StringUtils.MEASURE_ASSIGNMENT,
            ),
            new SimpleFilterInputItem(
              StringUtils.MANUAL_MEASURE_ASSIGNMENT,
              convertedManualMeasure
                ? convertedManualMeasure.name
                : StringUtils.MANUAL_MEASURE_ASSIGNMENT,
            ),
          ],
        ),
        new SimpleFilterInput(
          integrationsKey,
          integrationsKey,
          StringUtils.icons.integrations,
          SimpleFilterInputType.MultiSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(integrationsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(integrationsKey, ""));
            }
            return keyValuePairs;
          },
          convertedIntegrations,
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(
        "AssignmentTemplate.Facility.Name" as keyof Assignment,
        "Location",
      ),
      new SortObject(
        "ErrorResult.TaskChoice.Choice.Value" as keyof Assignment,
        "Assessment",
      ),
      new SortObject(this.propertyStrings.comment, "ErrorComment"),
      new SortObject(
        this.propertyStrings.issueNumber as keyof Assignment,
        "IssueNumber",
      ),
      new SortObject(this.propertyStrings.adminComment, "AdminComment"),
      new SortObject(
        this.propertyStrings.completed,
        "Archived",
        true,
        "Descending",
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableMeasureTargetColumn(
        ["Location", "Entity"],
        (row) => new MeasureTargetContent(row.facility, row.entity),
      ),
      new SimpleTableAssessmentColumn(
        ["Assessment"],
        (row) => new AssessmentContent(row.choices[0], row.task),
      ),
      new SimpleTableTextColumn(
        ["ErrorComment", "ResolvementComment"],
        (row) => new TextContent(row.comment, row.solutionComment),
      ),
      new SimpleTableUserColumn(
        ["HandledBy"],
        (row) => new UserContent(row.completedByUser),
      ),
      new SimpleTableIssueNumberColumn(
        ["IssueNumber"],
        (row) => new IssueNumberContent(row.issueNumber),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.geoControlled ? StringUtils.icons.geo : "",
              "Geofencing",
              "GeofencingText",
              true,
            ),
            new IconObject(
              row.client ? StringUtils.icons.client : "",
              "Client",
              row.client
                ? `${row.client.name} ${row.client.emailAddress ? "(" + row.client.emailAddress + ")" : ""}`
                : "",
            ),
            new IconObject(
              row.priority ? StringUtils.icons.priority + " red" : "",
              "Prioritized",
              "PrioritizedText",
              true,
            ),
            new IconObject(
              row.adminComment ? StringUtils.icons.adminComment : "",
              "AdminComment",
              row.adminComment,
            ),
          ]),
      ),
      new SimpleTableDateColumn(
        ["Archived"],
        (row) => new DateContent(row.archived),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status === StringUtils.COMPLETED
              ? StringUtils.RESOLVED
              : row.status,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "GenerateErrorReport",
        StringUtils.icons.generateErrorReport,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleGenerateReport.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "GetAssignmentReport",
        StringUtils.icons.download,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleGetReport.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () =>
          this.isInModal() ||
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl),
      ),
    ]);

    // ROW

    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowAction(
        "GenerateErrorReport",
        StringUtils.icons.generateErrorReport,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleGenerateReport.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowAction(
        "GetAssignmentReport",
        StringUtils.icons.download,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleGetReport.set(true);
        },
        (row) => !row.reportUrl || this.isInModal(),
      ),
      new SimpleTableRowActionView(
        "ViewClient",
        StringUtils.icons.client,
        (row) => {
          this.client.set(row.client);
          this.visibleViewClient.set(true);
        },
        (row) => !row.client,
      ),
      new SimpleTableRowActionView(
        "ViewAdminComment",
        StringUtils.icons.adminComment,
        (row) => {
          this.adminComment.set(row.adminComment);
          this.visibleViewContent.set(true);
        },
        (row) => !row.adminComment,
      ),
      new SimpleTableRowAction(
        "RecreateMeasureAssignment",
        StringUtils.icons.recreateAssignment,
        (row) => {
          // this.setAsAffected([row.id]);
          // this.administrationService.openRecreateAssignmentSwal(
          //   row.id,
          //   null,
          //   () => this.getTableDataAndClearAfterUpdate(row.id),
          //   true
          // ); TODO
        },
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleTableRowAction(
        "RecreateAssignmentReport",
        StringUtils.icons.recreateReport,
        (row) => {
          // this.setAsAffected([row.id]);
          // this.administrationService.openRecreateReportSwal(
          //   row.id,
          //   this.globalState.selectedOrganization().id,
          //   () => this.getTableData()
          // ); TODO
        },
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () =>
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) ||
          this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState(
        "MeasureAssignments",
        StringUtils.icons.measureAssignment,
      ),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteMeasureAssignment")
            : this.translationService.instant("DeleteMeasureAssignments"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.assignmentService.deleteRange(
            this.itemIds(),
            this.globalState.selectedOrganization().id,
          );
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete(
              "MeasureAssignment",
              "MeasureAssignments",
            ),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseGenerateReport() {
    this.visibleGenerateReport.set(false);
    this.itemIds.set([]);
  }

  handleCloseGetReport() {
    this.visibleGetReport.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneGenerateReport(data: GeneratedReport[]) {
    this.handleCloseGenerateReport();
    this.clearSelectedRows();
  }

  async handleRequestDoneGetReport(data: StaticReport[]) {
    this.handleCloseGetReport();
    this.clearSelectedRows();
  }
}
