<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ChangeStatus' | translate"
  [closeText]="'Close' | translate"
  [submitText]="getSubmitText()"
  [disableSubmit]="
    !modifiedModel() ||
    pending() ||
    !changeDetailsIsValid() ||
    !affectableIds().length
  "
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="'UserSetStatusHelpText' | translate"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-status-user-tab
            [modifiedModel]="modifiedModel()"
            [affectableIds]="affectableIds()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-status-user-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
