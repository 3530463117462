<div
  class="help-actions-menu"
  [ngClass]="{
    opened: popoverOpened(),
    'in-modal': isInModal(),
  }"
  [title]="'UserSupport' | translate"
  (click)="!fetching() ? visible.set(true) : null"
>
  @if (!fetching()) {
    <i class="bi bi-question"></i>
  }
  @if (fetching()) {
    <i class="bi bi-arrow-repeat"></i>
  }
  <div class="text">
    {{ "Help" | translate }}
  </div>
</div>
<p-drawer
  [(visible)]="visible"
  [position]="'left'"
  [showCloseIcon]="false"
  [appendTo]="'body'"
>
  <ng-template #header>
    <div class="label">
      {{ "Help" | translate }}
      <div class="secondary-label">({{ "AllLinksInNewTabs" | translate }})</div>
    </div>
    <div class="actions">
      <div
        class="action"
        [title]="'Close' | translate"
        (click)="visible.set(false)"
      >
        <i class="icon bi-x-lg"></i>
      </div>
    </div>
  </ng-template>
  <div class="help-content">
    @for (helpArticle of helpArticles(); track $index) {
      <div
        class="help-menu-item"
        [title]="helpArticle.title"
        (click)="navigateToWiki(helpArticle)"
      >
        <div class="help-article-wrapper">
          <div class="icon-title-wrapper">
            <div class="help-article-icon {{ helpArticle.icon }}"></div>
            <div class="help-article-title">
              {{ helpArticle.title }}
            </div>
          </div>
        </div>
      </div>
    }
    @if (!helpArticles().length) {
      <div class="help-menu-item dummy">
        <div class="help-article-wrapper">
          <div class="icon-title-wrapper">
            <div class="help-article-icon bi-info-circle"></div>
            <div class="help-article-title">
              {{ "NoArticles" | translate }}
            </div>
          </div>
        </div>
      </div>
    }

    <div
      class="help-menu-item"
      [title]="'VisitWiki' | translate"
      (click)="navigateToWiki()"
    >
      <div class="help-article-wrapper">
        <div class="icon-title-wrapper">
          <div class="help-article-icon bi-server"></div>
          <div class="help-article-title">
            {{ "VisitWiki" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</p-drawer>
