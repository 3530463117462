<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('LocationChangeDetailsEditHelpText' | translate)
                : ('LocationChangeDetailsHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'ii-entities'"
            [title]="'Entities' | translate"
            [helpText]="
              isEdit
                ? ('LocationHandleEntitiesEditHelpText' | translate)
                : ('LocationHandleEntitiesHelpText' | translate)
            "
            [displayCount]="modifiedModel().entities.length"
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="2">
          <simple-tab-heading
            [icon]="'bi bi-files'"
            [title]="'Media' | translate"
            [helpText]="
              isEdit
                ? ('LocationHandleMediaEditHelpText' | translate)
                : ('LocationHandleMediaHelpText' | translate)
            "
            [displayCount]="modifiedModel().media.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-facility-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-facility-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-entities-tab
            [items]="modifiedModel().entities"
            [headerSubtitleText]="'AddedEntitiesSubtitleLocation' | translate"
            [hideFacilityColumn]="true"
            [facilityName]="modifiedModel().name"
            (onItemsChange)="
              handleItemsChange(propertyStrings.entities, $event)
            "
          >
          </handle-entities-tab>
        </p-tabpanel>
        <p-tabpanel [value]="2">
          <handle-media-tab
            [items]="modifiedModel().media"
            [headerSubtitleText]="'AddedMediaSubtitleLocation' | translate"
            [canToggleDefault]="true"
            (onItemsChange)="handleItemsChange(propertyStrings.media, $event)"
          >
          </handle-media-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
