<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.name"
          [form]="form()"
          [maxlength]="100"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!modifiedModel().id"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'Abbreviation' | translate"
          [name]="propertyStrings.abbreviation"
          [form]="form()"
          [maxlength]="10"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        @if (isEdit) {
          <div class="content">
            <div class="content-header">
              {{ "BaseType" | translate }}
            </div>
            <div class="content-value">
              {{ modifiedModel().templateBaseType.name }}
            </div>
          </div>
        } @else {
          <simple-form-select
            [label]="'BaseType' | translate"
            [name]="propertyStrings.templateBaseTypeId"
            [form]="form()"
            [disabled]="!!modifiedModel().id"
            [items]="selectableTemplateBaseTypeItems()"
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
        }
      </div>
      <div class="details-section">
        <div class="details-section-header">
          {{ "UrgentChoice" | translate }}
        </div>
        <!-- <choices TODO
          #component
          [items]="
            modifiedModel().notificationChoice
              ? [modifiedModel().notificationChoice]
              : []
          "
          [actionTitle]="'Add' | translate"
          [useButtonAction]="true"
          [hideAddButton]="!!modifiedModel().notificationChoice"
          (onModelsChange)="handleChoicesChange($event)"
        >
        </choices> -->
      </div>
    </div>
  </div>
</simple-details-tab>
