<div class="entity">
  <simple-image [url]="entity().thumbnailUrl"></simple-image>
  <div class="text-wrapper">
    <div class="name" [title]="entity().name">
      {{ entity().name }}
    </div>
    <div class="description" [title]="entity().description">
      {{ entity().description }}
    </div>
  </div>
</div>
