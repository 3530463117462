<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select
          [label]="'GeoFencing' | translate"
          [name]="propertyStrings.geoControlled"
          [form]="form()"
          [items]="selectableGeoFencingItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>
        <simple-form-info [firstText]="affectableIds().length.toString()">
        </simple-form-info>
      </div>
    </div>
  </div>
</simple-details-tab>
