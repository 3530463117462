import { Component, input, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { SimpleFormInfoComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "details-geo-fencing-assignment-template-tab",
  templateUrl: "./details-geo-fencing-assignment-template-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    TranslateModule,
    SimpleFormInfoComponent,
    SimpleFormSelectComponent,
  ],
})
export class DetailsGeoFencingAssignmentTemplateTabComponent extends DetailsBaseDirective<AssignmentTemplate> {
  selectableGeoFencingItems = signal<FormSelectItem[]>([]);

  affectableIds = input<string[]>();

  ngOnInit() {
    this.setSelectableGeoFencingItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.geoControlled]: [
          this.modifiedModel().geoControlled,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableGeoFencingItems() {
    this.selectableGeoFencingItems.set([
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
    ]);
  }
}
