import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { UserGroupService } from "../../../services/user-group.service";
import { SimpleCrudModalComponent } from "../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { UserGroup } from "../user-group";
import { DetailsUserGroupTabComponent } from "./tabs/details/details-user-group-tab.component";
import { HandleUsersTabComponent } from "./tabs/handle-users/handle-users-tab.component";

@Component({
  selector: "create-edit-user-group",
  templateUrl: "./create-edit-user-group.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsUserGroupTabComponent,
    HandleUsersTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditUserGroupComponent extends CreateEditBaseDirective<UserGroup> {
  private detailsUserGroupTabComponent = viewChild(
    DetailsUserGroupTabComponent,
  );

  private userGroupService = inject(UserGroupService);

  async initiate() {
    if (this.isEdit) {
      this.pending.set(true);
      try {
        const data = await this.userGroupService.get(this.itemIds()[0]);
        this.initialModel.set(data);
        this.pending.set(false);

        this.modifiedModel.set(
          new UserGroup(Utils.getUniqueVariant(this.initialModel())),
        );
      } catch (error) {
        this.pending.set(false);
        this.toastrService.error(error.message);
        this.handleClose();
      }
    } else {
      this.initialModel.set(new UserGroup({}));
      this.modifiedModel.set(
        new UserGroup(Utils.getUniqueVariant(this.initialModel())),
      );
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.userGroupService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.userGroupService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );

      // TODO
      // Refresh the logged in user if was added or removed from a group, in order to display correct info on the profile page.
      const loggedInUserExistsInInitial = this.initialModel().users.find(
        (user) => user.id === this.globalState.loggedInUser().id,
      );
      const loggedInUserExistsInModified = this.modifiedModel().users.find(
        (user) => user.id === this.globalState.loggedInUser().id,
      );
      if (
        (loggedInUserExistsInInitial && !loggedInUserExistsInModified) ||
        (!loggedInUserExistsInInitial && loggedInUserExistsInModified)
      ) {
        //this.authService.requestUser(); // TODO fixa detta på alla ställen som har refreshat user tidigare!!!?
        // Fixar ju dock sig om vi alltid hämtar en fräsch user på profilsidan!!!
      }

      if (this.isEdit) {
        this.toastrService.info(this.getSuccessMessage("Group"));
      } else {
        this.toastrService.success(this.getSuccessMessage("Group"));
      }
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsUserGroupTabComponent().setServerErrors(error.errors);
    }
  }
}
