import { NgClass } from "@angular/common";
import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { SimpleFormInfoComponent } from "../../../../../../simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";

@Component({
  selector: "details-client-assignment-template-tab",
  templateUrl: "./details-client-assignment-template-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormSelectComponent,
    SimpleFormInfoComponent,
    TranslateModule,
    NgClass,
  ],
})
export class DetailsClientAssignmentTemplateTabComponent extends DetailsBaseDirective<AssignmentTemplate> {
  selectableClientItems = signal<FormSelectItem[]>([]);

  clientsIsDisabled = signal<boolean>(false);
  disabledClientLink = signal<string>("");

  ngOnInit() {
    this.setSelectableClientItems();

    this.clientsIsDisabled.set(!this.selectableClientItems().length);
    this.disabledClientLink.set(this.globalState.selectedOrganization().url);

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.clientId]: [this.modifiedModel().clientId],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableClientItems() {
    this.selectableClientItems.set(
      this.modifiedModel().selectableClients.map(
        (client) =>
          new FormSelectItem(
            client.id,
            `${client.name} ${client.email ? "(" + client.email + ")" : ""}`,
          ),
      ),
    );
  }
}
