<div class="icons apx-scrollbar">
  @for (icon of icons(); track $index) {
    <div
      class="icon {{ icon.iconClass }}"
      [title]="!icon.extraContent ? (icon.titleText | translate) : ''"
      (mouseenter)="icon.extraContent ? iconTemplate.show($event) : null"
      (mouseleave)="icon.extraContent ? iconTemplate.hide() : null"
    ></div>
    <p-popover #iconTemplate>
      <div
        class="extra-content"
        [innerHTML]="
          (icon.titleText | translate) +
          ':<BR>' +
          (icon.translate
            ? (icon.extraContent | translate)
            : icon.parseDate
              ? (icon.extraContent | datex: 'with-time')
              : icon.extraContent
                ? (icon.extraContent | parseMD)
                : '')
        "
      ></div>
    </p-popover>
  }
</div>
