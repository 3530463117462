import {
  Directive,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { TabPanel } from "primeng/tabs";

// Used when needing tabs to destroy and create when switching between them.
@Directive({
  selector: "[tabVisible]",
  standalone: true,
})
export class TabVisibleDirective {
  private panel = inject(TabPanel);
  private viewContainer = inject(ViewContainerRef);
  private templateRef = inject(TemplateRef);

  @Input() set tabVisible(activeIndex: number) {
    if (this.panel.value() === activeIndex) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
