import { Component, signal, viewChild } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Entity } from "app/components/entity/entity";
import {
  GoogleMapsComponent,
  LocationObject,
} from "app/components/google-maps/google-maps.component";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { StringUtils } from "app/tools/string-utils";
import { SimpleEditorComponent } from "../../../../simple-components/crud/modal/tabs/details/form/form-editor/simple-form-editor.component";
import { SimpleDetailsTabComponent } from "../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";

@Component({
  selector: "details-entity-tab",
  templateUrl: "./details-entity-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    SimpleFormSelectComponent,
    GoogleMapsComponent,
    TranslateModule,
    SimpleEditorComponent,
  ],
})
export class DetailsEntityTabComponent extends DetailsBaseDirective<Entity> {
  selectableStatusItems = signal<FormSelectItem[]>([]);
  selectableAssemblyYearItems = signal<FormSelectItem[]>([]);
  selectableFacilityItems = signal<FormSelectItem[]>([]);
  selectableAccessibilityItems = signal<FormSelectItem[]>([]);

  googleMapsComponent = viewChild(GoogleMapsComponent);

  ngOnInit() {
    this.setSelectableStatusItems();
    this.setSelectableAssemblyYearItems();
    this.setSelectableFacilityItems();
    this.setSelectableAccessibilityItems();

    this.form.set(
      this.formBuilder.group(
        {
          [this.propertyStrings.name]: [
            this.modifiedModel().name,
            [Validators.required, Validators.maxLength(150)],
          ],
          [this.propertyStrings.description]: [
            this.modifiedModel().description,
            Validators.maxLength(500),
          ],
          [this.propertyStrings.status]: [
            this.modifiedModel().status,
            Validators.required,
          ],
          [this.propertyStrings.manufacturer]: [
            this.modifiedModel().manufacturer,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.assembler]: [
            this.modifiedModel().assembler,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.assemblyYear]: [
            this.modifiedModel().assemblyYear,
          ],
          [this.propertyStrings.typeDesignation]: [
            this.modifiedModel().typeDesignation,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.articleId]: [
            this.modifiedModel().articleId,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.latitude]: [
            this.modifiedModel().latitude,
            this.latitudePattern,
          ],
          [this.propertyStrings.longitude]: [
            this.modifiedModel().longitude,
            this.longitudePattern,
          ],
          [this.propertyStrings.accessible]: [
            this.modifiedModel().accessible,
            Validators.required,
          ],
          [this.propertyStrings.infoLink]: [
            this.modifiedModel().infoLink,
            [Validators.maxLength(250), this.urlPattern],
          ],
          [this.propertyStrings.externalId]: [
            this.modifiedModel().externalId,
            Validators.maxLength(50),
          ],
          [this.propertyStrings.extraInfo]: [this.modifiedModel().extraInfo],
        },
        { validators: this.positionValidator },
      ),
    );
    super.ngOnInit();
  }

  handleMarkerMove(locationObject: LocationObject) {
    this.form()
      .get(this.modifiedModel().propertyStrings.latitude)
      .setValue(locationObject.latitude.toString());
    this.form()
      .get(this.modifiedModel().propertyStrings.longitude)
      .setValue(locationObject.longitude.toString());
  }

  protected async getCurrentLocation() {
    const locationObject =
      await this.googleMapsComponent().getCurrentLocation();
    if (locationObject) {
      const location = new google.maps.LatLng(
        parseFloat(locationObject.latitude),
        parseFloat(locationObject.longitude),
      );
      this.googleMapsComponent().emitLocation(location);
    }
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }

  private setSelectableAssemblyYearItems() {
    const yearItems: FormSelectItem[] = [];
    const currentYear = new Date().getFullYear();
    let endYear = currentYear;
    while (currentYear - endYear < 30) {
      yearItems.push(
        new FormSelectItem(endYear.toString(), endYear.toString()),
      );
      endYear--;
    }
    this.selectableAssemblyYearItems.set(yearItems);
  }

  private setSelectableFacilityItems() {
    this.selectableFacilityItems.set(
      this.modifiedModel().selectableFacilities.map(
        (facility) => new FormSelectItem(facility.id, facility.name),
      ),
    );
  }

  private setSelectableAccessibilityItems() {
    this.selectableAccessibilityItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
    ]);
  }

  private latitudePattern(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.LATITUDE).test(control.value)) {
      return { latitude: true };
    } else {
      return null;
    }
  }

  private longitudePattern(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.LONGITUDE).test(control.value)
    ) {
      return { longitude: true };
    } else {
      return null;
    }
  }

  private positionValidator(form: FormGroup): ValidationErrors {
    const latitude = form.get("latitude");
    const longitude = form.get("longitude");

    if (latitude.value && !longitude.value) {
      longitude.setErrors({ required: true });
      longitude.markAsTouched();
    } else if (longitude.value && !latitude.value) {
      latitude.setErrors({ required: true });
      latitude.markAsTouched();
    } else if (!latitude.value && !longitude.value) {
      latitude.setErrors(null);
      longitude.setErrors(null);
    }
    return null;
  }

  private urlPattern(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.URL).test(control.value)) {
      return { url: true };
    } else {
      return null;
    }
  }
}
