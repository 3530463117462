import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableIconColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => IconContent;

  constructor(
    labels: string[],
    getValue: (row: T) => IconContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.ICON;
    this.maxwidth = 100;
  }
}

export class IconContent {
  iconObjects: IconObject[];

  constructor(iconObjects: IconObject[]) {
    this.iconObjects = iconObjects;
  }
}

export class IconObject {
  iconClass: string;
  titleText: string;
  extraContent: string;
  translate: boolean;
  parseDate: boolean;

  constructor(
    iconClass: string,
    titleText: string,
    extraContent?: string,
    translate?: boolean,
    parseDate?: boolean,
  ) {
    this.iconClass = iconClass;
    this.titleText = titleText;
    this.extraContent = extraContent;
    this.translate = translate;
    this.parseDate = parseDate;
  }
}
