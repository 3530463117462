import { Component, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Entity } from "app/components/entity/entity";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "details-accessibility-entity-tab",
  templateUrl: "./details-accessibility-entity-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    TranslateModule,
    SimpleFormSelectComponent,
  ],
})
export class DetailsAccessibilityEntityTabComponent extends DetailsBaseDirective<Entity> {
  selectableAccessibilityItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableAccessibilityItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.accessible]: [
          this.modifiedModel().accessible,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableAccessibilityItems() {
    this.selectableAccessibilityItems.set([
      new FormSelectItem(
        true,
        this.translationService.instant(StringUtils.YES),
      ),
      new FormSelectItem(
        false,
        this.translationService.instant(StringUtils.NO),
      ),
    ]);
  }
}
