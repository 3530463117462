<div class="assignment-template">
  <simple-image [url]="facility().thumbnailUrl"></simple-image>
  <div class="text-wrapper">
    <div class="template-type" [title]="templateType().name">
      {{ templateType().name }}
    </div>
    <div class="facility" [title]="facility().name">
      {{ facility().name }}
    </div>
  </div>
</div>
