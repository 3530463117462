<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select
          [label]="'Status' | translate"
          [name]="propertyStrings.status"
          [form]="form()"
          [items]="selectableStatusItems()"
          [redBackground]="!modifiedModel().status"
          [greenBackground]="modifiedModel().status"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>
      </div>
    </div>
  </div>
</simple-details-tab>
