import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { Organization } from "app/components/organization/organization";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";

@Component({
  selector: "view-details-tab",
  templateUrl: "./view-details-tab.component.html",
  standalone: true,
  imports: [SimpleDetailsTabComponent, TranslateModule],
})
export class ViewDetailsTabComponent extends DetailsBaseDirective<Organization> {
  appInitializer = inject(AppInitializer);
}
