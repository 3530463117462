<simple-crud-modal
  [visible]="visible()"
  [headerText]="
    (facilityName() ? facilityName() + ' - ' : '') + modifiedModel()?.name
  "
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('EntityChangeDetailsEditHelpText' | translate)
                : ('EntityChangeDetailsHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>
        <p-tab [value]="1">
          <simple-tab-heading
            [icon]="'bi bi-files'"
            [title]="'Media' | translate"
            [helpText]="
              isEdit
                ? ('EntityHandleMediaEditHelpText' | translate)
                : ('EntityHandleMediaHelpText' | translate)
            "
            [displayCount]="modifiedModel().media.length"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-entity-tab
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-entity-tab>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <handle-media-tab
            [items]="modifiedModel().media"
            [headerSubtitleText]="'AddedMediaSubtitleEntity' | translate"
            [canToggleDefault]="true"
            (onItemsChange)="handleItemsChange(propertyStrings.media, $event)"
          >
          </handle-media-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
