<div
  class="image"
  [ngClass]="{
    clickable: url(),
  }"
  [ngStyle]="{
    width: size() ? size() + 'px' : '32px',
    height: size() ? size() + 'px' : '32px',
  }"
  (click)="url() ? handleClick($event) : null"
>
  <extensive-image
    [imageUrl]="url()"
    [imageStorageSize]="size() ? size() : 32"
    [rounded]="rounded()"
    [placeholderImageType]="placeholderImageType()"
  >
  </extensive-image>
</div>

<media-widget-item-viewer
  [visible]="visibleMediaViewer()"
  [item]="currentItem()"
  [collection]="[currentItem()]"
  (onClose)="visibleMediaViewer.set(false)"
>
</media-widget-item-viewer>
