<simple-details-tab>
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Name" | translate }}
          </div>
          <div class="content-value">
            {{ modifiedModel().fullName }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "EmailAddress" | translate }}
          </div>
          <div class="content-value">
            {{ modifiedModel().email }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "PhoneNumber" | translate }}
          </div>
          @if (
            modifiedModel().contactCountryCode &&
            modifiedModel().contactPhoneNumber
          ) {
            <div class="content-value">
              (+{{ modifiedModel().contactCountryCode }})
              {{ modifiedModel().contactPhoneNumber }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Country" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getCountryName(modifiedModel().country) }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Language" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getCultureName(modifiedModel().culture) }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Timezone" | translate }}
          </div>
          <div class="content-value">
            {{ appInitializer.getTimeZoneName(modifiedModel().timeZone) }}
          </div>
        </div>
      </div>
      <div class="details-section">
        <div class="details-section-header">
          {{ "Certificate" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "CertificateNumber" | translate }}
          </div>
          @if (modifiedModel().certificates) {
            <div class="content-value">
              {{ modifiedModel().certificates }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Documents" | translate }}
          </div>
          @if (documents().length) {
            <div class="content-value">
              <div class="documents apx-scrollbar">
                @for (document of documents(); track $index) {
                  <div class="document" [title]="document.title">
                    <extensive-pdf
                      [pdfUrl]="document.url"
                      [blob]="document.blob ? document.blob : null"
                      [thumbnailVersion]="true"
                    >
                    </extensive-pdf>
                  </div>
                }
              </div>
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
      </div>
    </div>
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "Authorization" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "MainOrganization" | translate }}
          </div>
          <div class="content-value">
            <div
              class="organization"
              [ngClass]="{
                highlighted:
                  modifiedModel().organization.id ===
                  globalState.selectedOrganization().id,
              }"
            >
              <simple-image
                [url]="modifiedModel().organization.logotypeUrl"
                [placeholderImageType]="'organization'"
                [size]="42"
              ></simple-image>
              <div class="info">
                <div class="name">
                  {{ modifiedModel().organization.orgName }}
                </div>
                <div class="roles">
                  {{ getFormattedRoles(modifiedModel().roles) }}
                </div>
                <div class="invite-date">
                  {{ "InvitedOn" | translate }}
                  {{ modifiedModel().inviteDate | datex }}
                </div>
              </div>
              <div class="status">
                <simple-status-label [text]="modifiedModel().status">
                </simple-status-label>
              </div>
              @if (modifiedModel().organization.isTest) {
                <div class="test-marker">
                  {{ stringUtils.TEST }}
                </div>
              }
            </div>
          </div>
        </div>
        @if (modifiedModel().organizationMemberships.length) {
          <div class="content">
            <div class="content-header">
              {{ "Memberships" | translate }}
            </div>
            <div class="content-value">
              @for (
                membership of modifiedModel().organizationMemberships;
                track $index
              ) {
                <div
                  class="organization"
                  [ngClass]="{
                    highlighted:
                      membership.organization.id ===
                      globalState.selectedOrganization().id,
                  }"
                >
                  <div class="image">
                    <simple-image
                      [url]="membership.organization.logotypeUrl"
                      [placeholderImageType]="'organization'"
                      [size]="42"
                    ></simple-image>
                  </div>
                  <div class="info">
                    <div class="name">
                      {{ membership.organization.orgName }}
                    </div>
                    <div class="roles">
                      {{ getFormattedRoles(membership.roles) }}
                    </div>
                    <div class="invite-date">
                      {{ "InvitedOn" | translate }}
                      {{ membership.inviteDate | datex }}
                    </div>
                  </div>
                  <div class="status">
                    <simple-status-label [text]="membership.status">
                    </simple-status-label>
                  </div>
                  @if (membership.organization.isTest) {
                    <div class="test-marker">
                      {{ stringUtils.TEST }}
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
      @if (modifiedModel().userGroups.length || schedules().length) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "Connections" | translate }}
          </div>
          @if (modifiedModel().userGroups.length) {
            <div class="content">
              <div class="content-header">
                {{ "Groups" | translate }}
              </div>
              <div class="content-value">
                <simple-view-connections
                  [userGroups]="modifiedModel().userGroups"
                  [disabled]="
                    !globalState
                      .loggedInUser()
                      .canEdit(globalState.selectedOrganization().friendlyUrl)
                  "
                >
                </simple-view-connections>
              </div>
            </div>
          }
          @if (schedules().length) {
            <div class="content">
              <div class="content-header">
                {{ "Schedules" | translate }}
              </div>
              <div class="content-value">
                <simple-view-connections
                  [schedulesForUsers]="schedules()"
                  [disabled]="
                    !globalState
                      .loggedInUser()
                      .canEdit(globalState.selectedOrganization().friendlyUrl)
                  "
                >
                </simple-view-connections>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
</simple-details-tab>
