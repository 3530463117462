import { Component, inject, input, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { Subscriber } from "app/components/user/user";
import { ReportService } from "app/services/report.service";
import { FileData, FileHelper } from "app/tools/file-helper";
import { NumberUtils } from "app/tools/number-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { StaticReport } from "./static-report";
import { DetailsStaticReportTabComponent } from "./tabs/details/details-static-report-tab.component";

@Component({
  selector: "static-report",
  templateUrl: "./static-report.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsStaticReportTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class StaticReportComponent extends CreateEditBaseDirective<StaticReport> {
  tooLargeForDownload = signal<boolean>(false);
  sendReportByEmail = signal<boolean>(false);
  subscribers = signal<Subscriber[]>([]);
  helpText = signal<string>("");
  affectableIds = signal<string[]>([]);

  hideText = input<boolean>();
  isMeasure = input<boolean>();

  detailsStaticReportTabComponent = viewChild(DetailsStaticReportTabComponent);

  private reportService = inject(ReportService);
  private fileHelper = inject(FileHelper);

  async initiate() {
    this.pending.set(true);
    try {
      this.subscribers.set(
        await this.reportService.getSubscribers(
          this.itemIds(),
          this.globalState.selectedOrganization().id,
        ),
      );
      const data = await this.reportService.getReportData(
        this.itemIds(),
        this.globalState.selectedOrganization().id,
      );
      this.affectableIds.set(data);
      this.pending.set(false);

      this.tooLargeForDownload.set(
        this.itemIds().length >= NumberUtils.MAX_ITEM_COUNT_FOR_REPORT_DOWNLOAD,
      );
      this.sendReportByEmail.set(this.tooLargeForDownload());

      this.initialModel.set(new StaticReport({}));
      this.modifiedModel.set(
        new StaticReport(Utils.getUniqueVariant(this.initialModel())),
      );

      this.setLoggedInUserAsRecipient();
      this.helpText =
        this.itemIds().length > 1
          ? this.isMeasure
            ? this.translationService.instant(
                "StaticReportMeasureHelpTextMultiple",
              )
            : this.translationService.instant("StaticReportHelpTextMultiple")
          : this.isMeasure
            ? this.translationService.instant("StaticReportMeasureHelpText")
            : this.translationService.instant("StaticReportHelpText");
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    const subscriberEmails = this.subscribers()
      .filter((subscriber) => subscriber.selected)
      .map((subscriber) => subscriber.email);
    const userEmails = this.modifiedModel().users.map((user) => user.email);
    const externalEmails = this.modifiedModel().emails;

    const allEmails = Utils.getUniqueEntriesOnly(
      ...subscriberEmails,
      ...userEmails,
      ...externalEmails,
    );

    const model = new StaticReport({
      itemIds: this.affectableIds(),
      organizationId: this.globalState.selectedOrganization().id,
      emails: this.sendReportByEmail() ? allEmails : [],
    });

    this.pending.set(true);
    try {
      const data = await this.reportService.getStaticReports(model);
      this.fileHelper.handleFile(new FileData(data));
      this.toastrService.secondary(
        this.translationService.instant("TheReportWasFetched"),
      );
      this.onRequestDone.emit([new StaticReport({})]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsStaticReportTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.detailsStaticReportTabComponent()) {
      const sendReportByEmailCtrl = this.detailsStaticReportTabComponent()
        .form()
        .get("sendReportByEmail");
      this.sendReportByEmail.set(sendReportByEmailCtrl.value);

      if (this.sendReportByEmail()) {
        this.detailsStaticReportTabComponent().setSelectableEmailItems();
      }
    }
  }

  // TODO använda dessa?
  private hasSubscribers() {
    return this.subscribers().some((subscriber) => subscriber.selected);
  }

  private hasUsers() {
    return !!this.modifiedModel().users.length;
  }

  private hasExternalEmails() {
    return !!this.modifiedModel().emails.length;
  }

  private setLoggedInUserAsRecipient() {
    const loggedInUserInSubscribers = this.subscribers().find(
      (subscriber) =>
        subscriber.email === this.globalState.loggedInUser().email,
    );
    const loggedInUserIsInOrganization = this.globalState
      .loggedInUser()
      .isOrgMember(this.globalState.selectedOrganization().friendlyUrl);

    if (loggedInUserInSubscribers) {
      loggedInUserInSubscribers.selected = true;
    } else if (loggedInUserIsInOrganization) {
      this.modifiedModel().users.push(this.globalState.loggedInUser());
    } else {
      this.modifiedModel().selectableEmails.push(
        this.globalState.loggedInUser().email,
      );
      this.modifiedModel().emails.push(this.globalState.loggedInUser().email);
    }
  }
}
