import { Component, computed, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssigneeGroup } from "app/components/assignment/assignment";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { ListUserGroupComponent } from "app/components/user-group/list-user-group/list-user-group.component";
import { UserGroup } from "app/components/user-group/user-group";
import { User } from "app/components/user/user";
import { StringUtils } from "app/tools/string-utils";

@Component({
  selector: "handle-assignee-groups-tab",
  templateUrl: "./handle-assignee-groups-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListUserGroupComponent,
    TranslateModule,
  ],
})
export class HandleAssigneeGroupsTabComponent extends HandleItemsBaseDirective<AssigneeGroup> {
  globalDummyGroup = new AssigneeGroup({});

  visibleListUserGroup = signal<boolean>(false);
  users = signal<User[]>([]);

  isMeasure = input<boolean>();

  itemAsIds = computed(() => this.items().map((item) => item.userGroup.id));

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "",
        "CanPerformScheduled",
        StringUtils.icons.scheduledAssignment,
        () => this.bulkChange(this.propertyStrings.canPerformScheduled),
        () => !this.selectedIds().length || this.isMeasure(),
      ),
      new SimpleListAction(
        "",
        "CanPerformMeasure",
        StringUtils.icons.measureAssignment,
        () => this.bulkChange(this.propertyStrings.canPerformMeasure),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "",
        "CanAssign",
        StringUtils.icons.group,
        () => this.bulkChange(this.propertyStrings.canAssign),
        () => !this.selectedIds().length || this.isMeasure(),
      ),
      new SimpleListAction(
        "",
        "NotifyOnAdd",
        StringUtils.icons.notifyOnAdd,
        () => this.bulkChange(this.propertyStrings.notifyOnAdd),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "AddGroups",
        "AddGroups",
        StringUtils.icons.new,
        () => this.visibleListUserGroup.set(true),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(
        ["Name"],
        (row) => new TextContent(row.userGroup.name),
      ),
      new SimpleTableRuleColumn(
        ["Rules"],
        (row) =>
          new RuleContent(
            this.isMeasure()
              ? row.rulesInMeasureAssignment
              : row.rulesInScheduledAssignment,
            true,
          ),
        (row, ruleName) => {
          row[ruleName] = !row[ruleName];
          this.configureTableColumns();
        },
      ),
      new SimpleTableRuleColumn(
        [],
        (row) => new RuleContent(row.notifyOnAddRule, true),
        (row, ruleName) => {
          row[ruleName] = !row[ruleName];
          this.configureTableColumns();
        },
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Groups", StringUtils.icons.group),
    );
  }

  handleCloseListUserGroup() {
    this.visibleListUserGroup.set(false);
    this.itemIds.set([]);
  }

  handleConfirmListUserGroup(items: UserGroup[]) {
    this.handleCloseListUserGroup();
    const assigneeGroups = items.map((group) => {
      const assigneeGroup = new AssigneeGroup(group);
      assigneeGroup.userGroup = new UserGroup(group);
      return assigneeGroup;
    });
    assigneeGroups.forEach((item) => {
      const existingItem = this.items().find((i) => i.userGroup.id === item.id);
      if (existingItem) {
        item.canPerformScheduled = existingItem.canPerformScheduled;
        item.canPerformMeasure = existingItem.canPerformMeasure;
        item.canAssign = existingItem.canAssign;
        item.notifyOnAdd = existingItem.notifyOnAdd;
      } else {
        item.notifyOnAdd = true;
      }
    });
    this.items.set(assigneeGroups);
    this.items().sortByProperty("name");
    this.onItemsChange.emit(this.items());
    this.selectedIds.set([]);
  }

  private bulkChange(property: string) {
    const selectedItems = this.items().filter((item) =>
      this.selectedIds().includes(item.id),
    );
    const allTrue = selectedItems.every((item) => item[property]);
    selectedItems.forEach((item) => (item[property] = !allTrue));
    this.configureTableColumns();
  }
}
