import { NgClass } from "@angular/common";
import { Component, input, signal } from "@angular/core";
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  Router,
  RouterEvent,
} from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { UserSupportDirective } from "app/components/user-support/user-support.directive";
import { TranslationService } from "app/services/translation.service";
import { UserSupportService } from "app/services/user-support.service";
import { RoutesUtils } from "app/tools/routes-utils";
import { UrlUtils } from "app/tools/url-utils";
import { Drawer } from "primeng/drawer";
import { takeUntil } from "rxjs/operators";
import { HelpArticle } from "./help-article";

@Component({
  selector: "help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.less"],
  standalone: true,
  imports: [NgClass, TranslateModule, Drawer],
})
export class HelpComponent<T> extends UserSupportDirective<T> {
  private _actionQueryParams = [
    RoutesUtils.wikiCrudParams.new,
    RoutesUtils.wikiCrudParams.edit,
    RoutesUtils.wikiCrudParams.view,
  ];
  private _queryParams = [];
  helpArticles = signal<HelpArticle[]>([]);
  visible = signal<boolean>(false);

  isInModal = input<boolean>();

  constructor(
    protected userSupportService: UserSupportService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
  ) {
    super(userSupportService);
  }

  async ngOnInit() {
    super.initiateFileSubscription();

    this.subscriptions.add(
      this.router.events
        .pipe(takeUntil(this.destroyed$))
        .subscribe((routerEvent: Event | RouterEvent) => {
          if (this.file) {
            if (routerEvent instanceof NavigationEnd) {
              this.fetching.set(true);
              setTimeout(() => {
                this.mapData();
                this.fetching.set(false);
              }, 500);
            }
          }
        }),
    );

    this.subscriptions.add(
      this.activatedRoute.queryParams
        .pipe(takeUntil(this.destroyed$))
        .subscribe((param) => {
          const extraParams = [];

          // "View" modals doesn't have a separate "view" parameter, but instead "viewSomeOtherName"
          if (
            Object.values(param).some(
              (o) => o.substring(0, 4) === RoutesUtils.wikiCrudParams.view,
            )
          ) {
            extraParams.push(RoutesUtils.wikiCrudParams.view);
          }

          const paramsWithValues = Object.keys(param)
            .concat(Object.values(param))
            .concat(extraParams)
            .map((o) => o.split("_")[0])
            .filter((o) => this._actionQueryParams.includes(o));

          this._queryParams = paramsWithValues.length
            ? paramsWithValues.concat("?")
            : paramsWithValues;
        }),
    );
  }

  protected mapData() {
    this.helpArticles.set(this.file.helpArticles);
    if (this.helpArticles().length) {
      const currentPath = this.router.url;
      let predicate: (
        value: HelpArticle,
        index: number,
        array: HelpArticle[],
      ) => {};

      if (this._queryParams.length) {
        predicate = (o) =>
          o.adminUrl.some(
            (p) =>
              p.regexp.test(currentPath) &&
              this._queryParams.every((queryParam) =>
                p.glob.includes(queryParam),
              ),
          );
      } else {
        predicate = (o) => o.adminUrl.some((p) => p.regexp.test(currentPath));
      }

      // Collect all articles mathing the current path
      this.helpArticles.set(
        this.helpArticles()
          .filter((o) => !!Object.keys(o).length)
          .filter(predicate),
      );

      // Sort by order property
      this.helpArticles().sort((x, y) => x.order - y.order);

      // Sort by relevance (where the full url regexp gets a hit)
      this.helpArticles().sort(
        (x, y) =>
          this.sortByUrlRelevance(x, currentPath) -
          this.sortByUrlRelevance(y, currentPath),
      );
    }
  }

  private sortByUrlRelevance(wikiData: HelpArticle, currentPath: string) {
    const isCurrentPath = wikiData.adminUrl.some((o) =>
      o.regexp.test(currentPath),
    );
    const isFirstCurrentPath = wikiData.adminUrl[0].regexp.test(currentPath);
    const isCrud = wikiData.adminUrl.some((o) =>
      this._queryParams.some((queryParam) => o.glob.includes(queryParam)),
    );
    const isTabAll = wikiData.adminUrl.some((o) =>
      new RegExp(/tab=all/i).test(o.glob),
    );
    const isTabSpecific = wikiData.adminUrl.some((o) =>
      new RegExp(/tab=(\d+)/i).test(o.glob),
    );

    const tabsAndCrudAll = isCurrentPath && isCrud && isTabAll;
    const tabsAndCrudSpecific = isCurrentPath && isCrud && isTabSpecific;
    const crudSpecific = isCurrentPath && isCrud;

    if (tabsAndCrudAll) {
      return 0;
    } else if (tabsAndCrudSpecific) {
      return 1;
    } else if (crudSpecific) {
      return 2;
    } else if (isFirstCurrentPath) {
      return 3;
    } else if (isCurrentPath) {
      return 4;
    }
    return 5;
  }

  navigateToWiki(helpArticle?: HelpArticle) {
    if (helpArticle) {
      window.open(helpArticle.path, "_blank");
    } else {
      window.open(
        `${UrlUtils.WIKI_URL}/${this.translationService.currentLang}/wiki/`,
        "_blank",
      );
    }
  }
}
