<simple-crud-modal
  [visible]="visible()"
  [headerText]="'Media' | translate"
  [closeText]="'Close' | translate"
  [hideSubmitButton]="true"
  [isLoading]="pending()"
  [onlyHeaderText]="true"
  (onClose)="currentItem.set(null); handleClose()"
>
  @if (currentItem()) {
    @if (hasPrev()) {
      <div
        class="mwiv-navigation nav-left"
        title="{{ 'Previous' | translate }}"
        (click)="prev()"
      >
        <img width="20" src="{{ media.BTN_ARROW_LEFT }}" />
      </div>
    }
    @if (hasNext()) {
      <div
        class="mwiv-navigation nav-right"
        title="{{ 'Next' | translate }}"
        (click)="next()"
      >
        <img width="20" src="{{ media.BTN_ARROW_RIGHT }}" />
      </div>
    }
    <div class="mwiv-container">
      <div class="mwiv-item" [title]="currentItem().title">
        @if (currentItem().isImage) {
          <extensive-image
            [imageUrl]="currentItem().url"
            [blob]="currentItem().blob ?? null"
            [useContain]="true"
            [displayLoader]="true"
          >
          </extensive-image>
        }
        @if (currentItem().isPDF) {
          <extensive-pdf
            [pdfUrl]="currentItem().url"
            [blob]="currentItem().blob ?? null"
            [displayLoader]="true"
          >
          </extensive-pdf>
        }
        <div class="mwiv-item-footer">
          <div class="title">
            {{ currentItem().title }}
          </div>
          @if (!currentItem().blob) {
            <div
              class="mwiv-item-action"
              [title]="'Download' | translate"
              (click)="download()"
            ></div>
          }
        </div>
      </div>
    </div>
  }
</simple-crud-modal>
