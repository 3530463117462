import { NgClass } from "@angular/common";
import { Component, effect, input, output, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ListActionsComponent } from "app/components/list-actions/list-actions.component";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableColumn } from "app/components/simple-components/list/table/columns/simple-table-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { PickListModule } from "primeng/picklist";
import { HelpComponent } from "../../user-support/help/help.component";

@Component({
  selector: "list-header",
  templateUrl: "./list-header.component.html",
  styleUrls: ["./list-header.component.less"],
  standalone: true,
  imports: [
    TranslateModule,
    ListActionsComponent,
    NgClass,
    PickListModule,
    HelpComponent,
  ],
})
export class ListHeaderComponent<T> {
  visible = signal<boolean>(false);

  emptyState = input<SimpleTableEmptyState>();
  listActions = input<SimpleListAction[]>();
  columns = input<SimpleTableColumn<T>[]>();
  inModal = input<boolean>();

  customVisibleColumns: SimpleTableColumn<T>[] = [];
  customHiddenColumns: SimpleTableColumn<T>[] = [];

  onCustomColumnConfigChange = output<SimpleTableColumn<T>[]>();

  constructor() {
    // TODO jobba bort från effect!
    effect(() => {
      this.customVisibleColumns = this.columns()
        .filter((column) => !column.customHidden)
        .sort((a, b) => a.customOrder - b.customOrder);
      this.customHiddenColumns = this.columns().filter(
        (column) => column.customHidden,
      );
    });
  }

  handleMoveToVisible(columns: SimpleTableColumn<T>[]) {
    // TODO när returnar vid disallow så uppdateras inte vyn förrän vid klick
    columns[0].customHidden = false;
    this.onCustomColumnConfigChange.emit(this.columns());
  }

  handleMoveToHidden(columns: SimpleTableColumn<T>[]) {
    columns[0].customHidden = true;

    // Do not allow to have no columns or just ones with maxwidth.
    if (
      (this.customVisibleColumns.length &&
        this.customVisibleColumns.every((column) => column.maxwidth)) ||
      !this.customVisibleColumns.length
    ) {
      this.handleMoveToVisible(columns);
      return;
    }

    this.onCustomColumnConfigChange.emit(this.columns());
  }

  handleVisibleReorder() {
    this.customVisibleColumns.forEach(
      (column, index) => (column.customOrder = index),
    );
    this.onCustomColumnConfigChange.emit(this.columns());
  }
}
