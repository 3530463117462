import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableCountColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (row: T) => CountContent;

  constructor(
    labels: string[],
    getValue: (row: T) => CountContent,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.type = SimpleTableColumnType.COUNT;
    this.maxwidth = 122;
  }
}

export class CountContent {
  countObjects: CountObject[];

  constructor(countObjects: CountObject[]) {
    this.countObjects = countObjects;
  }
}

export class CountObject {
  iconClass: string;
  count: number;
  titleText: string;

  constructor(iconClass: string, count: number, titleText: string) {
    this.iconClass = iconClass;
    this.count = count;
    this.titleText = titleText;
  }
}
