import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentTemplateService } from "app/services/assignment-template.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsGeoFencingAssignmentTemplateTabComponent } from "./tabs/details/details-geo-fencing-assignment-template-tab.component";

@Component({
  selector: "change-geo-fencing",
  templateUrl: "./change-geo-fencing.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsGeoFencingAssignmentTemplateTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangeGeoFencingComponent extends CreateEditBaseDirective<AssignmentTemplate> {
  affectableIds = signal<string[]>([]);

  detailsGeoFencingAssignmentTemplateTabComponent = viewChild(
    DetailsGeoFencingAssignmentTemplateTabComponent,
  );

  private assignmentTemplateService = inject(AssignmentTemplateService);

  async initiate() {
    this.initialModel.set(new AssignmentTemplate({}));
    this.modifiedModel.set(
      new AssignmentTemplate(Utils.getUniqueVariant(this.initialModel())),
    );
    this.getGeoFencingData(this.modifiedModel().geoControlled);
  }

  private async getGeoFencingData(value: boolean) {
    this.pending.set(true);
    try {
      const data = await this.assignmentTemplateService.getGeoFencingData(
        this.itemIds(),
        value,
        this.globalState.selectedOrganization().id,
      );
      this.affectableIds.set(data);
      this.pending.set(false);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.assignmentTemplateService.updateGeoFencing(
        this.affectableIds(),
        this.modifiedModel().geoControlled,
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("AssignmentTemplate", "AssignmentTemplates"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsGeoFencingAssignmentTemplateTabComponent().setServerErrors(
        error.errors,
      );
    }
  }

  handleDetailsChangeExtra() {
    if (this.detailsGeoFencingAssignmentTemplateTabComponent()) {
      const geoControlledCtrl =
        this.detailsGeoFencingAssignmentTemplateTabComponent()
          .form()
          .get(this.propertyStrings.geoControlled);
      this.getGeoFencingData(geoControlledCtrl.value);
    }
  }
}
