import { NgClass } from "@angular/common";
import { Component, inject, signal } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Toast } from "primeng/toast";
import { TokenStorageService } from "./authentication/token-storage.service";
import { DotsLoaderComponent } from "./components/dots-loader/dots-loader.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { NotificationMessagesComponent } from "./components/user-support/notification-message/notification-messages/notification-messages.component";
import { GlobalStateService } from "./global-state/global-state.service";
import { ExtensiveImageComponent } from "./media/extensive-image/extensive-image.component";
import { RoutesUtils } from "./tools/routes-utils";
import { Utils } from "./tools/utils";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
  standalone: true,
  imports: [
    FeedbackComponent,
    NotificationMessagesComponent,
    ExtensiveImageComponent,
    SideMenuComponent,
    RouterOutlet,
    NgClass,
    TranslateModule,
    DotsLoaderComponent,
    Toast,
  ],
})
export class AppComponent {
  routesUtils = RoutesUtils;

  menuOpenedMobile = signal<boolean>(false);

  protected globalState = inject(GlobalStateService);
  protected tokenStorage = inject(TokenStorageService);
  private router = inject(Router);

  get hasOpenModals() {
    return !!document.body.querySelector(".p-dialog-mask");
  }

  navigateToProfile() {
    this.router.navigate([
      `/${this.globalState.selectedOrganization().friendlyUrl}/${RoutesUtils.profile}`,
    ]);
  }

  routeIsActive(pathName: string) {
    const strippedPath = this.getCurrentStrippedURLPath();
    return pathName
      .split("/")
      .every((entry) => strippedPath.indexOf(entry) !== -1);
  }

  private getCurrentStrippedURLPath() {
    return window.location.pathname
      .replace(this.globalState.selectedOrganization().friendlyUrl, "")
      .split("/")
      .filter((entry) => !!entry);
  }

  routeIsDisabled(pathName: string) {
    // Disable when active, but not in "/new", "/:id" etc...
    const maxLength = pathName.split("/").length > 1 ? 4 : 3;
    return (
      this.routeIsActive(pathName) &&
      this.router.url.split("/").length - 1 < maxLength
    );
  }

  toggleMenuMobile() {
    if (Utils.isMediumScreenSize()) {
      this.menuOpenedMobile.set(!this.menuOpenedMobile());
    }
  }
}
