import { Component, input, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Choice } from "app/components/choice/choice";
import { HandleHeaderComponent } from "app/components/header/handle-header/handle-header.component";
import { HandleItemsBaseDirective } from "app/components/simple-components/directives/handle-items-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import { SimpleTableRowAction } from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  ChoiceContent,
  SimpleTableChoiceColumn,
} from "app/components/simple-components/list/table/columns/simple-table-choice-column";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableTaskColumn,
  TaskContent,
} from "app/components/simple-components/list/table/columns/simple-table-task-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import { SimpleTableHeaderAction } from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { Task } from "app/components/task/task";
import { StringUtils } from "app/tools/string-utils";
import { ListChoiceComponent } from "../../../../choice/list-choice/list-choice.component";
import { ListTaskComponent } from "../../../../task/list-task/list-task.component";

@Component({
  selector: "handle-tasks-tab",
  templateUrl: "./handle-tasks-tab.component.html",
  standalone: true,
  imports: [
    HandleHeaderComponent,
    SimpleTableComponent,
    ListTaskComponent,
    TranslateModule,
    ListChoiceComponent,
  ],
})
export class HandleTasksTabComponent extends HandleItemsBaseDirective<Task> {
  currentTaskId: string;
  visibleListTask = signal<boolean>(false);
  visibleListChoice = signal<boolean>(false);
  choiceIds = signal<string[]>([]);

  readonlyChoices = input<boolean>();
  includeChoicesInList = input<boolean>();
  templateTypeIdForList = input<string>();
  hideAddButton = input<boolean>();

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "",
        "ChangeChoices",
        StringUtils.icons.choice,
        () => this.visibleListChoice.set(true),
        () => !this.selectedIds().length,
      ),
      new SimpleListAction(
        "AddTasks",
        "AddTasks",
        StringUtils.icons.new,
        () => this.visibleListTask.set(true),
        () => this.hideAddButton(),
      ),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTaskColumn(
        ["Name", "Code"],
        (row) => new TaskContent(row),
      ),
      new SimpleTableRuleColumn(["Rules"], (row) => new RuleContent(row.rules)),
      new SimpleTableChoiceColumn(
        ["Choices"],
        (row) => new ChoiceContent(row.choices, !this.readonlyChoices()),
        (row, choice) => {
          row.choices = row.choices.filter((c) => c.id !== choice.id);
          this.configureTableColumns();
        },
        (row) => {
          this.currentTaskId = row.id;
          this.choiceIds.set(row.choices.map((c) => c.id));
          this.visibleListChoice.set(true);
        },
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "RemoveSelected",
        StringUtils.icons.remove,
        () => {
          this.itemIds.set(this.selectedIds());
          this.remove();
        },
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowAction("Remove", StringUtils.icons.remove, (row) => {
        this.itemIds.set([row.id]);
        this.remove();
      }),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Tasks", StringUtils.icons.task),
    );
  }

  handleCloseListTask() {
    this.visibleListTask.set(false);
    this.itemIds.set([]);
  }

  handleCloseListChoice() {
    this.visibleListChoice.set(false);
    this.choiceIds.set([]);
  }

  handleConfirmListTask(items: Task[]) {
    this.handleCloseListTask();
    items.forEach((item) => {
      const existingItem = this.items().find((i) => i.id === item.id);
      if (existingItem) {
        item.choices = existingItem.choices;
      }
    });
    this.items.set(items.map((item) => new Task(item)));
    this.items().sortByProperty("text");
    this.onItemsChange.emit(this.items());
    this.selectedIds.set([]);
  }

  handleConfirmListChoice(items: Choice[]) {
    this.handleCloseListChoice();
    items.sortByProperty("value");
    if (this.currentTaskId) {
      const currentTask = this.items().find((i) => i.id === this.currentTaskId);
      if (currentTask) {
        currentTask.choices = items.map((c) => new Choice(c));
        this.currentTaskId = null;
      }
    } else {
      const selectedItems = this.items().filter((i) =>
        this.selectedIds().includes(i.id),
      );
      selectedItems.forEach(
        (item) => (item.choices = items.map((c) => new Choice(c))),
      );
    }
    this.configureTableColumns();
  }
}
