<div class="sfs-wrapper apx-scrollbar scroll-shadow">
  @for (filterInput of filterInputs(); track $index) {
    @if (
      filterInput.items.length ||
      filterInput.type === inputTypes.DateSelect ||
      filterInput.type === inputTypes.DateRangeSelect
    ) {
      <div class="sfs-input-wrapper">
        <div
          class="sfs-label"
          [ngClass]="{
            highlighted: filterInput.selectedItems.length,
          }"
        >
          <i class="icon {{ filterInput.iconName }}"></i>
          <div class="text">
            {{ filterInput.label | translate }}
          </div>
        </div>
        <div class="sfs-input">
          @if (filterInput.type === inputTypes.MultiSelect) {
            <p-selectbutton
              class="is-filter"
              [id]="filterInput.id + randomNumberString()"
              [options]="filterInput.items"
              [multiple]="true"
              [(ngModel)]="filterInput.selectedItems"
              optionLabel="name"
              (onChange)="handleMultiChange(filterInput)"
            >
              <ng-template #item let-item>
                <div class="item" [title]="item.name | translate">
                  {{ item.name | translate }}
                </div>
              </ng-template>
            </p-selectbutton>
          } @else if (filterInput.type === inputTypes.SingleSelect) {
            <p-selectbutton
              class="is-filter"
              [id]="filterInput.id + randomNumberString()"
              [options]="filterInput.items"
              [(ngModel)]="filterInput.selectedItem"
              optionLabel="name"
              (onChange)="handleSingleChange(filterInput)"
              ><ng-template #item let-item>
                <div class="item" [title]="item.name | translate">
                  {{ item.name | translate }}
                </div>
              </ng-template>
            </p-selectbutton>
          } @else if (filterInput.type === inputTypes.MultiSelectWithSearch) {
            <ng-select
              [id]="filterInput.id + randomNumberString()"
              [items]="filterInput.items"
              [multiple]="true"
              [searchable]="true"
              [closeOnSelect]="false"
              [markFirst]="false"
              bindLabel="name"
              [(ngModel)]="filterInput.selectedItems"
              [placeholder]="'Search' | translate"
              [ngClass]="{
                'is-filter': true,
                multiple: true,
                searchable: true,
              }"
              (change)="handleMultiChange(filterInput)"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div [title]="item.name">{{ item.name }}</div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label" [title]="item.name">
                  {{ item.name }}
                </span>
                <span
                  class="ng-value-icon right"
                  [title]="'Remove' | translate"
                  (click)="clear(item)"
                  aria-hidden="true"
                >
                  ×
                </span>
              </ng-template>
            </ng-select>
          } @else if (filterInput.type === inputTypes.DateSelect) {
            <div class="date-wrapper">
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="yearItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedYear"
                [placeholder]="'Year' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name">
                    {{ item.name }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="monthItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedMonth"
                [placeholder]="'Month' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name | translate">
                    {{ item.name | translate }}
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name | translate">
                    {{ item.name | translate }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="dayItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedDay"
                [placeholder]="'Day' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name">
                    {{ item.name }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
            </div>
          } @else if (filterInput.type === inputTypes.DateRangeSelect) {
            <div class="date-header">
              {{ "From" | translate }}
            </div>
            <div class="date-wrapper">
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="yearItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedYear"
                [placeholder]="'Year' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateRangeChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name">
                    {{ item.name }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="monthItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedMonth"
                [placeholder]="'Month' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateRangeChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name | translate">
                    {{ item.name | translate }}
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name | translate">
                    {{ item.name | translate }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="dayItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedDay"
                [placeholder]="'Day' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateRangeChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name">
                    {{ item.name }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
            </div>
            <div class="date-header">
              {{ "To" | translate }}
            </div>
            <div class="date-wrapper">
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="yearItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedYear2"
                [placeholder]="'Year' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateRangeChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name">
                    {{ item.name }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="monthItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedMonth2"
                [placeholder]="'Month' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateRangeChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name | translate">
                    {{ item.name | translate }}
                  </div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name | translate">
                    {{ item.name | translate }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
              <ng-select
                [id]="filterInput.id + randomNumberString()"
                [items]="dayItems"
                [searchable]="true"
                [markFirst]="false"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="filterInput.selectedDay2"
                [placeholder]="'Day' | translate"
                [ngClass]="{
                  'is-filter': true,
                  searchable: true,
                }"
                (change)="handleDateRangeChange(filterInput)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div [title]="item.name">{{ item.name }}</div>
                </ng-template>
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <span class="ng-value-label" [title]="item.name">
                    {{ item.name }}
                  </span>
                  <span
                    class="ng-value-icon right"
                    [title]="'Remove' | translate"
                    (click)="clear(item)"
                    aria-hidden="true"
                  >
                    ×
                  </span>
                </ng-template>
              </ng-select>
            </div>
          }
        </div>
      </div>
    }
  }
</div>
