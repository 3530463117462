<div class="counts apx-scrollbar">
  @for (count of counts(); track $index) {
    <div
      class="section"
      [title]="(count.titleText | translate) + ': ' + count.count"
    >
      <div class="count {{ count.iconClass }}"></div>
      <div class="count-text">{{ count.count }}</div>
    </div>
  }
</div>
