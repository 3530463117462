import { Injectable } from "@angular/core";
import { Filter } from "app/filter";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Facility, FacilityPayload } from "../components/facility/facility";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class FacilityService extends BaseService<Facility> {
  prefix = RoutesUtils.facility;

  constructor() {
    super(Facility);
  }

  async get(id: string): Promise<Facility> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new Facility(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: FacilityPayload): Promise<Facility> {
    try {
      const response = await this.hostedHttpClient.post(`${this.prefix}`, item);
      return new Facility(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: FacilityPayload): Promise<Facility> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
      );
      return new Facility(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}/${id}`,
        { shouldDeleteEntities: true },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds, shouldDeleteEntities: true },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateEntities(
    facilityId: string,
    itemIds: string[],
    assignmentTemplateIds: string[] = [],
    entityIdsToRemove: string[],
  ) {
    try {
      const model = {
        entities: [],
        entityIdsToRemove: entityIdsToRemove,
      };
      itemIds.forEach((id) =>
        model.entities.push({
          entityId: id,
          assignmentTemplateIds: assignmentTemplateIds,
        }),
      );
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${facilityId}/entity`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateStatus(itemIds: string[], active: boolean): Promise<Facility[]> {
    try {
      const model = {
        itemIds: itemIds,
        active: active,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/statuses`,
        model,
      );
      return this.extractData(response).map(
        (item: Facility) => new Facility(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateAccessibility(
    itemIds: string[],
    accessible: boolean,
  ): Promise<Facility[]> {
    try {
      const model = {
        itemIds: itemIds,
        accessible: accessible,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/accessible`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async getAssignmentTemplatesWithEntities(facilityId: string) {
    try {
      const response = await this.hostedHttpClient.getWithParams(
        `${this.prefix}/${facilityId}/assignmentTemplatesWithEntities`,
        { facilityId: facilityId },
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async uploadMedia(parentId: string, mediaItem: MediaWidgetItem) {
    try {
      const formData = new FormData();
      formData.append("file", mediaItem.blob);
      formData.append("isDefault", mediaItem.default ? "true" : "false");

      // const response = await this.hostedHttpClient.upload(
      //   `${this.prefix}/${parentId}/media/upload`,
      //   formData,
      // );
      return this.extractData({} as any);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async assignMedia(parentId: string, mediaList: MediaWidgetItem[]) {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${parentId}/media`,
        {
          media: mediaList,
        },
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class FacilityFilter extends Filter {
  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.TEMPLATE_TYPES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.TEMPLATE_TYPE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.TEMPLATE_TYPE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_SCHEDULES_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_SCHEDULES_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_SCHEDULES_KEY] = "false";
        }
      } else if (facet.key === StringUtils.LOCATION_DATA_KEY) {
        const coordinates = facet.value;
        if (coordinates === StringUtils.YES) {
          tempFacets[StringUtils.HAS_POSITION_KEY] = "true";
        } else if (coordinates === StringUtils.NO) {
          tempFacets[StringUtils.HAS_POSITION_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_POSITION_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.STATUS_KEY) {
        const status = facet.value;
        if (status === StringUtils.ACTIVE) {
          tempFacets[StringUtils.STATUS_KEY] = "true";
        } else if (status === StringUtils.INACTIVE) {
          tempFacets[StringUtils.STATUS_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ACCESSIBILITY_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.ACCESSIBLE_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.ACCESSIBLE_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ACCESSIBLE_KEY) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    return {
      filter: JSON.stringify(this),
    };
  }
}
