import { NgClass } from "@angular/common";
import { Component, inject, input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { ScheduleForViewing } from "app/components/schedule/schedule";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { SimpleImageComponent } from "app/components/simple-components/various/image/simple-image.component";
import { SimpleStatusLabelComponent } from "app/components/simple-components/various/status-label/simple-status-label.component";
import { SimpleViewConnectionsComponent } from "app/components/simple-components/various/view-connections/simple-view-connections.component";
import { User } from "app/components/user/user";
import { ExtensivePdfComponent } from "app/media/extensive-pdf/extensive-pdf.component";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { DatexPipe } from "app/pipes/datex.pipe";

@Component({
  selector: "view-details-tab",
  templateUrl: "./view-details-tab.component.html",
  styleUrl: "./view-details-tab.component.less",
  standalone: true,
  imports: [
    ExtensivePdfComponent,
    SimpleStatusLabelComponent,
    SimpleViewConnectionsComponent,
    TranslateModule,
    DatexPipe,
    NgClass,
    SimpleImageComponent,
    SimpleDetailsTabComponent,
  ],
})
export class ViewDetailsTabComponent extends DetailsBaseDirective<User> {
  documents = input<MediaWidgetItem[]>([]);
  schedules = input<ScheduleForViewing[]>([]);

  appInitializer = inject(AppInitializer);

  getFormattedRoles(roles: string[]) {
    return this.translationService.translateArray(roles).toCommaSeparatedList();
  }
}
