import { NgClass } from "@angular/common";
import { Component, HostListener, inject, signal } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AuthenticationService } from "app/authentication/authentication.service";
import { TokenStorageService } from "app/authentication/token-storage.service";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { ToastrService } from "app/services/toastr.service";
import { TranslationService } from "app/services/translation.service";
import { RegexUtils } from "app/tools/regex-utils";
import { DotsLoaderComponent } from "../../components/dots-loader/dots-loader.component";

@Component({
  selector: "login-with-code",
  templateUrl: "./login-with-code.component.html",
  styleUrl: "../login-shared-style.less",
  standalone: true,
  imports: [
    TranslateModule,
    SimpleFormInputComponent,
    DotsLoaderComponent,
    NgClass,
  ],
})
export class LoginWithCodeComponent {
  @HostListener("window:keyup", ["$event"])
  onKeyup(event: KeyboardEvent) {
    if (event.key == "Enter") {
      if (this.form().valid && !this.pending()) {
        this.loginWithCode();
      }
    }
  }

  protected form = signal<FormGroup>(new FormGroup({}));
  protected email = signal<string>("");
  protected code = signal<string>("");
  protected pending = signal<boolean>(false);
  protected codeWasRequested = signal<boolean>(false);
  protected emailIsValid = signal<boolean>(false);

  private formBuilder = inject(FormBuilder);
  private authService = inject(AuthenticationService);
  private translationService = inject(TranslationService);
  private tokenStorage = inject(TokenStorageService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected toastrService = inject(ToastrService);

  ngOnInit() {
    this.email.set(this.route.snapshot.queryParams["email"]);
    this.form.set(
      this.formBuilder.group({
        ["email"]: [this.email(), [Validators.required, this.emailValidator]],
        ["code"]: [this.code(), [Validators.required]],
      }),
    );
    this.form().controls["email"].markAsTouched();
    this.emailIsValid.set(this.form().controls["email"].valid);
    this.form().valueChanges.subscribe(() => {
      Object.entries(this.form().controls).forEach(([name, control]) => {
        if (!control.disabled) {
          if (name === "email") {
            this.email.set(control.value);
            this.emailIsValid.set(control.valid);
          }
          if (name === "code") {
            this.code.set(control.value);
          }
        }
      });
    });
    this.translationService.use("sv"); // TODO sätta från url
    if (this.emailIsValid()) {
      this.requestCode();
    }
  }

  private emailValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.EMAIL).test(control.value)) {
      return { email: true };
    } else {
      return null;
    }
  }

  protected async loginWithCode() {
    this.pending.set(true);
    try {
      const accessData = await this.authService.loginWithCode(
        encodeURIComponent(this.email()),
        this.code(),
      );
      this.tokenStorage.setAccessData(accessData);

      const returnUrl = this.route.snapshot.queryParams["returnUrl"];
      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        this.router.navigate([`/`]);
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      // TODO server errors här?
    }
  }

  async requestCode() {
    this.codeWasRequested.set(false);
    try {
      await this.authService.requestCode(this.email());
      this.codeWasRequested.set(true);
      // TODO swal för success ist för texten???
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  switchToPassword() {
    this.router.navigate(["/login-with-password"], {
      queryParams: { email: this.email() },
    });
  }
}
