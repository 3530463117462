import { Component, input } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFormInputComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-input/simple-form-input.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { InvitedUser } from "app/components/user/user";
import { RegexUtils } from "app/tools/regex-utils";
import { SimpleDetailsTabComponent } from "../../../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";

@Component({
  selector: "details-password-tab",
  templateUrl: "./details-password-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormInputComponent,
    TranslateModule,
  ],
})
export class DetailsPasswordTabComponent extends DetailsBaseDirective<InvitedUser> {
  otherUserId = input<string>();

  ngOnInit() {
    if (this.otherUserId()) {
      this.form.set(
        this.formBuilder.group(
          {
            [this.propertyStrings.newPassword]: [
              this.modifiedModel().newPassword,
              [
                Validators.required,
                this.passwordDigitValidator,
                this.passwordLowerValidator,
                this.passwordUpperValidator,
                this.passwordSpecialValidator,
                this.passwordLengthValidator,
              ],
            ],
            [this.propertyStrings.confirmPassword]: [
              this.modifiedModel().confirmPassword,
              Validators.required,
            ],
          },
          { validators: this.passwordMatchValidator },
        ),
      );
    } else {
      this.form.set(
        this.formBuilder.group(
          {
            [this.propertyStrings.oldPassword]: [
              this.modifiedModel().oldPassword,
              Validators.required,
            ],
            [this.propertyStrings.newPassword]: [
              this.modifiedModel().newPassword,
              [
                Validators.required,
                this.passwordDigitValidator,
                this.passwordLowerValidator,
                this.passwordUpperValidator,
                this.passwordSpecialValidator,
                this.passwordLengthValidator,
              ],
            ],
            [this.propertyStrings.confirmPassword]: [
              this.modifiedModel().confirmPassword,
              Validators.required,
            ],
          },
          { validators: this.passwordMatchValidator },
        ),
      );
    }
    super.ngOnInit();
  }

  private passwordMatchValidator(form: FormGroup): ValidationErrors {
    const newPassword = form.get("newPassword");
    const confirmPassword = form.get("confirmPassword");

    if (
      newPassword.value &&
      confirmPassword.value &&
      newPassword.value !== confirmPassword.value
    ) {
      confirmPassword.setErrors({ passwordsDoNotMatch: true });
    }

    if (
      newPassword.value &&
      confirmPassword.value &&
      newPassword.value === confirmPassword.value
    ) {
      confirmPassword.setErrors(null);
    }
    return null;
  }

  private passwordDigitValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_DIGIT).test(control.value)
    ) {
      return { passwordRequiresDigit: true };
    } else {
      return null;
    }
  }

  private passwordLowerValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_LOWER).test(control.value)
    ) {
      return { passwordRequiresLower: true };
    } else {
      return null;
    }
  }

  private passwordUpperValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_UPPER).test(control.value)
    ) {
      return { passwordRequiresUpper: true };
    } else {
      return null;
    }
  }

  private passwordSpecialValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_SPECIAL).test(control.value)
    ) {
      return { passwordRequiresNonAlphanumeric: true };
    } else {
      return null;
    }
  }

  private passwordLengthValidator(control: AbstractControl) {
    if (
      control.value &&
      !new RegExp(RegexUtils.PASSWORD_LENGTH).test(control.value)
    ) {
      return { passwordRequiresLengthSixOrMore: true };
    } else {
      return null;
    }
  }
}
