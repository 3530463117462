import {
  Directive,
  effect,
  inject,
  input,
  output,
  signal,
  viewChild,
} from "@angular/core";
import { Filter, KeyValuePair } from "app/filter";
import { GlobalStateService } from "app/global-state/global-state.service";
import { BaseService } from "app/services/base.service";
import { SimpleRetainService } from "app/services/simple-retain.service";
import { ToastrService } from "app/services/toastr.service";
import { TranslationService } from "app/services/translation.service";
import { ACCEPTED_MEDIA_TYPES } from "app/tools/file-utils";
import { NumberUtils } from "app/tools/number-utils";
import { PropertyNameGetter } from "app/tools/property-name-getter";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { ScrollerScrollIndexChangeEvent } from "primeng/scroller";
import { SimpleListAction } from "../list/actions/simple-list-action";
import { SimpleTableRowAction } from "../list/table/body/simple-table-row-action";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "../list/table/columns/simple-table-column";
import { SimpleTableEmptyState } from "../list/table/empty-state/simple-table-empty-state";
import { SimpleFilterInput } from "../list/table/filter/simple-filter-input";
import { SortObject } from "../list/table/filter/sort-object";
import { SimpleTableHeaderAction } from "../list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "../list/table/simple-table.component";

@Directive()
export abstract class ListBaseDirective<
  T extends {
    id?: string;
    index?: number;
  },
> {
  readonly filterObject = new Filter();
  acceptedMediaTypesForImport = ACCEPTED_MEDIA_TYPES.SPREADSHEET;
  stringUtils = StringUtils;
  columnType = SimpleTableColumnType;

  listActions = signal<SimpleListAction[]>([]);
  initialFacets = signal<KeyValuePair[]>([]);
  searchValue = signal<string>("");
  filterInputs = signal<SimpleFilterInput[]>([]);
  sortObjects = signal<SortObject<T>[]>([]);
  currentSortObject = signal<SortObject<T>>(null);
  columns = signal<SimpleTableColumn<T>[]>([]);
  headerActions = signal<SimpleTableHeaderAction[]>([]);
  rowActions = signal<SimpleTableRowAction<T>[]>([]);
  emptyState = signal<SimpleTableEmptyState>(null);
  filteredIds = signal<string[]>([]);
  fetchedPages = signal<Array<T[]>>([]);
  selectedIds = signal<string[]>([]);
  unselectableIds = signal<string[]>([]);
  addedIds = signal<string[]>([]);
  modifiedIds = signal<string[]>([]);
  items = signal<T[]>([]);
  pending = signal<boolean>(false);
  itemIds = signal<string[]>([]);
  visibleCreateEdit = signal<boolean>(false);

  visible = input<boolean>();
  isInModal = input<boolean>();
  dataToSetAsSelected = input<string[]>();
  isSingleSelectTable = input<boolean>();

  onClose = output();
  onConfirm = output<T[]>();

  table = viewChild<SimpleTableComponent<T>>(SimpleTableComponent);

  protected retainService = inject(SimpleRetainService);
  protected globalState = inject(GlobalStateService);
  protected translationService = inject(TranslationService);
  protected toastrService = inject(ToastrService);

  constructor(private service: BaseService<T>) {
    effect(() => {
      if (this.visible()) {
        this.setSelecteditems();
      }
    });
  }

  get totalCount() {
    return this.filteredIds().length;
  }

  get selectedCount() {
    return this.selectedIds().length;
  }

  get hasSelectableData() {
    return !this.unselectableIds().length;
  }

  get someAreSelected() {
    return !!this.selectedIds().length;
  }

  get allAreSelected() {
    return (
      this.selectedIds().length && this.selectedIds().length === this.totalCount
    );
  }

  get hasActiveSearchOrFilter() {
    return (
      !!this.searchValue() ||
      !!this.filterInputs()?.filter((input) => input.selectedItems.length)
        .length
    );
  }

  get onlyOneSelected() {
    return this.itemIds().length === 1;
  }

  async ngOnInit() {
    this.configureListActions();
    this.configureTableFilter();
    this.configureTableSort();
    this.configureTableColumns();
    this.configureTableActions();
    this.configureTableEmptyState();
    this.setSort();
    this.applyInitialFacets();
    // this.applyCustomColumnConfig();
    this.getTableData();
  }

  private setSort() {
    this.currentSortObject.set(
      this.sortObjects().find((sortObject) => sortObject.shouldBeDefault),
    );
    if (!this.currentSortObject()) {
      this.currentSortObject.set(this.sortObjects()[0]);
    }
    this.filterObject.addSort(
      new KeyValuePair(
        Utils.capitalizeFirstLetter(this.currentSortObject().sortProperty),
        this.currentSortObject().direction,
      ),
    );
  }

  private applyInitialFacets() {
    this.initialFacets().forEach((facet) => this.filterObject.addFacet(facet));
  }

  // applyCustomColumnConfig() {
  //   const visibleColumns = this.columns().filter((column) => !column.hidden());
  //   // TODO Använd från storage, hämta för aktuell lista ocha användare! TÄnk på att vissa kolumner inte ska synas när en viss lista är i en modal osv!
  //   visibleColumns.forEach((column, index) => {
  //     column.customOrder = index;
  //   });
  //   this.columns.set([...visibleColumns]);
  // }

  handleCustomColumnConfigChange(columns: SimpleTableColumn<T>[]) {
    // TODO spara i storage!
    this.columns.set([...columns]);
  }

  private setSelecteditems() {
    if (this.dataToSetAsSelected()?.length) {
      this.selectedIds.set(this.dataToSetAsSelected());
    } else {
      this.selectedIds.set([]);
    }
  }

  handleClose() {
    this.onClose.emit();
  }

  async handleConfirm() {
    const selectedItems = this.items().filter((item) =>
      this.selectedIds().includes(item.id),
    );
    // Must check if all selected items are in the current page, otherwise we need to fetch all items to get the selected ones.
    if (
      this.selectedIds().every((id) =>
        selectedItems.some((item) => item.id === id),
      )
    ) {
      const itemsCopy = selectedItems.map((item) => ({ ...item }));
      this.onConfirm.emit(itemsCopy);
    } else {
      this.pending.set(true);
      this.filterObject.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      const allItems = await this.service.getFiltered(this.filterObject);
      this.filterObject.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE;
      this.pending.set(false);
      const allSelectedItems = allItems.filter((item) =>
        this.selectedIds().includes(item.id),
      );
      const itemsCopy = allSelectedItems.map((item) => ({ ...item }));
      this.onConfirm.emit(itemsCopy);
    }
  }

  handleSearch(value: string) {
    this.searchValue.set(value);
    this.filterObject.addSearch(value);
    this.getTableData();
  }

  handleSearchClear() {
    this.searchValue.set("");
    this.filterObject.clearSearch();
    this.getTableData();
  }

  handleSortDirectionChange(direction: "Ascending" | "Descending") {
    this.currentSortObject().direction = direction;
    this.handleSortLabelChange(this.currentSortObject());
    this.getTableData();
  }

  handleSortLabelChange(sortObject: SortObject<T>) {
    this.currentSortObject.set(sortObject);
    this.filterObject.clearSort();
    this.filterObject.addSort(
      new KeyValuePair(
        Utils.capitalizeFirstLetter(sortObject.sortProperty),
        sortObject.direction,
      ),
    );
    this.getTableData();
  }

  handleFilter(filterInput: SimpleFilterInput) {
    const values = filterInput.selectedItems.map((item) => item.id).join();
    const keyValuePairs = filterInput.transformValues(values);
    keyValuePairs.forEach((pair) => this.filterObject.addFacet(pair));
    this.getTableData();
  }

  handleFilterClear() {
    this.filterInputs().forEach((filterInput) => {
      filterInput.selectedItems = [];
      filterInput.selectedItem = null;
      filterInput.selectedYear = null;
      filterInput.selectedMonth = null;
      filterInput.selectedDay = null;
      filterInput.selectedYear2 = null;
      filterInput.selectedMonth2 = null;
      filterInput.selectedDay2 = null;
    });
    this.filterObject.clearFacets();
    this.getTableData();
  }

  handleSelectedClear() {
    // TODO clearselectedrows gör samma sak?
    this.selectedIds.set([]);
  }

  handleCheckboxClick(value: boolean) {
    if (value) {
      this.selectedIds.set(this.filteredIds());
    } else {
      this.selectedIds.set([]);
    }
  }

  handleRowSingleClick(row: T) {
    this.handleRowCheckboxClick(row);
  }

  handleRowDoubleClick(row: T) {
    this.itemIds.set([row.id]);
    this.visibleCreateEdit.set(true);
  }

  handleRowCheckboxClick(row: T) {
    if (this.isSingleSelectTable()) {
      if (!this.selectedIds().includes(row.id)) {
        this.selectedIds.set([row.id]);
      } else {
        this.selectedIds.set([]);
      }
    } else {
      if (!this.selectedIds().includes(row.id)) {
        this.selectedIds.update((ids) => [...ids, row.id]);
      } else {
        this.selectedIds.update((ids) => ids.filter((id) => id !== row.id));
      }
    }
  }

  get propertyStrings() {
    return PropertyNameGetter.propertiesOf({} as new (args?: unknown) => T);
  }

  protected async scrollToIndex(id: string) {
    const index = this.items().findIndex((row) => row.id === id);
    // Must check if item to scroll to is in the current page, otherwise we need to fetch all items to get it.
    if (index !== -1) {
      setTimeout(() => this.table().scrollToIndex(index));
    } else {
      this.pending.set(true);
      this.filterObject.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      const allItems = await this.service.getFiltered(this.filterObject);
      this.filterObject.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE;
      this.pending.set(false);
      this.items.set(allItems);
      const index = this.items().findIndex((row) => row.id === id);
      if (index !== -1) {
        setTimeout(() => this.table().scrollToIndex(index));
      }
    }
  }

  protected clearSelectedRows() {
    // TODO denna kommer ju cleara alla även om man kanske editerade något bara och hade valda rader.
    this.selectedIds.set([]);
  }

  handleCloseCreateEdit() {
    this.visibleCreateEdit.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneCreateEdit(data: T[]) {
    if (this.itemIds().length) {
      this.modifiedIds.update((ids) => [...ids, data[0].id]);
    } else {
      this.addedIds.update((ids) => [...ids, data[0].id]);
    }
    this.handleCloseCreateEdit();
    await this.getTableDataAndScrollToItem(data[0].id);
  }

  protected async getTableData(page: number = 0) {
    this.pending.set(true);
    const filteredIdsData = await this.service.getFilteredIds(
      this.filterObject,
    );
    this.filteredIds.set(filteredIdsData);

    this.fetchedPages.set([]);

    const promises = Array<Promise<unknown>>();

    // Get page.
    this.fetchedPages()[page] = [];
    promises.push(this.getData(page));

    await Promise.all(promises);
    this.pending.set(false);

    this.assignRows();
  }

  protected async getTableDataAndScrollToItem(itemId: string) {
    this.pending.set(true);
    const filteredIdsData = await this.service.getFilteredIds(
      this.filterObject,
    );
    this.filteredIds.set(filteredIdsData);

    this.fetchedPages.set([]);

    const promises = Array<Promise<unknown>>();

    const existInDatabase = this.filteredIds().includes(itemId);
    let page = 0;
    if (existInDatabase) {
      const index = this.filteredIds().findIndex((id) => id === itemId);
      page = Math.floor(index / NumberUtils.TABLE_DATA_PAGE_SIZE);
    }

    // Get pages.
    for (let i = page; i >= 0; --i) {
      this.fetchedPages()[i] = [];
      promises.push(this.getData(i));
    }

    await Promise.all(promises);
    this.pending.set(false);

    this.assignRows();

    if (existInDatabase) {
      this.scrollToIndex(itemId);
    }
  }

  async handleLazyLoad(event: ScrollerScrollIndexChangeEvent) {
    const promises = Array<Promise<unknown>>();

    // Downward scroll -> get the next page if there is one.
    if (event.last === this.items().length) {
      const latestPageIndex = this.fetchedPages().reduce(
        (acc, page, index) => (page && page.length ? index : acc),
        -1,
      );
      const isLastPage =
        latestPageIndex ===
        Math.floor(
          this.filteredIds().length / NumberUtils.TABLE_DATA_PAGE_SIZE,
        );
      if (!isLastPage && !this.fetchedPages()[latestPageIndex + 1]) {
        this.fetchedPages()[latestPageIndex + 1] = [];
        promises.push(this.getData(latestPageIndex + 1));
      }
    }

    if (promises.length) {
      this.pending.set(true);
      await Promise.all(promises);
      this.assignRows();
      this.pending.set(false);
    }
  }

  private assignRows() {
    const rowsToSet = new Array<T>()
      .concat(...this.fetchedPages())
      .filter((item) => item);
    this.items.set([...rowsToSet]);
  }

  protected async getData(page: number) {
    try {
      this.filterObject.page = page;
      const data = await this.service.getFiltered(this.filterObject);
      data.forEach((item) => {
        const index = this.filteredIds().findIndex((id) => id === item.id);
        if (index !== -1) {
          item.index = index + 1;
        }
      });
      this.fetchedPages()[page] = data;
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }

  protected getSuccessMessageDelete(model: string, modelPlural?: string) {
    return this.translationService.instant(
      `The${this.onlyOneSelected ? model : modelPlural}${this.onlyOneSelected ? "Was" : "Were"}Deleted`,
    );
  }

  protected abstract configureListActions(): void;
  protected abstract configureTableFilter(): Promise<void>;
  protected abstract configureTableSort(): void;
  protected abstract configureTableColumns(): void;
  protected abstract configureTableActions(): void;
  protected abstract configureTableEmptyState(): void;
}
