import { LowerCasePipe, NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ScheduledAssignment } from "app/components/assignment/scheduled-assignment";
import { SimpleFormCalendarComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-calendar/simple-form-calendar.component";
import { SimpleFormTimeComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-time/simple-form-time.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { Utils } from "app/tools/utils";
import { DateTime } from "luxon";
import { DatexPipe } from "../../../../../../../pipes/datex.pipe";
import { ParseMDPipe } from "../../../../../../../pipes/parse-md-pipe";
import { SimpleDetailsTabComponent } from "../../../../../../simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { SimpleAssessmentComponent } from "../../../../../../simple-components/various/assessments/simple-assessment.component";
import { SimpleCategoriesComponent } from "../../../../../../simple-components/various/categories/simple-categories.component";
import { SimpleEntityComponent } from "../../../../../../simple-components/various/entity/simple-entity.component";
import { SimpleFacilityComponent } from "../../../../../../simple-components/various/facility/simple-facility.component";
import { SimpleLinkButtonComponent } from "../../../../../../simple-components/various/link-button/simple-link-button.component";
import { SimpleStatusLabelComponent } from "../../../../../../simple-components/various/status-label/simple-status-label.component";

@Component({
  selector: "details-assignment-tab",
  templateUrl: "./details-assignment-tab.component.html",
  styleUrls: ["./details-assignment-tab.component.less"],
  standalone: true,
  imports: [
    SimpleFormCalendarComponent,
    SimpleFormTimeComponent,
    TranslateModule,
    SimpleDetailsTabComponent,
    SimpleCategoriesComponent,
    NgClass,
    SimpleStatusLabelComponent,
    ParseMDPipe,
    SimpleAssessmentComponent,
    SimpleEntityComponent,
    LowerCasePipe,
    SimpleLinkButtonComponent,
    SimpleFacilityComponent,
    DatexPipe,
  ],
})
export class DetailsAssignmentTabComponent extends DetailsBaseDirective<ScheduledAssignment> {
  get minDueDate() {
    return DateTime.now().toSQLDate();
  }

  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.dueDate]: [
          this.modifiedModel().dueDate,
          [Validators.required, this.dateValidator],
        ],
        [this.propertyStrings.dueTime]: [
          this.modifiedModel().dueTime,
          [Validators.required, this.timeValidator],
        ],
      }),
    );
    super.ngOnInit();

    this.modifiedModel()
      .entities.filter((e) => e.results)
      .forEach((entity) => {
        entity.numberOfErrors = 0;
        entity.results
          .filter((r) => r.task.choice.isError)
          .forEach(() => (entity.numberOfErrors += 1));
      });
    this.modifiedModel().facility.numberOfErrors = 0;
    this.modifiedModel()
      .facility.results.filter((r) => r.task.choice.isError)
      .forEach(() => (this.modifiedModel().facility.numberOfErrors += 1));
  }

  private dateValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.DATE).test(control.value)) {
      return { date: true };
    } else {
      return null;
    }
  }

  private timeValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.TIME).test(control.value)) {
      return { time: true };
    } else {
      return null;
    }
  }

  getHumanDueDate() {
    const humanDate = Utils.convertToHumanDate(this.modifiedModel().dueDate);
    const [first, second] = humanDate.split(" ");
    return `${Utils.uppercaseEachFirst(first)}${second}`;
  }

  isTodayAndFuture() {
    return (
      Utils.isTodayDate(this.modifiedModel().dueDate) &&
      Utils.isFutureDate(this.modifiedModel().dueDate)
    );
  }
}
