// tslint:disable:quotemark
import { inject, Injectable } from "@angular/core";
import Swal from "sweetalert2";
import { SwalConfig } from "../swal/swal-config.component";
import { FileData, FileHelper } from "../tools/file-helper";
import { StringUtils } from "../tools/string-utils";
import { HostedHttpClientService } from "./hosted-httpclient.service";
import {
  ImportActionKey,
  ImportExportModelKey,
  ImportJobStatusKey,
} from "./import-helper";
import { TranslationService } from "./translation.service";

export const ORG_QUERY_PARAM = "?organization=";

export const JOB_ENDPOINTS = {
  GET_JOB_STATUS: (type: string) => `backgroundJob/${type}`,
  DELETE_JOB: (id: string) => `backgroundJob/${id}`,
};

@Injectable({
  providedIn: "root",
})
export class ImportService<T extends { id: string; url: string }> {
  private checkStatusInterval = 1000;
  // private progressToast: ActiveToast<T>;
  // private successToast: ActiveToast<T>;
  // private errorToast: ActiveToast<T>;

  private hostedHttpClient = inject(HostedHttpClientService);
  private translationService = inject(TranslationService);
  private fileHelper = inject(FileHelper);

  async import(
    data: any[],
    modelName: ImportExportModelKey,
    actionName: ImportActionKey,
    orgId: number,
  ): Promise<T[]> {
    try {
      const endpoint = `${modelName}/import${ORG_QUERY_PARAM}${orgId.toString()}`;

      const stringifiedData = JSON.stringify(data).replace(/'/g, "\\'"); // Escape single quotes.

      const response = await this.hostedHttpClient.post(endpoint, {
        data: stringifiedData,
      });

      // Show export modal for failed rows.
      if (response["body"].data["failed"].length) {
        this.displayExportModal(new FileData(response["body"].data["file"]));
      }

      // Start monitoring if a big import or just add to the table.
      if (response["body"].data["backgroundJobStarted"]) {
        // this.toastrService.add(
        //   this.translationService.instant("BackgroundJobStartedImported"),
        // );
        this.monitorProgress(modelName, actionName);
        return [];
      } else {
        const newItems = response["body"].data["created"] ?? [];
        return newItems;
      }
    } catch (errorResponse) {
      // Show export modal for failed rows.
      if (
        errorResponse.error &&
        errorResponse.error.data &&
        errorResponse.error.data.failed.length
      ) {
        this.displayExportModal(new FileData(errorResponse.error.data.file));
      } else {
        throw new Error("Något gick fel med importen");
      }
    }
  }

  // Checks if any import job is in progress for a specified model.
  async hasImportInProgress(
    modelName: ImportExportModelKey,
    actionName: ImportActionKey,
  ): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.getWithParams(
        JOB_ENDPOINTS.GET_JOB_STATUS(modelName),
        {
          action: actionName,
        },
      );
      return (
        !!response["body"].data &&
        !!response["body"].data["length"] &&
        !!response["body"].data["find"]((item: unknown) => {
          return item["status"] === ImportJobStatusKey.InProgress;
        })
      );
    } catch (errorResponse) {
      // TODO extracta error!
      throw new Error("Något gick fel med att kolla efter pågående import");
    }
  }

  // Keeps track of import job statuses for a specified model.
  async monitorProgress(
    modelName: ImportExportModelKey,
    actionName: ImportActionKey,
  ) {
    try {
      const response = await this.hostedHttpClient.getWithParams(
        JOB_ENDPOINTS.GET_JOB_STATUS(modelName),
        {
          action: actionName,
        },
      );
      if (response["body"].data && response["body"].data["length"]) {
        response["body"].data["forEach"]((item: unknown) => {
          switch (item["status"]) {
            case ImportJobStatusKey.InProgress:
              this.handleInProgressStatus(modelName, actionName);
              break;
            case ImportJobStatusKey.Succeeded:
              this.handleSucceededStatus(item["id"]);
              break;
            case ImportJobStatusKey.Error:
              this.handleErrorStatus(item["id"]);
              break;
          }
        });
      }
    } catch (errorResponse) {
      // TODO extracta error!
      // this.toastrService.add(
      //   "Något gick fel med att övervaka importens framsteg",
      // );
    }
  }

  private handleInProgressStatus(
    modelName: ImportExportModelKey,
    actionName: ImportActionKey,
  ) {
    this.displayInProgressMessage();
    setTimeout(() => {
      this.monitorProgress(modelName, actionName);
    }, this.checkStatusInterval);
  }

  private handleSucceededStatus(jobId: string) {
    this.removeInProgressMessage();
    this.displaySuccessMessage();
    this.deleteJob(jobId);
    //this.successToast = undefined;
  }

  private handleErrorStatus(jobId: string) {
    this.removeInProgressMessage();
    this.displayErrorMessage();
    this.deleteJob(jobId);
    //this.errorToast = undefined;
  }

  private removeInProgressMessage() {
    //this.toastrService.remove(this.progressToast?.toastId);
    //this.progressToast = undefined;
  }

  private displayInProgressMessage() {
    // if (!this.progressToast) {
    //   this.progressToast = this.toastrService.info(
    //     this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS),
    //     this.translationService.instant(StringUtils.IMPORTING),
    //     {
    //       timeOut: 86400,
    //       closeButton: false,
    //     },
    //   );
    // }
  }

  private displaySuccessMessage() {
    // if (!this.successToast) {
    //   this.successToast = this.toastrService.success(
    //     this.translationService.instant(StringUtils.IMPORT_SUCCEEDED),
    //     this.translationService.instant(StringUtils.FINISHED),
    //   );
    // }
  }

  private displayErrorMessage() {
    // if (!this.errorToast) {
    //   this.errorToast = this.toastrService.error(
    //     this.translationService.instant(StringUtils.SOMETHING_WENT_WRONG),
    //     this.translationService.instant(StringUtils.ABORTED),
    //     {
    //       timeOut: 86400,
    //     },
    //   );
    // }
  }

  private deleteJob(jobId: string) {
    try {
      this.hostedHttpClient.delete(JOB_ENDPOINTS.DELETE_JOB(jobId));
    } catch (errorResponse) {
      // TODO extracta error!
      //this.toastrService.error("Något gick fel med att ta bort importjobb");
    }
  }

  private displayExportModal(fileData: FileData) {
    Swal.fire(
      new SwalConfig(this.translationService).getInfo({
        title: this.translationService.instant("ImportQuestion"),
        text: StringUtils.SOME_ROWS_HAD_ERRORS,
        confirmButtonText: StringUtils.DOWNLOAD_ERROR_LIST,
        cancelButtonText: StringUtils.CLOSE,
      }),
    ).then((result) => {
      if (result.value) {
        this.fileHelper.handleFile(fileData);
      }
    });
  }
}
