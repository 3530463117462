<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'LegalName' | translate"
          [name]="propertyStrings.name"
          [form]="form()"
          [maxlength]="50"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!isEdit"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'PublicName' | translate"
          [name]="propertyStrings.publicName"
          [form]="form()"
          [maxlength]="50"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'OrganizationNumber' | translate"
          [name]="propertyStrings.organizationNumber"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'EmailAddress' | translate"
          [name]="propertyStrings.emailAddress"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        @if (isEdit) {
          <div class="form-control-wrapper">
            <simple-form-select
              class="fixed-width"
              [label]="'CountryCode' | translate"
              [name]="propertyStrings.countryCode"
              [form]="form()"
              [items]="selectableCountryCodeItems()"
              [serverErrors]="serverErrors()"
            >
            </simple-form-select>

            <simple-form-input
              class="stretch"
              [label]="'PhoneNumber' | translate"
              [name]="propertyStrings.phoneNumber"
              [form]="form()"
              [serverErrors]="serverErrors()"
            >
            </simple-form-input>
          </div>

          <simple-form-input
            [label]="'StreetAddress' | translate"
            [name]="propertyStrings.streetAddress"
            [form]="form()"
            [maxlength]="50"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>

          <div class="form-control-wrapper">
            <simple-form-input
              class="fixed-width"
              [label]="'ZipCode' | translate"
              [name]="propertyStrings.zipCode"
              [form]="form()"
              [maxlength]="15"
              [serverErrors]="serverErrors()"
            >
            </simple-form-input>

            <simple-form-input
              class="stretch"
              [label]="'City' | translate"
              [name]="propertyStrings.city"
              [form]="form()"
              [maxlength]="50"
              [serverErrors]="serverErrors()"
            >
            </simple-form-input>
          </div>

          <simple-form-select
            [label]="'Country' | translate"
            [name]="propertyStrings.country"
            [form]="form()"
            [items]="selectableCountryItems()"
            [searchable]="true"
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
        }

        <simple-form-input
          [label]="'OperationsManager' | translate"
          [name]="propertyStrings.operationsManager"
          [form]="form()"
          [maxlength]="50"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-select
          [label]="'LanguageOnReports' | translate"
          [name]="propertyStrings.culture"
          [form]="form()"
          [items]="selectableCultureItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>
      </div>

      @if (globalState.loggedInUser().isSuperAdmin()) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "ForSuperAdmins" | translate }}
          </div>
          <simple-form-select
            [label]="'ParentOrganization' | translate"
            [name]="propertyStrings.parentId"
            [form]="form()"
            [items]="selectableOrganizationItems()"
            [searchable]="true"
            [disabled]="true"
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>

          <simple-form-select
            [label]="'TestOrganization' | translate"
            [name]="propertyStrings.isTest"
            [form]="form()"
            [items]="selectableIsTestItems()"
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
        </div>
      }
    </div>
  </div>
</simple-details-tab>
