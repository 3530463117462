import { Routes } from "@angular/router";
import { AuthGuardService } from "./authentication/auth-guard.service";
import { ListAssignmentTemplateComponent } from "./components/assignment-template/list-assignment-template/list-assignment-template.component";
import { ListAssignmentMeasureArchivedComponent } from "./components/assignment/list-assignment/measure/archived/list-assignment-measure-archived.component";
import { ListAssignmentMeasureOngoingComponent } from "./components/assignment/list-assignment/measure/ongoing/list-assignment-measure-ongoing.component";
import { ListAssignmentScheduledArchivedComponent } from "./components/assignment/list-assignment/scheduled/archived/list-assignment-scheduled-archived.component";
import { ListAssignmentScheduledOngoingComponent } from "./components/assignment/list-assignment/scheduled/ongoing/list-assignment-scheduled-ongoing.component";
import { ListChoiceComponent } from "./components/choice/list-choice/list-choice.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ListEntityComponent } from "./components/entity/list-entity/list-entity.component";
import { ListFacilityComponent } from "./components/facility/list-facility/list-facility.component";
import { MyOrganizationComponent } from "./components/organization/my-organization/my-organization.component";
import { ListScheduleComponent } from "./components/schedule/list-schedule/list-schedule.component";
import { ListTaskComponent } from "./components/task/list-task/list-task.component";
import { ListTemplateTypeComponent } from "./components/template-type/list-template-type/list-template-type.component";
import { ListUserGroupComponent } from "./components/user-group/list-user-group/list-user-group.component";
import { ListUserComponent } from "./components/user/list-user/list-user.component";
import { ProfileComponent } from "./components/user/profile/profile.component";
import { LoginWithCodeComponent } from "./login/login-with-code/login-with-code.component";
import { LoginWithPasswordComponent } from "./login/login-with-password/login-with-password.component";
import { RoutesUtils } from "./tools/routes-utils";

export const routes: Routes = [
  {
    path: ``,
    component: DashboardComponent, // Kvittar vilken komponent, authguarden löser redirect vid tomt.
    canActivate: [AuthGuardService],
  },
  {
    path: "login-with-password",
    component: LoginWithPasswordComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "LoginWithPassword",
    },
  },
  {
    path: "login-with-code",
    component: LoginWithCodeComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "LoginWithCode",
    },
  },
  {
    path: `${RoutesUtils.orgParam}`,
    redirectTo: `${RoutesUtils.orgParam}/${RoutesUtils.dashboard}`,
    pathMatch: "full",
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.dashboard}`,
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Dashboard",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.assignments}/${RoutesUtils.scheduledOngoing}`,
    component: ListAssignmentScheduledOngoingComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "OngoingAssignments",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.assignments}/${RoutesUtils.scheduledArchived}`,
    component: ListAssignmentScheduledArchivedComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "ArchivedAssignments",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.assignments}/${RoutesUtils.measureOngoing}`,
    component: ListAssignmentMeasureOngoingComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "OngoingMeasures",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.assignments}/${RoutesUtils.measureArchived}`,
    component: ListAssignmentMeasureArchivedComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "ArchivedMeasures",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.schedules}`,
    component: ListScheduleComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Schedules",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.assignmentTemplates}`,
    component: ListAssignmentTemplateComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "AssignmentTemplates",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.locations}`,
    component: ListFacilityComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Locations",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.entities}`,
    component: ListEntityComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Entities",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.users}`,
    component: ListUserComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Users",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.groups}`,
    component: ListUserGroupComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Groups",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.templateTypes}`,
    component: ListTemplateTypeComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "TemplateTypes",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.tasks}`,
    component: ListTaskComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Tasks",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.choices}`,
    component: ListChoiceComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Choices",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.profile}`,
    component: ProfileComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "Profile",
    },
  },
  {
    path: `${RoutesUtils.orgParam}/${RoutesUtils.myOrganization}`,
    component: MyOrganizationComponent,
    canActivate: [AuthGuardService],
    data: {
      title: "MyOrganization",
    },
  },
  // {
  //   path: RoutesUtils.invitation,
  //   component: InvitationComponent,
  //   data: {
  //     title: "Invitation",
  //   },
  // },

  // When no route matches.
  {
    path: RoutesUtils.notSpecified,
    redirectTo: ``,
    pathMatch: "full",
  },
];
