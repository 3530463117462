import { NgClass, NgIf } from "@angular/common";
import { Component, model, signal } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { GlobalStateService } from "app/global-state/global-state.service";
import {
  LocalStorageKey,
  LocalStorageService,
} from "app/services/local-storage.service";
import { TranslationService } from "app/services/translation.service";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { debounceTime, fromEvent, Subscription } from "rxjs";
import { ExtensiveImageComponent } from "../../media/extensive-image/extensive-image.component";
import { GlobalOrganizationModalComponent } from "../organization/global-organization/global-organization-modal.component";

@Component({
  selector: "side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.less"],
  standalone: true,
  imports: [
    NgClass,
    ExtensiveImageComponent,
    RouterLink,
    TranslateModule,
    NgIf,
    GlobalOrganizationModalComponent,
  ],
})
export class SideMenuComponent {
  routesUtils = RoutesUtils;
  stringUtils = StringUtils;

  collapsed = signal<boolean>(false);
  visibleSelectOrganization = signal<boolean>(false);
  isLargeScreen = signal<boolean>(false);

  menuOpenedMobile = model<boolean>();

  private viewModeSubscription = new Subscription();

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    private localStorageService: LocalStorageService,
    protected globalState: GlobalStateService,
  ) {
    this.setCollapsedState();
  }

  ngOnInit() {
    this.viewModeSubscription.add(
      fromEvent(window, "resize")
        .pipe(debounceTime(NumberUtils.WINDOW_RESIZE_WAIT_TIME))
        .subscribe(() => {
          if (Utils.isLargeScreenSize()) {
            this.isLargeScreen.set(true);
            this.collapsed.set(true);
          } else {
            this.isLargeScreen.set(false);
            this.collapsed.set(false);
          }
        }),
    );
  }

  private setCollapsedState() {
    const value = this.localStorageService.getItem(
      LocalStorageKey.CollapsedMenu,
    );
    this.collapsed.set(value ? value : false);
  }

  routeIsActive(pathName: string) {
    const strippedPath = this.getCurrentStrippedURLPath();
    return pathName
      .split("/")
      .every((entry) => strippedPath.indexOf(entry) !== -1);
  }

  routeIsDisabled(pathName: string) {
    // Disable when active, but not in "/new", "/:id" etc...
    const maxLength = pathName.split("/").length > 1 ? 4 : 3;
    return (
      this.routeIsActive(pathName) &&
      this.router.url.split("/").length - 1 < maxLength
    );
  }

  getCurrentStrippedURLPath() {
    return window.location.pathname
      .replace(this.globalState.selectedOrganization().friendlyUrl, "")
      .split("/")
      .filter((entry) => !!entry);
  }

  toggleMenu() {
    this.collapsed.set(!this.collapsed());
    this.localStorageService.setItem(
      LocalStorageKey.CollapsedMenu,
      this.collapsed(),
    );
  }

  toggleGlobalOrgModal() {
    if (this.canSwitchOrganization()) {
      this.visibleSelectOrganization.set(true);
    }
  }

  getCurrentRole() {
    const roles = this.globalState
      .loggedInUser()
      .getCurrentRoles(this.globalState.selectedOrganization().id);
    if (roles.length) {
      return this.translationService
        .translateArray(roles)
        .toCommaSeparatedList();
    } else {
      return this.translationService.instant("NotInOrganization");
    }
  }

  canSwitchOrganization() {
    return (
      this.globalState.loggedInUser().hasActiveMemberships() ||
      this.globalState.loggedInUser().isSuperAdmin()
    );
  }

  handleCloseSelectOrganization() {
    this.visibleSelectOrganization.set(false);
  }
}
