import { Component, input, signal } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { User } from "app/components/user/user";
import { StringUtils } from "app/tools/string-utils";
import { SimpleFormInfoComponent } from "../../../../../simple-components/crud/modal/tabs/details/form/form-info/simple-form-info.component";

@Component({
  selector: "details-status-user-tab",
  templateUrl: "./details-status-user-tab.component.html",
  standalone: true,
  imports: [
    TranslateModule,
    SimpleFormInfoComponent,
    SimpleFormSelectComponent,
    SimpleDetailsTabComponent,
  ],
})
export class DetailsStatusUserTabComponent extends DetailsBaseDirective<User> {
  selectableStatusItems = signal<FormSelectItem[]>([]);

  affectableIds = input<string[]>();

  ngOnInit() {
    this.setSelectableStatusItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.status]: [
          this.modifiedModel().status,
          Validators.required,
        ],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableStatusItems() {
    this.selectableStatusItems.set([
      new FormSelectItem(
        StringUtils.ACTIVE,
        this.translationService.instant(StringUtils.ACTIVE),
      ),
      new FormSelectItem(
        StringUtils.INACTIVE,
        this.translationService.instant(StringUtils.INACTIVE),
      ),
    ]);
  }
}
