import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { User } from "app/components/user/user";
import { UserService } from "app/services/user.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsResendEmailTabComponent } from "./tabs/details/details-resend-email-tab.component";

@Component({
  selector: "resend-email",
  templateUrl: "./resend-email.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsResendEmailTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ResendEmailComponent extends CreateEditBaseDirective<User> {
  isLoggedInUser: boolean = false;

  private detailsResendEmailTabComponent = viewChild(
    DetailsResendEmailTabComponent,
  );

  private userService = inject(UserService);

  async initiate() {
    this.isLoggedInUser =
      this.itemIds()[0] === this.globalState.loggedInUser().id;

    this.pending.set(true);
    try {
      const data = await this.userService.get(this.itemIds()[0]);
      this.initialModel.set(data);
      this.pending.set(false);

      this.initialModel().inviteDate = this.initialModel().getCurrentInviteDate(
        this.globalState.selectedOrganization().id,
      );

      this.modifiedModel.set(
        new User(Utils.getUniqueVariant(this.initialModel())),
      );
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.userService.resendInvite(
        this.modifiedModel().toPayloadObject(
          this.globalState.selectedOrganization().id,
          this.isLoggedInUser,
        ),
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.secondary(
        this.translationService.instant("TheEmailWasSent"),
      );
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsResendEmailTabComponent().setServerErrors(error.errors);
    }
  }
}
