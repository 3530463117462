import { NgTemplateOutlet } from "@angular/common";
import {
  Component,
  ElementRef,
  inject,
  input,
  signal,
  viewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { Entity } from "app/components/entity/entity";
import { Schedule } from "app/components/schedule/schedule";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  CountContent,
  CountObject,
  SimpleTableCountColumn,
} from "app/components/simple-components/list/table/columns/simple-table-count-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  FacilityContent,
  SimpleTableFacilityColumn,
} from "app/components/simple-components/list/table/columns/simple-table-facility-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { SimpleViewLocationDataComponent } from "app/components/simple-components/various/view-location-data/simple-view-location-data.component";
import { KeyValuePair } from "app/filter";
import { AssignmentService } from "app/services/assignment.service";
import { ExportService } from "app/services/export.service";
import {
  ImportActionKey,
  ImportExportModelKey,
  ImportHelper,
} from "app/services/import-helper";
import { ImportService } from "app/services/import.service";
import { ParseXLSXService } from "app/services/parse-xlsx.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { FileHelper } from "app/tools/file-helper";
import { ACCEPTED_MEDIA_TYPES, FileUtils } from "app/tools/file-utils";
import { StringUtils } from "app/tools/string-utils";
import { coordinateSystems } from "app/tools/utils";
import Swal from "sweetalert2";
import {
  FacilityFilter,
  FacilityService,
} from "../../../services/facility.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { SimpleViewConnectionsComponent } from "../../simple-components/various/view-connections/simple-view-connections.component";
import { SimpleViewContentComponent } from "../../simple-components/various/view-content/simple-view-content.component";
import { SimpleViewItemsComponent } from "../../simple-components/various/view-items/simple-view-items.component";
import { CreateEditFacilityComponent } from "../create-edit-facility/create-edit-facility.component";
import { Facility } from "../facility";
import { ChangeAccessibilityFacilityComponent } from "./change-accessibility/change-accessibility-facility.component";
import { ChangeStatusFacilityComponent } from "./change-status/change-status-facility.component";

@Component({
  selector: "list-facility",
  templateUrl: "./list-facility.component.html",
  standalone: true,
  imports: [
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    ChangeStatusFacilityComponent,
    ChangeAccessibilityFacilityComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewItemsComponent,
    SimpleViewContentComponent,
    CreateEditFacilityComponent,
    SimpleViewConnectionsComponent,
    SimpleViewLocationDataComponent,
  ],
})
export class ListFacilityComponent extends ListBaseDirective<Facility> {
  readonly filterObject = new FacilityFilter();
  acceptedMediaTypesForImport = ACCEPTED_MEDIA_TYPES.SPREADSHEET;

  visibleChangeStatus = signal<boolean>(false);
  visibleChangeAccessibility = signal<boolean>(false);
  locationData = signal<string[]>([]);
  extraInfo = signal<string>("");
  entities = signal<Entity[]>([]);
  assignmentTemplates = signal<AssignmentTemplate[]>([]);
  schedules = signal<Schedule[]>([]);
  visibleViewLocationData = signal<boolean>(false);
  visibleViewContent = signal<boolean>(false);
  visibleViewItems = signal<boolean>(false);

  onlyFacilitiesWithPositionData = input<boolean>();
  onlyActiveFacilities = input<boolean>();

  importInput = viewChild<ElementRef>("importInput");

  private importService = inject(ImportService);
  private exportService = inject(ExportService);
  private importHelper = inject(ImportHelper);
  private parseXLSXService = inject(ParseXLSXService);
  private fileHelper = inject(FileHelper);
  private assignmentService = inject(AssignmentService);

  constructor(private facilityService: FacilityService) {
    super(facilityService);
  }

  protected async configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewFacility",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () => this.isInModal(),
      ),
      new SimpleListAction(
        "Import",
        "Import",
        StringUtils.icons.import,
        () => this.importInput().nativeElement.click(),
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleListAction(
        "Export",
        "Export",
        StringUtils.icons.export,
        () => this.export(),
        () =>
          !this.globalState
            .loggedInUser()
            .canEdit(this.globalState.selectedOrganization().friendlyUrl) ||
          this.isInModal(),
      ),
    ]);

    if (this.globalState.loggedInUser().isSuperAdmin()) {
      // Check if has any imports to start monitoring.
      try {
        const inProgress = await this.importService.hasImportInProgress(
          ImportExportModelKey.Facility,
          ImportActionKey.ImportFacilities,
        );
        if (inProgress) {
          this.importService.monitorProgress(
            ImportExportModelKey.Facility,
            ImportActionKey.ImportFacilities,
          );
        }
      } catch (error) {
        this.toastrService.error(error.message);
      }
    }
  }

  protected async configureTableFilter() {
    if (this.onlyFacilitiesWithPositionData() || this.onlyActiveFacilities()) {
      const initialFacets = [];
      if (this.onlyFacilitiesWithPositionData()) {
        initialFacets.push(
          new KeyValuePair(StringUtils.LOCATION_DATA_KEY, StringUtils.YES),
        );
      }
      if (this.onlyActiveFacilities()) {
        initialFacets.push(new KeyValuePair(StringUtils.STATUS_KEY, "true"));
      }
      this.initialFacets.set(initialFacets);
    }

    const locationDataKey = StringUtils.LOCATION_DATA_KEY;
    const accessibleKey = StringUtils.ACCESSIBILITY_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    const templateTypesKey = StringUtils.TEMPLATE_TYPES_KEY;
    const hasAssignmentTemplatesKey = StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY;
    const hasSchedulesKey = StringUtils.HAS_SCHEDULES_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [locationDataKey]: null,
        [accessibleKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
        [statusKey]: null,
        [templateTypesKey]: null,
        [hasAssignmentTemplatesKey]: null,
        [hasSchedulesKey]: null,
      });
    }

    try {
      const [templateTypes] = await Promise.all([
        this.assignmentService.getTemplateTypes(
          this.globalState.selectedOrganization().id,
        ),
      ]);

      const convertedTemplateTypes = templateTypes
        .filter((templateType) => !templateType.isManualMeasure)
        .map(
          (templateType) =>
            new SimpleFilterInputItem(templateType.id, templateType.name),
        );

      this.filterInputs.set([
        new SimpleFilterInput(
          accessibleKey,
          accessibleKey,
          StringUtils.icons.accessibility,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(accessibleKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(accessibleKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          locationDataKey,
          locationDataKey,
          StringUtils.icons.locationData,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(locationDataKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(locationDataKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          templateTypesKey,
          templateTypesKey,
          StringUtils.icons.templateType,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(templateTypesKey, ""));
            }
            return keyValuePairs;
          },
          convertedTemplateTypes,
        ),
        new SimpleFilterInput(
          hasAssignmentTemplatesKey,
          hasAssignmentTemplatesKey,
          StringUtils.icons.assignmentTemplate,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(
                new KeyValuePair(hasAssignmentTemplatesKey, values),
              );
            } else {
              keyValuePairs.push(
                new KeyValuePair(hasAssignmentTemplatesKey, ""),
              );
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          hasSchedulesKey,
          hasSchedulesKey,
          StringUtils.icons.schedule,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasSchedulesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasSchedulesKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          createdKey,
          createdKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(createdKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(createdKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          updatedKey,
          updatedKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(updatedKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(updatedKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.ACTIVE, StringUtils.ACTIVE),
            new SimpleFilterInputItem(
              StringUtils.INACTIVE,
              StringUtils.INACTIVE,
            ),
          ],
          () => this.onlyActiveFacilities(),
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.name, "Name", true),
      new SortObject(this.propertyStrings.description, "Description"),
      new SortObject(this.propertyStrings.streetName, "StreetAddress"),
      new SortObject(this.propertyStrings.city, "MailingAddress"),
      new SortObject(this.propertyStrings.accessible, "Accessibility"),
      new SortObject(this.propertyStrings.hasPosition, "LocationData"),
      new SortObject(this.propertyStrings.extraInfo, "ExtraInfo"),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
      new SortObject(this.propertyStrings.status, "Status"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableFacilityColumn(
        ["Name", "Description"],
        (row) => new FacilityContent(row),
      ),
      new SimpleTableTextColumn(
        ["StreetAddress", "MailingAddress"],
        (row) =>
          new TextContent(
            `${row.streetName} ${row.streetNumber}`,
            `${row.zipCode} ${row.city}`,
          ),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.accessible ? StringUtils.icons.accessibility : "",
              "Accessibility",
              "AccessibilityTextLocation",
              true,
            ),
            new IconObject(
              !!row.latitude && !!row.longitude
                ? StringUtils.icons.locationData
                : "",
              "LocationData",
              `Lat: ${row.latitude}<BR>Lon: ${row.longitude}<BR>Rad: ${row.radius}`,
            ),
            new IconObject(
              row.extraInfo ? StringUtils.icons.extraInfo : "",
              "ExtraInfo",
              row.extraInfo,
            ),
          ]),
      ),
      new SimpleTableCountColumn(
        ["Connections"],
        (row) =>
          new CountContent([
            new CountObject(
              StringUtils.icons.entity,
              row.entities.length,
              "Entities",
            ),
            new CountObject(
              StringUtils.icons.assignmentTemplate,
              row.assignmentTemplates.length,
              "AssignmentTemplates",
            ),
            new CountObject(
              StringUtils.icons.schedule,
              row.schedules.length,
              "Schedules",
            ),
          ]),
      ),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.status ? StringUtils.ACTIVE : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "ChangeAccessibility",
        StringUtils.icons.setAccessibility,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeAccessibility.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "ChangeStatus",
        StringUtils.icons.setStatus,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeStatus.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionView(
        "ViewLocationData",
        StringUtils.icons.locationData,
        (row) => {
          this.locationData.set([row.latitude, row.longitude, row.radius]);
          this.visibleViewLocationData.set(true);
        },
        (row) => !row.latitude || !row.longitude || !row.radius,
      ),
      new SimpleTableRowActionView(
        "ViewExtraInfo",
        StringUtils.icons.extraInfo,
        (row) => {
          this.extraInfo.set(row.extraInfo);
          this.visibleViewContent.set(true);
        },
        (row) => !row.extraInfo,
      ),
      new SimpleTableRowActionView(
        "ViewConnections",
        StringUtils.icons.connections,
        (row) => {
          this.entities.set(row.entities);
          this.assignmentTemplates.set(row.assignmentTemplates);
          this.schedules.set(row.schedules);
          this.visibleViewItems.set(true);
        },
        (row) =>
          !row.entities.length &&
          !row.assignmentTemplates.length &&
          !row.schedules.length,
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Locations", StringUtils.icons.location),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteLocation")
            : this.translationService.instant("DeleteLocations"),
        text: this.translationService.instant("DeleteLocationStatement"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.facilityService.deleteRange(this.itemIds());
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete("Location", "Locations"),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseChangeStatus() {
    this.visibleChangeStatus.set(false);
    this.itemIds.set([]);
  }

  handleCloseChangeAccessibility() {
    this.visibleChangeAccessibility.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneChangeStatus(data: Facility[]) {
    this.handleCloseChangeStatus();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }

  async handleRequestDoneChangeAccessibility(data: Facility[]) {
    this.handleCloseChangeAccessibility();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }

  handleImportInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    if (file) {
      this.import(file);
    }
    input.value = "";
  }

  protected async import(file: File) {
    if (!FileUtils.isExcel(file)) {
      this.toastrService.error(
        this.translationService.instant(StringUtils.BAD_FILE_FORMAT),
      );
    }
    try {
      const inProgress = await this.importService.hasImportInProgress(
        ImportExportModelKey.Facility,
        ImportActionKey.ImportFacilities,
      );
      if (inProgress) {
        this.toastrService.secondary(
          `${this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS)}!`,
        );
      } else {
        let { value: coordinatSystem } = await Swal.fire(
          new SwalConfig(this.translationService).getInfo({
            title: this.translationService.instant("ChooseCoordinateSystem"),
            text: this.translationService.instant(
              "ChooseCoordinateSystemStatement",
            ),
            confirmButtonText: this.translationService.instant("Import"),
            cancelButtonText: this.translationService.instant("Cancel"),
            input: "select",
            inputOptions: {
              doNotConvert: "WGS84",
              ...coordinateSystems,
            },
          }),
        );
        if (coordinatSystem) {
          if (coordinatSystem === "doNotConvert") {
            coordinatSystem = "";
          }
          const parsedData = await this.parseXLSXService.parseFile(file);
          const data = this.importHelper.generateData(
            parsedData,
            ImportExportModelKey.Facility,
            coordinatSystem as string,
          );
          if (!data.length) {
            this.toastrService.error(
              this.translationService.instant(StringUtils.EMPTY_FILE_ERROR),
            );
          }
          const items = await this.importService.import(
            data,
            ImportExportModelKey.Facility,
            ImportActionKey.ImportFacilities,
            this.globalState.selectedOrganization().id,
          );
          if (items.length) {
            this.toastrService.success(
              this.translationService.instant(StringUtils.IMPORT_SUCCEEDED),
            );
            this.handleRequestDoneCreateEdit(items);
          }
        }
      }
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  private async export() {
    this.pending.set(true);
    try {
      const fileData = await this.exportService.export(
        this.selectedIds(),
        ImportExportModelKey.Facility,
        this.globalState.selectedOrganization().id,
      );
      this.pending.set(false);
      this.fileHelper.handleFile(fileData);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
}
