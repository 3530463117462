<div
  class="app-container"
  [ngClass]="{
    blurred: hasOpenModals,
  }"
>
  <div class="app-header">
    @if (globalState.loggedInUser() && globalState.selectedOrganization()) {
      <div
        class="collapse-button-mobile bi-list"
        [title]="'ToggleMenu' | translate"
        (click)="toggleMenuMobile()"
      ></div>
    }
    <div class="logotype" [title]="'Inquinova'"></div>
    @if (globalState.loggedInUser() && globalState.selectedOrganization()) {
      <!-- <important-message></important-message> -->
      <div class="actions-wrapper">
        <div class="actions">
          <feedback></feedback>
          <notification-messages></notification-messages>
        </div>
        <div
          class="current-user"
          [ngClass]="{
            'is-active': routeIsActive(routesUtils.profile),
            'is-disabled': routeIsDisabled(routesUtils.profile),
          }"
          [title]="'NavigateToProfile' | translate"
          (click)="navigateToProfile()"
        >
          <div class="user-info">
            <div
              class="full-name"
              title="{{ globalState.loggedInUser().firstname }} {{
                globalState.loggedInUser().lastname
              }}"
            >
              {{ globalState.loggedInUser().firstname }}
              {{ globalState.loggedInUser().lastname }}
            </div>
            <div class="email" title="{{ globalState.loggedInUser().email }}">
              {{ globalState.loggedInUser().email }}
            </div>
          </div>
          <div class="user-image">
            <extensive-image
              [imageUrl]="globalState.loggedInUser().profileImageUrl"
              [imageStorageSize]="32"
              [rounded]="true"
              [placeholderImageType]="'user'"
            >
            </extensive-image>
          </div>
        </div>
      </div>
    }
  </div>
  <div class="app-body">
    @if (globalState.loggedInUser() && globalState.selectedOrganization()) {
      <side-menu [(menuOpenedMobile)]="menuOpenedMobile"></side-menu>
      <div class="main-content">
        <div class="main-content-header"></div>
        <div class="main-content-body">
          <router-outlet></router-outlet>
          <p-toast />
        </div>
      </div>
    } @else {
      @if (!!tokenStorage.getAccessData()) {
        <dots-loader></dots-loader>
      } @else {
        <router-outlet></router-outlet>
      }
    }
  </div>
</div>
