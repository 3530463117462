<div class="choice-wrapper">
  <simple-choices
    [choices]="value().choices"
    [editable]="value().editable"
    [shouldStopPropagation]="value().editable"
    [hideRules]="value().hideRules"
    (onChoiceClick)="handleChoiceClick($event)"
    (onAddClick)="handleAddClick()"
  ></simple-choices>
</div>
