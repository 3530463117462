import { NgTemplateOutlet } from "@angular/common";
import { Component, ElementRef, inject, input, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  ChoiceContent,
  SimpleTableChoiceColumn,
} from "app/components/simple-components/list/table/columns/simple-table-choice-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import {
  SimpleTableTaskColumn,
  TaskContent,
} from "app/components/simple-components/list/table/columns/simple-table-task-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { KeyValuePair } from "app/filter";
import { ExportService } from "app/services/export.service";
import {
  ImportActionKey,
  ImportExportModelKey,
  ImportHelper,
} from "app/services/import-helper";
import { ImportService } from "app/services/import.service";
import { ParseXLSXService } from "app/services/parse-xlsx.service";
import { SwalConfig } from "app/swal/swal-config.component";
import { FileHelper } from "app/tools/file-helper";
import { ACCEPTED_MEDIA_TYPES, FileUtils } from "app/tools/file-utils";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import { SimpleTableHeaderActionDelete } from "../../../../app/components/simple-components/list/table/head/simple-table-header-action";
import { TaskFilter, TaskService } from "../../../services/task.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { CreateEditTaskComponent } from "../create-edit-task/create-edit-task.component";
import { Task } from "../task";

@Component({
  selector: "list-task",
  templateUrl: "./list-task.component.html",
  standalone: true,
  imports: [
    CreateEditTaskComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    NgTemplateOutlet,
    ListHeaderComponent,
  ],
})
export class ListTaskComponent extends ListBaseDirective<Task> {
  readonly filterObject = new TaskFilter();
  acceptedMediaTypesForImport = ACCEPTED_MEDIA_TYPES.SPREADSHEET;

  includeChoices = input<boolean>();
  templateTypeId = input<string>();

  importInput = viewChild<ElementRef>("importInput");

  private importService = inject<ImportService<Task>>(ImportService);
  private exportService = inject(ExportService);
  private importHelper = inject(ImportHelper);
  private parseXLSXService = inject(ParseXLSXService);
  private fileHelper = inject(FileHelper);

  constructor(private taskService: TaskService) {
    super(taskService);
  }

  protected async configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewTask",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () => this.isInModal(),
      ),
      new SimpleListAction(
        "Import",
        "Import",
        StringUtils.icons.import,
        () => this.importInput().nativeElement.click(),
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
      new SimpleListAction(
        "Export",
        "Export",
        StringUtils.icons.export,
        () => this.export(),
        () =>
          !this.globalState.loggedInUser().isSuperAdmin() || this.isInModal(),
      ),
    ]);

    if (this.globalState.loggedInUser().isSuperAdmin()) {
      // Check if has any imports to start monitoring.
      try {
        const inProgress = await this.importService.hasImportInProgress(
          ImportExportModelKey.Task,
          ImportActionKey.ImportTasks,
        );
        if (inProgress) {
          this.importService.monitorProgress(
            ImportExportModelKey.Task,
            ImportActionKey.ImportTasks,
          );
        }
      } catch (error) {
        this.toastrService.error(error.message);
      }
    }
  }

  protected async configureTableFilter() {
    if (this.includeChoices()) {
      this.filterObject.includeChoices = this.includeChoices();
    }
    if (this.templateTypeId()) {
      this.initialFacets.set([
        new KeyValuePair(
          StringUtils.TEMPLATE_TYPE_ID_KEY,
          this.templateTypeId(),
        ),
      ]);
    }

    const rulesKey = StringUtils.RULES_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [rulesKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
      });
    }

    this.filterInputs.set([
      new SimpleFilterInput(
        rulesKey,
        rulesKey,
        StringUtils.icons.rules,
        SimpleFilterInputType.MultiSelect,
        (values: string) => {
          const keyValuePairs: KeyValuePair[] = [];
          if (values) {
            keyValuePairs.push(new KeyValuePair(rulesKey, values));
          } else {
            keyValuePairs.push(new KeyValuePair(rulesKey, ""));
          }
          return keyValuePairs;
        },
        [
          new SimpleFilterInputItem(
            StringUtils.IS_REQUIRED,
            StringUtils.IS_REQUIRED,
          ),
          new SimpleFilterInputItem(
            StringUtils.ALLOWS_COMMENT,
            StringUtils.ALLOWS_COMMENT,
          ),
          new SimpleFilterInputItem(
            StringUtils.ALLOWS_MEDIA,
            StringUtils.ALLOWS_MEDIA,
          ),
          new SimpleFilterInputItem(
            StringUtils.IS_DEFAULT,
            StringUtils.IS_DEFAULT,
          ),
        ],
      ),
      new SimpleFilterInput(
        createdKey,
        createdKey,
        StringUtils.icons.date,
        SimpleFilterInputType.DateSelect,
        (values: string) => {
          const keyValuePairs: KeyValuePair[] = [];
          if (values) {
            keyValuePairs.push(new KeyValuePair(createdKey, values));
          } else {
            keyValuePairs.push(new KeyValuePair(createdKey, ""));
          }
          return keyValuePairs;
        },
      ),
      new SimpleFilterInput(
        updatedKey,
        updatedKey,
        StringUtils.icons.date,
        SimpleFilterInputType.DateSelect,
        (values: string) => {
          const keyValuePairs: KeyValuePair[] = [];
          if (values) {
            keyValuePairs.push(new KeyValuePair(updatedKey, values));
          } else {
            keyValuePairs.push(new KeyValuePair(updatedKey, ""));
          }
          return keyValuePairs;
        },
      ),
    ]);
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.text, "Name", true),
      new SortObject(this.propertyStrings.code, "Code"),
      new SortObject(this.propertyStrings.description, "Description"),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTaskColumn(
        ["Name", "Code"],
        (row) => new TaskContent(row),
      ),
      new SimpleTableTextColumn(
        ["Description"],
        (row) => new TextContent(row.description),
      ),
      new SimpleTableRuleColumn(["Rules"], (row) => new RuleContent(row.rules)),
      new SimpleTableChoiceColumn(
        ["Choices"],
        (row) => new ChoiceContent(row.choices),
        null,
        null,
        () => !this.includeChoices(),
      ),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Tasks", StringUtils.icons.task),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteTask")
            : this.translationService.instant("DeleteTasks"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.taskService.deleteRange(this.itemIds());
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete("Task", "Tasks"),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleImportInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    if (file) {
      this.import(file);
    }
    input.value = "";
  }

  private async import(file: File) {
    if (!FileUtils.isExcel(file)) {
      this.toastrService.error(
        this.translationService.instant(StringUtils.BAD_FILE_FORMAT),
      );
    }
    try {
      const inProgress = await this.importService.hasImportInProgress(
        ImportExportModelKey.Task,
        ImportActionKey.ImportTasks,
      );
      if (inProgress) {
        this.toastrService.secondary(
          `${this.translationService.instant(StringUtils.IMPORT_IN_PROGRESS)}!`,
        );
      } else {
        const parsedData = await this.parseXLSXService.parseFile(file);
        const data = this.importHelper.generateData(
          parsedData,
          ImportExportModelKey.Task,
        );
        if (!data.length) {
          this.toastrService.error(
            this.translationService.instant(StringUtils.EMPTY_FILE_ERROR),
          );
        }
        const items = await this.importService.import(
          data,
          ImportExportModelKey.Task,
          ImportActionKey.ImportTasks,
          this.globalState.selectedOrganization().id,
        );
        if (items.length) {
          this.toastrService.success(
            this.translationService.instant(StringUtils.IMPORT_SUCCEEDED),
          );
          this.handleRequestDoneCreateEdit(items);
        }
      }
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  private async export() {
    this.pending.set(true);
    try {
      const fileData = await this.exportService.export(
        this.selectedIds(),
        ImportExportModelKey.Task,
        this.globalState.selectedOrganization().id,
      );
      this.pending.set(false);
      this.fileHelper.handleFile(fileData);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
}
