import { inject, Injectable } from "@angular/core";
import { NumberUtils } from "app/tools/number-utils";
import { BehaviorSubject, interval, ReplaySubject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserSupportFile } from "../components/user-support/user-support";
import { HostedHttpClientService } from "./hosted-httpclient.service";
import { ToastrService } from "./toastr.service";
import { TranslationService } from "./translation.service";

@Injectable({
  providedIn: "root",
})
export class UserSupportService {
  file = new BehaviorSubject<UserSupportFile>(null);
  fetching = new BehaviorSubject<boolean>(false);

  private subscriptions = new Subscription();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private hostedHttpClient = inject(HostedHttpClientService);
  private translationService = inject(TranslationService);
  protected toastrService = inject(ToastrService);

  async initiateFetch() {
    if (!this.file.value && !this.fetching.value) {
      this.fetchUserSupportFile();
      this.subscriptions.add(
        interval(NumberUtils.USER_SUPPORT_FILE_POLLING_TIME)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(async () => {
            this.fetchUserSupportFile();
          }),
      );
    }
  }

  private async fetchUserSupportFile() {
    this.fetching.next(true);
    try {
      const file = await this.hostedHttpClient
        .fetch("https://inquinova.com/admin.index.json")
        .then(
          (res) =>
            new UserSupportFile(res, this.translationService.currentLang),
        );

      this.fetching.next(false);
      this.file.next(file);
    } catch {
      this.fetching.next(false);
      this.toastrService.secondary(
        this.translationService.instant("TheUserSupportIsCurrentlyUnavailable"),
      );
    }

    // https://inquinova.com/admin.index.json
    // ../assets/admin-local.index.json // Use when testing, if index file in production is empty or broken
  }
}
