<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      @if (canChangeDetails()) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "General" | translate }}
          </div>
          @if (isEdit || !isInvite()) {
            <simple-form-input
              [label]="'Firstname' | translate"
              [name]="propertyStrings.firstname"
              [form]="form()"
              [maxlength]="25"
              [serverErrors]="serverErrors()"
              [focusOnInit]="!isEdit && !isInvite"
            >
            </simple-form-input>

            <simple-form-input
              [label]="'Lastname' | translate"
              [name]="propertyStrings.lastname"
              [form]="form()"
              [maxlength]="25"
              [serverErrors]="serverErrors()"
            >
            </simple-form-input>
          }

          <simple-form-input
            [label]="'EmailAddress' | translate"
            [name]="propertyStrings.email"
            [form]="form()"
            [serverErrors]="serverErrors()"
            [focusOnInit]="!isEdit && isInvite()"
          >
          </simple-form-input>

          @if (isEdit) {
            <div class="form-control-wrapper">
              <simple-form-select
                class="fixed-width"
                [label]="'CountryCode' | translate"
                [name]="propertyStrings.contactCountryCode"
                [form]="form()"
                [items]="selectableCountryCodeItems()"
                [serverErrors]="serverErrors()"
              >
              </simple-form-select>

              <simple-form-input
                class="stretch"
                [label]="'PhoneNumber' | translate"
                [name]="propertyStrings.contactPhoneNumber"
                [form]="form()"
                [serverErrors]="serverErrors()"
              >
              </simple-form-input>
            </div>

            <simple-form-select
              [label]="'Country' | translate"
              [name]="propertyStrings.country"
              [form]="form()"
              [items]="selectableCountryItems()"
              [searchable]="true"
              [serverErrors]="serverErrors()"
            >
            </simple-form-select>

            <simple-form-select
              [label]="'Language' | translate"
              [name]="propertyStrings.culture"
              [form]="form()"
              [items]="selectableCultureItems()"
              [serverErrors]="serverErrors()"
            >
            </simple-form-select>

            <simple-form-select
              [label]="'Timezone' | translate"
              [name]="propertyStrings.timeZone"
              [form]="form()"
              [items]="selectableTimeZoneItems()"
              [searchable]="true"
              [serverErrors]="serverErrors()"
            >
            </simple-form-select>
          }
        </div>
      }

      @if (isEdit && canChangeDetails()) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "Certificate" | translate }}
          </div>
          <simple-form-input
            [label]="'CertificateNumber' | translate"
            [name]="propertyStrings.certificates"
            [form]="form()"
            [maxlength]="50"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>
          <div class="info-text-wrapper place-end">
            <simple-form-info
              [firstText]="'*' + ('CertificateInfoText' | translate)"
            >
            </simple-form-info>
          </div>
          documents
          <media-widget
            [collection]="documents()"
            [onlyPdfUpload]="true"
            [useHeaderInBottom]="true"
            (onCollectionChange)="handleMediaCollectionChange($event)"
          >
          </media-widget>
        </div>
      }

      @if (canChangeAuthorization()) {
        <div class="details-section">
          <div class="details-section-header">
            {{ "Authorization" | translate }}
          </div>
          <simple-form-select
            [label]="'Roles' | translate"
            [name]="propertyStrings.rolesTemp"
            [form]="form()"
            [items]="selectableRoleItems()"
            [serverErrors]="serverErrors()"
            [multiple]="true"
          >
          </simple-form-select>

          @if (isEdit) {
            <simple-form-select
              [label]="'Status' | translate"
              [name]="propertyStrings.statusTemp"
              [form]="form()"
              [items]="selectableStatusItems()"
              [redBackground]="
                modifiedModel().statusTemp === stringUtils.INACTIVE
              "
              [greenBackground]="
                modifiedModel().statusTemp === stringUtils.ACTIVE
              "
              [serverErrors]="serverErrors()"
            >
            </simple-form-select>
          }
        </div>
      }

      @if (!isEdit && !isInvite()) {
        <div class="details-section">
          <simple-form-input
            [label]="'Password' | translate"
            [name]="propertyStrings.password"
            [form]="form()"
            [serverErrors]="serverErrors()"
            [usePasswordType]="true"
          >
          </simple-form-input>

          <simple-form-input
            [label]="'ConfirmPassword' | translate"
            [name]="propertyStrings.confirmPassword"
            [form]="form()"
            [serverErrors]="serverErrors()"
            [usePasswordType]="true"
          >
          </simple-form-input>
        </div>
      }
    </div>
  </div>
</simple-details-tab>
