import { Component, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Schedule } from "app/components/schedule/schedule";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { ScheduleService } from "app/services/schedule.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { HandleScheduleGroupsTabComponent } from "../../create-edit-schedule/tabs/handle-schedule-groups/handle-schedule-groups-tab.component";
import { HandleScheduleUsersTabComponent } from "../../create-edit-schedule/tabs/handle-schedule-users/handle-schedule-users-tab.component";

@Component({
  selector: "handle-schedule-groups-and-schedule-users",
  templateUrl: "./handle-schedule-groups-and-schedule-users.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    HandleScheduleGroupsTabComponent,
    HandleScheduleUsersTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class HandleScheduleGroupsAndScheduleUsersComponent extends CreateEditBaseDirective<Schedule> {
  private scheduleService = inject(ScheduleService);

  async initiate() {
    this.pending.set(true);
    try {
      const schedules =
        await this.scheduleService.getScheduleGroupsAndScheduleUsers(
          this.itemIds(),
          this.globalState.selectedOrganization().id,
        );
      this.pending.set(false);

      const allScheduleGroups = schedules.flatMap(
        (item) => item.scheduleGroups,
      );
      const allScheduleUsers = schedules.flatMap((item) => item.scheduleUsers);
      const allUniqueScheduleGroups = [];
      const allUniqueScheduleUsers = [];

      const map = new Map();
      for (const item of allScheduleGroups) {
        if (!map.has(item.userGroup.id)) {
          map.set(item.userGroup.id, true);
          allUniqueScheduleGroups.push(item);
        }
      }
      for (const item of allScheduleUsers) {
        if (!map.has(item.user.id)) {
          map.set(item.user.id, true);
          allUniqueScheduleUsers.push(item);
        }
      }

      this.initialModel.set(
        new Schedule({
          scheduleGroups: allUniqueScheduleGroups,
          scheduleUsers: allUniqueScheduleUsers,
        }),
      );
      this.modifiedModel.set(
        new Schedule(Utils.getUniqueVariant(this.initialModel())),
      );
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data =
        await this.scheduleService.updateScheduleGroupsAndScheduleUsers(
          this.itemIds(),
          this.modifiedModel().scheduleGroups,
          this.modifiedModel().scheduleUsers,
        );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("Schedule", "Schedules"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }
}
