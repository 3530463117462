import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment, Result } from "app/components/assignment/assignment";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { SimpleStatusLabelComponent } from "app/components/simple-components/various/status-label/simple-status-label.component";
import { DatexPipe } from "../../../../../../../pipes/datex.pipe";
import { SimpleAssessmentComponent } from "../../../../../../simple-components/various/assessments/simple-assessment.component";
import { SimpleEntityComponent } from "../../../../../../simple-components/various/entity/simple-entity.component";
import { SimpleFacilityComponent } from "../../../../../../simple-components/various/facility/simple-facility.component";
import { SimpleUserComponent } from "../../../../../../simple-components/various/user/simple-user.component";

@Component({
  selector: "details-assignment-tab",
  templateUrl: "./details-assignment-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleStatusLabelComponent,
    TranslateModule,
    SimpleAssessmentComponent,
    DatexPipe,
    SimpleUserComponent,
    SimpleFacilityComponent,
    SimpleEntityComponent,
  ],
})
export class DetailsAssignmentTabComponent extends DetailsBaseDirective<Assignment> {
  resolvementResult = signal<Result>(null);

  ngOnInit() {
    this.resolvementResult.set(
      this.modifiedModel().entities.length
        ? this.modifiedModel().entities[0].results[0]
        : this.modifiedModel().facility.results[0],
    );
  }

  getReport() {
    //TODO
    // this.bsModalRef = this.modalService.show(StaticReportComponent, {
    //   initialState: {
    //     editModelIds: [this.viewModel.id],
    //     disableTabIndexUrl: true,
    //     useAffectableIds: true,
    //     hideText: true,
    //     isMeasure: this.viewModel.isMeasureAssignment(),
    //   },
    //   ...ConfigUtils.MODAL_CONFIG_LARGE,
    // });
    // this.subscribeToCrudModalContent();
  }
}
