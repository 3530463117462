<div class="media-widget">
  @if (!viewOnly() && !useHeaderInBottom()) {
    <div class="media-widget-heading">
      <input
        #fileInput
        type="file"
        class="u-display-none"
        [multiple]="allowMultipleUpload()"
        [accept]="acceptedMediaTypes()"
        (change)="handleFileChange($event)"
      />

      @if (infoText()) {
        <div class="info-text" [title]="infoText()">*{{ infoText() }}</div>
      }

      @if (!hideAddButton()) {
        <button
          type="button"
          class="btn btn-transparent w-border u-uppercase"
          title="{{ 'AddMedia' | translate }}"
          [disabled]="disabled()"
          (click)="disabled() ? null : fileInput.click()"
        >
          {{ "AddMedia" | translate }}
        </button>
      }
    </div>
  }

  <div
    id="{{ mediaWidgetBodyId }}"
    class="media-widget-body apx-scrollbar scroll-shadow"
    [ngClass]="{
      disabled: disabled(),
    }"
    (dragover)="handleDragOver($event)"
    (dragleave)="handleDragLeave($event)"
    (drop)="handleFileDrop($event)"
  >
    @if (collection().length) {
      <div class="ml-list">
        @for (item of collection(); track $index) {
          <media-widget-item
            #widgetItem
            [item]="item"
            [canToggleDefault]="canToggleDefault()"
            [viewOnly]="viewOnly() || disabled()"
            [itemSize]="itemSize()"
            (onOpen)="currentItem.set($event); visibleMediaViewer.set(true)"
            (onToggleDefault)="toggleDefault($event)"
            (onDownload)="downloadItem($event)"
            (onRemove)="removeItem($event)"
          >
          </media-widget-item>
        }
      </div>
    }

    @if (!collection().length) {
      <div class="ml-empty">
        {{
          modifiedDummyText()
            ? modifiedDummyText()
            : ("NoMediaAdded" | translate)
        }}
      </div>
    }

    <div #dropHelper class="filedrop-helper">
      <img width="68" height="68" src="{{ media.MISC_UPLOAD }}" />
    </div>
  </div>

  @if (!viewOnly() && useHeaderInBottom()) {
    <div class="media-widget-heading bottom">
      <input
        #fileInput
        type="file"
        class="u-display-none"
        [multiple]="allowMultipleUpload()"
        [accept]="acceptedMediaTypes()"
        (change)="handleFileChange($event)"
      />

      @if (infoText()) {
        <div class="info-text" [title]="infoText()">*{{ infoText() }}</div>
      }

      @if (!hideAddButton()) {
        <button
          type="button"
          class="btn btn-transparent w-border u-uppercase"
          title="{{ 'AddMedia' | translate }}"
          [disabled]="disabled()"
          (click)="disabled() ? null : fileInput.click()"
        >
          {{ "AddMedia" | translate }}
        </button>
      }
    </div>
  }
  <media-widget-item-viewer
    [visible]="visibleMediaViewer()"
    [item]="currentItem()"
    [collection]="collection()"
    (onClose)="visibleMediaViewer.set(false); currentItem.set(null)"
  >
  </media-widget-item-viewer>
</div>
