import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsPriorityAssignmentTabComponent } from "./tabs/details/details-priority-assignment-tab.component";

@Component({
  selector: "change-priority",
  templateUrl: "./change-priority.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsPriorityAssignmentTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangePriorityComponent extends CreateEditBaseDirective<MeasureAssignment> {
  affectableIds = signal<string[]>([]);

  detailsPriorityAssignmentTabComponent = viewChild(
    DetailsPriorityAssignmentTabComponent,
  );

  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.initialModel.set(new MeasureAssignment({}));
    this.modifiedModel.set(
      new MeasureAssignment(Utils.getUniqueVariant(this.initialModel())),
    );
    this.getPriorityData(this.modifiedModel().priority);
  }

  private async getPriorityData(value: boolean) {
    this.pending.set(true);
    try {
      const data = await this.assignmentService.getPriorityData(
        this.itemIds(),
        value,
        this.globalState.selectedOrganization().id,
      );
      this.affectableIds.set(data);
      this.pending.set(false);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.assignmentService.updatePriority(
        this.affectableIds(),
        this.modifiedModel().priority,
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("MeasureAssignment", "MeasureAssignments"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsPriorityAssignmentTabComponent().setServerErrors(
        error.errors,
      );
    }
  }

  handleDetailsChangeExtra() {
    if (this.detailsPriorityAssignmentTabComponent()) {
      const priorityCtrl = this.detailsPriorityAssignmentTabComponent()
        .form()
        .get(this.propertyStrings.priority);
      this.getPriorityData(priorityCtrl.value);
    }
  }
}
