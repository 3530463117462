import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { HandleMediaTabComponent } from "app/media/handle-media/handle-media-tab.component";
import { MediaWidgetItem } from "app/media/widget/item/media-widget-item";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { FacilityService } from "../../../services/facility.service";
import { Facility } from "../facility";
import { DetailsFacilityTabComponent } from "./tabs/details/details-facility-tab.component";
import { HandleEntitiesTabComponent } from "./tabs/handle-entities/handle-entities-tab.component";

@Component({
  selector: "create-edit-facility",
  templateUrl: "./create-edit-facility.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsFacilityTabComponent,
    HandleEntitiesTabComponent,
    HandleMediaTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditFacilityComponent extends CreateEditBaseDirective<Facility> {
  private detailsFacilityTabComponent = viewChild(DetailsFacilityTabComponent);

  private facilityService = inject(FacilityService);

  async initiate() {
    if (this.isEdit) {
      this.pending.set(true);
      try {
        const data = await this.facilityService.get(this.itemIds()[0]);

        this.initialModel.set(data);
        this.pending.set(false);

        this.modifiedModel.set(
          new Facility(Utils.getUniqueVariant(this.initialModel())),
        );
      } catch (error) {
        this.pending.set(false);
        this.toastrService.error(error.message);
        this.handleClose();
      }
    } else {
      this.initialModel.set(new Facility({}));
      this.modifiedModel.set(
        new Facility(Utils.getUniqueVariant(this.initialModel())),
      );
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.facilityService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.facilityService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );
      const uploadMediaPromises: Promise<any>[] = [];
      const existingMedia: MediaWidgetItem[] = [];
      this.modifiedModel().media.forEach((item) => {
        if (item.blob) {
          const uploadMediaPromise = this.facilityService.uploadMedia(
            data.id,
            item,
          );
          uploadMediaPromises.push(uploadMediaPromise);
        } else {
          existingMedia.push(item);
        }
      });
      if (this.isEdit) {
        const uploadMediaResponses = await Promise.all(uploadMediaPromises);

        const uploadedMedia = uploadMediaResponses.map((obj) => {
          const data = JSON.parse(obj["response"]).data;
          return new MediaWidgetItem(data);
        });
        const defaultIsAmongUploaded = uploadedMedia.some(
          (item) => item.default,
        );
        if (defaultIsAmongUploaded) {
          existingMedia.forEach((item) => (item.default = false));
        }
        const allMedia = existingMedia.concat(uploadedMedia);
        await this.facilityService.assignMedia(data.id, allMedia);
        this.toastrService.info(this.getSuccessMessage("Location"));
        this.onRequestDone.emit([data]);
      } else {
        await Promise.all(uploadMediaPromises);
        this.toastrService.success(this.getSuccessMessage("Location"));
        this.onRequestDone.emit([data]);
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsFacilityTabComponent().setServerErrors(error.errors);
    }
  }
  // TODO ta reda på hur kan uppdatera entities men alltid automatiskt uppdatera uppdragsmallar!
  // Fråga Henke!
  //     const entityIds = this.modifiedModel().entities.map(
  //       (entity) => entity.id,
  //     );

  //     try {
  //       if (this.isEdit && this.hasAddedEntity()) {
  //         const assignmentTemplates =
  //           await this.facilityService.getAssignmentTemplatesWithEntities(
  //             facilityId,
  //           );

  //         if (assignmentTemplates && assignmentTemplates.length) {
  //           // this.bsModalRef = this.modalService.show( TODO
  //           //   UpdateAssignmentTemplateWithEntitiesComponent,
  //           //   {
  //           //     initialState: {
  //           //       assignmentTemplatesToUpdate: assignmentTemplates,
  //           //     },
  //           //     ...ConfigUtils.MODAL_CONFIG_LARGE,
  //           //   }
  //           // );
  //           // const assTempWithEntities = this.bsModalRef
  //           //   .content as UpdateAssignmentTemplateWithEntitiesComponent;
  //           // this.subscriptions.add(
  //           //   assTempWithEntities.onUpdate
  //           //     .pipe(takeUntil(this.destroyed$))
  //           //     .subscribe(async (updatedItems) => {
  //           //       this.closeModal();
  //           //       try {
  //           //         const assigmentTemplateIds = updatedItems.map((at) => at.id);
  //           //         this.updateEntitiesAndHandleResponse(
  //           //           facilityId,
  //           //           entityIds,
  //           //           assigmentTemplateIds,
  //           //           this.entityIdsToRemove,
  //           //           originalResponse
  //           //         );
  //           //       } catch (errorResponse) {
  //           //         this.pending = false;
  //           //         this.handleErrorResponse(errorResponse);
  //           //       }
  //           //     })
  //           // );
  //         } else {
  //           try {
  //             const response = await this.facilityService.updateEntities(
  //               facilityId,
  //               entityIds,
  //               assigmentTemplateIds,
  //               entityIdsToRemove,
  //             );
  //             //this.responseService.displaySuccessMessage(response.successMessage);
  //             // const data = this.responseService.handleSuccessResponse(originalResponse);
  //             // this.onRequestDone.emit(data);
  //           } catch (errorResponse) {
  //             this.pending.set(false);
  //             // this.responseService.handleErrorResponse(errorResponse);
  //             // this.responseService.handleSuccessResponse(originalResponse);
  //           }
  //         }
  //       } else {
  //         try {
  //           const response = await this.facilityService.updateEntities(
  //             facilityId,
  //             entityIds,
  //             assigmentTemplateIds,
  //             entityIdsToRemove,
  //           );
  //           //this.responseService.displaySuccessMessage(response.successMessage);
  //           // const data = this.responseService.handleSuccessResponse(originalResponse);
  //           // this.onRequestDone.emit(data);
  //         } catch (errorResponse) {
  //           this.pending.set(false);
  //           // this.responseService.handleErrorResponse(errorResponse);
  //           // this.responseService.handleSuccessResponse(originalResponse);
  //         }
  //       }
  //     } catch (errorResponse) {
  //       this.pending.set(false);
  //       // this.responseService.handleErrorResponse(errorResponse);
  //       // this.responseService.handleSuccessResponse(originalResponse);
  //     }
  //   } catch (errorResponse) {
  //     this.pending.set(false);
  //     //this.responseService.handleErrorResponse(errorResponse);

  //     // if (this.serverErrors.length) { TODO
  //     //   this.changeFacilityDetailsTabComponent.setServerErrors(
  //     //     this.serverErrors
  //     //   );
  //     //   this.goToTab(1);
  //     // }
  //   }
  // }

  handleDetailsChangeExtra() {
    if (this.detailsFacilityTabComponent()) {
      const latCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.latitude);
      const longCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.longitude);
      const radiusCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.radius);

      if (
        latCtrl.value &&
        latCtrl.valid &&
        longCtrl.value &&
        longCtrl.valid &&
        radiusCtrl.value &&
        radiusCtrl.valid
      ) {
        this.refreshMap(
          latCtrl.value.replace(",", "."),
          longCtrl.value.replace(",", "."),
          radiusCtrl.value,
        );
      }

      const streetNameCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.streetName);
      const streetNumberCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.streetNumber);
      const zipCodeCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.zipCode);
      const cityCtrl = this.detailsFacilityTabComponent()
        .form()
        .get(this.propertyStrings.city);

      if (
        streetNameCtrl.value &&
        streetNameCtrl.valid &&
        streetNumberCtrl.value &&
        streetNumberCtrl.valid &&
        zipCodeCtrl.value &&
        zipCodeCtrl.valid &&
        cityCtrl.value &&
        cityCtrl.valid
      ) {
        this.refreshAddress(
          streetNameCtrl.value,
          streetNumberCtrl.value,
          zipCodeCtrl.value,
          cityCtrl.value,
        );
      }
    }
  }

  private refreshMap(lat: string, long: string, radius: string) {
    const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(long));

    this.detailsFacilityTabComponent().googleMapsComponent().radius = radius;
    this.detailsFacilityTabComponent()
      .googleMapsComponent()
      .updateLocation(latLng);
    this.detailsFacilityTabComponent().googleMapsComponent().displayMarker();
    this.detailsFacilityTabComponent().googleMapsComponent().determineZoom();
  }

  private refreshAddress(
    streetName: string,
    streetNumber: string,
    zipCode: string,
    city: string,
  ) {
    this.detailsFacilityTabComponent().buildAddress(
      streetName,
      streetNumber,
      zipCode,
      city,
    );
  }

  private hasAddedEntity() {
    return this.modifiedModel().entities.some(
      (ent) =>
        !this.initialModel().entities.find(
          (existingEnt) => existingEnt.id === ent.id,
        ),
    );
  }
}
