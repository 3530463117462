import { Component, inject, signal, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { User } from "app/components/user/user";
import { UserService } from "app/services/user.service";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsStatusUserTabComponent } from "./tabs/details/details-status-user-tab.component";

@Component({
  selector: "change-status-user",
  templateUrl: "./change-status-user.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    TranslateModule,
    TabsModule,
    DetailsStatusUserTabComponent,
  ],
})
export class ChangeStatusUserComponent extends CreateEditBaseDirective<User> {
  affectableIds = signal<string[]>([]);

  detailsStatusUserTabComponent = viewChild(DetailsStatusUserTabComponent);

  private userService = inject(UserService);

  async initiate() {
    this.initialModel.set(new User({}));
    this.modifiedModel.set(
      new User(Utils.getUniqueVariant(this.initialModel())),
    );
    this.getStatusData(this.modifiedModel().status);
  }

  private async getStatusData(value: string) {
    this.pending.set(true);
    try {
      const status = value === StringUtils.ACTIVE ? true : false;
      const data = await this.userService.getStatusData(
        this.itemIds(),
        status,
        this.globalState.selectedOrganization().id,
      );
      this.affectableIds.set(data);
      this.pending.set(false);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const status =
        this.modifiedModel().status === StringUtils.ACTIVE ? true : false;
      const data = await this.userService.updateStatusWithOrgId(
        this.affectableIds(),
        status,
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("User", "Users"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsStatusUserTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.detailsStatusUserTabComponent()) {
      const statusCtrl = this.detailsStatusUserTabComponent()
        .form()
        .get(this.propertyStrings.status);
      this.getStatusData(statusCtrl.value);
    }
  }
}
