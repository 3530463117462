import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { Client } from "app/components/organization/organization";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentTemplateService } from "app/services/assignment-template.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsClientAssignmentTemplateTabComponent } from "./tabs/details/details-client-assignment-template-tab.component";

@Component({
  selector: "change-client",
  templateUrl: "./change-client.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsClientAssignmentTemplateTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class ChangeClientComponent extends CreateEditBaseDirective<AssignmentTemplate> {
  private detailsClientAssignmentTemplateTabComponent = viewChild(
    DetailsClientAssignmentTemplateTabComponent,
  );

  private assignmentTemplateService = inject(AssignmentTemplateService);

  async initiate() {
    this.pending.set(true);
    try {
      const clients = await this.assignmentTemplateService.getClientsFromItems(
        this.itemIds(),
        this.globalState.selectedOrganization().id,
      );
      const selectableClients = await this.assignmentTemplateService.getClients(
        this.globalState.selectedOrganization().id,
      );
      this.pending.set(false);

      this.initialModel.set(
        new AssignmentTemplate({
          client: clients.length ? new Client(clients[0]) : null,
          selectableClients: selectableClients,
        }),
      );

      this.modifiedModel.set(
        new AssignmentTemplate(Utils.getUniqueVariant(this.initialModel())),
      );
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.assignmentTemplateService.updateClient(
        this.itemIds(),
        this.modifiedModel().clientId,
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("AssignmentTemplate", "AssignmentTemplates"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsClientAssignmentTemplateTabComponent().setServerErrors(
        error.errors,
      );
    }
  }
}
