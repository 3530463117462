import { Choice } from "app/components/choice/choice";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "./simple-table-column";

export class SimpleTableChoiceColumn<T> extends SimpleTableColumn<T> {
  readonly getValue: (item: T) => ChoiceContent;

  constructor(
    labels: string[],
    getValue: (row: T) => ChoiceContent,
    onClickChoice?: (row: T, choice: Choice) => void,
    onClickChoiceAdd?: (row: T) => void,
    hidden?: () => boolean,
  ) {
    super(labels, hidden);
    this.getValue = getValue;
    this.onClickChoice = onClickChoice;
    this.onClickChoiceAdd = onClickChoiceAdd;
    this.type = SimpleTableColumnType.CHOICE;
  }
}

export class ChoiceContent {
  choices: Choice[] = [];
  editable: boolean = false;
  hideRules: boolean = false;

  constructor(choices: Choice[], editable?: boolean, hideRules?: boolean) {
    this.choices = choices;
    this.editable = editable;
    this.hideRules = hideRules;
  }
}
