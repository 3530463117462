import { Component, computed, Signal } from "@angular/core";
import { Choice } from "app/components/choice/choice";
import { SimpleChoicesComponent } from "../../../../../various/choices/simple-choices.component";
import { ChoiceContent } from "../../../columns/simple-table-choice-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-choice",
  templateUrl: "./column-type-choice.component.html",
  standalone: true,
  imports: [SimpleChoicesComponent],
})
export class ColumnTypeChoiceComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<ChoiceContent>;

  handleChoiceClick(choice: Choice) {
    this.column().onClickChoice(this.item(), choice);
  }

  handleAddClick() {
    this.column().onClickChoiceAdd(this.item());
  }
}
