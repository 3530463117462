<simple-landing-page [isLoading]="pending()">
  <div class="header-wrapper" ngProjectAs="landing-page-header-slot">
    <div class="image clickable" (click)="visibleChangeProfileImage.set(true)">
      <extensive-image
        [imageUrl]="loggedInUser().profileImageUrl"
        [imageStorageSize]="65"
        [placeholderImageType]="'user'"
        [displayLoader]="true"
        [rounded]="true"
      >
      </extensive-image>
      <div class="image-overlay bi bi-camera-fill u-rounded"></div>
    </div>
    <div class="details">
      <div class="title" [title]="loggedInUser().fullName">
        {{ loggedInUser().fullName }}
      </div>
      <div class="subtitle" [title]="loggedInUser().email">
        {{ loggedInUser().email }}
      </div>
    </div>
    <div class="actions">
      <div
        class="actions-menu bi-three-dots"
        [ngClass]="{
          opened: actionsOpened(),
        }"
        [title]="'OpenMenu' | translate"
        (click)="actionTemplate.toggle($event)"
      ></div>
      <p-popover
        #actionTemplate
        (onShow)="actionsOpened.set(true)"
        (onHide)="actionsOpened.set(false)"
      >
        <div class="popover-panel-header">
          {{ loggedInUser().fullName }}
        </div>
        <div
          class="standard-menu-item"
          [title]="'EditUser' | translate"
          (click)="visibleEdit.set(true); actionTemplate.toggle($event)"
        >
          <i [class]="icons.edit"></i>{{ "EditUser" | translate }}
        </div>
        <div
          class="standard-menu-item"
          [title]="'ChangePassword' | translate"
          (click)="
            visibleChangePassword.set(true); actionTemplate.toggle($event)
          "
        >
          <i [class]="icons.password"></i>{{ "ChangePassword" | translate }}
        </div>
        <div
          class="standard-menu-item"
          [title]="'GetUserData' | translate"
          (click)="getUserData(); actionTemplate.toggle($event)"
        >
          <i [class]="icons.download"></i>{{ "GetUserData" | translate }}
        </div>
        <div
          class="standard-menu-item"
          [title]="'Signout' | translate"
          (click)="signout(); actionTemplate.toggle($event)"
        >
          <i [class]="'bi-box-arrow-right'"></i>{{ "Signout" | translate }}
        </div>
      </p-popover>
    </div>
  </div>
  <div class="content" ngProjectAs="landing-page-content-slot">
    <view-details-tab
      [modifiedModel]="loggedInUser()"
      [documents]="documents()"
      [schedules]="schedules()"
    >
    </view-details-tab>
  </div>
</simple-landing-page>

<edit-logged-in-user
  [visible]="visibleEdit()"
  [useUnsavedChangesLogic]="true"
  [displayDeleteButton]="false"
  (onClose)="handleCloseEdit()"
  (onRequestDone)="handleRequestDoneEdit($event)"
></edit-logged-in-user>

<change-password
  [visible]="visibleChangePassword()"
  (onClose)="handleCloseChangePassword()"
  (onRequestDone)="handleRequestDoneChangePassword($event)"
></change-password>

<change-profile-image
  [visible]="visibleChangeProfileImage()"
  (onClose)="handleCloseChangeProfileImage()"
  (onRequestDone)="handleRequestDoneChangeProfileImage($event)"
></change-profile-image>
