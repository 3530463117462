import { Component, input } from "@angular/core";
import { Entity } from "app/components/entity/entity";
import { Facility } from "app/components/facility/facility";
import { SimpleImageComponent } from "../image/simple-image.component";

@Component({
  selector: "simple-measure-target",
  templateUrl: "./simple-measure-target.component.html",
  styleUrls: ["./simple-measure-target.component.less"],
  standalone: true,
  imports: [SimpleImageComponent],
})
export class SimpleMeasureTargetComponent {
  facility = input<Facility>();
  entity = input<Entity>();
}
