@for (entity of entities(); track $index) {
  <div class="item">
    <simple-entity [entity]="entity"></simple-entity>
    <simple-link-button
      [url]="entity.url"
      [name]="entity.name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}

@for (group of userGroups(); track $index) {
  <div class="item">
    <simple-link-button
      [url]="group.url"
      [name]="group.name"
      [linkText]="group.name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}

@for (assignmentTemplate of assignmentTemplatesWithButtons(); track $index) {
  <div class="item">
    <simple-assignment-template
      [templateType]="getFakeTemplateType(assignmentTemplate)"
      [facility]="assignmentTemplate.facility"
    ></simple-assignment-template>
    <simple-link-button
      [url]="assignmentTemplate.url"
      [name]="assignmentTemplate.name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}

@for (assignmentTemplate of assignmentTemplatesAsLinks(); track $index) {
  <div class="item">
    <simple-link-button
      [url]="assignmentTemplate.url"
      [name]="assignmentTemplate.name"
      [linkText]="assignmentTemplate.name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}

@for (schedule of schedules(); track $index) {
  <div class="item">
    <simple-link-button
      [url]="schedule.url"
      [name]="schedule.name"
      [linkText]="schedule.name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}
@for (schedule of schedulesForUsers(); track $index) {
  <div class="item">
    <simple-link-button
      [url]="schedule.url"
      [name]="schedule.name"
      [linkText]="schedule.name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
    @if (
      schedule.canPerformScheduled ||
      schedule.canPerformMeasure ||
      schedule.canAssign
    ) {
      <span class="dots"></span>
    }

    <!-- TODO borde använda simple rules... -->
    <div class="icons">
      @if (schedule.canPerformScheduled) {
        <i
          class="ii-assignment green"
          [title]="'CanPerformScheduled' | translate"
        >
        </i>
      }
      @if (schedule.canPerformMeasure) {
        <i class="ii-measure red" [title]="'CanPerformMeasure' | translate">
        </i>
      }
      @if (schedule.canAssign) {
        <i class="bi-people-fill blue" [title]="'CanAssign' | translate"> </i>
      }
    </div>
  </div>
}

@for (user of users(); track $index) {
  <div class="item">
    <simple-user [user]="user"></simple-user>
    <simple-link-button
      [url]="user.url"
      [name]="user.fullName ? user.fullName : user.email"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}

@if (facility()) {
  <div class="item">
    <simple-facility [facility]="facility()"> </simple-facility>
    <simple-link-button
      [url]="facility().url"
      [name]="facility().name"
      [shouldDisable]="disabled()"
    >
    </simple-link-button>
  </div>
}
