import { NgClass } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "simple-rules",
  templateUrl: "./simple-rules.component.html",
  styleUrls: ["./simple-rules.component.less"],
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class SimpleRulesComponent {
  rules = input<RuleObject[]>();
  editable = input<boolean>();
  shouldStopPropagation = input<boolean>();

  onRuleClick = output<RuleObject>();

  handleRuleClick(rule: RuleObject, $event: MouseEvent) {
    if (this.shouldStopPropagation()) {
      $event.stopImmediatePropagation();
    }
    this.onRuleClick.emit(rule);
  }
}

export class RuleObject {
  icon: string = "";
  title: string = "";

  constructor(ruleObject: Partial<RuleObject>) {
    Object.assign(this, ruleObject);
  }
}
