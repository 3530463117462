<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.name"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [(value)]="tabValue" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('AssignmentTemplateChangeDetailsEditHelpText' | translate)
                : ('AssignmentTemplateChangeDetailsHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>
        @if (globalState.loggedInUser().isSuperAdmin()) {
          @if (!isEdit) {
            <p-tab [value]="1">
              <simple-tab-heading
                [icon]="'bi-pin-map-fill'"
                [title]="'Locations' | translate"
                [helpText]="
                  isEdit
                    ? ('AssignmentTemplateHandleLocationsEditHelpText'
                      | translate)
                    : ('AssignmentTemplateHandleLocationsHelpText' | translate)
                "
                [displayCount]="modifiedModel().facilities.length"
              >
              </simple-tab-heading>
            </p-tab>
          }
          <p-tab [value]="2">
            <simple-tab-heading
              [icon]="'ii-task'"
              [title]="'LocationTasks' | translate"
              [helpText]="
                isEdit
                  ? ('AssignmentTemplateHandleLocationTasksEditHelpText'
                    | translate)
                  : ('AssignmentTemplateHandleLocationTasksHelpText'
                    | translate)
              "
              [displayCount]="modifiedModel().facilityTasks.length"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="3">
            <simple-tab-heading
              [icon]="'ii-entities'"
              [title]="'Entities' | translate"
              [helpText]="
                isEdit
                  ? ('AssignmentTemplateHandleEntitiesEditHelpText' | translate)
                  : ('AssignmentTemplateHandleEntitiesHelpText' | translate)
              "
              [displayCount]="modifiedModel().entities.length"
            >
            </simple-tab-heading>
          </p-tab>
          <p-tab [value]="4">
            <simple-tab-heading
              [icon]="'ii-task'"
              [title]="'EntityTasks' | translate"
              [helpText]="
                'AssignmentTemplateHandleEntityTasksHelpText' | translate
              "
              [displayCount]="modifiedModel().entityTasks.length"
            >
            </simple-tab-heading>
          </p-tab>
        }
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-assignment-template-tab
            *tabVisible="tabValue()"
            [modifiedModel]="modifiedModel()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-assignment-template-tab>
        </p-tabpanel>
        @if (globalState.loggedInUser().isSuperAdmin()) {
          @if (!isEdit) {
            <p-tabpanel [value]="1">
              <handle-facilities-tab
                *tabVisible="tabValue()"
                [items]="modifiedModel().facilities"
                [headerSubtitleText]="
                  'AddedLocationsSubtitleAssignmentTemplate' | translate
                "
                [displayOnlyFacilitiesWithPositionDataInList]="
                  modifiedModel().geoControlled
                "
                s
                (onItemsChange)="
                  handleItemsChange(propertyStrings.facilities, $event);
                  handleItemsChangeExtraFacility()
                "
              >
              </handle-facilities-tab>
            </p-tabpanel>
          }
          <p-tabpanel [value]="2">
            <handle-tasks-tab
              *tabVisible="tabValue()"
              [items]="modifiedModel().facilityTasks"
              [headerSubtitleText]="
                'AddedTasksSubtitleAssignmentTemplate' | translate
              "
              [readonlyChoices]="true"
              [includeChoicesInList]="true"
              [templateTypeIdForList]="modifiedModel().templateTypeId"
              [hideAddButton]="!modifiedModel().facilities.length"
              (onItemsChange)="
                handleItemsChange(propertyStrings.facilityTasks, $event)
              "
            >
            </handle-tasks-tab>
          </p-tabpanel>
          <p-tabpanel [value]="3">
            <handle-entities-tab
              *tabVisible="tabValue()"
              [items]="modifiedModel().entities"
              [headerSubtitleText]="
                'AddedEntitiesSubtitleAssignmentTemplate' | translate
              "
              [facilityIds]="facilityIds()"
              [hideFacilityColumn]="isEdit"
              [hideAddButton]="!modifiedModel().facilities.length"
              (onItemsChange)="
                handleItemsChange(propertyStrings.entities, $event);
                handleItemsChangeExtraEntity()
              "
            >
            </handle-entities-tab>
          </p-tabpanel>
          <p-tabpanel [value]="4">
            <handle-tasks-tab
              *tabVisible="tabValue()"
              [items]="modifiedModel().entityTasks"
              [headerSubtitleText]="
                'AddedTasksSubtitleAssignmentTemplate' | translate
              "
              [readonlyChoices]="true"
              [includeChoicesInList]="true"
              [templateTypeIdForList]="modifiedModel().templateTypeId"
              [hideAddButton]="!modifiedModel().entities.length"
              (onItemsChange)="
                handleItemsChange(propertyStrings.entityTasks, $event)
              "
            >
            </handle-tasks-tab>
          </p-tabpanel>
        }
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
