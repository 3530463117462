<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-calendar
          [label]="'DueDate' | translate"
          [name]="propertyStrings.dueDate"
          [form]="form()"
          [minDate]="minDueDate"
          [serverErrors]="serverErrors()"
        >
        </simple-form-calendar>
        <simple-form-time
          [label]="'DueTime' | translate"
          [name]="propertyStrings.dueTime"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-time>
      </div>
    </div>
  </div>
</simple-details-tab>
