import { NgTemplateOutlet } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  ChoiceContent,
  SimpleTableChoiceColumn,
} from "app/components/simple-components/list/table/columns/simple-table-choice-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  RuleContent,
  SimpleTableRuleColumn,
} from "app/components/simple-components/list/table/columns/simple-table-rule-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { SwalConfig } from "app/swal/swal-config.component";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import { SimpleTableHeaderActionDelete } from "../../../../app/components/simple-components/list/table/head/simple-table-header-action";
import { KeyValuePair } from "../../../filter";
import { ChoiceFilter, ChoiceService } from "../../../services/choice.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { Choice } from "../choice";
import { CreateEditChoiceComponent } from "../create-edit-choice/create-edit-choice.component";

@Component({
  selector: "list-choice",
  templateUrl: "./list-choice.component.html",
  standalone: true,
  imports: [
    CreateEditChoiceComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    NgTemplateOutlet,
    ListHeaderComponent,
  ],
})
export class ListChoiceComponent extends ListBaseDirective<Choice> {
  readonly filterObject = new ChoiceFilter();

  constructor(private choiceService: ChoiceService) {
    super(choiceService);
  }

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewChoice",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () => this.isInModal(),
      ),
    ]);
  }

  protected async configureTableFilter() {
    const rulesKey = StringUtils.RULES_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [rulesKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
      });
    }

    this.filterInputs.set([
      new SimpleFilterInput(
        rulesKey,
        rulesKey,
        StringUtils.icons.rules,
        SimpleFilterInputType.MultiSelect,
        (values: string) => {
          const keyValuePairs: KeyValuePair[] = [];
          if (values) {
            keyValuePairs.push(new KeyValuePair(rulesKey, values));
          } else {
            keyValuePairs.push(new KeyValuePair(rulesKey, ""));
          }
          return keyValuePairs;
        },
        [
          new SimpleFilterInputItem(StringUtils.IS_ERROR, StringUtils.IS_ERROR),
          new SimpleFilterInputItem(
            StringUtils.IS_DEFAULT,
            StringUtils.IS_DEFAULT,
          ),
          new SimpleFilterInputItem(
            StringUtils.IS_UNSPECIFIED,
            StringUtils.IS_UNSPECIFIED,
          ),
          new SimpleFilterInputItem(
            StringUtils.ALLOWS_PRIORITY,
            StringUtils.ALLOWS_PRIORITY,
          ),
        ],
      ),
      new SimpleFilterInput(
        createdKey,
        createdKey,
        StringUtils.icons.date,
        SimpleFilterInputType.DateSelect,
        (values: string) => {
          const keyValuePairs: KeyValuePair[] = [];
          if (values) {
            keyValuePairs.push(new KeyValuePair(createdKey, values));
          } else {
            keyValuePairs.push(new KeyValuePair(createdKey, ""));
          }
          return keyValuePairs;
        },
      ),
      new SimpleFilterInput(
        updatedKey,
        updatedKey,
        StringUtils.icons.date,
        SimpleFilterInputType.DateSelect,
        (values: string) => {
          const keyValuePairs: KeyValuePair[] = [];
          if (values) {
            keyValuePairs.push(new KeyValuePair(updatedKey, values));
          } else {
            keyValuePairs.push(new KeyValuePair(updatedKey, ""));
          }
          return keyValuePairs;
        },
      ),
    ]);
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.value, "Name", true),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableChoiceColumn(
        ["Name", "Color"],
        (row) => new ChoiceContent([row], false, true),
      ),
      new SimpleTableRuleColumn(["Rules"], (row) => new RuleContent(row.rules)),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Choices", StringUtils.icons.choice),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteChoice")
            : this.translationService.instant("DeleteChoices"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.choiceService.deleteRange(this.itemIds());
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete("Choice", "Choices"),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }
}
