<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-input
          [label]="'Name' | translate"
          [name]="propertyStrings.name"
          [form]="form()"
          [maxlength]="100"
          [serverErrors]="serverErrors()"
          [focusOnInit]="!isEdit"
        >
        </simple-form-input>

        <simple-form-select
          [label]="'PublishingType' | translate"
          [name]="propertyStrings.publishingType"
          [form]="form()"
          [items]="selectablePublishingTypeItems()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>

        @if (modifiedModel().publishingType !== publishingType.Once) {
          <simple-form-select
            [label]="'Interval' | translate"
            [name]="propertyStrings.interval"
            [form]="form()"
            [items]="selectableIntervalItems()"
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
        }
        @if (modifiedModel().publishingType === publishingType.Weekly) {
          <simple-form-select
            [label]="'Weekdays' | translate"
            [name]="propertyStrings.weekdaysInternal"
            [form]="form()"
            [items]="selectableWeekdayItems()"
            [multiple]="true"
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
        }

        <simple-form-calendar
          [label]="'StartDate' | translate"
          [name]="propertyStrings.startDate"
          [form]="form()"
          [minDate]="minStartDate"
          [maxDate]="maxStartDate"
          [serverErrors]="serverErrors()"
        >
        </simple-form-calendar>

        @if (modifiedModel().publishingType !== publishingType.Once) {
          <simple-form-calendar
            [label]="'EndDate' | translate"
            [name]="propertyStrings.endDate"
            [form]="form()"
            [minDate]="minEndDate"
            [serverErrors]="serverErrors()"
          >
          </simple-form-calendar>

          @if (!modifiedModel().endDate) {
            <div class="info-text-wrapper place-end">
              <simple-form-info
                [firstText]="'*' + ('EndDateInfotext' | translate)"
              >
              </simple-form-info>
            </div>
          }
        }

        <simple-form-time
          [label]="'PublishingTime' | translate"
          [name]="propertyStrings.publishingTime"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-time>

        <simple-form-input
          [label]="'ExpirationTimeForAssignments' | translate"
          [name]="propertyStrings.daysDelay"
          [form]="form()"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-select
          [label]="'Status' | translate"
          [name]="propertyStrings.active"
          [form]="form()"
          [items]="selectableStatusItems()"
          [redBackground]="!modifiedModel().active"
          [greenBackground]="modifiedModel().active"
          [serverErrors]="serverErrors()"
        >
        </simple-form-select>
      </div>
      <div class="details-section">
        <div class="details-section-header">
          {{ "Preview" | translate }}
        </div>
        <timeline
          [items]="modifiedModel().publicationDates()"
          [previewCount]="modifiedModel().previewCount"
        >
        </timeline>
      </div>
    </div>
  </div>
</simple-details-tab>
