<simple-crud-modal
  [visible]="visible()"
  [headerText]="'ChangePassword' | translate"
  [closeText]="'Close' | translate"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="'PasswordHelpText' | translate"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-password-tab
            [modifiedModel]="modifiedModel()"
            [otherUserId]="otherUserId()"
            (onDetailsChange)="handleDetailsChange($event)"
          >
          </details-password-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
