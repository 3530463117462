import { Component, computed, input } from "@angular/core";
import {
  SimpleTableColumn,
  SimpleTableColumnType,
} from "../../columns/simple-table-column";
import { ColumnTypeAssessmentComponent } from "./assessment/column-type-assessment.component";
import { ColumnTypeAssignmentTemplateComponent } from "./assignment-template/column-type-assignment-template.component";
import { ColumnTypeCategoryComponent } from "./category/column-type-category.component";
import { ColumnTypeChoiceComponent } from "./choice/column-type-choice.component";
import { ColumnTypeCountComponent } from "./count/column-type-count.component";
import { ColumnTypeDateComponent } from "./date/column-type-date.component";
import { ColumnTypeDueDateComponent } from "./due-date/column-type-due-date.component";
import { ColumnTypeEntityComponent } from "./entity/column-type-entity.component";
import { ColumnTypeFacilityComponent } from "./facility/column-type-facility.component";
import { ColumnTypeIconComponent } from "./icon/column-type-icon.component";
import { ColumnTypeIssueNumberComponent } from "./issue-number/column-type-issue-number.component";
import { ColumnTypeMeasureTargetComponent } from "./measure-target/column-type-measure-target.component";
import { ColumnTypePublishDateComponent } from "./publish-date/column-type-publish-date.component";
import { ColumnTypeRuleComponent } from "./rule/column-type-rule.component";
import { ColumnTypeStatusComponent } from "./status/column-type-status.component";
import { ColumnTypeTaskComponent } from "./task/column-type-task.component";
import { ColumnTypeTextComponent } from "./text/column-type-text.component";
import { ColumnTypeTranslatedTextComponent } from "./translated-text/column-type-translated-text.component";
import { ColumnTypeUserComponent } from "./user/column-type-user.component";

@Component({
  selector: "column-type-selector",
  templateUrl: "./column-type-selector.component.html",
  standalone: true,
  imports: [
    ColumnTypeTextComponent,
    ColumnTypeAssessmentComponent,
    ColumnTypeCategoryComponent,
    ColumnTypeDateComponent,
    ColumnTypePublishDateComponent,
    ColumnTypeIconComponent,
    ColumnTypeRuleComponent,
    ColumnTypeStatusComponent,
    ColumnTypeChoiceComponent,
    ColumnTypeCountComponent,
    ColumnTypeDueDateComponent,
    ColumnTypeIssueNumberComponent,
    ColumnTypeTranslatedTextComponent,
    ColumnTypeUserComponent,
    ColumnTypeTaskComponent,
    ColumnTypeFacilityComponent,
    ColumnTypeEntityComponent,
    ColumnTypeAssignmentTemplateComponent,
    ColumnTypeMeasureTargetComponent,
  ],
})
export class ColumnTypeSelectorComponent<T> {
  column = input<SimpleTableColumn<T>>();
  item = input<T>();
  type = computed(() => this.column().type);
  columnType = SimpleTableColumnType;
}
