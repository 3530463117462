<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.fullName"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="'Update' | translate"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="update()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="'UserChangeDetails' | translate"
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-user-tab
            [modifiedModel]="modifiedModel()"
            [canChangeDetails]="true"
            [canChangeAuthorization]="false"
            [documents]="currentDocuments()"
            [isInvite]="false"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-user-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
