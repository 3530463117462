import { Component, inject, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HandleAssigneeGroupsTabComponent } from "app/components/assignment/detailed-assignment/scheduled/ongoing/tabs/handle-assignee-groups/handle-assignee-groups-tab.component";
import { HandleAssigneesTabComponent } from "app/components/assignment/detailed-assignment/scheduled/ongoing/tabs/handle-assignees/handle-assignees-tab.component";
import { ManualMeasureAssignment } from "app/components/assignment/manual-measure-assignment";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { TabVisibleDirective } from "app/directives/tab-visible.directive";
import { AssignmentService } from "app/services/assignment.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { HandleFacilitiesTabComponent } from "../../../../../assignment-template/create-edit-assignment-template/tabs/handle-facilities/handle-facilities-tab.component";
import { HandleEntitiesTabComponent } from "../../../../../facility/create-edit-facility/tabs/handle-entities/handle-entities-tab.component";

@Component({
  selector: "create-manual-measure",
  templateUrl: "./create-manual-measure.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    HandleAssigneeGroupsTabComponent,
    HandleAssigneesTabComponent,
    TranslateModule,
    TabsModule,
    HandleFacilitiesTabComponent,
    HandleEntitiesTabComponent,
    TabVisibleDirective,
  ],
})
export class CreateManualMeasureComponent extends CreateEditBaseDirective<ManualMeasureAssignment> {
  facilityIds = signal<string[]>([]);

  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.initialModel.set(new ManualMeasureAssignment({}));
    this.modifiedModel.set(
      new ManualMeasureAssignment(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      await this.assignmentService.createManualMeasure(
        this.modifiedModel().toPayloadObject(
          this.globalState.selectedOrganization().id,
        ),
      );
      this.toastrService.success(
        this.translationService.instant(
          this.getSuccessMessage("MeasureAssignment"),
        ),
      );
      this.onRequestDone.emit([this.modifiedModel()]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
    }
  }

  handleItemsChangeExtraFacility() {
    this.facilityIds.set(
      this.modifiedModel().facilities.map((facility) => facility.id),
    );
    if (!this.modifiedModel().facilities.length) {
      this.modifiedModel().entities = [];
    }
  }
}
