import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { AssignmentService } from "app/services/assignment.service";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../../simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../../simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { DetailsAdminCommentTabComponent } from "./tabs/details/tabs/details/details-admin-comment-tab.component";

@Component({
  selector: "change-admin-comment",
  templateUrl: "./change-admin-comment.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    TranslateModule,
    TabsModule,
    DetailsAdminCommentTabComponent,
  ],
})
export class ChangeAdminCommentComponent extends CreateEditBaseDirective<MeasureAssignment> {
  private detailsStatusAssignmentTabComponent = viewChild(
    DetailsAdminCommentTabComponent,
  );

  private assignmentService = inject(AssignmentService);

  async initiate() {
    this.initialModel.set(new MeasureAssignment({}));
    this.modifiedModel.set(
      new MeasureAssignment(Utils.getUniqueVariant(this.initialModel())),
    );
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = await this.assignmentService.updateAdminComment(
        this.itemIds(),
        this.modifiedModel().adminComment,
        this.globalState.selectedOrganization().id,
      );
      this.toastrService.info(
        this.translationService.instant(
          this.getSuccessMessage("Assignment", "Assignments"),
        ),
      );
      this.onRequestDone.emit(data);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsStatusAssignmentTabComponent().setServerErrors(error.errors);
    }
  }
}
