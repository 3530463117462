import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { NumberUtils } from "app/tools/number-utils";
import { Utils } from "app/tools/utils";
import { DateTime } from "luxon";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { ScheduleService } from "../../../services/schedule.service";
import { PublishingType, Schedule } from "../schedule";
import { DateItem } from "../timeline/timeline.component";
import { DetailsScheduleTabComponent } from "./tabs/details/details-schedule-tab.component";
import { HandleAssignmentTemplatesTabComponent } from "./tabs/handle-assignment-templates/handle-assignment-templates-tab.component";
import { HandleScheduleGroupsTabComponent } from "./tabs/handle-schedule-groups/handle-schedule-groups-tab.component";
import { HandleScheduleUsersTabComponent } from "./tabs/handle-schedule-users/handle-schedule-users-tab.component";

@Component({
  selector: "create-edit-schedule",
  templateUrl: "./create-edit-schedule.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsScheduleTabComponent,
    HandleAssignmentTemplatesTabComponent,
    HandleScheduleGroupsTabComponent,
    HandleScheduleUsersTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditScheduleComponent extends CreateEditBaseDirective<Schedule> {
  private detailsScheduleTabComponent = viewChild(DetailsScheduleTabComponent);

  private scheduleService = inject(ScheduleService);

  get hasAtLeastOneNotificationChoice() {
    return this.modifiedModel().assignmentTemplates.some(
      (assignmentTemplate) =>
        assignmentTemplate.templateType.notificationChoice,
    );
  }

  async initiate() {
    if (this.isEdit) {
      this.pending.set(true);
      try {
        const data = await this.scheduleService.get(this.itemIds()[0]);
        this.initialModel.set(data);
        this.pending.set(false);

        this.initialModel().startDate =
          this.initialModel().startDate.split(" ")[0];

        this.modifiedModel.set(
          new Schedule(Utils.getUniqueVariant(this.initialModel())),
        );

        this.refreshPublicationDates();
      } catch (error) {
        this.pending.set(false);
        this.toastrService.error(error.message);
        this.handleClose();
      }
    } else {
      this.initialModel.set(new Schedule({}));
      this.modifiedModel.set(
        new Schedule(Utils.getUniqueVariant(this.initialModel())),
      );

      this.refreshPublicationDates();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.scheduleService.update(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.scheduleService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );

      if (this.isEdit) {
        this.toastrService.info(this.getSuccessMessage("Schedule"));
      } else {
        this.toastrService.success(this.getSuccessMessage("Schedule"));
      }
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsScheduleTabComponent().setServerErrors(error.errors);
    }
  }

  handleDetailsChangeExtra() {
    if (this.detailsScheduleTabComponent()) {
      this.detailsScheduleTabComponent().setSelectableIntervalItems();
      if (this.detailsScheduleTabComponent().form().valid) {
        this.refreshPublicationDatesWithDebounce();
      }
    }
  }

  private refreshPublicationDatesWithDebounce = Utils.debounce(
    () => this.refreshPublicationDates(),
    NumberUtils.DEFAULT_DEBOUNCE_TIME,
  );

  private async refreshPublicationDates() {
    let publicationDates = await this.scheduleService.getPublicationDates(
      this.modifiedModel().toPayloadObject(
        this.globalState.selectedOrganization().id,
      ),
    );

    if (
      !publicationDates.length &&
      this.modifiedModel().publishingType === PublishingType.Once
    ) {
      publicationDates = [
        `${
          this.modifiedModel().toPayloadObject(
            this.globalState.selectedOrganization().id,
          ).startDate
        } ${this.modifiedModel().publishingTime}`,
      ];
    }

    this.modifiedModel().publicationDates.set([]);
    this.modifiedModel().publicationDates.set(
      publicationDates.map(
        (item) =>
          new DateItem({
            yearString: Utils.getYear(item),
            dayAndMonthString: `${Utils.getDay(item)} ${Utils.getMonthName(
              item,
            )}`,
            dayNameString: Utils.getDayName(item),
            infoText: this.getInfoText(item),
          }),
      ),
    );

    setTimeout(() => {
      this.modifiedModel()
        .publicationDates()
        .forEach((item) => item.display.set(true));
    }, 0);
  }

  private getInfoText(dateString: string) {
    const first = DateTime.fromSQL(dateString)
      .toLocaleString(DateTime.DATETIME_MED)
      .replace(".", "");
    const second = `${this.translationService.instant("Expires")}:\n${DateTime.fromSQL(
      dateString,
    )
      .plus({ days: this.modifiedModel().daysDelay })
      .toLocaleString(DateTime.DATETIME_MED)
      .replace(".", "")}`;
    return `${first}\n\n${second}`;
  }
}
