<div class="due-date-wrapper">
  <div
    class="due-date"
    [title]="value().date | datex: 'with-time'"
    [ngClass]="{
      today:
        utils.isTodayDate(value().date) && utils.isFutureDate(value().date),
    }"
  >
    {{ format(utils.convertToHumanDate(value().date)) }}
  </div>
</div>
