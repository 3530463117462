import { Component, computed, Signal } from "@angular/core";
import { SimpleAssignmentTemplateComponent } from "../../../../../various/assignment-template/simple-assignment-template.component";
import { AssignmentTemplateContent } from "../../../columns/simple-table-assignment-template-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-assignment-template",
  templateUrl: "./column-type-assignment-template.component.html",
  standalone: true,
  imports: [SimpleAssignmentTemplateComponent],
})
export class ColumnTypeAssignmentTemplateComponent<
  T,
> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<AssignmentTemplateContent>;
}
