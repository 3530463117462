@if (!isInModal()) {
  <ng-container *ngTemplateOutlet="tableContent"></ng-container>
}
@if (isInModal()) {
  <simple-crud-modal
    [visible]="visible()"
    [headerText]="'AddSchedules' | translate"
    [closeText]="'Close' | translate"
    [submitText]="'ConfirmSelected' | translate"
    [large]="true"
    (onClose)="handleClose()"
    (onSubmit)="handleConfirm()"
  >
    <ng-container *ngTemplateOutlet="tableContent"></ng-container>
  </simple-crud-modal>
}

<ng-template #tableContent>
  <list-header
    [emptyState]="emptyState()"
    [listActions]="listActions()"
    [columns]="columns()"
    [inModal]="isInModal()"
    (onCustomColumnConfigChange)="handleCustomColumnConfigChange($event)"
  ></list-header>
  <simple-table
    [initialFacets]="initialFacets()"
    [searchValue]="searchValue()"
    [filterInputs]="filterInputs()"
    [sortObjects]="sortObjects()"
    [currentSortObject]="currentSortObject()"
    [columns]="columns()"
    [headerActions]="headerActions()"
    [rowActions]="rowActions()"
    [emptyState]="emptyState()"
    [filteredIds]="filteredIds()"
    [rows]="items()"
    [selectedIds]="selectedIds()"
    [addedIds]="addedIds()"
    [modifiedIds]="modifiedIds()"
    [unselectableIds]="unselectableIds()"
    [pending]="pending()"
    [inModal]="isInModal()"
    [totalCount]="totalCount"
    [selectedCount]="selectedCount"
    [hasSelectableData]="hasSelectableData"
    [someAreSelected]="someAreSelected"
    [allAreSelected]="allAreSelected"
    [hasActiveSearchOrFilter]="hasActiveSearchOrFilter"
    (onSearch)="handleSearch($event)"
    (onSearchClear)="handleSearchClear()"
    (onSortDirectionChange)="handleSortDirectionChange($event)"
    (onSortLabelChange)="handleSortLabelChange($event)"
    (onFilter)="handleFilter($event)"
    (onFilterClear)="handleFilterClear()"
    (onSelectedClear)="handleSelectedClear()"
    (onCheckboxClick)="handleCheckboxClick($event)"
    (onRowSingleClick)="handleRowSingleClick($event)"
    (onRowDoubleClick)="handleRowDoubleClick($event)"
    (onRowCheckboxClick)="handleRowCheckboxClick($event)"
    (onLazyLoad)="handleLazyLoad($event)"
  >
  </simple-table>

  <create-edit-schedule
    [visible]="visibleCreateEdit()"
    [itemIds]="itemIds()"
    [useUnsavedChangesLogic]="!isInModal()"
    [displayDeleteButton]="!!itemIds().length"
    (onClose)="handleCloseCreateEdit()"
    (onDelete)="handleDelete()"
    (onRequestDone)="handleRequestDoneCreateEdit($event)"
  ></create-edit-schedule>

  <handle-schedule-groups-and-schedule-users
    [visible]="visibleHandleScheduleGroupsAndScheduleUsers()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseHandleScheduleGroupsAndScheduleUsers()"
    (onRequestDone)="
      handleRequestDoneHandleScheduleGroupsAndScheduleUsers($event)
    "
  >
  </handle-schedule-groups-and-schedule-users>

  <change-status-schedule
    [visible]="visibleChangeStatus()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseChangeStatus()"
    (onRequestDone)="handleRequestDoneChangeStatus($event)"
  >
  </change-status-schedule>

  <simple-view-timeline
    [(visible)]="visibleViewTimeline"
    [headerText]="'Preview' | translate"
    [timeline]="timeline()"
    (onClose)="visibleViewTimeline.set(false)"
  >
  </simple-view-timeline>

  <simple-view-items
    [(visible)]="visibleViewItems"
    (onClose)="visibleViewItems.set(false)"
  >
    @if (assignmentTemplates().length) {
      <div>
        <div class="sub-label">
          {{ "AssignmentTemplates" | translate }}
        </div>
        <simple-view-connections
          [assignmentTemplatesWithButtons]="assignmentTemplates()"
        >
        </simple-view-connections>
      </div>
    }
    @if (scheduleGroups().length) {
      <div>
        <div class="sub-label">
          {{ "Groups" | translate }}
        </div>
        <simple-view-connections [userGroups]="scheduleGroups()">
        </simple-view-connections>
      </div>
    }
    @if (scheduleUsers().length) {
      <div>
        <div class="sub-label">
          {{ "Assignees" | translate }}
        </div>
        <simple-view-connections [users]="scheduleUsers()">
        </simple-view-connections>
      </div>
    }
  </simple-view-items>
</ng-template>
