import { Component, computed, Signal } from "@angular/core";
import {
  RuleObject,
  SimpleRulesComponent,
} from "../../../../../various/rules/simple-rules.component";
import { RuleContent } from "../../../columns/simple-table-rule-column";
import { ColumnTypeBaseDirective } from "../column-type-base.directive";

@Component({
  selector: "column-type-rule",
  templateUrl: "./column-type-rule.component.html",
  standalone: true,
  imports: [SimpleRulesComponent],
})
export class ColumnTypeRuleComponent<T> extends ColumnTypeBaseDirective<T> {
  value = computed(() =>
    this.column().getValue(this.item()),
  ) as Signal<RuleContent>;

  handleRuleClick(ruleObject: RuleObject) {
    this.column().onClickRule(
      this.item(),
      ruleObject.title.charAt(0).toLowerCase() + ruleObject.title.slice(1),
    );
  }
}
