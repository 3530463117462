<simple-crud-modal
  [visible]="visible()"
  [headerText]="modifiedModel()?.fullName"
  [closeText]="'Close' | translate"
  [displayDeleteButton]="displayDeleteButton()"
  [submitText]="getSubmitText()"
  [disableSubmit]="!modifiedModel() || pending() || !changeDetailsIsValid()"
  [isLoading]="pending()"
  (onClose)="handleClose()"
  (onDelete)="handleDelete()"
  (onSubmit)="createOrUpdate()"
>
  @if (modifiedModel()) {
    <p-tabs [value]="0" scrollable>
      <p-tablist>
        <p-tab [value]="0">
          <simple-tab-heading
            [icon]="'bi-card-text'"
            [title]="'Details' | translate"
            [helpText]="
              isEdit
                ? ('UserChangeDetailsEditHelpText' | translate)
                : isInvite
                  ? ('UserChangeDetailsHelpText' | translate)
                  : ('CreateNewUserHelpText' | translate)
            "
          >
          </simple-tab-heading>
        </p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel [value]="0">
          <details-user-tab
            [modifiedModel]="modifiedModel()"
            [canChangeDetails]="canChangeDetails()"
            [canChangeAuthorization]="canChangeAuthorization()"
            [documents]="currentDocuments()"
            [isInvite]="isInvite()"
            (onDetailsChange)="
              handleDetailsChange($event); handleDetailsChangeExtra()
            "
          >
          </details-user-tab>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  }
</simple-crud-modal>
