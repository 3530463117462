<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        @if (!otherUserId()) {
          <simple-form-input
            [label]="'OldPassword' | translate"
            [name]="propertyStrings.oldPassword"
            [form]="form()"
            [usePasswordType]="true"
            [serverErrors]="serverErrors()"
          >
          </simple-form-input>
        }

        <simple-form-input
          [label]="'NewPassword' | translate"
          [name]="propertyStrings.newPassword"
          [form]="form()"
          [usePasswordType]="true"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>

        <simple-form-input
          [label]="'ConfirmPassword' | translate"
          [name]="propertyStrings.confirmPassword"
          [form]="form()"
          [usePasswordType]="true"
          [serverErrors]="serverErrors()"
        >
        </simple-form-input>
      </div>
    </div>
  </div>
</simple-details-tab>
