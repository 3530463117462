<div class="list-header" [ngClass]="{ 'in-modal': inModal() }">
  @if (!inModal()) {
    <div class="wrapper">
      <div class="icon {{ emptyState().icon }}"></div>
      <div class="text">
        {{ emptyState().identifier | translate }}
      </div>
    </div>
    <help></help>
  }
  <list-actions [actions]="listActions()" [inModal]="inModal()"></list-actions>
  <!--  TODO gör i ny branch sedan, det som fattas är bara möjligheten att spara sina val samt change detection
    för när man gör ett otillåtet val och det revertas  -->
  <!-- @if (!inModal()) {
    <div
      class="layout-action"
      title="{{ 'ChangeColumns' | translate }} {{
        customHiddenColumns.length
          ? '(' + ('HasHiddenColumns' | translate) + ')'
          : ''
      }}"
      (click)="visible.set(!visible())"
    >
      <div class="icon bi-wrench-adjustable"></div>
      <div class="text">
        {{ "ChangeColumns" | translate }}
      </div>
      @if (customHiddenColumns.length) {
        <div class="modified-marker">&#9874;</div>
      }
    </div>
  } -->
</div>

<!-- <p-sidebar
  [(visible)]="visible"
  [position]="'left'"
  [showCloseIcon]="false"
  [appendTo]="'body'"
>
  <ng-template pTemplate="header">
    <div class="label">
      {{ "ChangeColumns" | translate }}
    </div>
    <div class="actions">
      <div
        class="action"
        [title]="'Close' | translate"
        (click)="visible.set(false)"
      >
        <i class="icon bi-x-lg"></i>
      </div>
    </div>
  </ng-template>
  <p-pickList
    [source]="customVisibleColumns"
    [target]="customHiddenColumns"
    sourceHeader="{{ 'Visible' | translate }}"
    targetHeader="{{ 'Hidden' | translate }}"
    [dragdrop]="true"
    [showSourceControls]="false"
    [showTargetControls]="false"
    [sourceStyle]="{
      height: 50 * columns().length + 8 * (columns().length - 1) + 'px',
    }"
    [targetStyle]="{
      height: 50 * columns().length + 8 * (columns().length - 1) + 'px',
    }"
    (onMoveToSource)="handleMoveToVisible($event.items)"
    (onMoveToTarget)="handleMoveToHidden($event.items)"
    (onSourceReorder)="handleVisibleReorder()"
  >
    <ng-template let-column let-index="index" pTemplate="item">
      <div class="first">
        {{ (column.labels[0] ? column.labels[0] : "Icons") | translate }}
      </div>
      @if (column.labels[1]) {
        <div class="second">
          {{ column.labels[1] | translate }}
        </div>
      }
    </ng-template>
  </p-pickList>
</p-sidebar> -->
