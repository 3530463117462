@if (!rows().length) {
  <list-skeleton
    [columns]="visibleColumns()"
    [emptyState]="emptyState()"
    [displayMode]="pending() ? 'pending' : 'empty'"
    [inModal]="inModal()"
    [inWizard]="inWizard()"
    [hasActiveSearchOrFilter]="hasActiveSearchOrFilter()"
  ></list-skeleton>
}

@if (rows().length) {
  <p-virtualscroller
    #scroller
    [items]="rows()"
    [itemSize]="itemSize()"
    styleClass="st-body apx-scrollbar firefox-no-anchor {{
      inModal() ? 'in-modal' : ''
    }} {{ inWizard() ? 'in-wizard' : '' }}"
    [lazy]="!inWizard()"
    [trackBy]="trackByIndex"
    (onScrollIndexChange)="handleLazyLoad($event)"
  >
    <ng-template #item let-item let-options="options">
      @if (options.index === 0 && !inWizard()) {
        <div class="st-fake-row-top"></div>
      }
      <div
        class="st-row"
        [ngClass]="{
          selected: selectedIds()?.includes(item.id),
          added: addedIds()?.includes(item.id),
          modified: modifiedIds()?.includes(item.id),
          unselectable: unselectableIds()?.includes(item.id),
        }"
        [ngStyle]="{
          height: itemSize() + 'px',
        }"
        (click)="handleRowSingleClick(item)"
        (dblclick)="handleRowDoubleClick(item, $event)"
      >
        @if (!inWizard()) {
          <div class="st-number-wrapper">
            <div class="st-number">
              {{ item.index }}
            </div>
          </div>
        }

        @for (column of visibleColumns(); track $index) {
          <div
            class="st-section"
            [ngStyle]="{
              'max-width': column.maxwidth ? column.maxwidth + 'px' : '',
            }"
          >
            <div class="st-mobile-header">
              <div class="main">
                {{ column.labels[0] | translate }}
              </div>
              @if (column.labels[1]) {
                <div class="secondary">
                  {{ column.labels[1] | translate }}
                </div>
              }
            </div>
            <div class="st-value">
              <column-type-selector
                [column]="column"
                [item]="item"
              ></column-type-selector>
            </div>
          </div>
        }
        <div
          class="st-section actions"
          [ngStyle]="{
            width: inWizard()
              ? 50 * (rowActions().length + 1) + 'px'
              : 100 + 'px',
          }"
        >
          @if (
            !inWizard() && (rowActions() | getVisibleRowActions: item).length
          ) {
            <div
              class="st-actions-menu bi-three-dots"
              [ngClass]="{
                opened: item.actionsOpened,
              }"
              [title]="'OpenMenu' | translate"
              (click)="
                rowActionTemplate.toggle($event); handleRowSingleClick(item)
              "
            ></div>
            <p-popover
              #rowActionTemplate
              (onShow)="item.actionsOpened = true"
              (onHide)="item.actionsOpened = false"
            >
              <div class="popover-panel-header" [title]="item.displayName">
                {{ item.displayName }}
              </div>
              @for (action of rowActions(); track $index) {
                <div
                  class="standard-menu-item"
                  [title]="action.name | translate"
                  [ngClass]="{
                    'red-highlight': action.isDeleteAction,
                    disabled:
                      unselectableIds()?.includes(item.id) ||
                      action.hidden(item),
                  }"
                  (click)="action.event(item); rowActionTemplate.toggle($event)"
                >
                  <i [class]="action.iconName"></i>{{ action.name | translate }}
                </div>
                @if (
                  globalState.loggedInUser().isSuperAdmin() &&
                  !inWizard() &&
                  !inModal() &&
                  $index === rowActions().length - 2
                ) {
                  <div
                    class="standard-menu-item"
                    [title]="'CopyReference' | translate"
                    [cdkCopyToClipboard]="getReferenceUrl(item)"
                    (click)="
                      handleCopyReferenceClick();
                      rowActionTemplate.toggle($event)
                    "
                  >
                    <i [class]="'bi-copy'"></i>{{ "CopyReference" | translate }}
                  </div>
                }
              }
            </p-popover>
          }
          @if (inWizard()) {
            @for (action of rowActions(); track $index) {
              <div
                class="st-row-action"
                [ngClass]="{
                  disabled:
                    unselectableIds()?.includes(item.id) || action.hidden(item),
                }"
                [title]="action.name | translate"
                (click)="$event.stopImmediatePropagation(); action.event(item)"
              >
                <i [class]="action.iconName"></i>
              </div>
            }
          }
          <div
            class="st-checkbox"
            (mouseover)="item.hovered = true"
            (mouseleave)="item.hovered = false"
            [ngClass]="{
              disabled: unselectableIds()?.includes(item.id),
              'bi-circle':
                isSingleSelect() && !selectedIds()?.includes(item.id),
              'bi-circle-fill':
                isSingleSelect() && selectedIds()?.includes(item.id),

              'bi-square':
                !item.hovered &&
                !selectedIds()?.includes(item.id) &&
                !isSingleSelect(),
              'bi-check-square':
                item.hovered &&
                !selectedIds()?.includes(item.id) &&
                !isSingleSelect(),
              'bi-check-square-fill':
                selectedIds()?.includes(item.id) && !isSingleSelect(),
            }"
            [title]="
              selectedIds()?.includes(item.id)
                ? ('Unselect' | translate)
                : ('Select' | translate)
            "
            (click)="handleRowCheckboxClick(item)"
          ></div>
        </div>
      </div>
      @if (options.index === rows().length - 1 && !inWizard()) {
        <div class="st-fake-row-bottom"></div>
      }
    </ng-template>
  </p-virtualscroller>
}
