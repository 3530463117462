{
  "name": "inquinova-webadmin",
  "version": "1.50.0",
  "license": "MIT",
  "angular-cli": {},
  "scripts": {
    "ng-high-memory-build-development": "node ./node_modules/@angular/cli/bin/ng build --configuration=development",
    "ng-high-memory-build-stage": "node --max_old_space_size=8192 ./node_modules/@angular/cli/bin/ng build --configuration=stage && node ./versioning.build.js",
    "ng-high-memory-build-production": "node --max_old_space_size=8192 ./node_modules/@angular/cli/bin/ng build --configuration=production && node ./versioning.build.js",
    "ng": "ng",
    "start": "ng serve --aot",
    "local": "ng serve --configuration=localhost --open",
    "local-with-api": "@PowerShell -NoProfile -ExecutionPolicy Unrestricted ./api.ps1",
    "dev": "ng serve --configuration=localhostdev --open",
    "docs": "http-server -c-1 -o -p 9876 ./documentation",
    "docs:gen": "compodoc -p src/tsconfig.json --theme postmark",
    "build": "ng build --aot --build-optimizer=false --verbose --progress && node ./versioning.build.js",
    "test": "ng test",
    "test2": "ng test --watch false",
    "coverage": "ng test --code-coverage",
    "host-coverage": "http-server -c-1 -o -p 9877 ./coverage",
    "pree2e": "webdriver-manager update --standalone false --gecko false",
    "e2e": "protractor",
    "release:patch": "npm run version:patch",
    "release:minor": "npm run version:minor",
    "release:major": "npm run version:major",
    "version:patch": "npm version patch -m \"Patch release version %s\"",
    "version:minor": "npm version minor -m \"Minor release version %s\"",
    "version:major": "npm version major -m \"Major release version %s\"",
    "push:git": "git push"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.5",
    "@angular/common": "19.0.5",
    "@angular/compiler": "19.0.5",
    "@angular/core": "19.0.5",
    "@angular/forms": "19.0.5",
    "@angular/google-maps": "^18.2.4",
    "@angular/platform-browser": "19.0.5",
    "@angular/platform-browser-dynamic": "19.0.5",
    "@angular/platform-server": "19.0.5",
    "@angular/pwa": "^0.901.2",
    "@angular/router": "19.0.5",
    "@googlemaps/js-api-loader": "^1.16.2",
    "@ng-select/ng-select": "^14.2.0",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "8.0.0",
    "@primeng/themes": "^19.0.1",
    "@sweetalert2/ngx-sweetalert2": "^12.0.0",
    "@types/marked": "^4.0.1",
    "@types/qs": "^6.9.7",
    "angular2-multiselect-dropdown": "^5.0.4",
    "broadcast-channel": "^3.1.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "core-js": "^3.20.0",
    "dom-to-image": "^2.6.0",
    "es6-iterator": "^2.0.3",
    "file-saver": "^2.0.2",
    "font-awesome": "^4.7.0",
    "google-libphonenumber": "^3.2.29",
    "hammerjs": "^2.0.8",
    "http-proxy": "~1.18.1",
    "inspire-tree": "^6.0.1",
    "lodash": "^4.17.21",
    "luxon": "^3.5.0",
    "marked": "^4.3.0",
    "ng-multiselect-dropdown": "^1.0.0",
    "ng2-charts": "^5.0.4",
    "ng2-pdf-viewer": "^9.1.2",
    "ngx-auth": "^6.0.0",
    "ngx-color-picker": "^17.0.0",
    "ngx-image-cropper": "^7.2.0",
    "pdfmake": "~0.1.65",
    "primeng": "^19.0.4",
    "proj4": "^2.8.1",
    "qs": "^6.9.3",
    "quill": "^2.0.3",
    "replace-in-file": "6.1.0",
    "rxjs": "7.8.1",
    "scroll-shadow-element": "^1.1.6",
    "stream": "0.0.2",
    "sweetalert2": "^11.0.0",
    "ts-helpers": "^1.1.2",
    "xlsx": "^0.17.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.0.6",
    "@angular/cli": "^18.2.4",
    "@angular/compiler-cli": "19.0.5",
    "@compodoc/compodoc": "^1.1.14",
    "@eslint/js": "^9.10.0",
    "@types/fs-extra": "8.1.0",
    "@types/jasmine": "~3.8.0",
    "@types/lodash": "^4.14.178",
    "@types/luxon": "^3.4.2",
    "@types/node": "^18.18.0",
    "codelyzer": "^6.0.0",
    "copy-webpack-plugin": "^11.0.0",
    "eslint": "^9.10.0",
    "globals": "^15.9.0",
    "http-server": "^0.12.3",
    "intl": "^1.2.5",
    "jasmine": "^4.6.0",
    "jasmine-core": "~4.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "jszip": "^3.7.1",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "~3.2.0",
    "karma-cli": "^2.0.0",
    "karma-coverage": "^2.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "karma-junit-reporter": "^2.0.1",
    "less": "^4.1.1",
    "prettier": "^3.3.3",
    "ts-node": "8.9.0",
    "typescript": "~5.6.3",
    "typescript-eslint": "^8.6.0",
    "webpack-cli": "^4.10.0",
    "webpack-dev-server": "^4.15.1"
  },
  "false": {}
}
