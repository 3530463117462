import { NgClass } from "@angular/common";
import {
  Component,
  ElementRef,
  inject,
  signal,
  viewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { KeyValuePair } from "app/filter";
import {
  AssignmentFilter,
  AssignmentService,
} from "app/services/assignment.service";
import { LocalStorageKey } from "app/services/local-storage.service";
import { ToastrService } from "app/services/toastr.service";
import { ColorUtils } from "app/tools/color-utils";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import { ChartData, ChartEvent, ChartType } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";
import { environment } from "environments/environment";
import { DateTime } from "luxon";
import { BaseChartDirective, NgChartsModule } from "ng2-charts";
import { Popover } from "primeng/popover";
import { debounceTime, fromEvent, Subscription } from "rxjs";
import { ExtensiveImageComponent } from "../../media/extensive-image/extensive-image.component";
import { MeasureAssignment } from "../assignment/measure-assignment";
import { ScheduledAssignment } from "../assignment/scheduled-assignment";
import { LandingPageBaseDirective } from "../simple-components/directives/landing-page-base.directive";
import { SimpleLandingPageComponent } from "../simple-components/landing-page/simple-landing-page.component";
import {
  DashboardConfig,
  DashboardStorage,
  FilterItem,
  FilterObject,
  LabelObject,
  StoredFilter,
} from "./classes";
import { DashboardUtils } from "./dashboard-utils";
import { AssignmentState, TimeSpan } from "./enums";
import { ViewAssignmentsComponent } from "./view-assignments/view-assignments.component";

@Component({
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.less"],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    NgChartsModule,
    TranslateModule,
    SimpleLandingPageComponent,
    ExtensiveImageComponent,
    ViewAssignmentsComponent,
    Popover,
  ],
})
export class DashboardComponent extends LandingPageBaseDirective<Assignment> {
  chartType: ChartType = "bar";
  chartOptions = DashboardUtils.chartOptions;
  chartData: ChartData<"bar", number[]> = {
    datasets: [],
    labels: [],
  };
  chartPlugins = [DataLabelsPlugin];
  timeSpanEnum = TimeSpan;
  assignmentStateEnum = AssignmentState;

  currentChartLabels: LabelObject[] = [];

  currentItemsFilter: AssignmentFilter;
  currentItemsProperty = signal<keyof Assignment>(null);
  currentfirstLabel = signal<string>(null);
  currentSecondLabel = signal<string>(null);
  currentThirdLabel = signal<string>(null);

  filterObjectScheduledExpiring = new AssignmentFilter(
    [],
    DashboardUtils.filterObjectScheduledExpiringFacets,
    NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE,
  );
  filterObjectScheduledCompleted = new AssignmentFilter(
    [],
    DashboardUtils.filterObjectScheduledCompletedFacets,
    NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE,
  );
  filterObjectScheduledExpired = new AssignmentFilter(
    [],
    DashboardUtils.filterObjectScheduledExpiredFacets,
    NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE,
  );
  filterObjectMeasureCreated = new AssignmentFilter(
    [],
    DashboardUtils.filterObjectMeasureCreatedFacets,
    NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE,
  );
  filterObjectMeasureResolved = new AssignmentFilter(
    [],
    DashboardUtils.filterObjectMeasureResolvedFacets,
    NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE,
  );
  filterObjectMeasureClosed = new AssignmentFilter(
    [],
    DashboardUtils.filterObjectMeasureClosedFacets,
    NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE,
  );

  pendingCounts = signal<boolean>(false);
  pendingItems = signal<boolean>(false);
  selectedTimeSpan = signal<TimeSpan>(null);
  selectedAssignmentState = signal<AssignmentState>(null);
  scheduledAssignmentsExpiringCount = signal<number>(0);
  scheduledAssignmentsCompletedCount = signal<number>(0);
  scheduledAssignmentsExpiredCount = signal<number>(0);
  measureAssignmentsCreatedCount = signal<number>(0);
  measureAssignmentsResolvedCount = signal<number>(0);
  measureAssignmentsClosedCount = signal<number>(0);

  savedAssignments: Assignment[][] = [];

  private viewModeSubscription = new Subscription();

  currentTodayValue: string = "";

  filterObjects = signal<FilterObject[]>([]);

  filterOpened = signal<boolean>(false);

  assignmentsToView = signal<Assignment[]>([]);
  visibleViewAssignments = signal<boolean>(false);

  chart = viewChild<BaseChartDirective>(BaseChartDirective);

  filterInput = viewChild<ElementRef>("filterInput");

  get hasSelectedFilters() {
    return this.filterObjects().some((filter) => filter.hasSelectedItems);
  }

  get selectedFilters() {
    return this.filterObjects().filter((filter) => filter.hasSelectedItems);
  }

  private assignmentService = inject(AssignmentService);
  protected toastrService = inject(ToastrService);

  async ngOnInit() {
    this.removeTypingBorder();
    this.setViewMode();
    this.viewModeSubscription.add(
      fromEvent(window, "resize")
        .pipe(debounceTime(NumberUtils.WINDOW_RESIZE_WAIT_TIME))
        .subscribe(() => {
          this.setViewMode();
        }),
    );
    this.chartOptions.scales["x"].ticks.color = (context) => {
      return DashboardUtils.isCurrent(context, this.currentTodayValue)
        ? ColorUtils.GREEN_COLOR
        : ColorUtils.MAIN_FONT_COLOR;
    };
    this.chartOptions.scales["y"].ticks.color = (context) => {
      return DashboardUtils.isCurrent(context, this.currentTodayValue)
        ? ColorUtils.GREEN_COLOR
        : ColorUtils.MAIN_FONT_COLOR;
    };
    this.chart().render();

    this.pendingCounts.set(true);
    this.pendingItems.set(true);
    const [
      templateTypes,
      facilities,
      categories,
      schedules,
      groups,
      assignees,
      choices,
    ] = await Promise.all([
      this.assignmentService.getTemplateTypes(
        this.globalState.selectedOrganization().id,
      ),
      this.assignmentService.getFacilities(),
      this.assignmentService.getCategories(
        this.globalState.selectedOrganization().id,
      ),
      this.assignmentService.getSchedules(
        this.globalState.selectedOrganization().id,
      ),
      this.assignmentService.getUserGroups(
        this.globalState.selectedOrganization().id,
      ),
      this.assignmentService.getUsers(
        this.globalState.selectedOrganization().id,
      ),
      this.assignmentService.getChoices(
        this.globalState.selectedOrganization().id,
      ),
    ]);

    this.filterObjects.set([
      new FilterObject(
        this.translationService.instant("TemplateTypes"),
        templateTypes.map((item) => new FilterItem(item.id, item.name)),
        "TemplateTypes",
      ),
      new FilterObject(
        this.translationService.instant("Locations"),
        facilities.map((item) => new FilterItem(item.id, item.name)),
        "Locations",
      ),
      new FilterObject(
        this.translationService.instant("Categories"),
        categories.map((item) => new FilterItem(item.id, item.name)),
        "Categories",
      ),
      new FilterObject(
        this.translationService.instant("Schedules"),
        schedules.map((item) => new FilterItem(item.id, item.name)),
        "Schedules",
      ),
      new FilterObject(
        this.translationService.instant("Groups"),
        groups.map((item) => new FilterItem(item.id, item.name)),
        "Groups",
      ),
      new FilterObject(
        this.translationService.instant("Assignees"),
        assignees.map(
          (item) =>
            new FilterItem(item.id, item.fullName ? item.fullName : item.email),
        ),
        "Assignees",
      ),
      new FilterObject(
        this.translationService.instant("Choices"),
        choices.map((item) => new FilterItem(item.id, item.value)),
        "Choices",
      ),
    ]);

    this.getStoredValuesOrDefault();

    await this.fetchAllCounts();
    this.getItems();
  }

  private setViewMode() {
    if (Utils.isMediumScreenSize()) {
      this.chartOptions.scales["y"].ticks.display = true;
      this.chartOptions.scales["x"].ticks.display = false;
      this.chartOptions.indexAxis = "y";
      this.chartOptions.plugins.datalabels.align = "left";
    } else {
      this.chartOptions.scales["y"].ticks.display = false;
      this.chartOptions.scales["x"].ticks.display = true;
      this.chartOptions.indexAxis = "x";
      this.chartOptions.plugins.datalabels.align = "bottom";
    }
    this.chart().render();
  }

  async removeFilter(selectedFilter: FilterObject) {
    const filterObject = this.filterObjects().find(
      (filterObject) => filterObject.title === selectedFilter.title,
    );
    if (filterObject) {
      filterObject.items.forEach((item) => (item.selected = false));
      this.doRemoveFilter(filterObject.facet);
      this.setStoredDashboardConfig();
      this.clearAndFetch();
    }
  }

  async handleFilterClick(item: FilterItem, filterObject: FilterObject) {
    item.selected = !item.selected;

    const values = filterObject.items
      .filter((item) => item.selected)
      .map((value) => value.id)
      .join();

    if (values) {
      this.doAddFilter(filterObject.facet, values);
    } else {
      this.doRemoveFilter(filterObject.facet);
    }

    this.setStoredDashboardConfig();
    this.clearAndFetchWithDebounce();
  }

  private clearAndFetchWithDebounce = Utils.debounce(
    () => this.clearAndFetch(),
    NumberUtils.DEFAULT_DEBOUNCE_TIME,
  );

  private async clearAndFetch() {
    this.clearChartData();
    await this.fetchAllCounts();
    this.setCurrentItemsVariables();
    this.getItems();
  }

  private getStoredConfig() {
    const storedStorage = this.localStorageService.getItem(
      LocalStorageKey.DashboardStorage,
    ) as DashboardStorage;
    const storedStorageInstance = new DashboardStorage(
      storedStorage ? storedStorage : {},
    );
    return storedStorageInstance.dashboardConfigs.find(
      (storedConfig) =>
        storedConfig.organizationId ===
          this.globalState.selectedOrganization().id &&
        storedConfig.userId === this.globalState.loggedInUser().id,
    );
  }

  private getStoredValuesOrDefault() {
    const storedConfig = this.getStoredConfig();
    if (storedConfig) {
      const storedConfigInstance = new DashboardConfig(storedConfig);

      if (storedConfigInstance.dashboardFilter) {
        storedConfigInstance.dashboardFilter.keyValuePairs.forEach((pair) => {
          const filterObject = this.filterObjects().find(
            (filterObject) => filterObject.facet === pair.key,
          );
          if (filterObject) {
            this.doAddFilter(pair.key, pair.value);
            filterObject.items.forEach(
              (item) => (item.selected = pair.value.includes(item.id)),
            );
          }
        });
      }

      if (storedConfigInstance.dashboardTimespan) {
        this.setTimeSpan(storedConfigInstance.dashboardTimespan);
      } else {
        this.setTimeSpan(this.timeSpanEnum.ThisWeek);
      }

      if (storedConfigInstance.dashboardAssignmentState) {
        this.setAssignmentState(storedConfigInstance.dashboardAssignmentState);
      } else {
        this.setAssignmentState(this.assignmentStateEnum.CompletedAssignments);
      }
    } else {
      this.setTimeSpan(this.timeSpanEnum.ThisWeek);
      this.setAssignmentState(this.assignmentStateEnum.CompletedAssignments);
    }

    // Update chart
    this.prepareFiltersAndLabels();
    this.setCurrentItemsVariables();
  }

  private doAddFilter(facet: string, values: string) {
    const keyValuePair = new KeyValuePair(facet, values);
    this.filterObjectScheduledExpiring.addFacet(keyValuePair);
    this.filterObjectScheduledCompleted.addFacet(keyValuePair);
    this.filterObjectScheduledExpired.addFacet(keyValuePair);
    this.filterObjectMeasureCreated.addFacet(keyValuePair);
    this.filterObjectMeasureResolved.addFacet(keyValuePair);
    this.filterObjectMeasureClosed.addFacet(keyValuePair);
  }

  private doRemoveFilter(facet: string) {
    const keyValuePair = new KeyValuePair(facet, "");
    this.filterObjectScheduledExpiring.removeFacet(keyValuePair);
    this.filterObjectScheduledCompleted.removeFacet(keyValuePair);
    this.filterObjectScheduledExpired.removeFacet(keyValuePair);
    this.filterObjectMeasureCreated.removeFacet(keyValuePair);
    this.filterObjectMeasureResolved.removeFacet(keyValuePair);
    this.filterObjectMeasureClosed.removeFacet(keyValuePair);
  }

  private setStoredDashboardConfig() {
    const filterKeyValuePairs = [];
    const filterObjectsWithSelectedItems = this.filterObjects().filter(
      (filterObject) => filterObject.hasSelectedItems,
    );
    filterObjectsWithSelectedItems.forEach((filterObject) => {
      filterKeyValuePairs.push(
        new KeyValuePair(filterObject.facet, filterObject.selectedItemIds),
      );
    });
    const storedFilter = new StoredFilter({
      keyValuePairs: filterKeyValuePairs,
    });

    const dashboardConfig = new DashboardConfig({
      organizationId: this.globalState.selectedOrganization().id,
      userId: this.globalState.loggedInUser().id,
      dashboardFilter: storedFilter,
      dashboardTimespan: this.selectedTimeSpan(),
      dashboardAssignmentState: this.selectedAssignmentState(),
    });

    const storedStorage = this.localStorageService.getItem(
      LocalStorageKey.DashboardStorage,
    ) as DashboardStorage;
    const storedStorageInstance = new DashboardStorage(
      storedStorage ? storedStorage : {},
    );

    const storedConfig = storedStorageInstance.dashboardConfigs.find(
      (storedConfig) =>
        storedConfig.organizationId ===
          this.globalState.selectedOrganization().id &&
        storedConfig.userId === this.globalState.loggedInUser().id,
    );
    if (storedConfig) {
      Object.assign(storedConfig, dashboardConfig);
      this.localStorageService.setItem(
        LocalStorageKey.DashboardStorage,
        storedStorageInstance,
      );
    } else {
      storedStorageInstance.dashboardConfigs.push(dashboardConfig);
      this.localStorageService.setItem(
        LocalStorageKey.DashboardStorage,
        storedStorageInstance,
      );
    }
  }

  async changeTimeSpan(timeSpan: TimeSpan) {
    if (timeSpan !== this.selectedTimeSpan()) {
      this.setTimeSpan(timeSpan);
      this.clearChartData();
      this.prepareFiltersAndLabels();
      this.setStoredDashboardConfig();
      await this.fetchAllCounts();
      this.setCurrentItemsVariables();
      this.getItems();
    }
  }

  async changeAssignmentState(assignmentState: AssignmentState) {
    if (assignmentState !== this.selectedAssignmentState()) {
      this.setAssignmentState(assignmentState);
      this.clearChartData();
      this.setCurrentItemsVariables();
      this.setStoredDashboardConfig();
      this.getItems();
    }
  }

  private setTimeSpan(timeSpan: TimeSpan) {
    this.selectedTimeSpan.set(timeSpan);
  }

  private prepareFiltersAndLabels() {
    this.currentChartLabels = [];

    if (this.selectedTimeSpan() === this.timeSpanEnum.ThisDay) {
      const now = DateTime.now();
      const steps = 24;

      for (let i = 0; i < steps; i++) {
        this.currentChartLabels.push({
          labelFirst: `${(steps - i - 1).toString().padStart(2, "0")}:00`,
          labelSecond: "",
          value: (steps - i - 1).toString().padStart(2, "0"),
        });
      }

      const dateString = `${now.toFormat("YYYY")}-${now.toFormat(
        "MM",
      )}-${now.toFormat("DD")}_${now.toFormat("YYYY")}-${now.toFormat(
        "MM",
      )}-${now.toFormat("DD")}`;
      this.adjustFiltersDate(dateString);

      this.currentTodayValue = `${DateTime.now().toFormat("HH").padStart(2, "0")}:00`;
    } else if (this.selectedTimeSpan() === this.timeSpanEnum.ThisWeek) {
      const now = DateTime.now();
      const steps = 7;
      const startOfWeek = now.startOf("week").toFormat("DD");
      const endOfWeek = now.endOf("week").toFormat("DD");

      for (let i = 0; i < steps; i++) {
        this.currentChartLabels.push({
          labelFirst: `${now
            // .isoWeekday(steps - i) TODO
            .toFormat("dddd")}`.toUpperCase(),
          labelSecond: "", // TODO
          // now.date(parseInt(endOfWeek) - i).format("D/M"),
          value: (parseInt(endOfWeek) - i).toString().padStart(2, "0"),
        });
      }

      const dateString = `${now.toFormat("YYYY")}-${now.toFormat(
        "MM",
      )}-${startOfWeek}_${now.toFormat("YYYY")}-${now.toFormat("MM")}-${endOfWeek}`;
      this.adjustFiltersDate(dateString);

      this.currentTodayValue = DateTime.now().toFormat("dddd").toUpperCase();
    } else if (this.selectedTimeSpan() === this.timeSpanEnum.ThisMonth) {
      const now = DateTime.now();
      const steps = now.daysInMonth;

      for (let i = 0; i < steps; i++) {
        this.currentChartLabels.push({
          labelFirst: (steps - i).toString(),
          labelSecond: "", // TODO
          // `${now.date(steps - i).format("ddd")}`.toUpperCase(),
          value: (steps - i).toString().padStart(2, "0"),
        });
      }

      const dateString = `${now.toFormat("YYYY")}-${now.toFormat(
        "MM",
      )}-01_${now.toFormat("YYYY")}-${now.toFormat("MM")}-${steps
        .toString()
        .padStart(2, "0")}`;
      this.adjustFiltersDate(dateString);

      this.currentTodayValue = DateTime.now().toFormat("D");
    } else if (this.selectedTimeSpan() === this.timeSpanEnum.ThisYear) {
      const now = DateTime.now();
      const steps = 12;

      for (let i = 0; i < steps; i++) {
        this.currentChartLabels.push({
          labelFirst: `${now
            // .month(steps - 1 - i) TODO
            .toFormat("MMMM")}`.toUpperCase(),
          labelSecond: "",
          value: (steps - i).toString().padStart(2, "0"),
        });
      }

      const dateString = `${now.toFormat("YYYY")}-01-01_${now.toFormat(
        "YYYY",
      )}-12-31`;
      this.adjustFiltersDate(dateString);

      this.currentTodayValue = DateTime.now().toFormat("MMMM").toUpperCase();
    }

    this.currentChartLabels.reverse();
    this.chartData.labels = this.currentChartLabels.map((labelObject) =>
      labelObject.labelSecond
        ? [labelObject.labelFirst, labelObject.labelSecond]
        : labelObject.labelFirst,
    );
    this.chart().update();
  }

  private adjustFiltersDate(dateString: string) {
    this.setFilterFacet(
      this.filterObjectScheduledExpiring,
      new KeyValuePair(StringUtils.EXPIRES_BETWEEN_KEY, dateString),
      RoutesUtils.scheduledOngoing,
    );

    this.setFilterFacet(
      this.filterObjectScheduledCompleted,
      new KeyValuePair(StringUtils.ARCHIVED_BETWEEN_KEY, dateString),
      RoutesUtils.scheduledArchived,
    );

    this.setFilterFacet(
      this.filterObjectScheduledExpired,
      new KeyValuePair(StringUtils.ARCHIVED_BETWEEN_KEY, dateString),
      RoutesUtils.scheduledArchived,
    );

    this.setFilterFacet(
      this.filterObjectMeasureCreated,
      new KeyValuePair(StringUtils.CREATED_BETWEEN_KEY, dateString),
      RoutesUtils.measureOngoing,
    );

    this.setFilterFacet(
      this.filterObjectMeasureResolved,
      new KeyValuePair(StringUtils.ARCHIVED_BETWEEN_KEY, dateString),
      RoutesUtils.measureArchived,
    );

    this.setFilterFacet(
      this.filterObjectMeasureClosed,
      new KeyValuePair(StringUtils.ARCHIVED_BETWEEN_KEY, dateString),
      RoutesUtils.measureArchived,
    );
  }

  private setFilterFacet(
    filter: AssignmentFilter,
    facet: KeyValuePair,
    context: string,
  ) {
    filter.addFacet(facet);
    filter.context = context;
  }

  private async fetchAllCounts() {
    this.pendingCounts.set(true);
    try {
      const [
        saExpiring,
        saCompleted,
        saExpired,
        maCreated,
        maResolved,
        maClosed,
      ] = await Promise.all([
        this.assignmentService.getFilteredIds(
          this.filterObjectScheduledExpiring,
        ),
        this.assignmentService.getFilteredIds(
          this.filterObjectScheduledCompleted,
        ),
        this.assignmentService.getFilteredIds(
          this.filterObjectScheduledExpired,
        ),
        this.assignmentService.getFilteredIds(this.filterObjectMeasureCreated),
        this.assignmentService.getFilteredIds(this.filterObjectMeasureResolved),
        this.assignmentService.getFilteredIds(this.filterObjectMeasureClosed),
      ]);
      this.pendingCounts.set(false);
      this.scheduledAssignmentsExpiringCount.set(saExpiring.length);
      this.scheduledAssignmentsCompletedCount.set(saCompleted.length);
      this.scheduledAssignmentsExpiredCount.set(saExpired.length);
      this.measureAssignmentsCreatedCount.set(maCreated.length);
      this.measureAssignmentsResolvedCount.set(maResolved.length);
      this.measureAssignmentsClosedCount.set(maClosed.length);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  private clearChartData() {
    this.pendingItems.set(true);
    this.chartData.datasets = [];
    this.chart().update();
    this.chart().render();
    this.savedAssignments = [];
  }

  private setAssignmentState(assignmentState: AssignmentState) {
    this.selectedAssignmentState.set(assignmentState);
  }

  private setCurrentItemsVariables() {
    if (
      this.selectedAssignmentState() ===
      this.assignmentStateEnum.ExpiringAssignments
    ) {
      this.currentItemsFilter = this.filterObjectScheduledExpiring;
      this.currentItemsProperty.set("dueDate");
      this.currentfirstLabel.set(
        this.translationService.instant("TemplateType"),
      );
      this.currentSecondLabel.set(this.translationService.instant("Location"));
      this.currentThirdLabel.set(this.translationService.instant("Expires"));
    } else if (
      this.selectedAssignmentState() ===
      this.assignmentStateEnum.CompletedAssignments
    ) {
      this.currentItemsFilter = this.filterObjectScheduledCompleted;
      this.currentItemsProperty.set("completed");
      this.currentfirstLabel.set(
        this.translationService.instant("TemplateType"),
      );
      this.currentSecondLabel.set(this.translationService.instant("Location"));
      this.currentThirdLabel.set(this.translationService.instant("Completed"));
    } else if (
      this.selectedAssignmentState() ===
      this.assignmentStateEnum.ExpiredAssignments
    ) {
      this.currentItemsFilter = this.filterObjectScheduledExpired;
      this.currentItemsProperty.set("dueDate");
      this.currentfirstLabel.set(
        this.translationService.instant("TemplateType"),
      );
      this.currentSecondLabel.set(this.translationService.instant("Location"));
      this.currentThirdLabel.set(this.translationService.instant("Expired"));
    } else if (
      this.selectedAssignmentState() ===
      this.assignmentStateEnum.CreatedMeasures
    ) {
      this.currentItemsFilter = this.filterObjectMeasureCreated;
      this.currentItemsProperty.set("created");
      this.currentfirstLabel.set(this.translationService.instant("Location"));
      this.currentSecondLabel.set(this.translationService.instant("Entity"));
      this.currentThirdLabel.set(this.translationService.instant("Created"));
    } else if (
      this.selectedAssignmentState() ===
      this.assignmentStateEnum.ResolvedMeasures
    ) {
      this.currentItemsFilter = this.filterObjectMeasureResolved;
      this.currentItemsProperty.set("completed");
      this.currentfirstLabel.set(this.translationService.instant("Location"));
      this.currentSecondLabel.set(this.translationService.instant("Entity"));
      this.currentThirdLabel.set(this.translationService.instant("Resolved"));
    } else if (
      this.selectedAssignmentState() === this.assignmentStateEnum.ClosedMeasures
    ) {
      this.currentItemsFilter = this.filterObjectMeasureClosed;
      this.currentItemsProperty.set("completed");
      this.currentfirstLabel.set(this.translationService.instant("Location"));
      this.currentSecondLabel.set(this.translationService.instant("Entity"));
      this.currentThirdLabel.set(this.translationService.instant("Closed"));
    }
  }

  private async getItems() {
    this.pendingItems.set(true);
    try {
      let assignments = await this.assignmentService.getFilteredDashboard(
        this.currentItemsFilter,
      );
      this.pendingItems.set(false);

      if (this.selectedAssignmentState().includes("Measures")) {
        assignments = assignments.map(
          (assignment) => new MeasureAssignment(assignment),
        );
      } else {
        assignments = assignments.map(
          (assignment) => new ScheduledAssignment(assignment),
        );
      }
      if (assignments.length) {
        this.applyDataToChart(assignments);
      }
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  private applyDataToChart(assignments: Assignment[]) {
    this.chartData.datasets[0] = {
      label: this.translationService.instant(this.selectedAssignmentState()),
      backgroundColor: ColorUtils.GREEN_COLOR,
      borderColor: ColorUtils.GREEN_COLOR,
      data: [],
      minBarLength: 30,
      datalabels: {
        display: false,
      },
      borderRadius: 4,
      maxBarThickness: 30,
    };

    this.currentChartLabels.forEach((labelObject, index) => {
      let assignmentsForLabel: Assignment[] = [];

      if (this.selectedTimeSpan() === this.timeSpanEnum.ThisDay) {
        assignmentsForLabel = assignments.filter(
          (assignment) =>
            DateTime.fromSQL(assignment[this.currentItemsProperty.toString()])
              .toFormat("HH:mm")
              .split(":")[0] === labelObject.value,
        );
      } else if (this.selectedTimeSpan() === this.timeSpanEnum.ThisWeek) {
        assignmentsForLabel = assignments.filter(
          (assignment) =>
            DateTime.fromSQL(assignment[this.currentItemsProperty.toString()])
              .toFormat("YYYY-MM-DD")
              .split("-")[2] === labelObject.value,
        );
      } else if (this.selectedTimeSpan() === this.timeSpanEnum.ThisMonth) {
        assignmentsForLabel = assignments.filter(
          (assignment) =>
            DateTime.fromSQL(assignment[this.currentItemsProperty.toString()])
              .toFormat("YYYY-MM-DD")
              .split("-")[2] === labelObject.value,
        );
      } else if (this.selectedTimeSpan() === this.timeSpanEnum.ThisYear) {
        assignmentsForLabel = assignments.filter(
          (assignment) =>
            DateTime.fromSQL(assignment[this.currentItemsProperty.toString()])
              .toFormat("YYYY-MM-DD")
              .split("-")[1] === labelObject.value,
        );
      }

      if (assignmentsForLabel.length) {
        this.chartData.datasets[0].data[index] = assignmentsForLabel.length;
        this.savedAssignments[index] = assignmentsForLabel;
      }
    });
    this.chart().update();
  }

  handleChartClick({ active }: { event?: ChartEvent; active?: object[] }) {
    if (active.length) {
      const index = active[0]["index"];
      this.assignmentsToView.set(this.savedAssignments[index]);
      if (this.assignmentsToView().length) {
        this.assignmentsToView().sortByProperty("facilityName");
        this.visibleViewAssignments.set(true);
      }
    }
  }

  openInNewTab(urlToOpen: string) {
    const url = `${environment.coreUrl}/${
      this.globalState.selectedOrganization().friendlyUrl
    }/${urlToOpen}`;
    window.open(url, "_blank");
  }

  focusFilterSearchInput() {
    setTimeout(() => {
      this.filterInput().nativeElement.focus();
    });
  }

  getGreeting() {
    return DashboardUtils.getGreeting(this.translationService);
  }

  private removeTypingBorder() {
    setTimeout(() => {
      const el = document.body.querySelector(".greeting");
      if (el) {
        el.classList.add("no-border");
      }
    }, 2000);
  }

  handleCloseViewAssignments() {
    this.visibleViewAssignments.set(false);
  }

  ngOnDestroy() {
    this.viewModeSubscription.unsubscribe();
  }
}
