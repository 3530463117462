import { Injectable } from "@angular/core";
import { MeasureAssignment } from "app/components/assignment/measure-assignment";
import { ScheduledAssignment } from "app/components/assignment/scheduled-assignment";
import { Integration } from "app/components/organization/my-organization/integrations/integration";
import { Schedule } from "app/components/schedule/schedule";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";
import { Task } from "app/components/task/task";
import { UserGroup } from "app/components/user-group/user-group";
import { User } from "app/components/user/user";
import { Filter, KeyValuePair, SortDirection } from "app/filter";
import { ConversionHelper } from "app/tools/conversion-helper";
import { NumberUtils } from "app/tools/number-utils";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Utils } from "app/tools/utils";
import {
  Assignee,
  AssigneeGroup,
  Assignment,
  AssignmentPayload,
} from "../components/assignment/assignment";
import {
  ManualMeasureAssignment,
  ManualMeasurePayload,
} from "../components/assignment/manual-measure-assignment";
import { Choice } from "../components/choice/choice";
import { Entity } from "../components/entity/entity";
import { Facility } from "../components/facility/facility";
import { TemplateType } from "../components/template-type/template-type";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class AssignmentService extends BaseService<Assignment> {
  prefix = RoutesUtils.assignment;

  constructor() {
    super(Assignment);
  }

  async get(id: string): Promise<Assignment> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new Assignment(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: AssignmentPayload): Promise<Assignment> {
    try {
      const response = await this.hostedHttpClient.post(`${this.prefix}`, item);
      return new Assignment(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: AssignmentPayload): Promise<Assignment> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/${item.id}`,
        item,
      );
      return new Assignment(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(
    assignmentId: string,
    organizationId: number,
  ): Promise<Assignment> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${assignmentId}?organization=${organizationId}`,
      );
      return new Assignment(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(
    itemIds: string[],
    organizationId: number,
  ): Promise<Assignment[]> {
    try {
      const model = {
        itemIds: itemIds,
        organizationId: organizationId,
      };
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        model,
      );
      return this.extractData(response).map(
        (item: Assignment) => new Assignment(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getFilteredDashboard(filter: Filter): Promise<Assignment[]> {
    try {
      const response = await this.hostedHttpClient.getWithParams(
        `${this.prefix}/filtered/dashboard`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: Assignment) => new Assignment(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async createManualMeasure(
    manualMeasurePayload: ManualMeasurePayload,
  ): Promise<ManualMeasureAssignment> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/manualmeasure`,
        manualMeasurePayload,
      );
      return new ManualMeasureAssignment(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getAssigneeGroupsAndAssignees(
    itemIds: string[],
    organizationId: number,
  ): Promise<Assignment[]> {
    try {
      const model = {
        itemIds: itemIds,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/assignees?organization=${organizationId}`,
        model,
      );
      return this.extractData(response).map(
        (item: Assignment) => new Assignment(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateAssigneeGroupsAndAssignees(
    itemIds: string[],
    assigneeGroups: AssigneeGroup[],
    assignees: Assignee[],
    isMeasure: boolean,
  ): Promise<Assignment[]> {
    try {
      const model = {
        assignments: [],
      };

      const userIdsFromGroupsToNotify = assigneeGroups
        .filter((group) => group.notifyOnAdd)
        .flatMap((group) => group.userGroup.users)
        .filter(
          (user) => !assignees.find((assignee) => assignee.user.id === user.id),
        )
        .map((user) => user.id);

      const userIdsToNotify = assignees
        .filter((assignee) => assignee.notifyOnAdd)
        .map((assignee) => assignee.user.id);

      if (isMeasure) {
        itemIds.forEach((id) => {
          model.assignments.push({
            id: id,
            assignees: assignees.map((user) => {
              return {
                userId: user.userId,
                canPerformMeasure: user.canPerformMeasure,
              };
            }),
            assigneeGroups: assigneeGroups.map((group) => {
              return {
                userGroupId: group.userGroupId,
                canPerformMeasure: group.canPerformMeasure,
              };
            }),
            userIdsToNotify: [...userIdsFromGroupsToNotify, ...userIdsToNotify],
          });
        });
      } else {
        itemIds.forEach((id) => {
          model.assignments.push({
            id: id,
            assignees: assignees.map((user) => {
              return {
                userId: user.userId,
                canPerformMeasure: user.canPerformMeasure,
                canPerformScheduled: user.canPerformScheduled,
                canAssign: user.canAssign,
              };
            }),
            assigneeGroups: assigneeGroups.map((group) => {
              return {
                userGroupId: group.userGroupId,
                canPerformMeasure: group.canPerformMeasure,
                canPerformScheduled: group.canPerformScheduled,
                canAssign: group.canAssign,
              };
            }),
            userIdsToNotify: [...userIdsFromGroupsToNotify, ...userIdsToNotify],
          });
        });
      }
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/assignees`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async updateDueDate(
    itemIds: string[],
    date: string,
  ): Promise<ScheduledAssignment[]> {
    try {
      const model = {
        itemIds: itemIds,
        dueDate: date,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/duedate`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getPriorityData(
    itemIds: string[],
    priority: boolean,
    orgId: number,
  ): Promise<string[]> {
    try {
      const model = {
        itemIds: itemIds,
        priority: priority,
        organizationId: orgId,
      };
      const response = await this.hostedHttpClient.post(
        `${this.prefix}/priorityids`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updatePriority(
    itemIds: string[],
    priority: boolean,
    organizationId: number,
  ) {
    try {
      const model = {
        itemIds: itemIds,
        priority: priority,
        organizationId: organizationId,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/priority`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateMeasureStatus(
    itemIds: string[],
    status: string,
    notes: string,
    organizationId: number,
  ): Promise<MeasureAssignment[]> {
    try {
      const model = {
        itemIds: itemIds,
        status: status,
        notes: notes,
        organizationId: organizationId,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/assignmentstatus`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa!
  async updateAdminComment(
    itemIds: string[],
    adminComment: string,
    organizationId: number,
  ): Promise<MeasureAssignment[]> {
    try {
      const model = {
        itemIds: itemIds,
        adminComment: adminComment,
        organizationId: organizationId,
      };
      const response = await this.hostedHttpClient.put(
        `${this.prefix}/admincomment`,
        model,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getTemplateTypes(organizationId: number): Promise<TemplateType[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `templateType/organization/${organizationId}?includeManualMeasure=true`,
      );
      return this.extractData(response).map(
        (item: TemplateType) => new TemplateType(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getFacilities(): Promise<Facility[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<Facility>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `facility/filteredac`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: Facility) => new Facility(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getSchedules(organizationId: number): Promise<Schedule[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<Schedule>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `schedule?organization=${organizationId}`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map(
        (item: Schedule) => new Schedule(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getEntities(): Promise<Entity[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<Entity>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `entity/filteredac`,
        filter.toPayloadObject(),
      );
      return this.extractData(response).map((item: Entity) => new Entity(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getUserGroups(organizationId: number): Promise<UserGroup[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<UserGroup>("name")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `usergroup?organization=${organizationId}`,
        filter.toPayloadObject(),
        true,
      );
      return this.extractData(response).map(
        (item: UserGroup) => new UserGroup(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getUsers(organizationId: number): Promise<User[]> {
    try {
      const filter = new Filter();
      filter.pageSize = NumberUtils.TABLE_DATA_PAGE_SIZE_MAX_VALUE;
      filter.addSort(
        new KeyValuePair(
          Utils.uppercaseEachFirst(Utils.nameof<User>("firstname")),
          SortDirection.Ascending,
        ),
      );
      const response = await this.hostedHttpClient.getWithParams(
        `user?organization=${organizationId}`,
        filter.toPayloadObject(),
        true,
      );
      return this.extractData(response).map((item: User) => new User(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getCategories(organizationId: number): Promise<Category[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `organization/${organizationId}/category`,
      );
      return this.extractData(response).map(
        (item: Category) => new Category(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getIssueNumbers(
    organizationId: number,
    isOngoing: boolean,
  ): Promise<number[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `${this.prefix}/issueNumber?organization=${organizationId}&active=${isOngoing}`,
      );
      return this.extractData(response);
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getTasks(organizationId: number): Promise<Task[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `task?organization=${organizationId}`,
      );
      return this.extractData(response).map((item: Task) => new Task(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getChoices(organizationId: number): Promise<Choice[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `choice?organization=${organizationId}`,
      );
      return this.extractData(response).map((item: Choice) => new Choice(item));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async getIntegrations(organizationId: number): Promise<Integration[]> {
    try {
      const response = await this.hostedHttpClient.get(
        `organization/${organizationId}/webhooks`,
        true,
      );
      return this.extractData(response).map(
        (item: Integration) => new Integration(item),
      );
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  // TODO vad ger denna tillbaka? Typa! Används denna ens?!?!
  private convertNewMedia(manualMeasureData: any) {
    return new Promise((resolve, reject) => {
      const hasNewMedia = manualMeasureData.errors.filter((error: any) => {
        return error.newMedia.length;
      }).length;
      if (!hasNewMedia) {
        resolve(manualMeasureData);
      } else {
        const promises = [];
        manualMeasureData.errors
          .map((error: any) => {
            return error.newMedia;
          })
          .forEach((newMediaArray: any[]) => {
            newMediaArray.forEach((newItem: any) => {
              const promise = ConversionHelper.blobToBase64asPromise(
                newItem.data,
              ).then((result) => {
                newItem.data = result;
              });
              promises.push(promise);
            });
          });
        Promise.all(promises)
          .then((_) => {
            resolve(manualMeasureData);
          })
          .catch((response) => {
            reject(response);
          });
      }
    });
  }
}

export class AssignmentFilter extends Filter {
  context: string;

  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.TEMPLATE_TYPES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.TEMPLATE_TYPE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.TEMPLATE_TYPE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.LOCATIONS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.FACILITY_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.FACILITY_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.CATEGORIES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.CATEGORY_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.CATEGORY_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.SCHEDULES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.SCHEDULE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.SCHEDULE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.ENTITIES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.ERROR_ENTITY_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.ERROR_ENTITY_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.TASKS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.TASK_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.TASK_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.CHOICES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.CHOICE_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.CHOICE_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.PRIORITIZED_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_PRIORITY_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_PRIORITY_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_PRIORITY_KEY) {
        delete this.facets[facet.key];
      } else if (
        facet.key === StringUtils.COMPLETED_BY_KEY ||
        facet.key === StringUtils.HANDLED_BY_KEY
      ) {
        const ids = facet.value;
        tempFacets[StringUtils.COMPLETED_BY_USER_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.COMPLETED_BY_USER_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.CREATED_BY_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.CREATED_BY_USER_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.CREATED_BY_USER_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_GROUPS_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_ASSIGNEE_GROUPS_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_ASSIGNEE_GROUPS_KEY] = "false";
        }
      } else if (facet.key === StringUtils.HAS_ASSIGNEE_GROUPS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.GROUPS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.USER_GROUP_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.USER_GROUP_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.HAS_ASSIGNEES_KEY) {
        const status = facet.value;
        if (status === StringUtils.YES) {
          tempFacets[StringUtils.HAS_ASSIGNEES_KEY] = "true";
        } else if (status === StringUtils.NO) {
          tempFacets[StringUtils.HAS_ASSIGNEES_KEY] = "false";
        }
      } else if (facet.key === StringUtils.ASSIGNEES_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.USER_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.USER_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.INTEGRATIONS_KEY) {
        const ids = facet.value;
        tempFacets[StringUtils.INTEGRATION_IDS_KEY] = ids;
      } else if (facet.key === StringUtils.INTEGRATION_IDS_KEY) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.CREATED_BETWEEN_KEY) {
        const strippedKey = facet.key.replace(StringUtils.BETWEEN, "");
        const [fromDate, toDate] = facet.value.split("_");
        const fromFacet = `${StringUtils.FROM_FACET}:${strippedKey}`;
        const toFacet = `${StringUtils.TO_FACET}:${strippedKey}`;
        tempFacets[fromFacet] = fromDate;
        tempFacets[toFacet] = toDate;
      } else if (
        (!currentFacets.some(
          (f) => f.key === StringUtils.CREATED_BETWEEN_KEY,
        ) &&
          facet.key ===
            `${StringUtils.FROM_FACET}:${StringUtils.CREATED_KEY}`) ||
        facet.key === `${StringUtils.TO_FACET}:${StringUtils.CREATED_KEY}`
      ) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.EXPIRES_BETWEEN_KEY) {
        const [fromDate, toDate] = facet.value.split("_");
        const fromFacet = `${StringUtils.FROM_FACET}:${StringUtils.DUE_DATE_KEY}`;
        const toFacet = `${StringUtils.TO_FACET}:${StringUtils.DUE_DATE_KEY}`;
        tempFacets[fromFacet] = fromDate;
        tempFacets[toFacet] = toDate;
      } else if (
        (!currentFacets.some(
          (f) => f.key === StringUtils.EXPIRES_BETWEEN_KEY,
        ) &&
          facet.key ===
            `${StringUtils.FROM_FACET}:${StringUtils.DUE_DATE_KEY}`) ||
        facet.key === `${StringUtils.TO_FACET}:${StringUtils.DUE_DATE_KEY}`
      ) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.ARCHIVED_BETWEEN_KEY) {
        const strippedKey = facet.key.replace(StringUtils.BETWEEN, "");
        const [fromDate, toDate] = facet.value.split("_");
        const fromFacet = `${StringUtils.FROM_FACET}:${strippedKey}`;
        const toFacet = `${StringUtils.TO_FACET}:${strippedKey}`;
        tempFacets[fromFacet] = fromDate;
        tempFacets[toFacet] = toDate;
      } else if (
        (!currentFacets.some(
          (f) => f.key === StringUtils.ARCHIVED_BETWEEN_KEY,
        ) &&
          facet.key ===
            `${StringUtils.FROM_FACET}:${StringUtils.ARCHIVED_KEY}`) ||
        facet.key === `${StringUtils.TO_FACET}:${StringUtils.ARCHIVED_KEY}`
      ) {
        delete this.facets[facet.key];
      } else if (facet.key === StringUtils.TYPE_KEY) {
        const type = facet.value;
        if (type === StringUtils.MEASURE_ASSIGNMENT) {
          tempFacets[StringUtils.IS_MEASURE_KEY] = "true";
          delete this.facets[StringUtils.IS_MANUAL_MEASURE_KEY];
        } else if (type === StringUtils.MANUAL_MEASURE_ASSIGNMENT) {
          tempFacets[StringUtils.IS_MANUAL_MEASURE_KEY] = "true";
          delete this.facets[StringUtils.IS_MEASURE_KEY];
        }
      } else if (
        !currentFacets.some((f) => f.key === StringUtils.TYPE_KEY) &&
        (facet.key === StringUtils.IS_MEASURE_KEY ||
          facet.key === StringUtils.IS_MANUAL_MEASURE_KEY) &&
        facet.value === "true"
      ) {
        tempFacets[StringUtils.IS_MEASURE_KEY] = "true";
        tempFacets[StringUtils.IS_MANUAL_MEASURE_KEY] = "true";
      } else if (
        this.context &&
        this.context === RoutesUtils.scheduledArchived
      ) {
        if (facet.key === StringUtils.STATUS_KEY) {
          const status = facet.value;
          if (status === StringUtils.COMPLETED) {
            tempFacets[StringUtils.IS_COMPLETED_KEY] = "true";
            delete this.facets[StringUtils.IS_EXPIRED_KEY];
          } else if (status === StringUtils.EXPIRED) {
            tempFacets[StringUtils.IS_EXPIRED_KEY] = "true";
            delete this.facets[StringUtils.IS_COMPLETED_KEY];
          }
        } else if (
          !currentFacets.some((f) => f.key === StringUtils.STATUS_KEY) &&
          (facet.key === StringUtils.IS_COMPLETED_KEY ||
            facet.key === StringUtils.IS_EXPIRED_KEY) &&
          facet.value === "true"
        ) {
          tempFacets[StringUtils.IS_COMPLETED_KEY] = "true";
          tempFacets[StringUtils.IS_EXPIRED_KEY] = "true";
        }
      } else if (
        this.context &&
        this.context === RoutesUtils.scheduledOngoing
      ) {
        if (facet.key === StringUtils.STATUS_KEY) {
          const status = facet.value;
          if (status === StringUtils.STARTED) {
            tempFacets[StringUtils.IS_STARTED_KEY] = "true";
            delete this.facets[StringUtils.IS_ACTIVE_KEY];
          } else if (status === StringUtils.NOT_STARTED) {
            tempFacets[StringUtils.IS_ACTIVE_KEY] = "true";
            delete this.facets[StringUtils.IS_STARTED_KEY];
          }
        } else if (
          !currentFacets.some((f) => f.key === StringUtils.STATUS_KEY) &&
          (facet.key === StringUtils.IS_STARTED_KEY ||
            facet.key === StringUtils.IS_ACTIVE_KEY) &&
          facet.value === "true"
        ) {
          tempFacets[StringUtils.IS_STARTED_KEY] = "true";
          tempFacets[StringUtils.IS_ACTIVE_KEY] = "true";
        }
      } else if (this.context && this.context === RoutesUtils.measureArchived) {
        if (facet.key === StringUtils.STATUS_KEY) {
          const status = facet.value;
          if (status === StringUtils.COMPLETED) {
            tempFacets[StringUtils.IS_COMPLETED_KEY] = "true";
            delete this.facets[StringUtils.IS_CLOSED_KEY];
          } else if (status === StringUtils.CLOSED) {
            tempFacets[StringUtils.IS_CLOSED_KEY] = "true";
            delete this.facets[StringUtils.IS_COMPLETED_KEY];
          }
        } else if (
          !currentFacets.some((f) => f.key === StringUtils.STATUS_KEY) &&
          (facet.key === StringUtils.IS_COMPLETED_KEY ||
            facet.key === StringUtils.IS_CLOSED_KEY) &&
          facet.value === "true"
        ) {
          tempFacets[StringUtils.IS_COMPLETED_KEY] = "true";
          tempFacets[StringUtils.IS_CLOSED_KEY] = "true";
        }
      } else if (this.context && this.context === RoutesUtils.measureOngoing) {
        if (facet.key === StringUtils.STATUS_KEY) {
          const status = facet.value;
          if (status === StringUtils.STARTED) {
            tempFacets[StringUtils.IS_STARTED_KEY] = "true";
            delete this.facets[StringUtils.IS_ACTIVE_KEY];
          } else if (status === StringUtils.NOT_STARTED) {
            tempFacets[StringUtils.IS_ACTIVE_KEY] = "true";
            delete this.facets[StringUtils.IS_STARTED_KEY];
          }
        } else if (
          !currentFacets.some((f) => f.key === StringUtils.STATUS_KEY) &&
          (facet.key === StringUtils.IS_STARTED_KEY ||
            facet.key === StringUtils.IS_ACTIVE_KEY) &&
          facet.value === "true"
        ) {
          tempFacets[StringUtils.IS_STARTED_KEY] = "true";
          tempFacets[StringUtils.IS_ACTIVE_KEY] = "true";
        }
      }
    });

    Object.assign(this.facets, tempFacets);

    // Exclude context property from the filterObject.
    const { context, ...theRest } = this;

    return {
      filter: JSON.stringify(theRest),
    };
  }
}
