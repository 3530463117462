import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AppInitializer } from "app/app-initializer";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { OrganizationService } from "../../../services/organization.service";
import { Address, Organization, Phone } from "../organization";
import { DetailsOrganizationTabComponent } from "./tabs/details/details-organization-tab.component";

@Component({
  selector: "create-edit-organization",
  templateUrl: "./create-edit-organization.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsOrganizationTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditOrganizationComponent extends CreateEditBaseDirective<Organization> {
  private detailsOrganizationTabComponent = viewChild(
    DetailsOrganizationTabComponent,
  );

  private organizationService = inject(OrganizationService);
  private appInitializer = inject(AppInitializer);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        // TODO behöver ju inte hämta den såhär... gör som för edit user osv!!!!!!
        const data = await this.organizationService.get(
          parseInt(this.itemIds()[0]),
        );
        this.initialModel.set(data);
        this.pending.set(false);

        this.assignCultureSettings();

        this.modifiedModel.set(
          new Organization(Utils.getUniqueVariant(this.initialModel())),
        );
      } else {
        this.initialModel.set(
          new Organization({
            parentId: this.globalState.selectedOrganization().id,
            parentOrganization: this.globalState.selectedOrganization(),
            culture: "sv-SE",
          }),
        );
        this.pending.set(false);

        this.assignCultureSettings();

        this.modifiedModel.set(
          new Organization(Utils.getUniqueVariant(this.initialModel())),
        );
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.organizationService.update(
            this.modifiedModel().toPayloadObject(),
          )
        : await this.organizationService.create(
            this.modifiedModel().toPayloadObject(),
          );

      if (this.isEdit) {
        if (
          this.modifiedModel().emailAddress !== this.initialModel().emailAddress
        ) {
          this.modifiedModel().emailObject.emailAddress =
            this.modifiedModel().emailAddress;
          this.modifiedModel().emailObject.isDefault = true;
          await this.organizationService.updateEmail(
            this.modifiedModel().emailObject,
          );
        }
        if (
          this.modifiedModel().countryCode !==
            this.initialModel().countryCode ||
          this.modifiedModel().phoneNumber !== this.initialModel().phoneNumber
        ) {
          if (this.initialModel().phoneNumber) {
            if (this.modifiedModel().phoneNumber) {
              this.modifiedModel().phoneObject.countryCode =
                this.modifiedModel().countryCode;
              this.modifiedModel().phoneObject.number =
                this.modifiedModel().phoneNumber;
              await this.organizationService.updatePhone(
                this.modifiedModel().phoneObject,
              );
            } else {
              await this.organizationService.deletePhone(
                this.modifiedModel().phoneObject.id,
              );
            }
          } else {
            this.modifiedModel().phoneObject = new Phone({
              countryCode: this.modifiedModel().countryCode,
              number: this.modifiedModel().phoneNumber,
              organizationId: this.globalState.selectedOrganization().id,
            });
            await this.organizationService.createPhone(
              this.modifiedModel().phoneObject,
            );
          }
        }
        if (
          this.modifiedModel().streetAddress !==
            this.initialModel().streetAddress ||
          this.modifiedModel().zipCode !== this.initialModel().zipCode ||
          this.modifiedModel().city !== this.initialModel().city ||
          this.modifiedModel().country !== this.initialModel().country
        ) {
          if (this.initialModel().streetAddress) {
            if (this.modifiedModel().streetAddress) {
              this.modifiedModel().addressObject.streetAddress =
                this.modifiedModel().streetAddress;
              this.modifiedModel().addressObject.zipCode =
                this.modifiedModel().zipCode;
              this.modifiedModel().addressObject.city =
                this.modifiedModel().city;
              this.modifiedModel().addressObject.country =
                this.modifiedModel().country;
              await this.organizationService.updateAddress(
                this.modifiedModel().addressObject,
              );
            } else {
              await this.organizationService.deleteAddress(
                this.modifiedModel().addressObject.id,
              );
            }
          } else {
            this.modifiedModel().addressObject = new Address({
              streetAddress: this.modifiedModel().streetAddress,
              zipCode: this.modifiedModel().zipCode,
              city: this.modifiedModel().city,
              country: this.modifiedModel().country,
              organizationId: this.globalState.selectedOrganization().id,
            });
            await this.organizationService.createAddress(
              this.modifiedModel().addressObject,
            );
          }
        }
      }
      this.toastrService.secondary(this.getSuccessMessage("Organization"));
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsOrganizationTabComponent().setServerErrors(error.errors);
    }
  }

  private assignCultureSettings() {
    this.initialModel().selectableCountryCodes =
      this.appInitializer.cultureSettings.countryCodes;
    this.initialModel().selectableCountries =
      this.appInitializer.cultureSettings.countries;
    this.initialModel().selectableCultures =
      this.appInitializer.cultureSettings.cultures;
  }
}
