import { Component, inject, viewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CreateEditBaseDirective } from "app/components/simple-components/directives/create-edit-base.directive";
import { Utils } from "app/tools/utils";
import { TabsModule } from "primeng/tabs";
import { SimpleCrudModalComponent } from "../../../../app/components/simple-components/crud/modal/simple-crud-modal.component";
import { SimpleTabHeadingComponent } from "../../../../app/components/simple-components/crud/modal/tabs/tab-heading/simple-tab-heading.component";
import { StringUtils } from "../../../../app/tools/string-utils";
import { TemplateTypeService } from "../../../services/template-type.service";
import { TemplateType } from "../template-type";
import { DetailsTemplateTypeTabComponent } from "./tabs/details/details-template-type-tab.component";
import { HandleTasksTabComponent } from "./tabs/handle-tasks/handle-tasks-tab.component";

@Component({
  selector: "create-edit-template-type",
  templateUrl: "./create-edit-template-type.component.html",
  standalone: true,
  imports: [
    SimpleCrudModalComponent,
    SimpleTabHeadingComponent,
    DetailsTemplateTypeTabComponent,
    HandleTasksTabComponent,
    TranslateModule,
    TabsModule,
  ],
})
export class CreateEditTemplateTypeComponent extends CreateEditBaseDirective<TemplateType> {
  private detailsTemplateTypeTabComponent = viewChild(
    DetailsTemplateTypeTabComponent,
  );

  private templateTypeService = inject(TemplateTypeService);

  async initiate() {
    this.pending.set(true);
    try {
      if (this.isEdit) {
        const data = await this.templateTypeService.get(this.itemIds()[0]);
        this.initialModel.set(data);
        this.pending.set(false);

        this.modifiedModel.set(
          new TemplateType(Utils.getUniqueVariant(this.initialModel())),
        );
      } else {
        const templateBaseTypes =
          await this.templateTypeService.getTemplateBaseTypes(
            true,
            this.globalState.selectedOrganization().id,
          );

        // Abort if no template base types.
        if (!templateBaseTypes.length) {
          this.toastrService.error(
            `${this.translationService.instant(
              StringUtils.NO_TEMPLATE_BASE_TYPE_ERROR,
            )}!`,
          );
          this.handleClose();
          return;
        }
        this.pending.set(false);

        const standardTemplateBaseType = templateBaseTypes.find(
          (templateBaseType) => Utils.isStandard(templateBaseType),
        );
        this.initialModel.set(new TemplateType({}));

        this.modifiedModel.set(
          new TemplateType(Utils.getUniqueVariant(this.initialModel())),
        );

        this.initialModel().templateBaseTypeId = standardTemplateBaseType.id;
        this.initialModel().selectableTemplateBaseTypes = templateBaseTypes;

        this.modifiedModel().templateBaseTypeId = standardTemplateBaseType.id;
        this.modifiedModel().selectableTemplateBaseTypes = templateBaseTypes;
      }
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.handleClose();
    }
  }

  protected async createOrUpdate() {
    this.pending.set(true);
    try {
      const data = this.isEdit
        ? await this.templateTypeService.updateWithAffectedEntities(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          )
        : await this.templateTypeService.create(
            this.modifiedModel().toPayloadObject(
              this.globalState.selectedOrganization().id,
            ),
          );
      if (this.isEdit) {
        this.toastrService.info(this.getSuccessMessage("TemplateType"));
      } else {
        this.toastrService.success(this.getSuccessMessage("TemplateType"));
      }
      this.onRequestDone.emit([data]);
    } catch (error) {
      this.pending.set(false);
      this.toastrService.error(error.message);
      this.detailsTemplateTypeTabComponent().setServerErrors(error.errors);
    }
  }
}
