<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Location" | translate }}
          </div>
          <div class="content-value">
            <simple-facility
              [facility]="modifiedModel().facility"
            ></simple-facility>
          </div>
        </div>
        @if (modifiedModel().entities.length) {
          <div class="content">
            <div class="content-header">
              {{ "Entity" | translate }}
            </div>
            <div class="content-value">
              <simple-entity
                [entity]="modifiedModel().entities[0]"
              ></simple-entity>
            </div>
          </div>
        }
        <div class="content">
          <div class="content-header">
            {{ "IssueNumber" | translate }}
          </div>
          <div class="content-value">
            {{ modifiedModel().issueNumber }}
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Geofencing" | translate }}
          </div>
          {{
            modifiedModel().assignmentTemplate.geoControlled
              ? ("Yes" | translate)
              : ("No" | translate)
          }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Client" | translate }}
          </div>
          @if (modifiedModel().assignmentTemplate.client) {
            <div class="content-value">
              {{ modifiedModel().assignmentTemplate.client.name }}
            </div>
          } @else {
            <div class="dummy-text">
              {{ "NotSpecified" | translate }}
            </div>
          }
        </div>
        @if (
          globalState
            .loggedInUser()
            .canEdit(globalState.selectedOrganization().friendlyUrl)
        ) {
          <simple-form-select
            [label]="'Prioritized' | translate"
            [name]="propertyStrings.priority"
            [form]="form()"
            [items]="selectablePriorityItems()"
            [disabled]="
              priorityIsDisabled() ||
              !globalState
                .loggedInUser()
                .canEdit(globalState.selectedOrganization().friendlyUrl)
            "
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
          @if (choice() && priorityIsDisabled()) {
            <div class="info-text-wrapper">
              <simple-form-info
                [firstText]="'Choice' | translate"
                [linkText]="choice().value"
                [linkHref]="disabledPriorityLink()"
                [secondText]="'DisabledPriorityInfoText' | translate"
                [name]="choice().value"
              >
              </simple-form-info>
            </div>
          }

          <simple-form-input
            [label]="'AdminComment' | translate"
            [name]="propertyStrings.adminComment"
            [form]="form()"
            [maxlength]="500"
            [disabled]="
              !globalState
                .loggedInUser()
                .canEdit(globalState.selectedOrganization().friendlyUrl)
            "
            [serverErrors]="serverErrors()"
            [useTextarea]="true"
          >
          </simple-form-input>

          <simple-form-select
            [label]="'Status' | translate"
            [name]="propertyStrings.status"
            [form]="form()"
            [items]="selectableStatusItems()"
            [disabled]="
              !globalState
                .loggedInUser()
                .canEdit(globalState.selectedOrganization().friendlyUrl)
            "
            [serverErrors]="serverErrors()"
          >
          </simple-form-select>
          @if (
            modifiedModel().status !== stringUtils.ACTIVE &&
            modifiedModel().status !== stringUtils.NOT_STARTED &&
            modifiedModel().status !== stringUtils.STARTED
          ) {
            <simple-form-input
              [label]="'Message' | translate"
              [name]="propertyStrings.notes"
              [form]="form()"
              [maxlength]="500"
              [serverErrors]="serverErrors()"
              [useTextarea]="true"
            >
            </simple-form-input>
          }
        } @else {
          <div class="content">
            <div class="content-header">
              {{ "Prioritized" | translate }}
            </div>
            <div class="content-value">
              {{
                modifiedModel().errorResult.priority
                  ? ("Yes" | translate)
                  : ("No" | translate)
              }}
            </div>
          </div>
          <div class="content">
            <div class="content-header">
              {{ "AdminComment" | translate }}
            </div>
            @if (modifiedModel().adminComment) {
              <div class="content-value">
                {{ modifiedModel().adminComment }}
              </div>
            } @else {
              <div class="dummy-text">
                {{ "NotSpecified" | translate }}
              </div>
            }
          </div>
          <div class="content">
            <div class="content-header">
              {{ "Status" | translate }}
            </div>
            <div class="content-value">
              <simple-status-label
                [text]="
                  modifiedModel().status === stringUtils.COMPLETED
                    ? stringUtils.RESOLVED
                    : modifiedModel().status
                "
                [title]="
                  modifiedModel().status === stringUtils.COMPLETED
                    ? stringUtils.RESOLVED
                    : modifiedModel().status
                "
              >
              </simple-status-label>
            </div>
          </div>
        }
      </div>
      @if (modifiedModel().errorAssignment.reportUrl) {
        <div class="details-section">
          <button
            class="btn-transparent"
            [title]="'GetAssignmentReport' | translate"
            (click)="getReport()"
          >
            {{ "GetAssignmentReport" | translate }}
          </button>
        </div>
      }
    </div>
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "ErrorDescription" | translate }}
        </div>
        <div class="content">
          <div class="content-header">
            {{ "Assessment" | translate }}
          </div>
          <div class="content-value">
            <simple-assessment
              [task]="modifiedModel().errorResult.task"
              [choice]="modifiedModel().errorResult.task.choice"
              [comment]="modifiedModel().errorResult.comment"
              [media]="modifiedModel().errorResult.media"
              [displayInfoDirectly]="true"
              [infoText]="
                modifiedModel().errorAssignment.assignmentTemplate.templateType
                  .isManualMeasure
                  ? ''
                  : ('From' | translate) +
                    ' ' +
                    modifiedModel().errorAssignment.assignmentTemplate
                      .templateType.name
              "
              [url]="
                modifiedModel().errorAssignment.assignmentTemplate.templateType
                  .isManualMeasure
                  ? ''
                  : modifiedModel().errorAssignment.url
              "
              [linkText]="
                modifiedModel().errorAssignment.assignmentTemplate.templateType
                  .name
              "
            ></simple-assessment>
          </div>
        </div>
        <div class="content">
          <div class="content-header">
            {{ "CreatedBy" | translate }}
          </div>
          <div class="content-value">
            <simple-user
              [user]="
                modifiedModel().createdByUser
                  ? modifiedModel().createdByUser
                  : modifiedModel().errorAssignment.completedByUser
              "
            >
            </simple-user>
          </div>
        </div>
      </div>
    </div>
  </div>
</simple-details-tab>
