import { NgTemplateOutlet } from "@angular/common";
import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import { SimpleCrudModalComponent } from "app/components/simple-components/crud/modal/simple-crud-modal.component";
import { ListBaseDirective } from "app/components/simple-components/directives/list-base.directive";
import { SimpleListAction } from "app/components/simple-components/list/actions/simple-list-action";
import {
  SimpleTableRowActionDelete,
  SimpleTableRowActionEdit,
  SimpleTableRowActionView,
} from "app/components/simple-components/list/table/body/simple-table-row-action";
import {
  CountContent,
  CountObject,
  SimpleTableCountColumn,
} from "app/components/simple-components/list/table/columns/simple-table-count-column";
import {
  DateContent,
  SimpleTableDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-date-column";
import {
  IconContent,
  IconObject,
  SimpleTableIconColumn,
} from "app/components/simple-components/list/table/columns/simple-table-icon-column";
import {
  PublishDateContent,
  SimpleTablePublishDateColumn,
} from "app/components/simple-components/list/table/columns/simple-table-publish-date-column";
import {
  SimpleTableStatusColumn,
  StatusContent,
} from "app/components/simple-components/list/table/columns/simple-table-status-column";
import {
  SimpleTableTextColumn,
  TextContent,
} from "app/components/simple-components/list/table/columns/simple-table-text-column";
import {
  SimpleTableTranslatedTextColumn,
  TranslatedTextContent,
} from "app/components/simple-components/list/table/columns/simple-table-translated-text-column";
import { SimpleTableEmptyState } from "app/components/simple-components/list/table/empty-state/simple-table-empty-state";
import {
  SimpleFilterInput,
  SimpleFilterInputType,
} from "app/components/simple-components/list/table/filter/simple-filter-input";
import { SimpleFilterInputItem } from "app/components/simple-components/list/table/filter/simple-filter-input-item";
import { SortObject } from "app/components/simple-components/list/table/filter/sort-object";
import {
  SimpleTableHeaderAction,
  SimpleTableHeaderActionDelete,
} from "app/components/simple-components/list/table/head/simple-table-header-action";
import { SimpleTableComponent } from "app/components/simple-components/list/table/simple-table.component";
import { SimpleViewItemsComponent } from "app/components/simple-components/various/view-items/simple-view-items.component";
import { KeyValuePair } from "app/filter";
import { SwalConfig } from "app/swal/swal-config.component";
import { StringUtils } from "app/tools/string-utils";
import Swal from "sweetalert2";
import {
  ScheduleFilter,
  ScheduleService,
} from "../../../services/schedule.service";
import { ListHeaderComponent } from "../../header/list-header/list-header.component";
import { SimpleViewConnectionsComponent } from "../../simple-components/various/view-connections/simple-view-connections.component";
import { SimpleViewTimelineComponent } from "../../simple-components/various/view-timeline/simple-view-timeline.component";
import { CreateEditScheduleComponent } from "../create-edit-schedule/create-edit-schedule.component";
import {
  PublishingType,
  Schedule,
  ScheduleGroup,
  ScheduleUser,
} from "../schedule";
import { ChangeStatusScheduleComponent } from "./change-status/change-status-schedule.component";
import { HandleScheduleGroupsAndScheduleUsersComponent } from "./handle-schedule-groups-and-schedule-users/handle-schedule-groups-and-schedule-users.component";

@Component({
  selector: "list-schedule",
  templateUrl: "./list-schedule.component.html",
  standalone: true,
  imports: [
    CreateEditScheduleComponent,
    SimpleTableComponent,
    SimpleCrudModalComponent,
    TranslateModule,
    HandleScheduleGroupsAndScheduleUsersComponent,
    ChangeStatusScheduleComponent,
    NgTemplateOutlet,
    ListHeaderComponent,
    SimpleViewItemsComponent,
    SimpleViewConnectionsComponent,
    SimpleViewTimelineComponent,
  ],
})
export class ListScheduleComponent extends ListBaseDirective<Schedule> {
  readonly filterObject = new ScheduleFilter();

  displayNewButton = signal<boolean>(false);

  visibleHandleScheduleGroupsAndScheduleUsers = signal<boolean>(false);
  visibleChangeStatus = signal<boolean>(false);
  timeline = signal<Schedule>(new Schedule({}));
  assignmentTemplates = signal<AssignmentTemplate[]>([]);
  scheduleGroups = signal<ScheduleGroup[]>([]);
  scheduleUsers = signal<ScheduleUser[]>([]);
  visibleViewTimeline = signal<boolean>(false);
  visibleViewItems = signal<boolean>(false);

  constructor(private scheduleService: ScheduleService) {
    super(scheduleService);
  }

  protected configureListActions() {
    this.listActions.set([
      new SimpleListAction(
        "Create",
        "NewSchedule",
        StringUtils.icons.new,
        () => this.visibleCreateEdit.set(true),
        () => !this.displayNewButton() || this.isInModal(),
      ),
    ]);
  }

  protected async configureTableFilter() {
    const assignmentTemplatesKey = StringUtils.ASSIGNMENT_TEMPLATES_KEY;
    const groupsKey = StringUtils.GROUPS_KEY;
    const assigneesKey = StringUtils.ASSIGNEES_KEY;
    const createdKey = StringUtils.CREATED_KEY;
    const updatedKey = StringUtils.UPDATED_KEY;
    const statusKey = StringUtils.STATUS_KEY;
    const hasAssignmentTemplatesKey = StringUtils.HAS_ASSIGNMENT_TEMPLATES_KEY;
    const hasGroupsKey = StringUtils.HAS_GROUPS_KEY;
    const hasAssigneesKey = StringUtils.HAS_ASSIGNEES_KEY;
    if (!this.isInModal()) {
      this.retainService.setCurrentRetainEntries({
        search: null,
        sort: null,
        [assignmentTemplatesKey]: null,
        [groupsKey]: null,
        [assigneesKey]: null,
        [createdKey]: null,
        [updatedKey]: null,
        [statusKey]: null,
        [hasAssignmentTemplatesKey]: null,
        [hasGroupsKey]: null,
        [hasAssigneesKey]: null,
      });
    }

    try {
      const [assignmentTemplates, userGroups, users] = await Promise.all([
        this.scheduleService.getAssignmentTemplates(
          this.globalState.selectedOrganization().id,
        ),
        this.scheduleService.getUserGroups(
          this.globalState.selectedOrganization().id,
        ),
        this.scheduleService.getUsers(
          this.globalState.selectedOrganization().id,
        ),
      ]);

      const convertedAssignmentTemplates = assignmentTemplates.map(
        (assignmentTemplate) =>
          new SimpleFilterInputItem(
            assignmentTemplate.id,
            assignmentTemplate.name,
          ),
      );
      const convertedUserGroups = userGroups.map(
        (userGroup) => new SimpleFilterInputItem(userGroup.id, userGroup.name),
      );
      const convertedUsers = users.map(
        (user) =>
          new SimpleFilterInputItem(
            user.id,
            user.fullName ? user.fullName : user.email,
          ),
      );

      this.displayNewButton.set(!!assignmentTemplates.length);

      this.filterInputs.set([
        new SimpleFilterInput(
          assignmentTemplatesKey,
          assignmentTemplatesKey,
          StringUtils.icons.assignmentTemplate,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(
                new KeyValuePair(assignmentTemplatesKey, values),
              );
            } else {
              keyValuePairs.push(new KeyValuePair(assignmentTemplatesKey, ""));
            }
            return keyValuePairs;
          },
          convertedAssignmentTemplates,
        ),
        new SimpleFilterInput(
          hasAssignmentTemplatesKey,
          hasAssignmentTemplatesKey,
          StringUtils.icons.assignmentTemplate,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(
                new KeyValuePair(hasAssignmentTemplatesKey, values),
              );
            } else {
              keyValuePairs.push(
                new KeyValuePair(hasAssignmentTemplatesKey, ""),
              );
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          groupsKey,
          groupsKey,
          StringUtils.icons.group,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(groupsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(groupsKey, ""));
            }
            return keyValuePairs;
          },
          convertedUserGroups,
        ),
        new SimpleFilterInput(
          hasGroupsKey,
          hasGroupsKey,
          StringUtils.icons.group,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasGroupsKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasGroupsKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          assigneesKey,
          assigneesKey,
          StringUtils.icons.user,
          SimpleFilterInputType.MultiSelectWithSearch,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(assigneesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(assigneesKey, ""));
            }
            return keyValuePairs;
          },
          convertedUsers,
        ),
        new SimpleFilterInput(
          hasAssigneesKey,
          hasAssigneesKey,
          StringUtils.icons.user,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(hasAssigneesKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(hasAssigneesKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.YES, StringUtils.YES),
            new SimpleFilterInputItem(StringUtils.NO, StringUtils.NO),
          ],
        ),
        new SimpleFilterInput(
          createdKey,
          createdKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(createdKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(createdKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          updatedKey,
          updatedKey,
          StringUtils.icons.date,
          SimpleFilterInputType.DateSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(updatedKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(updatedKey, ""));
            }
            return keyValuePairs;
          },
        ),
        new SimpleFilterInput(
          statusKey,
          statusKey,
          StringUtils.icons.setStatus,
          SimpleFilterInputType.SingleSelect,
          (values: string) => {
            const keyValuePairs: KeyValuePair[] = [];
            if (values) {
              keyValuePairs.push(new KeyValuePair(statusKey, values));
            } else {
              keyValuePairs.push(new KeyValuePair(statusKey, ""));
            }
            return keyValuePairs;
          },
          [
            new SimpleFilterInputItem(StringUtils.ACTIVE, StringUtils.ACTIVE),
            new SimpleFilterInputItem(
              StringUtils.INACTIVE,
              StringUtils.INACTIVE,
            ),
          ],
        ),
      ]);
    } catch (error) {
      this.toastrService.error(error.message);
    }
  }

  protected configureTableSort() {
    this.sortObjects.set([
      new SortObject(this.propertyStrings.name, "Name", true),
      new SortObject(this.propertyStrings.created, "Created"),
      new SortObject(this.propertyStrings.updated, "Updated"),
      new SortObject(this.propertyStrings.active, "Status"),
    ]);
  }

  protected configureTableColumns() {
    this.columns.set([
      new SimpleTableTextColumn(["Name"], (row) => new TextContent(row.name)),
      new SimpleTableTranslatedTextColumn(
        ["Interval", "Weekdays"],
        (row) =>
          new TranslatedTextContent(
            row.publishingType === PublishingType.Once
              ? ""
              : StringUtils.getIntervalText(row.interval, row.publishingType),
            row.publishingType === PublishingType.Weekly
              ? StringUtils.getWeekdayText(row.weekdaysInternal, "long")
              : "",
          ),
      ),
      new SimpleTablePublishDateColumn(
        ["Publishes"],
        (row) => new PublishDateContent(row.nextPublishDate, row.active),
      ),
      new SimpleTableIconColumn(
        [],
        (row) =>
          new IconContent([
            new IconObject(
              row.endDate ? StringUtils.icons.endDate : "",
              "EndDate",
              row.endDate,
              false,
              true,
            ),
            new IconObject(
              row.isSingleOccurrence ? StringUtils.icons.oneTimePublish : "",
              "OneTimePublish",
              "OneTimePublishInfoText",
              true,
            ),
          ]),
      ),
      new SimpleTableCountColumn(
        ["Connections"],
        (row) =>
          new CountContent([
            new CountObject(
              StringUtils.icons.assignmentTemplate,
              row.assignmentTemplates.length,
              "AssignmentTemplates",
            ),
            new CountObject(
              StringUtils.icons.group,
              row.scheduleGroups.length,
              "Groups",
            ),
            new CountObject(
              StringUtils.icons.user,
              row.scheduleUsers.length,
              "Assignees",
            ),
          ]),
      ),
      new SimpleTableDateColumn(
        ["Created", "Updated"],
        (row) => new DateContent(row.created, row.updated),
      ),
      new SimpleTableStatusColumn(
        ["Status"],
        (row) =>
          new StatusContent(
            row.active ? StringUtils.ACTIVE : StringUtils.INACTIVE,
          ),
      ),
    ]);
  }

  protected configureTableActions() {
    // HEADER
    this.headerActions.set([
      new SimpleTableHeaderAction(
        "HandleGroupsAndAssignees",
        StringUtils.icons.group,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleHandleScheduleGroupsAndScheduleUsers.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderAction(
        "ChangeStatus",
        StringUtils.icons.setStatus,
        () => {
          this.itemIds.set(this.selectedIds());
          this.visibleChangeStatus.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableHeaderActionDelete(
        "Delete",
        StringUtils.icons.delete,
        () => this.delete(this.selectedIds()),
        () => this.isInModal(),
      ),
    ]);

    // ROW
    this.rowActions.set([
      new SimpleTableRowActionEdit(
        "Open",
        StringUtils.icons.open,
        (row) => {
          this.itemIds.set([row.id]);
          this.visibleCreateEdit.set(true);
        },
        () => this.isInModal(),
      ),
      new SimpleTableRowActionView(
        "ViewPreview",
        StringUtils.icons.preview,
        (row) => {
          this.timeline.set(row);
          this.visibleViewTimeline.set(true);
        },
        (row) => !row.active,
      ),
      new SimpleTableRowActionView(
        "ViewConnections",
        StringUtils.icons.connections,
        (row) => {
          this.assignmentTemplates.set(row.assignmentTemplates);
          this.scheduleGroups.set(row.scheduleGroups);
          this.scheduleUsers.set(row.scheduleUsers);
          this.visibleViewItems.set(true);
        },
        (row) =>
          !row.assignmentTemplates.length &&
          !row.scheduleGroups.length &&
          !row.scheduleUsers.length,
      ),
      new SimpleTableRowActionDelete(
        "Delete",
        StringUtils.icons.delete,
        (row) => this.delete([row.id]),
        () => this.isInModal(),
      ),
    ]);
  }

  protected configureTableEmptyState() {
    this.emptyState.set(
      new SimpleTableEmptyState("Schedules", StringUtils.icons.schedule),
    );
  }

  private delete(ids: string[]) {
    Swal.fire(
      new SwalConfig(this.translationService).getDelete({
        title:
          ids.length === 1
            ? this.translationService.instant("DeleteSchedule")
            : this.translationService.instant("DeleteSchedules"),
      }),
    ).then(async (result) => {
      if (result.value) {
        this.handleCloseCreateEdit();
        this.itemIds.set(ids);
        this.pending.set(true);
        try {
          const data = await this.scheduleService.deleteRange(this.itemIds());
          this.pending.set(false);
          this.toastrService.secondary(
            this.getSuccessMessageDelete("Schedule", "Schedules"),
          );
          this.selectedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.unselectableIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.addedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.modifiedIds.update((ids) =>
            ids.filter((id) => !this.itemIds().includes(id)),
          );
          this.itemIds.set([]);
          this.getTableData();
        } catch (error) {
          this.pending.set(false);
          this.toastrService.error(error.message);
        }
      }
    });
  }

  handleDelete() {
    this.delete(this.itemIds());
  }

  handleCloseHandleScheduleGroupsAndScheduleUsers() {
    this.visibleHandleScheduleGroupsAndScheduleUsers.set(false);
    this.itemIds.set([]);
  }

  handleCloseChangeStatus() {
    this.visibleChangeStatus.set(false);
    this.itemIds.set([]);
  }

  async handleRequestDoneHandleScheduleGroupsAndScheduleUsers(
    data: Schedule[],
  ) {
    this.handleCloseHandleScheduleGroupsAndScheduleUsers();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }

  async handleRequestDoneChangeStatus(data: Schedule[]) {
    this.handleCloseChangeStatus();
    await this.getTableDataAndScrollToItem(data[0].id);
    this.clearSelectedRows();
  }
}
