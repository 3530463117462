import { Injectable } from "@angular/core";
import { Filter } from "app/filter";
import { RoutesUtils } from "app/tools/routes-utils";
import { StringUtils } from "app/tools/string-utils";
import { Task, TaskPayload } from "../components/task/task";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class TaskService extends BaseService<Task> {
  prefix = RoutesUtils.task;

  constructor() {
    super(Task);
  }

  async get(id: string): Promise<Task> {
    try {
      const response = await this.hostedHttpClient.get(`${this.prefix}/${id}`);
      return new Task(this.extractData(response));
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async create(item: TaskPayload, organizationId: number): Promise<Task> {
    try {
      const response = await this.hostedHttpClient.post(
        `${this.prefix}?organization=${organizationId}`,
        [item],
      );
      return this.extractData(response)["created"].map(
        (data: Task) => new Task(data),
      )[0];
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async update(item: TaskPayload, organizationId: number): Promise<Task> {
    try {
      const response = await this.hostedHttpClient.put(
        `${this.prefix}?organization=${organizationId}`,
        [item],
      );
      return this.extractData(response).map((data: Task) => new Task(data))[0];
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.delete(
        `${this.prefix}/${id}`,
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }

  async deleteRange(itemIds: string[]): Promise<boolean> {
    try {
      const response = await this.hostedHttpClient.deleteWithBody(
        `${this.prefix}`,
        { itemIds: itemIds },
      );
      return true;
    } catch (errorResponse) {
      throw this.extractError(errorResponse);
    }
  }
}

export class TaskFilter extends Filter {
  includeChoices: boolean;

  toPayloadObject() {
    const tempFacets = {};
    const currentFacets = (
      Object.entries(this.facets) as [string, string][]
    ).map((facet) => {
      return {
        key: facet[0],
        value: facet[1],
      };
    });

    currentFacets.forEach((facet) => {
      if (facet.key === StringUtils.RULES_KEY) {
        const rules = facet.value.split(",");
        rules.forEach((rule) => {
          if (rule === StringUtils.IS_REQUIRED) {
            tempFacets[StringUtils.IS_REQUIRED_KEY] = "true";
          } else if (rule === StringUtils.ALLOWS_COMMENT) {
            tempFacets[StringUtils.ALLOW_COMMENT_KEY] = "true";
          } else if (rule === StringUtils.ALLOWS_MEDIA) {
            tempFacets[StringUtils.ALLOW_MEDIA_KEY] = "true";
          } else if (rule === StringUtils.IS_DEFAULT) {
            tempFacets[StringUtils.IS_DEFAULT_KEY] = "true";
          }
        });
      } else if (
        facet.key === StringUtils.IS_REQUIRED_KEY ||
        facet.key === StringUtils.ALLOW_COMMENT_KEY ||
        facet.key === StringUtils.ALLOW_MEDIA_KEY ||
        facet.key === StringUtils.IS_DEFAULT_KEY
      ) {
        delete this.facets[facet.key];
      }
    });

    Object.assign(this.facets, tempFacets);

    // Exclude includeChoices property from the filterObject.
    const { includeChoices, ...theRest } = this;

    if (this.includeChoices) {
      return {
        filter: JSON.stringify(theRest),
        includeChoices: this.includeChoices,
      };
    } else {
      return {
        filter: JSON.stringify(theRest),
      };
    }
  }
}
