<p-dialog
  [modal]="true"
  [visible]="visible()"
  [closable]="false"
  [draggable]="false"
  [appendTo]="'body'"
  [focusOnShow]="false"
  styleClass="{{
    isLoading()
      ? large()
        ? 'loadmask large'
        : 'loadmask'
      : large()
        ? 'large'
        : ''
  }}"
>
  <ng-template #header>
    <div class="modal-header">
      @if (onlyHeaderText()) {
        <div class="title" [title]="headerText()">{{ headerText() }}</div>
      } @else {
        <div
          class="title"
          [title]="
            context()
              ? (context() | translate) + ' - ' + headerText()
              : headerText()
          "
        >
          {{ context() | translate }}
          @if (context()) {
            -
          }
          {{ headerText() }}
        </div>
      }
      <div class="actions">
        <feedback [isInModal]="true"></feedback>
        <help [isInModal]="true"></help>
        @if (!hideCloseButton()) {
          <div
            class="close-button bi bi-x-lg"
            title="{{ closeText() }}"
            (click)="handleClose()"
          ></div>
        }
      </div>
    </div>
  </ng-template>

  <ng-content></ng-content>

  <ng-template #footer>
    <div class="modal-footer">
      @if (!hideCloseButton()) {
        <button
          type="button"
          class="btn btn-transparent"
          title="{{ closeText() }}"
          (click)="handleClose()"
        >
          {{ closeText() }}
        </button>
      }
      @if (displayDeleteButton()) {
        <div
          class="delete bi-trash"
          title="{{ 'Delete' | translate }}"
          (click)="handleDelete()"
        ></div>
      }
      @if (!hideSubmitButton()) {
        <button
          type="button"
          class="btn btn-success submit"
          title="{{ submitText() }}"
          [disabled]="disableSubmit()"
          (click)="handleSubmit()"
        >
          {{ submitText() }}
        </button>
      }
    </div>
  </ng-template>
  @if (isLoading()) {
    <dots-loader></dots-loader>
  }
</p-dialog>
