import { Component, signal } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentTemplate } from "app/components/assignment-template/assignment-template";
import {
  FormSelectItem,
  SimpleFormSelectComponent,
} from "app/components/simple-components/crud/modal/tabs/details/form/form-select/simple-form-select.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { Category } from "app/components/simple-components/various/categories/simple-categories.component";

@Component({
  selector: "details-categories-assignment-template-tab",
  templateUrl: "./details-categories-assignment-template-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormSelectComponent,
    TranslateModule,
  ],
})
export class DetailsCategoriesAssignmentTemplateTabComponent extends DetailsBaseDirective<AssignmentTemplate> {
  selectableCategoryItems = signal<FormSelectItem[]>([]);

  ngOnInit() {
    this.setSelectableCategoryItems();

    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.categoryIds]: [this.modifiedModel().categoryIds],
      }),
    );
    super.ngOnInit();
  }

  private setSelectableCategoryItems() {
    this.selectableCategoryItems.set(
      this.modifiedModel().selectableCategories.map(
        (category) => new FormSelectItem(category.id, category.name),
      ),
    );
  }

  handleAddNewItemInSelect(item: FormSelectItem) {
    const category = new Category({
      id: item.id,
      name: item.name,
    });
    this.modifiedModel().selectableCategories.push(category);
  }
}
