<div class="facility">
  <simple-image [url]="facility().thumbnailUrl"></simple-image>
  <div class="text-wrapper">
    <div class="name" [title]="facility().name">
      {{ facility().name }}
    </div>
    <div class="description" [title]="facility().description">
      {{ facility().description }}
    </div>
  </div>
</div>
