import { Component } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { Assignment } from "app/components/assignment/assignment";
import { SimpleFormCalendarComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-calendar/simple-form-calendar.component";
import { SimpleFormTimeComponent } from "app/components/simple-components/crud/modal/tabs/details/form/form-time/simple-form-time.component";
import { SimpleDetailsTabComponent } from "app/components/simple-components/crud/modal/tabs/details/simple-details-tab.component";
import { DetailsBaseDirective } from "app/components/simple-components/directives/details-base.directive";
import { RegexUtils } from "app/tools/regex-utils";
import { DateTime } from "luxon";

@Component({
  selector: "details-due-date-assignment-tab",
  templateUrl: "./details-due-date-assignment-tab.component.html",
  standalone: true,
  imports: [
    SimpleDetailsTabComponent,
    SimpleFormCalendarComponent,
    SimpleFormTimeComponent,
    TranslateModule,
  ],
})
export class DetailsDueDateAssignmentTabComponent extends DetailsBaseDirective<Assignment> {
  get minDueDate() {
    return DateTime.now().toSQLDate();
  }

  ngOnInit() {
    this.form.set(
      this.formBuilder.group({
        [this.propertyStrings.dueDate]: [
          this.modifiedModel().dueDate,
          [Validators.required, this.dateValidator],
        ],
        [this.propertyStrings.dueTime]: [
          this.modifiedModel().dueTime,
          [Validators.required, this.timeValidator],
        ],
      }),
    );
    super.ngOnInit();
  }

  private dateValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.DATE).test(control.value)) {
      return { date: true };
    } else {
      return null;
    }
  }

  private timeValidator(control: AbstractControl) {
    if (control.value && !new RegExp(RegexUtils.TIME).test(control.value)) {
      return { time: true };
    } else {
      return null;
    }
  }
}
