@if (!isInModal()) {
  <ng-container *ngTemplateOutlet="tableContent"></ng-container>
}
@if (isInModal()) {
  <simple-crud-modal
    [visible]="visible()"
    [headerText]="'AddAssignmentTemplates' | translate"
    [closeText]="'Close' | translate"
    [submitText]="'ConfirmSelected' | translate"
    [large]="true"
    (onClose)="handleClose()"
    (onSubmit)="handleConfirm()"
  >
    <ng-container *ngTemplateOutlet="tableContent"></ng-container>
  </simple-crud-modal>
}

<ng-template #tableContent>
  <list-header
    [emptyState]="emptyState()"
    [listActions]="listActions()"
    [columns]="columns()"
    [inModal]="isInModal()"
    (onCustomColumnConfigChange)="handleCustomColumnConfigChange($event)"
  ></list-header>
  <simple-table
    [initialFacets]="initialFacets()"
    [searchValue]="searchValue()"
    [filterInputs]="filterInputs()"
    [sortObjects]="sortObjects()"
    [currentSortObject]="currentSortObject()"
    [columns]="columns()"
    [headerActions]="headerActions()"
    [rowActions]="rowActions()"
    [emptyState]="emptyState()"
    [filteredIds]="filteredIds()"
    [rows]="items()"
    [selectedIds]="selectedIds()"
    [addedIds]="addedIds()"
    [modifiedIds]="modifiedIds()"
    [unselectableIds]="unselectableIds()"
    [pending]="pending()"
    [inModal]="isInModal()"
    [totalCount]="totalCount"
    [selectedCount]="selectedCount"
    [hasSelectableData]="hasSelectableData"
    [someAreSelected]="someAreSelected"
    [allAreSelected]="allAreSelected"
    [hasActiveSearchOrFilter]="hasActiveSearchOrFilter"
    (onSearch)="handleSearch($event)"
    (onSearchClear)="handleSearchClear()"
    (onSortDirectionChange)="handleSortDirectionChange($event)"
    (onSortLabelChange)="handleSortLabelChange($event)"
    (onFilter)="handleFilter($event)"
    (onFilterClear)="handleFilterClear()"
    (onSelectedClear)="handleSelectedClear()"
    (onCheckboxClick)="handleCheckboxClick($event)"
    (onRowSingleClick)="handleRowSingleClick($event)"
    (onRowDoubleClick)="handleRowDoubleClick($event)"
    (onRowCheckboxClick)="handleRowCheckboxClick($event)"
    (onLazyLoad)="handleLazyLoad($event)"
  >
  </simple-table>

  <create-edit-assignment-template
    [visible]="visibleCreateEdit()"
    [itemIds]="itemIds()"
    [useUnsavedChangesLogic]="!isInModal()"
    [displayDeleteButton]="!!itemIds().length"
    (onClose)="handleCloseCreateEdit()"
    (onDelete)="handleDelete()"
    (onRequestDone)="handleRequestDoneCreateEdit($event)"
  ></create-edit-assignment-template>

  <change-categories
    [visible]="visibleChangeCategories()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseHandleCategories()"
    (onRequestDone)="handleRequestDoneHandleCategories($event)"
  >
  </change-categories>

  <change-client
    [visible]="visibleChangeClient()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseHandleClient()"
    (onRequestDone)="handleRequestDoneHandleClient($event)"
  >
  </change-client>

  <change-geo-fencing
    [visible]="visibleChangeGeoFencing()"
    [itemIds]="itemIds()"
    (onClose)="handleCloseChangeGeoFencing()"
    (onRequestDone)="handleRequestDoneChangeGeoFencing($event)"
  >
  </change-geo-fencing>

  <simple-view-client
    [(visible)]="visibleViewClient"
    [headerText]="'Client' | translate"
    [client]="client()"
    (onClose)="visibleViewClient.set(false)"
  >
  </simple-view-client>

  <simple-view-items
    [(visible)]="visibleViewItems"
    (onClose)="visibleViewItems.set(false)"
  >
    @if (schedules().length) {
      <div>
        <div class="sub-label">
          {{ "Schedules" | translate }}
        </div>
        <simple-view-connections [schedules]="schedules()">
        </simple-view-connections>
      </div>
    }
  </simple-view-items>
</ng-template>
