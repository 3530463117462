<simple-details-tab [form]="form()">
  <div class="details-section-layout-wrapper">
    <div class="details-section-layout">
      <div class="details-section">
        <div class="details-section-header">
          {{ "General" | translate }}
        </div>
        <simple-form-select
          [label]="'Client' | translate"
          [name]="propertyStrings.clientId"
          [form]="form()"
          [items]="selectableClientItems()"
          [multiple]="false"
          [searchable]="true"
          [serverErrors]="serverErrors()"
          [disabled]="clientsIsDisabled()"
        >
        </simple-form-select>

        @if (clientsIsDisabled() || !modifiedModel().clientId) {
          <div
            class="info-text-wrapper"
            [ngClass]="{ 'place-end': !clientsIsDisabled() }"
          >
            @if (clientsIsDisabled()) {
              <simple-form-info
                [firstText]="'Organization' | translate"
                [linkText]="globalState.selectedOrganization().orgName"
                [linkHref]="disabledClientLink()"
                [secondText]="'DisabledClientInfoText' | translate"
                [name]="globalState.selectedOrganization().orgName"
              >
              </simple-form-info>
            }
            <simple-form-info
              [firstText]="'*' + ('EmptyClientInfoText' | translate)"
            >
            </simple-form-info>
          </div>
        }
      </div>
    </div>
  </div>
</simple-details-tab>
